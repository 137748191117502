import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useId, useState, useEffect } from 'react';

import {
	Table,
	useAxios,
	TableRow,
	DataCell,
	Container,
	TableHead,
	TableBody,
	EmptyTable,
	HeaderCell,
	TableLoading,
	ContentWrapper,
	ContentSection,
	ArrowLinkButton,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ClinicData,
	Pagination,
	formatDate,
	ORGANIZATIONS,
	NonClinicData,
	PaginationState,
	getSampleStatuses,
	getLabelFromValue,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';
import TableSearchFiltersForm from '../../../components/tables/accessioning/table-search-filters-form';

const projectedFields = [
	'type',
	'sample.status',
	'sample.ruo',
	'sample.priority',
	'customer.first_name',
	'customer.last_name',
	'organization.name',
	'sample.pangea_id',
	'sample.specimen_id',
	'sample.collection_kit_id',
	'patient.first_name',
	'patient.last_name',
	'patient.date_of_birth',
	'sample.sample_received_date',
	'requisition_form.billing_verified',
	'requisition_form.sample_collection_date',
	'sample.sample_collection_date',
	'metadata.date_created',
	'sample.accessioning_approval.approve.value',
	'sample.accessioning_approval.approve.metadata.date',
];

/* eslint-disable-next-line */
export interface ViewSamplesProps {}

export default function ViewSamples(props: ViewSamplesProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [query, setQuery] = useState({});
	const [data, setData] = useState<
		(ClinicData | NonClinicData)[] | undefined
	>(undefined);
	const [disabled, setDisabled] = useState(false);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setData(undefined);
				setDisabled(true);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/accessioner/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query,
						projected_fields: projectedFields,
					}
				);

				if (!unmounted) {
					const samples = JSON.parse(data);
					console.log(samples);

					setData(samples);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);

			toast.dismiss();
		};

		if (!unmounted) fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	return (
		<Container>
			<ContentWrapper Icon="FingerPrintIcon" heading="All Samples">
				<TableSearchFiltersForm
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection
					heading="All samples"
					description="The listed patient information is what was provided on the requisition form."
				>
					<AllSamplesTable
						data={data}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}

interface AllSamplesTableProps {
	pagination: PaginationState;
	data: (ClinicData | NonClinicData)[] | undefined;
}

function AllSamplesTable(props: AllSamplesTableProps) {
	const navigate = useNavigate();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Status</HeaderCell>
				<HeaderCell>Priority Level</HeaderCell>
				<HeaderCell>RUO</HeaderCell>
				<HeaderCell>Entity</HeaderCell>
				<HeaderCell>Customer</HeaderCell>
				<HeaderCell>Organization</HeaderCell>
				<HeaderCell>Pangea ID</HeaderCell>
				<HeaderCell>Specimen ID</HeaderCell>
				<HeaderCell>Collection Kit ID</HeaderCell>
				<HeaderCell>Patient First Name</HeaderCell>
				<HeaderCell>Patient Last Name</HeaderCell>
				<HeaderCell>Patient Date of Birth</HeaderCell>
				<HeaderCell>Received Date</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
				<HeaderCell>Approved Date</HeaderCell>
				<HeaderCell>Created Date</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => {
							const clinicSample = datum.type === 'clinic';

							return (
								<TableRow key={index} index={index}>
									<DataCell>
										{getLabelFromValue(
											datum.sample.status,
											getSampleStatuses()
										)}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.priority,
											ORGANIZATIONS.priorityLevels
										)}
									</DataCell>
									<DataCell>
										{datum.sample.ruo === null
											? null
											: datum.sample.ruo
											? 'Yes'
											: 'No'}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.type,
											ORGANIZATIONS.types
										)}
									</DataCell>
									<DataCell>
										{datum.customer.first_name.concat(
											' ',
											datum.customer.last_name
										)}
									</DataCell>
									<DataCell>
										{datum.organization.name}
									</DataCell>
									<DataCell>
										<ArrowLinkButton
											text={datum.sample.pangea_id}
											onClick={() =>
												navigate(
													`/accessioner/view/samples/${datum._id.$oid}`
												)
											}
										/>
									</DataCell>
									<DataCell>
										{datum.sample.specimen_id}
									</DataCell>
									<DataCell>
										{datum.sample.collection_kit_id}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.first_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.last_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? formatDate(
													datum.patient.date_of_birth
											  )
											: null}
									</DataCell>
									<DataCell>
										{formatDate(
											datum.sample.sample_received_date
												? datum.sample
														.sample_received_date
												: undefined
										)}
									</DataCell>
									<DataCell>
										{clinicSample
											? formatDate(
													datum.requisition_form
														.sample_collection_date
											  )
											: formatDate(
													datum.sample
														.sample_collection_date
											  )}
									</DataCell>
									<DataCell>
										{datum.sample.accessioning_approval
											.approve.value
											? formatDate(
													datum.sample
														.accessioning_approval
														.approve.metadata.date
														? datum.sample
																.accessioning_approval
																.approve
																.metadata.date
																.$date
														: undefined
											  )
											: null}
									</DataCell>
									<DataCell>
										{formatDate(
											datum.metadata.date_created.$date
										)}
									</DataCell>
								</TableRow>
							);
						})
					) : (
						<EmptyTable
							heading="No samples"
							Icon="FingerPrintIcon"
							description="Start by creating a sample"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
