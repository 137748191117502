/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useParams, useSearchParams } from 'react-router-dom';
import {
	useId,
	useRef,
	Dispatch,
	useState,
	useEffect,
	createContext,
	SetStateAction,
	MutableRefObject,
	ReactNode,
} from 'react';

import { NonClinicData } from '@pangea-lis-apps/utils';
import {
	useAxios,
	LoadingBox,
	Container,
	ContentSection,
} from '@pangea-lis-apps/ui';

export interface ProcessNonClinicSamplesContextType {
	formValues: any;
	setFormValues: Dispatch<SetStateAction<any>>;
	refresh: boolean;
	disabled: boolean;
	redirectPathQuery: string;
	data: NonClinicData | undefined;
	formValuesRef: MutableRefObject<any>;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	setDisabled: Dispatch<SetStateAction<boolean>>;
	setData: Dispatch<SetStateAction<NonClinicData | undefined>>;
	canUpdateSampleInfo: boolean;
}

export const ProcessNonClinicSamplesContext =
	createContext<ProcessNonClinicSamplesContextType>({
		refresh: false,
		data: undefined,
		disabled: false,
		setData: () => {},
		setRefresh: () => {},
		setDisabled: () => {},
		redirectPathQuery: '',
		formValues: undefined,
		setFormValues: () => {},
		formValuesRef: { current: {} },
		canUpdateSampleInfo: false,
	});

interface FormWrapperProps {
	children: ReactNode;
}

export default function FormWrapper(props: FormWrapperProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { dataId } = useParams();

	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');
	const redirectPathQuery = redirectPath
		? `/?redirectPath=${redirectPath}`
		: '';

	// To check if updates were made
	const formValuesRef = useRef();

	// Data population
	const [refresh, setRefresh] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [formValues, setFormValues] = useState<any>(undefined);
	const [data, setData] = useState<NonClinicData | undefined>(undefined);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !dataId) return;

			setData(undefined);

			try {
				const {
					data: { data },
				} = await (await axios).get(`/api/accessioner/data/${dataId}`);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);
					setData(parsedData);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, dataId, refresh]);

	return (
		<ProcessNonClinicSamplesContext.Provider
			value={{
				data,
				setData,
				refresh,
				disabled,
				formValues,
				setRefresh,
				setDisabled,
				formValuesRef,
				setFormValues,
				redirectPathQuery,
				canUpdateSampleInfo: Boolean(
					data &&
						![
							'pending',
							'pending_verification',
							'verified',
						].includes(data.sample.status)
				),
			}}
		>
			{data ? (
				props.children
			) : (
				<Container>
					<ContentSection>
						<LoadingBox />
					</ContentSection>
				</Container>
			)}
		</ProcessNonClinicSamplesContext.Provider>
	);
}
