import { Dispatch, useContext, SetStateAction, MouseEventHandler } from 'react';

import { Form, Alert, FormFooter, Button, Modal } from '@pangea-lis-apps/ui';

import { ProcessTRFStepsContext } from './form-wrapper';

interface TabSubmitConfirmationModalProps {
	handleSubmit: MouseEventHandler;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

export default function TabSubmitConfirmationModal({
	handleSubmit,
	visible: { visible, setVisible },
}: TabSubmitConfirmationModalProps) {
	const { formId, disabled } = useContext(ProcessTRFStepsContext);

	return (
		<Modal
			visible={visible}
			customWidth="max-w-lg"
			title="Confirm changes"
			onClose={() => setVisible(false)}
		>
			<Form>
				<Alert
					type="warning"
					heading="Confirmation required"
					description="One or more fields were updated. Before redirecting you to the selected tab, please confirm whether you'd like to continue with or without saving."
				/>
				<FormFooter className="flex items-center justify-between">
					<Button
						type="button"
						tier="tertiary"
						formId={formId}
						disabled={disabled}
						onClick={handleSubmit}
						dataAttribute="continue"
						Icon="ArrowSmallRightIcon"
						text="Continue without saving"
					/>
					<Button
						type="button"
						formId={formId}
						tier="tertiary"
						Icon="CheckIcon"
						disabled={disabled}
						dataAttribute="save"
						onClick={handleSubmit}
						text="Save and continue"
					/>
				</FormFooter>
			</Form>
		</Modal>
	);
}
