import { FormEvent, useEffect, BaseSyntheticEvent } from 'react';
import {
	Option,
	ClinicData,
	SubmitEvent,
	BLADDERCARE,
	ICD_10_CODES,
	getAssayTests,
	NonClinicData,
	REQUISITION_FORM,
	normalizeFormValues,
	convertDateTimeToYYYYMMDD,
} from '@pangea-lis-apps/utils';

/**
 * This function was created to account for cases where the key string is a dot-separated string, e.g., metadata.received.value
 * and we need to retrieve the value from the DB requisition form object which has nested objects.
 *
 * This function accounts for cases where the path is a single property or a nested property.
 *
 * @param obj: The requisition form object from the database.
 * @param path: The key string of the object.
 * @returns The value at that key string.
 */
export function getDatabaseValue(obj: any, path: string) {
	const keys = path.split('.');

	const nestedPropertyValue = keys.reduce((accumulator, currentKey) => {
		if (accumulator && accumulator[currentKey] !== undefined)
			return accumulator[currentKey];

		return undefined;
	}, obj);

	return nestedPropertyValue;
}

export function convertOptionsToString(
	databaseValue: string[],
	options: Option[]
) {
	return databaseValue.map((item: string) => {
		const foundItem = options.find((option) => option.value === item);

		if (foundItem)
			return {
				label: foundItem.label,
				value: foundItem.value,
			};

		return {
			label: item,
			value: item,
		};
	});
}

export function usePopulateFormValues(
	data: ClinicData | NonClinicData | undefined,
	formValuesRef: React.MutableRefObject<any>,
	setFormValues: any,
	initialFormValues: any,
	dataType: 'sample' | 'requisition_form'
) {
	useEffect(() => {
		if (data) {
			// Prevent object reference by creating a copy
			const INITIAL_FORM_VALUES = Object.assign({}, initialFormValues);

			setFormValues(() => {
				for (const key in INITIAL_FORM_VALUES) {
					if (key === 'flag') continue;

					// Populate flag array with flagged items
					INITIAL_FORM_VALUES['flag'][key] =
						data.metadata.accessioning.flagged_fields.includes(key);

					// Populate existing
					let databaseValue = getDatabaseValue(data[dataType], key);

					if (databaseValue !== null && databaseValue !== undefined) {
						if (typeof databaseValue === 'boolean') {
							databaseValue = databaseValue.toString();
						} else if (
							typeof databaseValue === 'object' &&
							'$date' in databaseValue
						) {
							databaseValue = convertDateTimeToYYYYMMDD(
								databaseValue.$date
							);
						} else if (
							key === 'customer' ||
							key === 'organization'
						) {
							databaseValue = data[key]._id.$oid;
						} else if (key === 'patient_race') {
							databaseValue = convertOptionsToString(
								databaseValue,
								REQUISITION_FORM[data.sample.assay][
									data.requisition_form.metadata.version
								].race_options
							);
						} else if (key === 'order_icd_10_codes') {
							databaseValue = convertOptionsToString(
								databaseValue,
								(ICD_10_CODES.codes as Record<string, any>)[
									data.sample.assay
								]
							);
						} else if (key === 'order_tests') {
							databaseValue = convertOptionsToString(
								databaseValue,
								getAssayTests()
							);
						} else if (
							key ===
							'relevant_clinical_information_urological_cancers'
						) {
							databaseValue = convertOptionsToString(
								databaseValue,
								BLADDERCARE[
									data.requisition_form.metadata.version
								].urological_cancers_options
							);
						} else if (
							key ===
							'relevant_clinical_information_urological_conditions'
						) {
							databaseValue = convertOptionsToString(
								databaseValue,
								BLADDERCARE[
									data.requisition_form.metadata.version
								].urological_conditions_options
							);
						} else if (
							key === 'relevant_clinical_information_treatments'
						) {
							databaseValue = convertOptionsToString(
								databaseValue,
								BLADDERCARE[
									data.requisition_form.metadata.version
								].treatment_options
							);
						} else if (
							key === 'physician_report_delivery_methods'
						) {
							databaseValue = convertOptionsToString(
								databaseValue,
								BLADDERCARE[
									data.requisition_form.metadata.version
								].physician_report_delivery_method_options
							);
						} else if (key === 'attachments') {
							continue;
						}

						INITIAL_FORM_VALUES[key] = databaseValue;
					}
				}

				formValuesRef.current = INITIAL_FORM_VALUES;
				return INITIAL_FORM_VALUES;
			});
		}
	}, [data, dataType, setFormValues, formValuesRef, initialFormValues]);
}

/**
 * Prepares the form values to be in the proper format to be received by the backend.
 * Removes any values that haven't been changed.
 *
 * @param {object} data The data document from the database.
 * @param {object} formValues - The form values for a specific stpe of the process-TRF flow.
 * @returns {object} An object containing only those values that have been edited.
 */
export const normalizeAccessioningFormValues = (
	data: any,
	formValues: any,
	dataType: 'sample' | 'requisition_form'
) => {
	const normalizedFormValues = normalizeFormValues(formValues);
	const unchangedValues = removeUnchangedValues(
		data,
		normalizedFormValues,
		dataType
	);

	return addPrefixToFormValues(unchangedValues, dataType);
};

export const arraysHaveSameElements = <T>(
	array1: Array<T>,
	array2: Array<T>
): boolean => {
	if (array1.length !== array2.length) {
		return false;
	}

	return array1.every((element) => array2.includes(element));
};

/**
 * This function checks if the form submit was caused by pressing enter on a tab submit.
 *
 * @param event A FormEvent object.
 * @returns A boolen indicating whether the FormEvent was triggered by a Steps tab button.
 */
export const isTabSubmit = (event: FormEvent) => {
	const submitEventAttributes = (event.nativeEvent as SubmitEvent).submitter
		.attributes as NamedNodeMap;

	if (
		submitEventAttributes &&
		submitEventAttributes.getNamedItem('data-trigger')
	) {
		return (
			submitEventAttributes.getNamedItem('data-trigger')?.value ===
			'tab_button'
		);
	}

	return false;
};

/**
 * This function checks the submitter object's attributes to determine if the button used to submit the
 * form was the normal form button or a nav tab button.
 *
 * The values of tabSubmit and tabSubmitNextStepId are saved in session storage because this information needs
 * to be passed to the confirmation modal (in the event that info is updated and another nav tab is selected).
 * There's no way to set data attributes for the next step in the form buttons inside the confirmation modal, so
 * this is the only way.
 *
 * @param event A FormEvent object.
 * @returns An object with a boolean of whether it's a tab submit or regular submit and the id of the next step.
 */
export const determineRedirectURL = (event: BaseSyntheticEvent) => {
	let tabSubmit = false;
	let tabSubmitNextStepId = null;
	let confirmationModalSubmitButtonType = null;

	const pointerEvent = event.nativeEvent as PointerEvent;
	const pointerEventTarget = pointerEvent && pointerEvent.target;
	const pointerEventTargetSubmitterAttributes: NamedNodeMap | null =
		pointerEventTarget && (pointerEventTarget as Element).attributes;

	if (pointerEventTargetSubmitterAttributes) {
		// Check if the submitting button is one from the confirmation modal
		confirmationModalSubmitButtonType =
			'data-custom-attribute' in pointerEventTargetSubmitterAttributes &&
			((
				pointerEventTargetSubmitterAttributes as NamedNodeMap
			).getNamedItem('data-custom-attribute')?.value
				? (
						pointerEventTargetSubmitterAttributes as NamedNodeMap
				  ).getNamedItem('data-custom-attribute')?.value
				: 'null');

		if (confirmationModalSubmitButtonType) {
			tabSubmit = sessionStorage.getItem('tabSubmit') === 'true';
			tabSubmitNextStepId =
				sessionStorage.getItem('tabSubmitNextStepId') || null;
		} else {
			// Retrieve data attribute values
			tabSubmit = 'data-trigger' in pointerEventTargetSubmitterAttributes;
			tabSubmitNextStepId =
				'data-step-id' in pointerEventTargetSubmitterAttributes
					? (
							pointerEventTargetSubmitterAttributes as NamedNodeMap
					  ).getNamedItem('data-step-id')?.value
					: null;

			// Preserve state information for the confirmation modal
			sessionStorage.setItem('tabSubmit', tabSubmit.toString());
			sessionStorage.setItem(
				'tabSubmitNextStepId',
				tabSubmitNextStepId ? tabSubmitNextStepId : 'null'
			);
		}
	}

	return {
		tabSubmit,
		tabSubmitNextStepId,
		confirmationModalSubmitButtonType,
	};
};

/**
 * Removes all the unchanged values from the formValues object (when compared to the
 * data document from database), so that only the changed values are sent to the backend.
 *
 * @param {object} data The data document from the database.
 * @param {object} formValues - The form values for a specific stpe of the process-TRF flow.
 * @returns {object} An object containing only those values that have been edited.
 */
export const removeUnchangedValues = (
	data: any,
	formValues: any,
	dataType: 'sample' | 'requisition_form'
) => {
	const keysToRemove = [];

	for (const key in formValues) {
		const value = formValues[key];

		// Array values
		if (Array.isArray(value)) {
			if (key === 'attachments' && value.length === 0) {
				keysToRemove.push(key);
			} else if (
				arraysHaveSameElements(
					value,
					getDatabaseValue(data[dataType], key)
				)
			) {
				keysToRemove.push(key);
			}
		} else {
			// Literal values
			const databaseValue = getDatabaseValue(data[dataType], key);

			if (databaseValue === value) {
				keysToRemove.push(key);
			}
		}
	}

	for (const key of keysToRemove) delete formValues[key];

	return formValues;
};

/**
 * Add requisition_form prefix to formValue properties to be sent to the backend.
 *
 * For example:
 *  - customer -> requisition_form.customer
 *  - organization -> requisition_form.organization
 *
 * @param {object} formValues - The form values for a specific stpe of the process-TRF flow.
 * @returns {object} An object containing the form values with the requisition_form prefix.
 */
export const addPrefixToFormValues = (formValues: any, prefix: string) => {
	const prefixedFormValues: Record<string, any> = {};

	for (const key in formValues) {
		if (key === 'flag') {
			prefixedFormValues[key] = formValues[key];
			continue;
		}

		const keyWithPrefix = prefix + '.' + key;
		prefixedFormValues[keyWithPrefix] = formValues[key];
	}

	return prefixedFormValues;
};
