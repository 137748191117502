import { useState, Fragment, SetStateAction, Dispatch } from 'react';

import { Attribute, initialAttributeValue } from '@pangea-lis-apps/utils';

import { UpdateNonResultModal } from '../update-data-components/index';
import { TRFSection } from '../update-data-components/trf-section-general';

interface ITRFSection {
	data: any;
	originalData: any;
	payload: any;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function TRFSectionResp4plexV1(props: ITRFSection) {
	const [modalVisible, setModalVisible] = useState(false);
	const [attribute, setAttribute] = useState<Attribute>(
		initialAttributeValue
	);

	return (
		<Fragment>
			{/* <UpdateNonResultModal
				data={props.data}
				attribute={attribute}
				setRefresh={props.setRefresh}
				heading="Edit info"
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
				endpoint={``}
			/> */}
			<TRFSection
				data={props.data}
				payload={props.payload}
				originalData={props.originalData}
				setRefresh={props.setRefresh}
			/>
		</Fragment>
	);
}
