import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';

import { LoadingPage, PageNotFound } from '@pangea-lis-apps/ui';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RoleProtectedRouteProps {
	roles: string[];
}

function hasIntersection(list1: string[], list2: string[]) {
	const set1 = new Set(list1);
	return list2.some((item) => set1.has(item));
}

export default function RoleProtectedRoute(props: RoleProtectedRouteProps) {
	const { user } = useAuth0();
	const [hasPermissions, setHasPermissions] = useState<boolean | null>(null);

	useEffect(() => {
		if (!user) return;

		setHasPermissions(
			user && hasIntersection(user['custom_claims/roles'], props.roles)
		);
	}, [user, props.roles]);

	if (hasPermissions === null) return <LoadingPage />;
	if (!hasPermissions) return <PageNotFound />;
	return <Outlet />;
}
