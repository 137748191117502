import validator from 'validator';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	useRef,
	useState,
	Fragment,
	Dispatch,
	useEffect,
	ChangeEvent,
	SetStateAction,
} from 'react';

import {
	Modal,
	Alert,
	Button,
	useAxios,
	FormFooter,
	LoadingBox,
	SpecimenInfo,
	GenericField,
	SelectField,
	SampleResults,
	ContentSection,
	DataComponents,
	useTabsContext,
	DescriptionList,
	DescriptionItem,
	AccessioningStatus,
	ContentSectionDivider,
	Results,
} from '@pangea-lis-apps/ui';
import {
	ClinicData,
	BLADDERCARE,
	NonClinicData,
	generateStringFromArrayOptionValues,
} from '@pangea-lis-apps/utils';

import {
	RerunModal,
	AddNoteModal,
	AddCommentModal,
	initialModalValue,
	ModalWithRefreshProps,
} from './modals';
import Notes from '../../../../components/notes';
import SampleActionsButton from './sample-actions-button';
import RejectSampleModal from '../../../../components/modals/reject-sample-modal';
import PrintBarcodeModal from '../../../../components/modals/print-barcode-modal'


export interface ViewFITSampleProps {
	data: ClinicData | NonClinicData | undefined;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export default function ViewFITSample({
	data,
	setRefresh,
}: ViewFITSampleProps) {
	const { dataId } = useParams();
	const [modalVisible, setModalVisible] = useState<{
		[key: string]: boolean;
	}>(initialModalValue);

	

	// Regenerate report
	// const [disabled, setDisabled] = useState(false);

	// const handleGenerateReport = async () => {
	// 	if (!axios) return;

	// 	setDisabled(true);

	// 	toast.loading('Generating...', toastOptions);

	// 	try {
	// 		await (
	// 			await axios
	// 		).post(`/api/cls/data/${dataId}/report/generate`, null);

	// 		setRefresh((value) => !value);
	// 	} catch (error) {
	// 		console.log(error);
	// 	} finally {
	// 		setDisabled(false);
	// 	}
	// };

	return (
		<Fragment>
			<RejectSampleModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<RerunModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			
			<AddNoteModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<EditResultsModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<AddCommentModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<VerifyModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			{!data ? (
				<LoadingBox />
			) : (
				<Fragment>
					<AccessioningStatus data={data} />


					<ContentSectionDivider />

					<SpecimenInfo data={data} />

					{'patient' in data && (
						<Fragment>
							<ContentSectionDivider />

							<DataComponents.PatientInformation data={data} />
						</Fragment>
					)}

					<ContentSectionDivider />

					<DataComponents.ProviderInformation data={data} />

					<ContentSectionDivider />

					<DataComponents.OrganizationInformation data={data} />

					<ContentSectionDivider />

					<DataComponents.ReportInformation
						data={data}
						setRefresh={setRefresh}
					>
						<DescriptionItem term="Results">
							<SampleResults data={data} />
						</DescriptionItem>
						<div className="sm:col-span-2"></div>
						
					</DataComponents.ReportInformation>

					<ContentSectionDivider />

					<ContentSection heading="Miscellaneous">
						<DescriptionList>
							<DescriptionItem
								term="Notes"
								customColSpan="sm:col-span-3"
							>
								<Notes
									id={dataId}
									collection="data"
									data={data.notes}
									setRefresh={setRefresh}
									receiveDepartment="cls"
								/>
							</DescriptionItem>
						</DescriptionList>
					</ContentSection>
					<ContentSection>
						<FormFooter>
							
							<SampleActionsButton
								data={data}
								setModalVisible={setModalVisible}
							/>
						</FormFooter>
					</ContentSection>
				</Fragment>
			)}
		</Fragment>
	);
}

export function VerifyModal(props: ModalWithRefreshProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { setRefreshTabs } = useTabsContext();
	const {
		visible: { verify: visible },
		setVisible,
	} = props.visible;
	const isClinicSample = props.data && props.data.type === 'clinic';

	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async () => {
		if (disabled || !axios || !props.data) return;

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(
				`/api/cls/data/${props.data._id.$oid}/sample/report/verify`,
				{}
			);

			toast.dismiss(toastId);

			props.setRefresh((value) => !value);
			setRefreshTabs((value) => !value);
			setVisible((prevValue) => ({
				...prevValue,
				verify: false,
			}));
		} catch (error) {
			console.log(error);
		}

		setDisabled(false);
	};

	return (
		<Modal
			visible={visible}
			title={isClinicSample ? 'Sign report' : 'Verify report'}
			onClose={() =>
				setVisible((prevValue) => ({
					...prevValue,
					verify: false,
				}))
			}
		>
			{!props.data ? (
				<LoadingBox />
			) : (
				<Fragment>
					<Alert
						type="warning"
						heading="Review results"
						description={`${
							isClinicSample ? 'Signing' : 'Verifying'
						} the report will release the report to the customer.`}
					/>
					<DescriptionList className="mt-4">
						<DescriptionItem
							term="Results"
							customColSpan="sm:col-span-3"
						>
							<SampleResults data={props.data} />
						</DescriptionItem>
					</DescriptionList>
					<FormFooter>
						<Button
							type="button"
							tier="tertiary"
							Icon="CheckIcon"
							disabled={disabled}
							onClick={handleSubmit}
							text={isClinicSample ? 'Sign off' : 'Verify'}
						/>
					</FormFooter>
				</Fragment>
			)}
		</Modal>
	);
}

type FITResults = {
	result: string
}

type BladderCAREResults = {
	bci: string;
	[key: string]: string | null;
};

interface ModalProps {
	data: ClinicData | NonClinicData | undefined;
	visible: {
		visible: Record<string, boolean>;
		setVisible: Dispatch<SetStateAction<Record<string, boolean>>>;
	};
}

interface EditResultsModalProps extends ModalProps {
	data: ClinicData | NonClinicData | undefined;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}


function EditResultsModal(props: EditResultsModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const { setRefreshTabs } = useTabsContext();
	const {
		visible: { editResults: visible },
		setVisible,
	} = props.visible;

	const [page, setPage] = useState(1);
	const resultsRef = useRef<FITResults>();
	const [results, setResults] = useState<FITResults>({
		result: '',
	});
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async (confirmed: boolean) => {
		console.log('-------', props.data)
		if (disabled || !axios || !props.data || !user) return;

		// Values were changed
		if (props.data.sample.status === 'verified') {
			if (page === 2) {
				// Sample wasn't confirmed
				if (!confirmed) return;
			} else {
				setPage(2);
				return;
			}
		}
		

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(`/api/cls/data/${props.data._id.$oid}/sample/results`, {
				user,
				new_results: results,
			});

			toast.dismiss(toastId);

			handleClose();
			props.setRefresh((value) => !value);
			setRefreshTabs((value) => !value);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (visible && props.data) {
			let res = null;

			res = {
				result: props.data.sample.results.result,
			};

			resultsRef.current = res;
			setResults(res);
		}
	}, [visible, props.data]);

	const handleChange = (event: ChangeEvent) => {
		const target = event.target as HTMLSelectElement;

		if (target && target.name)
			setResults((prevVal) => ({
				...prevVal,
				result: target.value === '' ? 'negative' : target.value,
			}));
	};

	const handleClose = () => {
		setPage(1);
		setDisabled(false);
		setVisible((prevValue) => ({
			...prevValue,
			editResults: false,
		}));
	};

	return (
		<Modal
			visible={visible}
			title="Edit results"
			onClose={handleClose}
			customWidth="max-w-sm"
		>
			{props.data ? (
				page === 1 ? (
					<EditResultsModalResultsPage
						setPage={setPage}
						results={results}
						data={props.data}
						disabled={disabled}
						handleSubmit={handleSubmit}
						handleChange={handleChange}
					/>
				) : (
					<EditResultsModalConfirmationPage
						setPage={setPage}
						results={results}
						data={props.data}
						disabled={disabled}
						handleSubmit={handleSubmit}
					/>
				)
			) : (
				<LoadingBox />
			)}
		</Modal>
	);
}

interface EditResultsModalResultsPageProps {
	disabled: boolean;
	data: ClinicData | NonClinicData;
	results: FITResults;
	setPage: Dispatch<SetStateAction<number>>;
	handleChange: (event: ChangeEvent) => void;
	handleSubmit: (confirmed: boolean) => void;
}

const EditResultsModalResultsPage = (
	props: EditResultsModalResultsPageProps
) => {
	return (
		<div>
			<SelectField
				required
				name="Result"
				label="Result"
				value={props.results.result ?? ''} 
				options={
					[
						{
							value: '',
							label: 'Select an Option',
							disabled: true
						},
						{
							value: 'Positive',
							label: 'Positive',
						},
						{
							value: 'Negative',
							label: 'Negative',
						},
					]
				}
				handleSelect={props.handleChange}
			/>
			<FormFooter>
				<Button
					type="button"
					text="Update"
					tier="tertiary"
					Icon="ArrowSmallRightIcon"
					disabled={props.disabled}
					onClick={() => props.handleSubmit(false)}
				/>
			</FormFooter>
		</div>
	);
};

interface EditResultsModalConfirmationPageProps {
	disabled: boolean;
	data: ClinicData | NonClinicData;
	results: Record<string, string | null>;
	setPage: Dispatch<SetStateAction<number>>;
	handleSubmit: (confirmed: boolean) => void;
}

const EditResultsModalConfirmationPage = (
	props: EditResultsModalConfirmationPageProps
) => {
	const isClinicSample = props.data.type === 'clinic';

	return (
		<div>
			<Alert
				type="warning"
				heading="A corrective report will be generated"
				description="A new report will be generated with a corrective comment and be released."
			/>
			<DescriptionList className="mt-4">
				<DescriptionItem
					term="Current results"
					customColSpan="sm:col-span-3"
				>
					<SampleResults data={props.data} />
				</DescriptionItem>
				<DescriptionItem
					term="New results"
					customColSpan="sm:col-span-3"
				>
					<ul>
						<li>
							{props.results['result'] === null
								? 'Null'
								: props.results['result'].charAt(0).toUpperCase()+props.results['result'].slice(1)}
						</li>
					</ul>
				</DescriptionItem>
			</DescriptionList>
			<FormFooter className="!justify-between">
				<Button
					type="button"
					text="Back"
					tier="tertiary"
					Icon="ArrowSmallLeftIcon"
					onClick={() => props.setPage(1)}
				/>
				<Button
					type="button"
					tier="tertiary"
					Icon="CheckIcon"
					disabled={props.disabled}
					onClick={() => props.handleSubmit(true)}
					text={isClinicSample ? 'Sign off' : 'Verify'}
				/>
			</FormFooter>
		</div>
	);
};
