import { Dispatch, SetStateAction } from 'react';

import { ButtonWithDropdown } from '@pangea-lis-apps/ui';
import { IconName, ClinicData, NonClinicData } from '@pangea-lis-apps/utils';

import { canVerify } from './helpers';

type Modal = { [key: string]: boolean };

interface SampleActionsButtonProps {
	data: ClinicData | NonClinicData;
	setModalVisible: Dispatch<SetStateAction<Modal>>;
}

export default function SampleActionsButton({
	data,
	setModalVisible,
}: SampleActionsButtonProps) {
	const items: {
		name: string;
		Icon: IconName;
		handleClick: () => void;
	}[] = [
		{
			Icon: 'PencilIcon',
			name: 'Edit results',
			handleClick: () => {
				setModalVisible((prevValue) => ({
					...prevValue,
					editResults: true,
				}));
			},
		},
		{
			name: 'Add report comment',
			Icon: 'ChatBubbleBottomCenterTextIcon',
			handleClick: () => {
				setModalVisible((prevValue) => ({
					...prevValue,
					addComment: true,
				}));
			},
		},
	];

	if (canVerify(data)) {
		items.unshift({
			name: data.type === 'clinic' ? 'Sign report' : 'Verify report',
			Icon: 'CheckIcon',
			handleClick: () =>
				setModalVisible((prevValue) => ({
					...prevValue,
					verify: true,
				})),
		});
	}

	if (data.sample.status !== 'reject' && data.sample.report.verified.value === false) {
		items.push({
			name: 'Reject sample',
			Icon: 'XCircleIcon',
			handleClick: () =>
				setModalVisible((prevValue) => ({
					...prevValue,
					reject: true,
				})),
		});
	}

	if (data.sample.report.verified.value === false) {
		items.push({
			Icon: 'FlagIcon',
			name: 'Rerun sample(s)',
			handleClick: () => {
				setModalVisible((prevValue) => ({
					...prevValue,
					rerun: true,
				}));
			},
		});
	}

	return (
		<ButtonWithDropdown
			Icon={items[0].Icon}
			items={items.slice(1)}
			mainButtonText={items[0].name}
			mainClick={items[0].handleClick}
		/>
	);
}
