import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useId, useState, useEffect } from 'react';

import {
	Table,
	Badge,
	DataCell,
	useAxios,
	TableRow,
	TableBody,
	Container,
	TableHead,
	HeaderCell,
	EmptyTable,
	TableLoading,
	ContentWrapper,
	ContentSection,
	ArrowLinkButton,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ClinicData,
	Pagination,
	formatDate,
	NonClinicData,
	PaginationState,
	getLabelFromValue,
	getSampleStatuses,
	initialPaginationValues,
	ASSAYS,
	ORGANIZATIONS,
} from '@pangea-lis-apps/utils';

import TableSearchFiltersForm from '../../../components/tables/accessioning/table-search-filters-form';

const projectedFields = [
	'sample.ruo',
	'sample.assay',
	'sample.priority',
	'sample.misc.sample_stability.stable_until',
	'sample.status',
	'organization.name',
	'customer.first_name',
	'customer.last_name',
	'sample.pangea_id',
	'_id',
	'sample.specimen_id',
	'sample.collection_kit_id',
	'patient.first_name',
	'patient.last_name',
	'patient.date_of_birth',
];

/* eslint-disable-next-line */
export interface ViewSamplesPastStabilityProps {}

export default function ViewSamplesPastStability(
	props: ViewSamplesPastStabilityProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [data, setData] = useState<
		(ClinicData | NonClinicData)[] | undefined
	>(undefined);
	const [query, setQuery] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setData(undefined);
				setDisabled(false);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/accessioner/search/samples/past-stability?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{ query, projected_fields: projectedFields }
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setData(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);

			toast.dismiss();
		};

		fetchData();

		return () => {
			toast.dismiss();
			unmounted = true;
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	return (
		<Container>
			<ContentWrapper
				Icon="CalendarDaysIcon"
				heading="Samples Past Stability"
				description="Samples past or nearly past the stability date."
			>
				<TableSearchFiltersForm
					tableType="past-stability"
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection heading="Sample list">
					<SamplesTable
						data={data}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}

interface SamplesTableProps {
	pagination: PaginationState;
	data: (ClinicData | NonClinicData)[] | undefined;
}

export function SamplesTable(props: SamplesTableProps) {
	const navigate = useNavigate();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Stable until</HeaderCell>
				<HeaderCell customWidth="w-72">
					Days until stability reached
				</HeaderCell>
				<HeaderCell>Status</HeaderCell>
				<HeaderCell>Assay</HeaderCell>
				<HeaderCell>Priority Level</HeaderCell>
				<HeaderCell>RUO</HeaderCell>
				<HeaderCell>Customer</HeaderCell>
				<HeaderCell>Organization</HeaderCell>
				<HeaderCell>Pangea ID</HeaderCell>
				<HeaderCell>Specimen ID</HeaderCell>
				<HeaderCell>Collection Kit ID</HeaderCell>
				<HeaderCell>Patient First Name</HeaderCell>
				<HeaderCell>Patient Last Name</HeaderCell>
				<HeaderCell>Patient Date of Birth</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => {
							const daysUntilStability =
								datum.sample.misc.sample_stability
									.stable_until &&
								Math.ceil(
									(new Date(
										datum.sample.misc.sample_stability.stable_until.$date
									).getTime() -
										new Date().getTime()) /
										(1000 * 3600 * 24)
								);

							const formattedDate =
								daysUntilStability.toString() +
								' ' +
								(Math.abs(daysUntilStability) === 1
									? 'Day'
									: 'Days');

							return (
								<TableRow key={index} index={index}>
									<DataCell>
										{formatDate(
											datum.sample.misc.sample_stability
												.stable_until &&
												datum.sample.misc
													.sample_stability
													.stable_until.$date
										)}
									</DataCell>
									<DataCell>
										{datum.sample.misc.sample_stability
											.stable_until ? (
											<Badge
												content={formattedDate}
												color="red"
											/>
										) : null}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.status,
											getSampleStatuses()
										)}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.assay,
											ASSAYS['assays']
										)}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.priority,
											ORGANIZATIONS.priorityLevels
										)}
									</DataCell>
									<DataCell>
										{datum.sample.ruo === null
											? null
											: datum.sample.ruo
											? 'Yes'
											: 'No'}
									</DataCell>
									<DataCell>
										{datum.customer.first_name.concat(
											' ',
											datum.customer.last_name
										)}
									</DataCell>
									<DataCell>
										{datum.organization.name}
									</DataCell>
									<DataCell>
										{datum.sample.pangea_id && (
											<ArrowLinkButton
												text={datum.sample.pangea_id}
												onClick={() =>
													navigate(
														`/accessioner/view/samples/${datum._id.$oid}`
													)
												}
											/>
										)}
									</DataCell>
									<DataCell>
										{datum.sample.specimen_id}
									</DataCell>
									<DataCell>
										{datum.sample.collection_kit_id}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.first_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.last_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? formatDate(
													datum.patient.date_of_birth
											  )
											: null}
									</DataCell>
								</TableRow>
							);
						})
					) : (
						<EmptyTable
							Icon="FingerPrintIcon"
							heading="No samples past stability"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
