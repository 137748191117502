import toast from 'react-hot-toast';
import { useId, useState, Fragment, Dispatch, SetStateAction } from 'react';

import {
	Table,
	Modal,
	Button,
	TableRow,
	useAxios,
	DataCell,
	TableBody,
	TableHead,
	FormFooter,
	EmptyTable,
	HeaderCell,
	TextButton,
	TableLoading,
	// useUserRoles,
	DescriptionList,
	DescriptionItem,
	OrderItemsSummary,
} from '@pangea-lis-apps/ui';
import {
	Shipment,
	formatDate,
	PaginationState,
	getRoleFromPathname,
} from '@pangea-lis-apps/utils';

interface AllShipmentsTableProps {
	pagination: PaginationState;
	data: Shipment[] | undefined;
	setRefresh?: Dispatch<SetStateAction<boolean>>;
}

export default function AllShipmentsTable(props: AllShipmentsTableProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	// const userRoles = useUserRoles();
	// const isAccessioner = userRoles.includes('BITSS_accessioner');
	// const apiURLPathPrefix = isAccessioner
	// 	? '/api/accessioner'
	// 	: '/api/shipping';
	const role = getRoleFromPathname();

	const [visible, setVisible] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [shipment, setShipment] = useState<Shipment | undefined>(undefined);

	const handleDeleteShipment = async () => {
		if (disabled || !axios) return;

		setDisabled(true);

		toast.loading('Deleting...', toastOptions);

		try {
			await (
				await axios
			).delete(`/api/${role}/shipments/${shipment?._id.$oid}`);

			toast.dismiss();

			setVisible(false);
			setDisabled(false);

			if (props.setRefresh) props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);
			setDisabled(false);
		}
	};

	return (
		<Fragment>
			{shipment && (
				<Modal
					visible={visible}
					title="Delete shipment"
					onClose={() => setVisible(false)}
					description="This action is irreversible. Please double-check the information before proceeding."
				>
					<DescriptionList>
						<DescriptionItem
							term="Items"
							customColSpan="sm:col-span-3"
						>
							<OrderItemsSummary
								// eslint-disable-next-line jsx-a11y/aria-role
								role="associate"
								minified={false}
								items={shipment.items}
							/>
						</DescriptionItem>
						<DescriptionItem term="Processed by">
							{shipment.metadata.created_by.first_name.concat(
								' ',
								shipment.metadata.created_by.last_name
							)}
						</DescriptionItem>
						<DescriptionItem
							term="Date processed"
							customColSpan="sm:col-span-2"
						>
							{formatDate(shipment.metadata.date_created.$date)}
						</DescriptionItem>
					</DescriptionList>
					<FormFooter>
						<Button
							type="button"
							text="Delete"
							tier="tertiary"
							Icon="CheckIcon"
							disabled={disabled}
							onClick={handleDeleteShipment}
						/>
					</FormFooter>
				</Modal>
			)}
			<Table pagination={props.pagination}>
				<TableHead>
					<HeaderCell>Items</HeaderCell>
					<HeaderCell>Processed by</HeaderCell>
					<HeaderCell>Date processed</HeaderCell>
					{/* {isAccessioner && <HeaderCell>Actions</HeaderCell>} */}
					<HeaderCell>Actions</HeaderCell>
				</TableHead>
				<TableBody>
					{props.data ? (
						props.data.length ? (
							props.data.map((shipment, index) => (
								<TableRow key={index} index={index}>
									<DataCell>
										<OrderItemsSummary
											// eslint-disable-next-line jsx-a11y/aria-role
											role="associate"
											minified={true}
											items={shipment.items}
										/>
									</DataCell>
									<DataCell>
										{shipment.metadata.created_by.first_name.concat(
											' ',
											shipment.metadata.created_by
												.last_name
										)}
									</DataCell>
									<DataCell>
										{formatDate(
											shipment.metadata.date_created.$date
										)}
									</DataCell>
									{/* {isAccessioner && (
										<DataCell>
											<TextButton
												color="blue"
												type="button"
												text="Delete"
												onClick={() => {
													setShipment(shipment);
													setVisible(true);
												}}
											/>
										</DataCell>
									)} */}
									<DataCell>
										<TextButton
											color="blue"
											type="button"
											text="Delete"
											onClick={() => {
												setShipment(shipment);
												setVisible(true);
											}}
										/>
									</DataCell>
								</TableRow>
							))
						) : (
							<EmptyTable
								Icon="TruckIcon"
								heading="No shipments"
								description="No shipments received or processed"
							/>
						)
					) : (
						<TableLoading />
					)}
				</TableBody>
			</Table>
		</Fragment>
	);
}
