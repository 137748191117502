import { Routes, Route } from 'react-router';

import ViewSample from './samples/view-sample';
import ViewAllSamples from './samples/view-all-samples';
import ViewRejectedSamples from './samples/view-rejected-samples';

import ViewHeldSamplesRackView from './samples/held/rack-view';
import ViewHeldSamplesTableView from './samples/held/table-view';

import ViewFlaggedSamplesRackView from './samples/flagged-samples/rack-view';
import ViewFlaggedSamplesTableView from './samples/flagged-samples/table-view';

import ViewTestRequisitionForm from './requisition-forms/view-test-requisition-form';
import ViewTestRequisitionForms from './requisition-forms/view-test-requisition-forms';

import AccessioningPreprocess from './accessioning/accession/preprocess';
import ViewAccessioningSessions from './accessioning/accession/sessions';
import ViewAccessioningSession from './accessioning/accession/sessions/view-session';

import STITRFFlowRoutes from './accessioning/accession/clinic-samples/trf/sti/routes';
import Resp4PlexTRFFlowRoutes from './accessioning/accession/clinic-samples/trf/resp4plex/routes';
import BladderCARETRFFlowRoutes from './accessioning/accession/clinic-samples/trf/bladdercare/routes';
import FITTRFFlowRoutes from './accessioning/accession/clinic-samples/trf/fit/routes'

import ClinicSampleProcessSample from './accessioning/accession/clinic-samples/sample/process-sample';
import ClinicSampleBarSample from './accessioning/accession/clinic-samples/sample/bar';
import ClinicSamplePrintBarcode from './accessioning/accession/clinic-samples/sample/print-barcode';
import ClinicSampleVerifyPriorityLevel from './accessioning/accession/clinic-samples/sample/verify-priority-level';
import ClinicSampleVerifySample from './accessioning/accession/clinic-samples/sample/verify-sample';
import ClinicSampleUpdateSample from './accessioning/accession/clinic-samples/sample/update/update-wrapper';

import NonClinicProcessSampleFormWrapper from './accessioning/accession/non-clinic-samples/process/form-wrapper';
import NonClinicProcessSample from './accessioning/accession/non-clinic-samples/process/index';
import NonClinicSampleBarSample from './accessioning/accession/non-clinic-samples/sample/bar';
import NonClinicSampleProcessSampleLayout from './accessioning/accession/non-clinic-samples/sample/process-sample-layout';
import NonClinicSamplePrintBarcode from './accessioning/accession/non-clinic-samples/sample/print-barcode';
import NonClinicSampleVerifyPriorityLevel from './accessioning/accession/non-clinic-samples/sample/verify-priority-level';
import NonClinicSampleVerifySample from './accessioning/accession/non-clinic-samples/sample/verify-sample';

import ViewOverdueSamples from './samples/view-overdue-samples';
import ViewIncompleteSamples from './samples/view-incomplete-samples';
import ViewSamplesPastStability from './samples/view-samples-past-stability';

import ViewReviewedSamples from './admin/reviewed-samples';
import ViewSamplesPendingReview from './admin/pending-review-samples';

import ViewReviewedSamplesRackView from './samples/reviewed/rack-view';
import ViewReviewedSamplesTableView from './samples/reviewed/table-view';

import RoleProtectedRoute from '../../../utils/routing';

export function AccessioningRoutes() {
	return (
		<Routes>
			<Route
				element={
					<RoleProtectedRoute
						roles={['BITSS_accessioner', 'BITSS_super-admin']}
					/>
				}
			>
				<Route path="/accession">
					<Route index element={<ViewAccessioningSessions />} />
					<Route path="sessions/:accessioningSessionID">
						<Route index element={<ViewAccessioningSession />} />
						<Route
							path="preprocess"
							element={<AccessioningPreprocess />}
						/>
					</Route>
					<Route path="trf/sti/*" element={<STITRFFlowRoutes />} />
					<Route
						path="trf/bladdercare/*"
						element={<BladderCARETRFFlowRoutes />}
					/>
					<Route
						path="trf/resp4plex/*"
						element={<Resp4PlexTRFFlowRoutes />}
					/>
					<Route
						path="trf/fit/*"
						element={<FITTRFFlowRoutes />}
					/>
				</Route>
				<Route
					id="process_nonclinic_sample"
					path="/accession/sample/:assay/data/:dataId/non-clinic-sample"
				>
					<Route
						index
						element={
							<NonClinicProcessSampleFormWrapper>
								<NonClinicProcessSample />
							</NonClinicProcessSampleFormWrapper>
						}
					/>
					<Route element={<NonClinicSampleProcessSampleLayout />}>
						<Route
							path="print-barcode"
							element={<NonClinicSamplePrintBarcode />}
						/>
						<Route
							path="verify-priority"
							element={<NonClinicSampleVerifyPriorityLevel />}
						/>
						<Route
							path="verify-sample"
							element={<NonClinicSampleVerifySample />}
						/>
						<Route
							path="bar/:action"
							element={<NonClinicSampleBarSample />}
						/>
					</Route>
				</Route>
				<Route
					id="process_clinic_sample"
					path="/accession/sample/:assay/data/:dataId/sample"
					element={<ClinicSampleProcessSample />}
				>
					<Route
						path="update-info"
						element={<ClinicSampleUpdateSample />}
					/>
					<Route
						path="print-barcode"
						element={<ClinicSamplePrintBarcode />}
					/>
					<Route
						path="verify-priority"
						element={<ClinicSampleVerifyPriorityLevel />}
					/>
					<Route
						path="verify-sample"
						element={<ClinicSampleVerifySample />}
					/>
					<Route
						path="bar/:action"
						element={<ClinicSampleBarSample />}
					/>
				</Route>
				<Route id="view_samples" path="view/samples">
					<Route path=":dataId" element={<ViewSample />} />
					<Route
						path="incomplete"
						element={<ViewIncompleteSamples />}
					/>
					<Route path="rejected" element={<ViewRejectedSamples />} />
					<Route path="overdue" element={<ViewOverdueSamples />} />
					<Route
						path="stability"
						element={<ViewSamplesPastStability />}
					/>
					<Route path="all" element={<ViewAllSamples />} />
				</Route>
				<Route
					path="view/held-samples/table"
					element={<ViewHeldSamplesTableView />}
				/>
				<Route
					path="view/held-samples/rack"
					element={<ViewHeldSamplesRackView />}
				/>
				<Route
					path="view/flagged-samples/table"
					element={<ViewFlaggedSamplesTableView />}
				/>
				<Route
					path="view/flagged-samples/rack"
					element={<ViewFlaggedSamplesRackView />}
				/>
				<Route
					path="view/reviewed-samples/table"
					element={<ViewReviewedSamplesTableView />}
				/>
				<Route
					path="view/reviewed-samples/rack"
					element={<ViewReviewedSamplesRackView />}
				/>
				<Route
					path="review/trf/:testRequisitionFormId"
					element={<ViewTestRequisitionForm function="review" />}
				/>
				<Route
					path="view/trfs"
					element={<ViewTestRequisitionForms />}
				/>
				<Route
					path="view/trfs/:dataId"
					element={<ViewTestRequisitionForm />}
				/>
				<Route
					path="update/trf/:testRequisitionFormId"
					element={<ViewTestRequisitionForm function="update" />}
				/>
			</Route>
		</Routes>
	);
}

export function AccessioningAdminRoutes() {
	return (
		<Routes>
			<Route
				element={
					<RoleProtectedRoute
						roles={['BITSS_accessioner-admin', 'BITSS_super-admin']}
					/>
				}
			>
				<Route path="view/samples">
					<Route path=":dataId" element={<ViewSample />} />
					<Route path="reviewed" element={<ViewReviewedSamples />} />
					<Route
						path="needs-review"
						element={<ViewSamplesPendingReview />}
					/>
				</Route>
			</Route>
		</Routes>
	);
}
