import { ASSAYS, ClinicData, NonClinicData } from '@pangea-lis-apps/utils';

export const canVerify = (data: ClinicData | NonClinicData) => {
	// If the specimen ends up in a batch, then the batch must have passed QC.
	if (ASSAYS[data.sample.assay]['in_batch']) {
		return (
			data.sample.status === 'pending_verification' &&
			data.metadata.accessioning.completed.value &&
			data.sample.batch &&
			data.sample.batch.qc.passed
		);
	}

	return (
		data.sample.status === 'pending_verification' &&
		data.metadata.accessioning.completed.value
	);
};
