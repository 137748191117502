import { Dispatch, SetStateAction } from 'react';

import { ProgressStep, Option } from '@pangea-lis-apps/utils';

export const handleComboboxSelect = (
	selectedOptions: Option[],
	setFormValues: any,
	property: string,
	setShowOtherModal?: Dispatch<SetStateAction<boolean>>
) => {
	if (
		setShowOtherModal &&
		selectedOptions.some((option) => option.value === 'other')
	) {
		setShowOtherModal(true);
		return;
	}

	setFormValues((prevValues: any) => {
		if (prevValues) {
			let newOptions = removeDuplicateDicts(selectedOptions);

			if (
				selectedOptions.findIndex(
					(option) => option.value === 'prefer_not_to_disclose'
				) !== -1
			)
				newOptions = newOptions.filter(
					(option) => option.value === 'prefer_not_to_disclose'
				);

			return {
				...prevValues,
				[property]: newOptions,
			};
		}

		return prevValues;
	});
};

function removeDuplicateDicts(arr: Option[]) {
	// A Map to keep track of the frequency of each dictionary
	const dictFrequency = new Map();

	for (const dict of arr) {
		// Convert the dictionary to a string representation
		const dictStr = JSON.stringify(dict.value);

		// Update the frequency of the dictionary in the Map
		dictFrequency.set(dictStr, (dictFrequency.get(dictStr) || 0) + 1);
	}

	// A new array to store dictionaries without duplicates
	const uniqueDicts = [];

	for (const dict of arr) {
		const dictStr = JSON.stringify(dict.value);

		// Check the frequency of the dictionary
		if (dictFrequency.get(dictStr) === 1) {
			// If the frequency is 1, add it to the new array
			uniqueDicts.push(dict);
		}
		// Otherwise, skip the dictionary (all duplicates will be skipped)
	}

	return uniqueDicts;
}

export const handleMultipleComboboxSelect = (
	selectedOptions: Option[],
	setFormValues: any,
	property: string,
	setOptions: Dispatch<SetStateAction<Option[]>>,
	setShowOtherModal?: Dispatch<SetStateAction<boolean>>
) => {
	setFormValues((prevValues: any) => {
		if (prevValues) {
			// Get the new element
			const newElement = selectedOptions.slice(-1)[0];

			if (
				newElement &&
				newElement.value === 'other' &&
				setShowOtherModal
			) {
				setShowOtherModal(true);
				return prevValues;
			}

			// If new element is an all-disabling element
			let newArray = selectedOptions.map((option) => option);

			if (newElement && newElement.disables_all) {
				newArray = [newElement];

				setOptions((prevValue) =>
					prevValue.map((val) => {
						if (val.value === newElement.value) return val;

						return {
							...val,
							disabled: true,
						};
					})
				);
			}

			return {
				...prevValues,
				[property]: newArray,
			};
		}

		return prevValues;
	});
};

export const setStepStyles = (steps: ProgressStep[], currentPageId: string) => {
	const index = steps.findIndex((step) => step.id === currentPageId);

	// Create a copy of the array.
	const arrayCopy = JSON.parse(JSON.stringify(steps));

	for (let i = 0; i < arrayCopy.length; i++) {
		if (i < index) arrayCopy[i].status = 'complete';
		else if (i === index) arrayCopy[i].status = 'current';
		else arrayCopy[i].status = 'upcoming';
	}

	return arrayCopy;
};

export const handleRemoveOption = (
	selectedOptionValue: string,
	setFormValues: any,
	property: string
) => {
	setFormValues((prevVal: any) => {
		if (prevVal) {
			const new_options = prevVal[property].filter(
				(option: Option) => option.value !== selectedOptionValue
			);

			return {
				...prevVal,
				[property]: new_options,
			};
		}

		return prevVal;
	});
};
