import { useState, Fragment, SetStateAction, Dispatch } from 'react';

import { Attribute, initialAttributeValue } from '@pangea-lis-apps/utils';

import { UpdateNonResultModal } from '../update-data-components/index';
import { TRFSection } from '../update-data-components/trf-section-general';

interface ITRFSection {
	data: any;
	originalData: any;
	payload: any;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function TRFSectionFitV1(props: ITRFSection) {
	return (
        <TRFSection
            data={props.data}
            payload={props.payload}
            originalData={props.originalData}
            setRefresh={props.setRefresh}
        />
	);
}
