import { useNavigate } from 'react-router-dom';

import {
	Table,
	TableRow,
	DataCell,
	TableBody,
	TableHead,
	HeaderCell,
	EmptyTable,
	TableLoading,
	SampleResults,
	ArrowLinkButton,
} from '@pangea-lis-apps/ui';
import {
	ASSAYS,
	ClinicData,
	formatDate,
	ORGANIZATIONS,
	PaginationState,
	getSpecimenTypes,
	getLabelFromValue,
	getRoleFromPathname,
} from '@pangea-lis-apps/utils';

interface ViewReportsTableProps {
	data: ClinicData[] | undefined;
	pagination: PaginationState;
}

export default function ViewReportsTable(props: ViewReportsTableProps) {
	const navigate = useNavigate();
	const role = getRoleFromPathname();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Assay</HeaderCell>
				<HeaderCell>RUO</HeaderCell>
				<HeaderCell>Priority Level</HeaderCell>
				<HeaderCell>Specimen Type</HeaderCell>
				<HeaderCell>Customer</HeaderCell>
				<HeaderCell>Organization</HeaderCell>
				<HeaderCell>Pangea id</HeaderCell>
				<HeaderCell>Specimen id</HeaderCell>
				<HeaderCell>Collection kit id</HeaderCell>
				<HeaderCell>Patient First Name</HeaderCell>
				<HeaderCell>Patient Last Name</HeaderCell>
				<HeaderCell>Patient Date of Birth</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
				<HeaderCell>Received Date</HeaderCell>
				<HeaderCell>Report date</HeaderCell>
				<HeaderCell>Results</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => {
							return (
								<TableRow key={index} index={index}>
									<DataCell>
										{getLabelFromValue(
											datum.sample.assay,
											ASSAYS['assays']
										)}
									</DataCell>
									<DataCell>
										{datum.sample.ruo ? 'Yes' : 'No'}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.priority,
											ORGANIZATIONS.priorityLevels
										)}
									</DataCell>
									<DataCell>
										{datum.sample.sample_specimen_type
											? getLabelFromValue(
													datum.sample
														.sample_specimen_type,
													getSpecimenTypes()
											  )
											: null}
									</DataCell>
									<DataCell>
										{datum.customer.first_name.concat(
											' ',
											datum.customer.last_name
										)}
									</DataCell>
									<DataCell>
										{datum.organization.name}
									</DataCell>
									<DataCell>
										<ArrowLinkButton
											text={datum.sample.pangea_id}
											onClick={() =>
												navigate(
													`/${role}/view/samples/${datum._id.$oid}`
												)
											}
										/>
									</DataCell>
									<DataCell>
										{datum.sample.specimen_id}
									</DataCell>
									<DataCell>
										{datum.sample.collection_kit_id}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.first_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.last_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? formatDate(
													datum.patient.date_of_birth
											  )
											: null}
									</DataCell>
									<DataCell>
										{formatDate(
											datum.sample.sample_collection_date
										)}
									</DataCell>
									<DataCell>
										{formatDate(
											datum.sample.sample_received_date
												? datum.sample
														.sample_received_date
												: undefined
										)}
									</DataCell>
									<DataCell>
										{datum.sample.report.verified.value
											? formatDate(
													datum.sample.report.verified
														.metadata.date
											  )
											: null}
									</DataCell>
									<DataCell>
										<SampleResults data={datum} />
									</DataCell>
								</TableRow>
							);
						})
					) : (
						<EmptyTable
							heading="No samples"
							Icon="FingerPrintIcon"
							description="Try a different search criteria"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
