import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useId, useState, Fragment, useEffect } from 'react';

import {
	Button,
	useAxios,
	Container,
	LoadingBox,
	ContentWrapper,
	ContentSection,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';

import { Attribute, initialAttributeValue } from '@pangea-lis-apps/utils';

import {
	IPayLoad,
	initPayLoad,
	INonClinicFormData,
	mapNonClinicFormData,
} from './data-types';
import { CommentsModal, FindDiff } from './update-data-components/index';

import { SampleSection } from './update-data-components/sample-section';

export default function UpdateReportNonClinic() {
	const toastId = useId();
	const axios = useAxios(toastId);
	const { dataId } = useParams();

	const [data, setData] = useState<INonClinicFormData>();
	const [originalData, setOriginalData] = useState<INonClinicFormData>();
	const [payload, setPayLoad] = useState<IPayLoad>(initPayLoad);

	const [refresh, setRefresh] = useState(false);
	const [fieldRefresh, setFieldRefresh] = useState(false);
	const [commentsModalVisible, setCommentsModalVisible] = useState(false);
	const [attribute, setAttribute] = useState<Attribute>(
		initialAttributeValue
	);

	const handleSubmitForm = () => {
		const payload = FindDiff(originalData, data);

		if (!payload) {
			toast.error('No Data Change');
			return;
		}

		setPayLoad(payload);
		setCommentsModalVisible(true);
	};

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(`/api/client-services/data/${dataId}`);

				if (!unmounted) {
					const parsedData = JSON.parse(data);

					const formdata = mapNonClinicFormData(parsedData);

					setOriginalData({ ...formdata });
					setData({ ...formdata });
				} else {
					console.log('Unmounted!');
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, dataId, refresh]);

	return (
		<Container>
			<ContentWrapper Icon="PencilIcon" heading="Update Data">
				{!data ? (
					<LoadingBox />
				) : (
					<Fragment>
						<CommentsModal
							data={payload}
							attribute={attribute}
							setRefresh={setRefresh}
							heading="Update Submission"
							visible={{
								visible: commentsModalVisible,
								setVisible: setCommentsModalVisible,
							}}
						/>

						<SampleSection
							data={data}
							payload={payload}
							originalData={originalData}
							setRefresh={setFieldRefresh}
						/>

						<ContentSectionDivider />

						<ContentSection className="py-6">
							<div className="flex-1 flex justify-between sm:justify-end space-x-2 ">
								<Button
									text="Update"
									type="button"
									tier="tertiary"
									onClick={() => handleSubmitForm()}
								/>
							</div>
						</ContentSection>
					</Fragment>
				)}
			</ContentWrapper>
		</Container>
	);
}
