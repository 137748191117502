import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
	useId,
	Fragment,
	Dispatch,
	useState,
	FormEvent,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Modal,
	Button,
	useAxios,
	FormGroup,
	FormFooter,
	LoadingBox,
	SelectField,
	TextareaField,
} from '@pangea-lis-apps/ui';
import {
	Assay,
	ASSAYS,
	ClinicData,
	BAR_ACTIONS,
	NonClinicData,
	REQUISITION_FORM,
	getRoleFromPathname,
} from '@pangea-lis-apps/utils';

import RemoveSampleFromRackModal from './remove-sample-from-rack-modal';
import {
	generateMessageTemplateContent,
	generateMessageTemplateOptions,
} from '../../views/accessioner/accessioning/accession/helpers/process-sample-flow';

interface ModalVisible {
	[key: string]: boolean;
}

interface RejectSampleModalProps {
	data: ClinicData | NonClinicData | undefined;
	setRefresh?: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: ModalVisible;
		setVisible: Dispatch<SetStateAction<ModalVisible>>;
	};
}

export default function RejectSampleModal(props: RejectSampleModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const navigate = useNavigate();
	const { dataId } = useParams();
	const role = getRoleFromPathname();

	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');

	const { visible, setVisible } = props.visible;
	const [disabled, setDisabled] = useState(false);
	const [rejectReason, setRejectReason] = useState('');
	const [messageTemplate, setMessageTemplate] = useState('');
	const [showRemoveFromRackModal, setShowRemoveFromRackModal] =
		useState(false);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios || !props.data) return;

		setDisabled(true);

		toast.loading('Rejecting...', toastOptions);

		try {
			await (
				await axios
			).patch(`/api/${role}/data/${dataId}/sample/reject`, {
				user,
				form_data: { rejection_reason: rejectReason },
			});

			toast.dismiss();

			handleClose();

			if (role === 'accessioner') {
				if (BAR_ACTIONS.includes(props.data.sample.status)) {
					setShowRemoveFromRackModal(true);
					return;
				}

				navigate(
					redirectPath
						? redirectPath
						: `/accessioner/accession/sessions/${props.data.accessioning_session._id.$oid}/preprocess`
				);
			} else {
				if (props.setRefresh) props.setRefresh((value) => !value);
			}
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<
		HTMLTextAreaElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLTextAreaElement | HTMLSelectElement;

		if (target && target.name) {
			if (target.name === 'reject_reason') {
				setRejectReason(target.value);
			} else if (target.name === 'message_template') {
				if (props.data) {
					setMessageTemplate(target.value);

					const contentOptions: Record<string, string> =
						generateMessageTemplateContent(
							props.data,
							props.data.sample.assay,
							'reject'
						);
					let message = contentOptions[target.value];

					if (message) {
						if ('requisition_form' in props.data) {
							message = message.replace(
								'{PATIENT_FULL_NAME}',
								`${props.data.requisition_form.patient_first_name} ${props.data.requisition_form.patient_last_name}`
							);
						} else {
							if (!props.data.sample.specimen_id) {
								message = message.replace(
									' with specimen ID {SPECIMEN_ID}',
									''
								);
							} else {
								message = message.replace(
									'{SPECIMEN_ID}',
									props.data.sample.specimen_id
								);
							}
						}

						let stability_window =
							ASSAYS[props.data.sample.assay]['stable_for'];

						if (props.data.sample.assay === 'resp4plex') {
							if (
								props.data.sample
									.sample_transport_storage_condition
							) {
								stability_window =
									stability_window[
										props.data.sample
											.sample_transport_storage_condition
									];
							} else {
								stability_window = '{STABILITY_WINDOW}';
							}
						}

						message = message.replace(
							'{STABILITY_WINDOW}',
							stability_window
						);

						setRejectReason(message);
					}
				}
			}
		}
	};

	const handleClose = () => {
		setVisible((prevValue) => ({
			...prevValue,
			reject: false,
		}));
		setRejectReason('');
		setMessageTemplate('');
	};

	return (
		<Fragment>
			{props.data && (
				<RemoveSampleFromRackModal
					data={props.data}
					visible={{
						visible: showRemoveFromRackModal,
						setVisible: setShowRemoveFromRackModal,
					}}
				/>
			)}
			<Modal
				onClose={handleClose}
				title="Reject sample"
				customWidth="max-w-lg"
				visible={visible['reject']}
				description="This action is irreversible. Upon rejection, the customer will be notified that a sample has been rejected for the provided reason."
			>
				{props.data ? (
					<Form>
						<FormGroup>
							<div className="sm:col-span-4">
								<SelectField
									label="Templates"
									name="message_template"
									value={messageTemplate}
									handleSelect={handleChange}
									options={generateMessageTemplateOptions(
										props.data,
										props.data.sample.assay as Assay,
										'reject'
									)}
								/>
							</div>
							<div className="sm:col-span-6">
								<TextareaField
									required
									rows={8}
									label="Reason"
									value={rejectReason}
									name="reject_reason"
									handleInputChange={handleChange}
									placeholder="Provide a reason for rejecting the sample."
								/>
							</div>
						</FormGroup>
						<FormFooter>
							<Button
								type="button"
								text="Reject"
								tier="tertiary"
								disabled={disabled}
								Icon="ArchiveBoxIcon"
								onClick={handleSubmit}
							/>
						</FormFooter>
					</Form>
				) : (
					<LoadingBox />
				)}
			</Modal>
		</Fragment>
	);
}
