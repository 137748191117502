import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import {
	useId,
	Dispatch,
	useState,
	useEffect,
	FormEvent,
	SetStateAction,
} from 'react';

import {
	Table,
	Button,
	DataCell,
	useAxios,
	TableRow,
	TableBody,
	TableHead,
	Container,
	EmptyTable,
	HeaderCell,
	TableLoading,
	ContentWrapper,
	ContentSection,
	ArrowLinkButton,
	HeaderCellWithSearch,
} from '@pangea-lis-apps/ui';
import {
	Query,
	QueryState,
	formatDate,
	Pagination,
	PaginationState,
	AccessioningSession,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

/* eslint-disable-next-line */
export interface ViewAccessioningSessionsProps {}

export default function ViewAccessioningSessions(
	props: ViewAccessioningSessionsProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();
	const navigate = useNavigate();

	const [query, setQuery] = useState<Query>({});
	const [refresh, setRefresh] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);
	const [data, setData] = useState<AccessioningSession[] | undefined>(
		undefined
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !user) return;
			else if (!unmounted) setData(undefined);

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/accessioner/search/accessioning-sessions?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					query
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setData(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		user,
		axios,
		query,
		refresh,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	const handleCreateSession = async (event: FormEvent) => {
		event.preventDefault();

		if (!axios || !user || disabled) return;

		toast.loading('Creating...');

		setDisabled(true);

		try {
			const {
				data: { data },
			} = await (
				await axios
			).post('/api/accessioner/accessioning-sessions');

			toast.dismiss();

			const parsedData = JSON.parse(data);
			console.log(parsedData.$oid);

			navigate(`/accessioner/accession/sessions/${parsedData.$oid}`);
		} catch (error) {
			console.log(error);
		}

		setDisabled(false);
		toast.dismiss();
	};

	return (
		<Container>
			<ContentWrapper heading="Accession" Icon="DocumentTextIcon">
				<ContentSection
					id="form"
					heading="All sessions"
					description="Select from an existing session or start a new one."
					headingAction={
						<Button
							type="button"
							tier="tertiary"
							Icon="PlusIcon"
							disabled={disabled}
							text="Create session"
							onClick={handleCreateSession}
						/>
					}
				>
					<AllSessionsTable
						data={data}
						query={{ query, setQuery }}
						setRefresh={setRefresh}
						pagination={{
							pagination,
							setPagination,
						}}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}

interface AllSessionsTableProps {
	query: QueryState;
	pagination: PaginationState;
	data: AccessioningSession[] | undefined;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

function AllSessionsTable(props: AllSessionsTableProps) {
	const navigate = useNavigate();
	const { query, setQuery } = props.query;

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Completed?</HeaderCell>
				<HeaderCellWithSearch
					type="text"
					name="name"
					label="Name"
					query={query}
					property="name"
					setQuery={setQuery}
					customHeaderWidth="w-64"
				/>
				<HeaderCell>Samples</HeaderCell>
				<HeaderCell>Start time</HeaderCell>
				<HeaderCell>Created by</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => {
							return (
								<TableRow key={index} index={index}>
									<DataCell>
										{datum.completed.value ? 'Yes' : 'No'}
									</DataCell>
									<DataCell>
										<ArrowLinkButton
											text={datum.name}
											onClick={() =>
												navigate(
													`/accessioner/accession/sessions/${datum._id.$oid}`
												)
											}
										/>
									</DataCell>
									<DataCell>{datum.samples.length}</DataCell>
									<DataCell>
										{formatDate(
											datum.metadata.date_created.$date,
											true
										)}
									</DataCell>
									<DataCell>
										{datum.metadata.created_by.first_name.concat(
											' ',
											datum.metadata.created_by.last_name
										)}
									</DataCell>
								</TableRow>
							);
						})
					) : (
						<EmptyTable
							heading="No sessions"
							Icon="DocumentTextIcon"
							description="Start by creating a new session"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
