import toast from 'react-hot-toast';
import { useId, useState, useEffect } from 'react';

import {
	Button,
	useAxios,
	Container,
	FormFooter,
	ContentWrapper,
	ContentSection,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ClinicData,
	Pagination,
	NonClinicData,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import PendingSamplesTable from '../../components/tables/pending-samples';
import TableSearchFiltersForm from '../../components/tables/lab-tech/table-search-filters-form';

const projectedFields = [
	'sample.priority',
	'metadata.accessioning.completed.value',
	'sample.pangea_id',
	'_id',
	'sample.specimen_id',
	'sample.assay',
	'sample.batch.name',
	'sample.sample_transport_storage_condition',
	'sample.sample_received_date',
	'sample.sample_collection_date',
	'sample.misc.transport_time_requirement.transport_by',
	'sample.misc.sample_stability.stable_until',
	'customer.first_name',
	'customer.last_name',
	'organization.name',
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewPendingSamplesProps {}

export default function ViewPendingSamples(props: ViewPendingSamplesProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const [query, setQuery] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [data, setData] = useState<
		(ClinicData | NonClinicData)[] | undefined
	>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setData(undefined);
				setDisabled(true);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/lab-tech/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query: {
							...query,
							'sample.status': 'pending',
						},
						projected_fields: projectedFields,
					}
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData, total_entries);

					setData(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);

			toast.dismiss();
		};

		if (!unmounted) fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	// Download button
	const [downloadDisabled, setDownloadDisabled] = useState(false);

	const handleDownloadResults = async () => {
		if (!axios || downloadDisabled) return;

		setDownloadDisabled(true);

		try {
			toast.loading('Downloading...', toastOptions);

			const { data } = await (
				await axios
			).get('/api/lab-tech/data/download/?sampleType=pending_samples', {
				responseType: 'arraybuffer',
			});

			toast.dismiss();

			const blob = new Blob([data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});

			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`Pending Samples - ${new Date().toDateString()}.xlsx`
			);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);

			setDownloadDisabled(false);
		} catch (error) {
			console.log(error);

			setDownloadDisabled(false);
		}
	};

	return (
		<Container>
			<ContentWrapper
				Icon="FingerPrintIcon"
				heading="Pending Samples"
				description="These are samples that have been approved by accessioning and need to be run."
			>
				<TableSearchFiltersForm
					tableType="pending-samples"
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection heading="All pending samples">
					<PendingSamplesTable
						data={data}
						pagination={{ pagination, setPagination }}
					/>

					<FormFooter>
						<Button
							type="submit"
							tier="tertiary"
							text="Download results"
							Icon="ArrowDownCircleIcon"
							disabled={downloadDisabled}
							onClick={handleDownloadResults}
						/>
					</FormFooter>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
