/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
	useId,
	Fragment,
	useState,
	MouseEvent,
	useContext,
	ChangeEvent,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Button,
	useAxios,
	FormGroup,
	TextButton,
	LoadingBox,
	FormFooter,
	SelectField,
	TextareaField,
	ContentSection,
	useTabsContext,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	BarAction,
	capitalize,
	Pagination,
	BAR_ACTIONS,
	SelectedWell,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import { ProcessSampleContext } from './process-sample-layout';
import RackWellSelectInputField from '../../../../../../components/rack/rack-well-select-input-field';
import RemoveSampleFromRackModal from '../../../../../../components/modals/remove-sample-from-rack-modal';
import {
	getAccessioningErrorsList,
	generateMessageTemplateContent,
	generateMessageTemplateOptions,
} from '../../helpers/process-sample-flow';

const selectedWellInitialValue = {
	index: 0,
	location: '',
	deselect: true,
	rack_name: '',
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AccessionBarSampleProps {}

export default function AccessionBarSample(props: AccessionBarSampleProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const navigate = useNavigate();
	const { setRefreshTabs } = useTabsContext();
	const { data } = useContext(ProcessSampleContext);
	const { action, assay, dataId } = useParams<{
		assay: string;
		dataId: string;
		action: BarAction;
	}>();

	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');
	const redirectPathQuery = redirectPath
		? `/?redirectPath=${redirectPath}`
		: '';

	const [formValues, setFormValues] = useState({
		bar_reason: '',
		bar_reason_template: '',
		review_flag_reason: '',
		review_flag_reason_template: '',
	});
	const [disabled, setDisabled] = useState(false);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);
	const [selectedWell, setSelectedWell] = useState<SelectedWell>(
		selectedWellInitialValue
	);
	const [notificationModalVisible, setNotificationModalVisible] =
		useState(false);

	const handleSubmit = async (event: MouseEvent) => {
		event.preventDefault();

		if (disabled || !data || !axios) return;
		else if (data.sample.sample_received_date && !selectedWell.location) {
			toast.error('Please select a well on a rack!');
			return;
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(`/api/accessioner/data/${data._id.$oid}/sample/${action}`, {
				user,
				form_data: {
					action,
					selected_well: selectedWell,
					reason: formValues.bar_reason,
					review_flag_reason: formValues.review_flag_reason,
				},
			});

			toast.dismiss();

			setRefreshTabs((value) => !value);

			if (BAR_ACTIONS.includes(data.sample.status)) {
				setNotificationModalVisible(true);
			} else {
				navigate(
					redirectPath
						? redirectPath
						: `/accessioner/accession/sessions/${data.accessioning_session._id.$oid}/preprocess`
				);
			}
		} catch (error) {
			console.log(error);
		}

		setDisabled(false);
	};

	const handleChange: ChangeEventHandler<
		HTMLTextAreaElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLTextAreaElement | HTMLSelectElement;

		if (target && target.name) {
			if (target.name.includes('template')) {
				if (data && action) {
					const reasonProperty = target.name.replace('_template', '');
					const contentOptions: Record<string, string> =
						generateMessageTemplateContent(
							data,
							data.sample.assay,
							reasonProperty === 'bar_reason' ? action : 'flag'
						);

					// Set fillables
					let message = contentOptions[target.value];

					if (!data.sample.specimen_id) {
						message = message.replace(
							' with specimen ID {SPECIMEN_ID}',
							''
						);
					} else {
						message = message.replace(
							'{SPECIMEN_ID}',
							data.sample.specimen_id
						);
					}

					if (
						target.value === 'missing_info' ||
						action === 'review'
					) {
						const errors = getAccessioningErrorsList(data);

						if (errors) {
							let finalString = '';

							for (let i = 0; i < errors.length; i++) {
								finalString += `${i + 1}. ${errors[i]}\n`;
							}

							message =
								message +
								' ' +
								(action === 'review'
									? 'The specimen has the following missing or discrepant information:'
									: 'Please provide the following information:') +
								'\n\n' +
								finalString;
						}
					}

					setFormValues((prevValues) => ({
						...prevValues,
						[target.name]: target.value,
						[reasonProperty]: message,
					}));
				}
			} else
				setFormValues((prevValues) => ({
					...prevValues,
					[target.name]: target.value,
				}));
		}
	};

	return !data || !action ? (
		<LoadingBox />
	) : (
		<Fragment>
			<RemoveSampleFromRackModal
				data={data}
				visible={{
					visible: notificationModalVisible,
					setVisible: setNotificationModalVisible,
				}}
			/>

			<ContentSectionDivider />

			<ContentSection
				heading={`${capitalize(action)} submission`}
				className="bg-gray-50 border border-gray-200 py-8 rounded-lg"
				description={`Provide a ${action} reason and, if a sample was received, a well location for the sample.`}
			>
				<Form>
					<FormGroup>
						<div className="sm:col-span-3">
							<SelectField
								label="Templates"
								handleSelect={handleChange}
								name="bar_reason_template"
								value={formValues.bar_reason_template}
								options={generateMessageTemplateOptions(
									data,
									data.sample.assay,
									action
								)}
							/>
						</div>
						<div className="sm:col-span-3"></div>
						<div className="sm:col-span-3">
							<TextareaField
								required
								label="Reason"
								name="bar_reason"
								value={formValues.bar_reason}
								handleInputChange={handleChange}
								placeholder={`e.g., ${
									action === 'flag'
										? 'The first name is wrong.'
										: 'This is a STAT sample that has discrepancies but must be approved for processing.'
								}`}
							/>
						</div>
						{data.sample.sample_received_date && (
							<div className="sm:col-span-6">
								<RackWellSelectInputField
									required
									rackType={action}
									selectedWell={selectedWell}
									setSelectedWell={setSelectedWell}
									pagination={{
										pagination,
										setPagination,
									}}
								/>
							</div>
						)}
					</FormGroup>

					<hr />

					{action === 'review' && (
						<FormGroup
							heading="Flag message"
							description="If there is a discrepancy, draft a flag reason. An email will be sent to the provider with the provided reason to resolve the discrepancy."
						>
							<div className="sm:col-span-3">
								<SelectField
									label="Templates"
									value={
										formValues.review_flag_reason_template
									}
									handleSelect={handleChange}
									name="review_flag_reason_template"
									options={generateMessageTemplateOptions(
										data,
										data.sample.assay,
										'flag'
									)}
								/>
							</div>
							<div className="sm:col-span-3"></div>
							<div className="sm:col-span-3">
								<TextareaField
									required={
										!data.metadata.accessioning.completed
											.value
											? true
											: undefined
									}
									label="Flag reason"
									name="review_flag_reason"
									handleInputChange={handleChange}
									value={formValues.review_flag_reason}
									placeholder="e.g., The first name is wrong."
								/>
							</div>
						</FormGroup>
					)}

					<FormFooter className="!justify-between">
						<TextButton
							color="gray"
							type="button"
							text={<span>&#8592; Back</span>}
							onClick={() =>
								navigate(
									`/accessioner/accession/sample/${assay}/data/${dataId}/non-clinic-sample/verify-sample${redirectPathQuery}`
								)
							}
						/>
						<Button
							type="button"
							tier="tertiary"
							disabled={disabled}
							Icon={
								action === 'flag'
									? 'FlagIcon'
									: 'DocumentCheckIcon'
							}
							onClick={handleSubmit}
							text={
								action === 'review'
									? 'Send request to review'
									: capitalize(action)
							}
						/>
					</FormFooter>
				</Form>
			</ContentSection>
		</Fragment>
	);
}
