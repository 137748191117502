import { Routes, Route } from 'react-router-dom';

import ViewOrder from './orders/view-order';
import ViewOrders from './orders/view-orders';
import ViewCustomer from '../shared/customers/view-customer';
// import ViewCustomers from '../shared/user-management/view-customers';

import RoleProtectedRoute from '../../../utils/routing';

export function ShippingRoutes() {
	return (
		<Routes>
			<Route
				element={
					<RoleProtectedRoute
						roles={['BITSS_shipping', 'BITSS_super-admin']}
					/>
				}
			>
				<Route path="view/orders" element={<ViewOrders />} />
				<Route path="view/orders/:orderId" element={<ViewOrder />} />
				{/* <Route path="view/customers" element={<ViewCustomers />} /> */}
				<Route
					path="view/customers/:customerId"
					element={<ViewCustomer />}
				/>
			</Route>
		</Routes>
	);
}
