import { useParams } from 'react-router-dom';
import { useId, useState, useEffect, Fragment } from 'react';

import {
	Button,
	useAxios,
	Container,
	LoadingBox,
	ContentSection,
	ContentWrapper,
	DescriptionList,
	DescriptionItem,
	OrderItemsSummary,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	Query,
	Shipment,
	Pagination,
	HydratedOrder,
	CustomerOrderStats,
	getRoleFromPathname,
	BaseHydratedCustomer,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import AllOrdersTable from '../../../components/tables/shipping/all-orders';
import RecordShipmentModal from '../../../components/modals/record-shipment';
import AllShipmentsTable from '../../../components/tables/shipping/all-shipments';

/* eslint-disable-next-line */
export interface ViewCustomerProps {}

export default function ViewCustomer(props: ViewCustomerProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const role = getRoleFromPathname();
	const { customerId } = useParams();
	const isAccessioner = role.includes('accessioner');

	const [refresh, setRefresh] = useState(false);
	const [customer, setCustomer] = useState<BaseHydratedCustomer | undefined>(
		undefined
	);

	// For orders
	const [query, setQuery] = useState<Query>({});
	const [orders, setOrders] = useState<HydratedOrder[] | undefined>(
		undefined
	);
	const [ordersPagination, setOrdersPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const [
					{
						data: { data: customer },
					},
					{
						data: {
							data: { data: ordersInfo, total_entries },
						},
					},
				] = await Promise.all([
					(await axios).get(`/api/${role}/customers/${customerId}`),
					(
						await axios
					).post(
						`/api/${role}/search/orders/?page_number=${ordersPagination.page_number}&entries_per_page=${ordersPagination.entries_per_page}`,
						{
							customer: customerId,
							...query,
						}
					),
				]);

				if (!unmounted) {
					// Purchaser info
					const parsedCustomer = JSON.parse(customer);
					console.log(parsedCustomer);

					setCustomer(parsedCustomer);

					// Orders info
					const orders = JSON.parse(ordersInfo);
					console.log(orders);

					setOrders(orders);
					setOrdersPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		if (customerId) fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		role,
		query,
		axios,
		refresh,
		customerId,
		ordersPagination.page_number,
		ordersPagination.entries_per_page,
	]);

	// For shipments
	const [customerOrderStats, setCustomerOrderStats] = useState<
		CustomerOrderStats | undefined
	>(undefined);
	const [shipments, setShipments] = useState<Shipment[] | undefined>(
		undefined
	);
	const [shipmentsPagination, setShipmentsPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!customerId || !axios) return;

			try {
				const [
					{
						data: {
							data: { data: data1, total_entries },
						},
					},
					{
						data: { data: data2 },
					},
				] = await Promise.all([
					(
						await axios
					).post(
						`/api/${role}/search/shipments/?page_number=${shipmentsPagination.page_number}&entries_per_page=${shipmentsPagination.entries_per_page}`,
						{
							customer: customerId,
							...query,
						}
					),
					(
						await axios
					).get(`/api/${role}/customers/${customerId}/order-stats`),
				]);

				if (!unmounted) {
					// Shipments information
					const parsedData1 = JSON.parse(data1);
					console.log(parsedData1);

					setShipments(parsedData1);
					setShipmentsPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));

					// Purchaser order stats
					const parsedData2 = JSON.parse(data2);
					console.log(parsedData2);

					setCustomerOrderStats(parsedData2);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		role,
		query,
		axios,
		refresh,
		customerId,
		shipmentsPagination.page_number,
		shipmentsPagination.entries_per_page,
	]);

	// For records shipment modal
	const [modalVisible, setModalVisible] = useState(false);

	return (
		<Fragment>
			{isAccessioner && customer && (
				<RecordShipmentModal
					setRefresh={setRefresh}
					customer={customer?._id.$oid}
					visible={{
						visible: modalVisible,
						setVisible: setModalVisible,
					}}
				/>
			)}
			<Container>
				<ContentWrapper
					Icon="UserIcon"
					heading="Customer details"
					description="View customer details, order statistics, and shipment information for the customer."
				>
					{!customer ? (
						<LoadingBox />
					) : (
						<Fragment>
							{customerOrderStats && (
								<Fragment>
									<ContentSection
										id="purchaser_stats"
										heading="Purchaser stats"
										description={
											<span>
												<span className="italic">
													Orders
												</span>{' '}
												is the number of units sent out.{' '}
												<span className="italic">
													Shipments
												</span>{' '}
												is the number of units received.{' '}
												<span className="italic">
													Results
												</span>{' '}
												is the number of units the
												customer has in their
												possession. For results, a
												negative quantity indicates that
												more was provided than received.
											</span>
										}
									>
										<DescriptionList>
											<DescriptionItem term="Orders">
												{customerOrderStats.orders
													.length && (
													<OrderItemsSummary
														// eslint-disable-next-line jsx-a11y/aria-role
														role="associate"
														minified={true}
														items={
															customerOrderStats.orders
														}
													/>
												)}
											</DescriptionItem>
											<DescriptionItem term="Shipments">
												{customerOrderStats.shipments
													.length && (
													<OrderItemsSummary
														// eslint-disable-next-line jsx-a11y/aria-role
														role="associate"
														minified={true}
														items={
															customerOrderStats.shipments
														}
													/>
												)}
											</DescriptionItem>
											<DescriptionItem term="Results">
												{customerOrderStats.results
													.length && (
													<OrderItemsSummary
														// eslint-disable-next-line jsx-a11y/aria-role
														role="associate"
														minified={true}
														items={
															customerOrderStats.results
														}
													/>
												)}
											</DescriptionItem>
										</DescriptionList>
									</ContentSection>

									<ContentSectionDivider />
								</Fragment>
							)}

							<ContentSection
								heading="Details"
								id="personal_information"
							>
								<DescriptionList>
									<DescriptionItem
										term="Organization"
										details={
											customer.organization &&
											customer.organization.name
										}
									/>
									<div className="sm:col-span-2"></div>
									<DescriptionItem
										term="First Name"
										details={customer.first_name}
									/>
									<DescriptionItem
										term="Last Name"
										details={customer.last_name}
									/>
									{customer.national_provider_identifier && (
										<DescriptionItem
											term="National Provider Identifier (NPI)"
											details={
												customer.national_provider_identifier
											}
										/>
									)}
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection
								heading="Contact"
								id="contact_information"
							>
								<DescriptionList>
									<DescriptionItem
										term="Email Address"
										details={customer.email_address}
									/>
									<DescriptionItem
										term="Phone Number"
										details={customer.phone_number}
									/>
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection
								heading="Address"
								id="address_information"
							>
								<DescriptionList>
									<DescriptionItem
										term="Address"
										details={customer.address}
									/>
									<div className="sm:col-span-2"></div>
									<DescriptionItem
										term="City"
										details={customer.city}
									/>
									<DescriptionItem
										term="State"
										details={customer.state}
									/>
									<DescriptionItem
										term="ZIP Code"
										details={customer.zip_code}
									/>
								</DescriptionList>
							</ContentSection>
						</Fragment>
					)}

					{orders && (
						<Fragment>
							<ContentSectionDivider />

							<ContentSection heading="Orders">
								<AllOrdersTable
									data={orders}
									query={{ query, setQuery }}
									pagination={{
										pagination: ordersPagination,
										setPagination: setOrdersPagination,
									}}
								/>
							</ContentSection>
						</Fragment>
					)}

					{orders && (
						<Fragment>
							<ContentSectionDivider />

							<ContentSection
								heading="Shipments"
								description="All shipments from this customer."
								headingAction={
									isAccessioner && (
										<Button
											type="button"
											tier="tertiary"
											Icon="PlusIcon"
											text="Process shipment"
											onClick={() =>
												setModalVisible(true)
											}
										/>
									)
								}
							>
								<AllShipmentsTable
									data={shipments}
									setRefresh={setRefresh}
									pagination={{
										pagination: shipmentsPagination,
										setPagination: setShipmentsPagination,
									}}
								/>
							</ContentSection>
						</Fragment>
					)}
				</ContentWrapper>
			</Container>
		</Fragment>
	);
}
