import { useNavigate } from 'react-router-dom';

import {
	TextButton,
	ContentSection,
	DescriptionList,
	DescriptionItem,
} from '@pangea-lis-apps/ui';
import { Batch, getRoleFromPathname } from '@pangea-lis-apps/utils';

export const BatchStatus = ({ batch }: { batch: Batch | null | undefined }) => {
	const navigate = useNavigate();
	const role = getRoleFromPathname();

	return (
		<ContentSection heading="Batch QC status">
			<DescriptionList>
				<DescriptionItem term="Name">
					{batch && (
						<TextButton
							text={batch.name}
							color="blue"
							type="button"
							onClick={() => {
								navigate(
									`/${role}/view/batches/${batch._id.$oid}`
								);
							}}
						/>
					)}
				</DescriptionItem>
				<DescriptionItem
					term="Completed?"
					details={
						batch
							? batch.qc.passed === null
								? null
								: batch.qc.passed
								? 'Acceptable'
								: 'Failed'
							: null
					}
				/>
			</DescriptionList>
		</ContentSection>
	);
};
