export const CLINIC_ORGANIZATION_STAFF_ROLE_ID = 'rol_1nzkTMyRp9fk2R7F';
export const CLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID = 'rol_C728S0AJ3Bdptqz6';
export const NONCLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID = 'rol_vPXVNjs4UlwO80ss';

export type OrganizationRoleID =
	| 'rol_1nzkTMyRp9fk2R7F'
	| 'rol_C728S0AJ3Bdptqz6'
	| 'rol_vPXVNjs4UlwO80ss';

export const ORGANIZATION_ROLE_MAPPING = {
	[CLINIC_ORGANIZATION_STAFF_ROLE_ID]: 'Staff',
	[CLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID]: 'Associate',
	[NONCLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID]: 'Associate',
};
