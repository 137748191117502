export interface formData {
	specimen_id: string;
	sample_collection_date: string;
	sample_received_date: string;
	provider_first_name: string;
	provider_last_name: string;
	organization_address_address: string;
	organization_address_city: string;
	organization_address_state: string;
	organization_address_zip_code: string;
	organization_address_country: string;
	organization_phone_number: string;
	patient_first_name: string;
	patient_last_name: string;
	patient_middle_name: string;
	patient_date_of_birth: string;
	patient_sex: string;
	patient_medical_record_number: string;
	comments?: [];
	additional?: string;
}

export interface IComment {
	edited?: boolean;
	value: string;
	id?: string;
	metadata?: object;
}

export interface IPayLoad {
	updated_values: object;
	revision_comment: string;
}

export const initPayLoad = {
	updated_values: {},
	revision_comment: '',
};

export function mapFormData(parsedData: any) {
	const sample = {
		specimen_id: parsedData.sample.specimen_id,
		sample_collection_date: parsedData.sample.sample_collection_date,
		sample_received_date: parsedData.sample.sample_received_date,
	};

	const trf = {
		provider_first_name: parsedData.requisition_form.provider_first_name,
		provider_last_name: parsedData.requisition_form.provider_last_name,
		organization_address_address:
			parsedData.requisition_form.organization_address_address,
		organization_address_city:
			parsedData.requisition_form.organization_address_city,
		organization_address_state:
			parsedData.requisition_form.organization_address_state,
		organization_address_zip_code:
			parsedData.requisition_form.organization_address_zip_code,
		organization_address_country:
			parsedData.requisition_form.organization_address_country,
		organization_phone_number:
			parsedData.requisition_form.organization_phone_number,
		patient_first_name: parsedData.requisition_form.patient_first_name,
		patient_last_name: parsedData.requisition_form.patient_last_name,
		patient_middle_name: parsedData.requisition_form.patient_middle_name,
		patient_date_of_birth:
			parsedData.requisition_form.patient_date_of_birth,
		patient_sex: parsedData.requisition_form.patient_sex,
		patient_medical_record_number:
			parsedData.requisition_form.patient_medical_record_number,
	};

	const formdata = {
		...sample,
		...trf,
	};

	return formdata;
}

export function mapNonClinicFormData(parsedData: any) {
	const sample = {
		specimen_id: parsedData.sample.specimen_id,
		sample_collection_date: parsedData.sample.sample_collection_date,
		sample_received_date: parsedData.sample.sample_received_date,
	};

	const formdata = {
		...sample,
	};

	return formdata;
}

export interface INonClinicFormData {
	specimen_id: string;
	sample_collection_date: string;
	sample_received_date: string;
}

export function mapClinicFormDataStiV1(parsedData: any) {
	const sample = {
		specimen_id: parsedData.sample.specimen_id,
		sample_collection_date: parsedData.sample.sample_collection_date,
		sample_received_date: parsedData.sample.sample_received_date,
		provider_first_name: parsedData.requisition_form.provider_first_name,
		provider_last_name: parsedData.requisition_form.provider_last_name,
		organization_address_address:
			parsedData.requisition_form.organization_address_address,
		organization_address_city:
			parsedData.requisition_form.organization_address_city,
		organization_address_state:
			parsedData.requisition_form.organization_address_state,
		organization_address_zip_code:
			parsedData.requisition_form.organization_address_zip_code,
		organization_address_country:
			parsedData.requisition_form.organization_address_country,
		organization_phone_number:
			parsedData.requisition_form.organization_phone_number,
		patient_first_name: parsedData.requisition_form.patient_first_name,
		patient_last_name: parsedData.requisition_form.patient_last_name,
		patient_middle_name: parsedData.requisition_form.patient_middle_name,
		patient_date_of_birth:
			parsedData.requisition_form.patient_date_of_birth,
		patient_sex: parsedData.requisition_form.patient_sex,
		patient_medical_record_number:
			parsedData.requisition_form.patient_medical_record_number,
	};

	const formdata = {
		...sample,
	};

	return formdata;
}
