/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useNavigate, useLocation } from 'react-router-dom';
import {
	Fragment,
	Dispatch,
	// useState,
	SetStateAction,
} from 'react';

import {
	ASSAYS,
	ClinicData,
	formatDate,
	QueryState,
	ORGANIZATIONS,
	PaginationState,
	// REQUISITION_FORM,
	getLabelFromValue,
} from '@pangea-lis-apps/utils';
import {
	Link,
	Table,
	DataCell,
	TableRow,
	TableBody,
	TableHead,
	EmptyTable,
	HeaderCell,
	TableLoading,
	ArrowLinkButton,
} from '@pangea-lis-apps/ui';

// import ApproveFlaggedSample from '../modals/approve-flagged-sample';

interface AllFlaggedSamplesTableProps {
	query: QueryState;
	pagination: PaginationState;
	data: ClinicData[] | undefined;
	setUpdateTable: Dispatch<SetStateAction<boolean>>;
}

export default function AllFlaggedSamplesTable(
	props: AllFlaggedSamplesTableProps
) {
	const { pathname } = useLocation();

	const navigate = useNavigate();

	// const [approveModalVisible, setApproveModalVisible] = useState(false);
	// const [selectedSample, setSelectedSample] = useState<
	// 	ClinicData | undefined
	// >(undefined);

	return (
		<Fragment>
			{/* <ApproveFlaggedSample
				visible={{
					visible: approveModalVisible,
					setVisible: setApproveModalVisible,
				}}
				setQuery={setQuery}
				data={selectedSample}
				setUpdateTable={props.setUpdateTable}
			/> */}
			<Table pagination={props.pagination}>
				<TableHead>
					<HeaderCell>Actions</HeaderCell>
					<HeaderCell>Sample received?</HeaderCell>
					<HeaderCell>Rack Name</HeaderCell>
					<HeaderCell>Well</HeaderCell>
					<HeaderCell>Assay</HeaderCell>
					<HeaderCell>Priority Level</HeaderCell>
					<HeaderCell>RUO</HeaderCell>
					<HeaderCell>Customer</HeaderCell>
					<HeaderCell>Organization</HeaderCell>
					<HeaderCell>Pangea ID</HeaderCell>
					<HeaderCell>Specimen ID</HeaderCell>
					<HeaderCell>Collection Kit ID</HeaderCell>
					<HeaderCell>Patient First Name</HeaderCell>
					<HeaderCell>Patient Last Name</HeaderCell>
					<HeaderCell>Date of Birth</HeaderCell>
					<HeaderCell>Collection Date</HeaderCell>
				</TableHead>
				<TableBody>
					{props.data ? (
						props.data.length ? (
							props.data.map((datum, index) => {
								const clinicSample = datum.type === 'clinic';

								return (
									<TableRow key={index} index={index}>
										<DataCell>
											<Link
												text="Update"
												path={
													clinicSample
														? `/accessioner/accession/trf/${datum.sample.assay}/v${datum.requisition_form.metadata.version}/data/${datum._id.$oid}/trf/review/?redirectPath=${pathname}`
														: `/accessioner/accession/sample/${datum.sample.assay}/data/${datum._id.$oid}/non-clinic-sample/?redirectPath=${pathname}`
												}
											/>
										</DataCell>
										<DataCell>
											{datum.sample.sample_received_date
												? 'Yes'
												: 'No'}
										</DataCell>
										<DataCell>
											{
												datum.sample
													.accessioning_approval.flag
													.selected_well.rack_name
											}
										</DataCell>
										<DataCell>
											{
												datum.sample
													.accessioning_approval.flag
													.selected_well.location
											}
										</DataCell>
										<DataCell>
											{getLabelFromValue(
												datum.sample.assay,
												ASSAYS['assays']
											)}
										</DataCell>
										<DataCell>
											{getLabelFromValue(
												datum.sample.priority,
												ORGANIZATIONS.priorityLevels
											)}
										</DataCell>
										<DataCell>
											{datum.sample.ruo === null
												? null
												: datum.sample.ruo
												? 'Yes'
												: 'No'}
										</DataCell>
										<DataCell>
											{datum.customer.first_name.concat(
												' ',
												datum.customer.last_name
											)}
										</DataCell>
										<DataCell>
											{datum.organization.name}
										</DataCell>
										<DataCell>
											<ArrowLinkButton
												text={datum.sample.pangea_id}
												onClick={() =>
													navigate(
														`/accessioner/view/samples/${datum._id.$oid}`
													)
												}
											/>
										</DataCell>
										<DataCell>
											{datum.sample.specimen_id}
										</DataCell>
										<DataCell>
											{datum.sample.collection_kit_id}
										</DataCell>
										<DataCell>
											{datum.patient
												? datum.patient.first_name
												: null}
										</DataCell>
										<DataCell>
											{datum.patient
												? datum.patient.last_name
												: null}
										</DataCell>
										<DataCell>
											{datum.patient
												? formatDate(
														datum.patient
															.date_of_birth
												  )
												: null}
										</DataCell>
										<DataCell>
											{formatDate(
												datum.sample
													.sample_collection_date
											)}
										</DataCell>
									</TableRow>
								);
							})
						) : (
							<EmptyTable
								Icon="FingerPrintIcon"
								heading="No flagged samples"
							/>
						)
					) : (
						<TableLoading />
					)}
				</TableBody>
			</Table>
		</Fragment>
	);
}
