/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	useId,
	useState,
	Fragment,
	Dispatch,
	FormEvent,
	useEffect,
	MouseEvent,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Alert,
	Modal,
	Button,
	useAxios,
	FormGroup,
	Container,
	TextButton,
	FormFooter,
	LoadingBox,
	TextareaField,
	ContentWrapper,
	ContentSection,
	TRFComponents,
	DescriptionItem,
	DescriptionList,
	ContentSectionDivider,
	SpecimenInfo,
} from '@pangea-lis-apps/ui';
import { ClinicData } from '@pangea-lis-apps/utils';

import Notes from '../../../components/notes';

interface ModalState {
	verify: boolean;
	addNote: boolean;
	// requestFlag: boolean;
}

const initialModalState = {
	verify: false,
	addNote: false,
	// requestFlag: false,
};

export interface ViewHeldSampleProps {
	function?: string;
}

export default function ViewHeldSample(props: ViewHeldSampleProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { dataId } = useParams();
	const navigate = useNavigate();

	const [refresh, setRefresh] = useState(false);
	const [data, setData] = useState<ClinicData | undefined>(undefined);
	const [modalVisible, setModalVisible] =
		useState<ModalState>(initialModalState);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(`/api/medical-biller/data/${dataId}`);

				if (!unmounted) {
					// requisition form data
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setData(parsedData);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, refresh, dataId]);

	return (
		<Container>
			<AddNoteModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			{/* <RequestFlagModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/> */}
			<VerifyModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<ContentWrapper
				subheading="On hold"
				Icon="DocumentTextIcon"
				heading="Sample Details"
				description="Update any incorrect information. Otherwise, mark as verified."
			>
				{!data ? (
					<LoadingBox />
				) : (
					<Fragment>
						<SpecimenInfo data={data} />

						<ContentSectionDivider />

						<TRFComponents.PatientInformation data={data} />

						<ContentSectionDivider />

						<TRFComponents.BillingInformation
							data={data}
							headingAction={
								<TextButton
									color="blue"
									text="Update"
									type="button"
									onClick={() =>
										navigate(
											`/medical-biller/view/samples/${dataId}/update-billing-info`
										)
									}
								/>
							}
						/>

						<ContentSectionDivider />

						<ContentSection heading="Miscellaneous">
							<DescriptionList>
								<DescriptionItem
									term="Notes"
									customColSpan="sm:col-span-3"
								>
									<Notes
										id={dataId}
										collection="data"
										setRefresh={setRefresh}
										data={data.notes}
										receiveDepartment="medical_billing"
									/>
								</DescriptionItem>
							</DescriptionList>
						</ContentSection>

						{/* {!data.sample.accessioning_approval.hold.reviewed
							.value && (
							<ContentSection>
								<FormFooter>
									<Button
										type="button"
										tier="tertiary"
										Icon="CheckIcon"
										text="Mark as verified"
										onClick={() =>
											setModalVisible((prevValue) => ({
												...prevValue,
												verify: true,
											}))
										}
									/>
								</FormFooter>
							</ContentSection>
						)} */}
					</Fragment>
				)}
			</ContentWrapper>
		</Container>
	);
}

interface ModalProps {
	data: ClinicData | undefined;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: ModalState;
		setVisible: Dispatch<SetStateAction<ModalState>>;
	};
}

function AddNoteModal(props: ModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const { visible, setVisible } = props.visible;

	const [note, setNote] = useState('');
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios || !props.data) return;

		setDisabled(true);

		toast.loading('Adding note...', toastOptions);

		try {
			await (
				await axios
			).post(`/api/medical-biller/data/${props.data._id.$oid}/note`, {
				user,
				note,
			});

			toast.dismiss();

			setNote('');
			setDisabled(false);
			setVisible((prevValue) => ({
				...prevValue,
				addNote: false,
			}));

			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLTextAreaElement;

		if (target && target.name) setNote(target.value);
	};

	return (
		<Modal
			title="Add note"
			customWidth="max-w-md"
			visible={visible.addNote}
			onClose={() =>
				setVisible((prevValue) => ({
					...prevValue,
					addNote: false,
				}))
			}
			description="Notes are for internal use only."
		>
			{!props.data ? (
				<LoadingBox />
			) : (
				<Form>
					<FormGroup>
						<div className="sm:col-span-6">
							<TextareaField
								required
								name="note"
								value={note}
								label="Note"
								maxLength={220}
								handleInputChange={handleChange}
								placeholder="e.g., The clinic did not pick up our call. Will try again later."
							/>
						</div>
					</FormGroup>
					<FormFooter>
						<Button
							text="Add"
							type="button"
							tier="tertiary"
							Icon="PlusIcon"
							disabled={disabled}
							onClick={handleSubmit}
						/>
					</FormFooter>
				</Form>
			)}
		</Modal>
	);
}

// export function RequestFlagModal(props: ModalProps) {
// 	const toastId = useId();
// 	const axios = useAxios(toastId);
// 	const toastOptions = { id: toastId };

// 	const { user } = useAuth0();
// 	const { visible, setVisible } = props.visible;

// 	const [reason, setReason] = useState('');
// 	const [disabled, setDisabled] = useState(false);

// 	const handleSubmit = async (event: MouseEvent) => {
// 		event.preventDefault();

// 		if (disabled || !props.data || !axios) return;

// 		setDisabled(true);

// 		toast.loading('Requesting...', toastOptions);

// 		try {
// 			await (
// 				await axios
// 			).patch(`/api/medical-biller/data/${props.data._id.$oid}/flag`, {
// 				user,
// 				reason,
// 			});

// 			toast.dismiss();
// 			handleClose();

// 			setDisabled(false);
// 			props.setRefresh((value) => !value);
// 		} catch (error) {
// 			setDisabled(false);
// 		}
// 	};

// 	const handleChange: ChangeEventHandler<
// 		HTMLTextAreaElement | HTMLSelectElement
// 	> = (event: ChangeEvent) => {
// 		const target = event.target as HTMLTextAreaElement;

// 		if (target && target.name) setReason(target.value);
// 	};

// 	const handleClose = () => {
// 		setReason('');
// 		setVisible((prevValue) => ({
// 			...prevValue,
// 			requestFlag: false,
// 		}));
// 	};

// 	return (
// 		<Modal
// 			onClose={handleClose}
// 			customWidth="max-w-md"
// 			title="Request to flag"
// 			visible={visible.requestFlag}
// 			description="If there is an error in the billing information, request to flag the sample. The accessioner will process the sample."
// 		>
// 			<Form>
// 				<FormGroup>
// 					<div className="sm:col-span-6">
// 						<TextareaField
// 							required
// 							name="reason"
// 							label="Reason"
// 							value={reason}
// 							handleInputChange={handleChange}
// 							placeholder="e.g., The first name of the primary insurance's subscriber information does not match what we have on record."
// 						/>
// 					</div>
// 				</FormGroup>
// 				<FormFooter>
// 					<Button
// 						type="button"
// 						Icon="FlagIcon"
// 						tier="tertiary"
// 						disabled={disabled}
// 						text="Request to flag"
// 						onClick={handleSubmit}
// 					/>
// 				</FormFooter>
// 			</Form>
// 		</Modal>
// 	);
// }

export function VerifyModal(props: ModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const { visible, setVisible } = props.visible;

	const [notes, setNotes] = useState('');
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async (event: MouseEvent) => {
		event.preventDefault();

		if (disabled || !props.data || !axios) return;

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(`/api/medical-biller/data/verify`, {
				user,
				notes: notes,
				data_ids: [props.data._id.$oid],
			});

			toast.dismiss(toastId);

			handleClose();
			setDisabled(false);
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLTextAreaElement;

		if (target && target.name) setNotes(target.value);
	};

	const handleClose = () => {
		setNotes('');
		setVisible((prevValue) => ({
			...prevValue,
			verify: false,
		}));
	};

	return (
		<Modal
			onClose={handleClose}
			customWidth="max-w-md"
			visible={visible.verify}
			title="Mark as verified"
			description="Only mark as verified if all the billing information is correct and the corresponding insurance is payable and/or patient payment has been received. The provided notes will be shared with the accessioning team."
		>
			<div className="space-y-4">
				<Alert
					type="info"
					heading="Reminder"
					description="Please check both the primary and/or secondary payer information. If the payer is the patient, confirm that the patient has paid the invoiced amount and to whom the payment was made."
				/>
				<Form>
					<FormGroup>
						<div className="sm:col-span-6">
							<TextareaField
								name="notes"
								label="Notes"
								value={notes}
								handleInputChange={handleChange}
								placeholder="e.g., The patient payment was made to our facility."
							/>
						</div>
					</FormGroup>
					<FormFooter>
						<Button
							type="button"
							tier="tertiary"
							Icon="CheckIcon"
							disabled={disabled}
							onClick={handleSubmit}
							text="Mark as verified"
						/>
					</FormFooter>
				</Form>
			</div>
		</Modal>
	);
}
