/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import {
	useId,
	useState,
	Fragment,
	Dispatch,
	useEffect,
	useCallback,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Button,
	useAxios,
	FormGroup,
	DateField,
	FormFooter,
	GenericField,
} from '@pangea-lis-apps/ui';
import {
	Patient,
	ClinicData,
	Pagination,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import SearchPatientsModal from '../components/search-patients-modal';
import { removeEmptyValues } from '../../../../../../../../utils/helpers/objects';

interface SearchFormValues {
	patient_first_name: string;
	patient_middle_name: string;
	patient_last_name: string;
	patient_date_of_birth: string;
}

const searchInitialFormValues = {
	patient_first_name: '',
	patient_middle_name: '',
	patient_last_name: '',
	patient_date_of_birth: '',
};

interface SearchPatientFormProps {
	data: ClinicData | undefined;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export default function SearchPatientForm({
	data,
	setRefresh,
}: SearchPatientFormProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [searchFormValues, setSearchFormValues] = useState<SearchFormValues>(
		searchInitialFormValues
	);

	// For search modal
	const [visible, setVisible] = useState(false);
	const [patients, setPatients] = useState<Patient[] | undefined>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	const handleSearch = async () => {
		const toastOptions = { id: toastId };

		if (!axios || !data) return;
		else if (
			!searchFormValues.patient_first_name &&
			!searchFormValues.patient_last_name &&
			!searchFormValues.patient_date_of_birth &&
			!searchFormValues.patient_middle_name
		) {
			toast.error('Please enter a search criteria!');
			return;
		}

		toast.loading('Searching...', toastOptions);

		// Generate query
		const query = removeEmptyValues({
			first_name: searchFormValues.patient_first_name,
			last_name: searchFormValues.patient_last_name,
			date_of_birth: searchFormValues.patient_date_of_birth,
			middle_name: searchFormValues.patient_middle_name,
		});

		try {
			const {
				data: {
					data: { data: patientsData, total_entries },
				},
			} = await (
				await axios
			).post(
				`/api/accessioner/search/patients/?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
				{
					...query,
				}
			);

			const parsedData = JSON.parse(patientsData);
			console.log(parsedData);

			if (parsedData.length) {
				// Only show modal if matching patient records were found
				setVisible(true);
				setPatients(parsedData);
				setPagination((prev) => ({
					...prev,
					total_entries: total_entries,
				}));
				toast.dismiss();
			} else {
				toast.error('No matching patient records!', toastOptions);
			}
		} catch (error) {
			console.log(error);
		}
	};

	// Trigger handleSearch when pagination.page_number changes
	useEffect(() => {
		handleSearch();
	}, [pagination.page_number]);

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name)
			setSearchFormValues((prevValues) => ({
				...prevValues,
				[target.name]: target.value,
			}));
	};

	return (
		<Fragment>
			<SearchPatientsModal
				data={data}
				patients={patients}
				patient={{
					first_name: searchFormValues.patient_first_name,
					last_name: searchFormValues.patient_last_name,
					date_of_birth: searchFormValues.patient_date_of_birth,
					middle_name: searchFormValues.patient_middle_name,
				}}
				setRefresh={setRefresh}
				paginationState={{
					pagination,
					setPagination,
				}}
				modalVisible={{
					visible,
					setVisible,
				}}
			/>

			<Form>
				<FormGroup
					heading="Search patient in database"
					className="bg-gray-50 p-4 border border-gray-200 rounded-lg"
				>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							name="patient_first_name"
							label="First Name"
							showRequiredAsterisk={true}
							placeholder="e.g., Sarah"
							value={searchFormValues.patient_first_name}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Middle Name"
							placeholder="e.g., Taylor"
							name="patient_middle_name"
							handleInputChange={handleChange}
							value={searchFormValues.patient_middle_name}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							name="patient_last_name"
							label="Last Name"
							showRequiredAsterisk={true}
							placeholder="e.g., Chang"
							value={searchFormValues.patient_last_name}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2">
						<DateField
							label="Date of Birth"
							name="patient_date_of_birth"
							showRequiredAsterisk={true}
							handleInputChange={handleChange}
							value={searchFormValues.patient_date_of_birth}
						/>
					</div>
					<div className="sm:col-span-6">
						<FormFooter>
							<Button
								text="Search"
								type="button"
								tier="tertiary"
								onClick={handleSearch}
								Icon="MagnifyingGlassIcon"
							/>
						</FormFooter>
					</div>
				</FormGroup>
			</Form>
		</Fragment>
	);
}
