import { useParams } from 'react-router-dom';
import ProcessNonClinicBladderCARESample from './bladdercare';
import ProcessNonClinicPrecisionBIOMEWoundSample from './precision_biome_wound';
import ProcessResp4PlexNonClinicSample from './resp4plex';
import ProcessSTINonClinicSample from './sti';
import ProcessNonClinicFITSample from './fit'

export default function ProcessSample() {
	const { assay } = useParams();

	if (assay === 'sti') return <ProcessSTINonClinicSample />;
	else if (assay === 'bladdercare')
		return <ProcessNonClinicBladderCARESample />;
	else if (assay === 'precision_biome_wound')
		return <ProcessNonClinicPrecisionBIOMEWoundSample />;
	else if (assay === 'resp4plex')
		return <ProcessResp4PlexNonClinicSample />;
	else if (assay === 'fit')
		return <ProcessNonClinicFITSample />
	
	return null;
}
