import toast from 'react-hot-toast';
import { useId, useState, useEffect } from 'react';

import {
	useAxios,
	Container,
	ContentWrapper,
	ContentSection,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ClinicData,
	Pagination,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import ViewReportsTable from '../../../components/tables/view-reports';
import TableSearchFiltersForm from '../../../components/tables/cls/table-search-filters-form';

const projectedFields = [
	'type',
	'sample.pangea_id',
	'_id',
	'sample.priority',
	'sample.specimen_id',
	'sample.collection_kit_id',
	'customer.first_name',
	'customer.last_name',
	'organization.name',
	'sample.assay',
	'sample.assay',
	'sample.sample_specimen_type',
	'sample.sample_collection_date',
	'sample.sample_received_date',
	'sample.report.verified.value',
	'sample.report.verified.metadata.date',
	'patient.first_name',
	'patient.last_name',
	'patient.date_of_birth',
	'sample.sample_specimen_type',
	'sample.results',
];

/* eslint-disable-next-line */
export interface SearchReportsProps {}

export default function SearchReports(props: SearchReportsProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [query, setQuery] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [data, setData] = useState<ClinicData[] | undefined>([]);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !query) return;
			else if (!unmounted) {
				setDisabled(true);
				setData(undefined);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/cls/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query: { ...query, 'sample.status': 'verified' },
						projected_fields: projectedFields,
					}
				);

				toast.dismiss();

				const parsedData = JSON.parse(data);
				console.log(parsedData, total_entries);

				if (!unmounted) {
					setData(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);

			toast.dismiss();
		};

		if (!unmounted) fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	return (
		<Container>
			<ContentWrapper heading="Search Reports" Icon="MagnifyingGlassIcon">
				<TableSearchFiltersForm
					tableType="search-reports"
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection heading="Available reports">
					<ViewReportsTable
						data={data}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
