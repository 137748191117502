import { ReactNode } from 'react';
import * as Heroicons from '@heroicons/react/24/solid';

import { Date, IconName } from '@pangea-lis-apps/utils';
import { DescriptionTerm, DescriptionDetails } from '@pangea-lis-apps/ui';

export interface IClientServiceDescriptionItemProps {
	term: string;
	Icon?: IconName;
	children?: ReactNode;
	withFunction?: boolean;
	customColSpan?: string;
	handleClick?: () => void;
	termStyles?: string | null;
	details?: string | number | Date | boolean | undefined | string[] | null;
	originalDetails?:
		| string
		| number
		| Date
		| boolean
		| undefined
		| string[]
		| null;
}

export function ClientServiceDescriptionItem(
	props: IClientServiceDescriptionItemProps
) {
	const Icon = Heroicons[props.Icon || 'ExclamationCircleIcon'];

	return (
		<div
			className={
				props.customColSpan ? props.customColSpan : 'sm:col-span-1'
			}
		>
			<div className="flex items-center">
				<DescriptionTerm className={props.termStyles}>
					{props.term}
				</DescriptionTerm>
				{props.withFunction && (
					<Icon
						aria-hidden="true"
						onClick={props.handleClick}
						className="ml-1 h-3 w-3 text-gray-400 hover:text-gray-500 cursor-pointer"
					/>
				)}
			</div>
			<DescriptionDetails>
				<span>
					{(() => {
						if (props.children) return props.children;

						if (typeof props.details === 'boolean') {
							if (props.details) return 'Yes';

							return 'No';
						} else if (
							!props.details ||
							props.details === undefined ||
							props.details === null
						) {
							return <span className="text-gray-500">-</span>;
						} else if (
							typeof props.details === 'object' &&
							'$date' in props.details
						) {
							return props.details.$date;
						}

						return props.details;
					})()}
				</span>
				{props.originalDetails !== props.details ? (
					<span className="text-red-600 font-medium pl-1">
						← {props.originalDetails?.toString() || 'N/A'}
					</span>
				) : (
					''
				)}
			</DescriptionDetails>
		</div>
	);
}

export default ClientServiceDescriptionItem;
