import { Routes, Route } from 'react-router';

import { CreateMember } from '@pangea-lis-apps/ui';

import ViewMember from './view-member/index';
import CreateOrganization from './organizations/create';
import AddMembersToOrganization from './organizations/add-members';
import ViewCustomers from '../shared/user-management/view-customers';
import ViewOrganization from '../shared/user-management/view-organization';
import ViewOrganizations from '../shared/user-management/view-organizations';

import RoleProtectedRoute from '../../../utils/routing';

export function AccountsRoutes() {
	return (
		<Routes>
			<Route
				element={
					<RoleProtectedRoute
						roles={['BITSS_accounts', 'BITSS_super-admin']}
					/>
				}
			>
				<Route
					// eslint-disable-next-line jsx-a11y/aria-role
					element={<CreateMember role="accounts" />}
					path="organizations/:organizationId/create/member"
				/>
				<Route
					path="create/organization"
					element={<CreateOrganization />}
				/>
				<Route
					path="view/organizations"
					element={<ViewOrganizations />}
				/>
				<Route
					path="view/organizations/:organizationId"
					element={<ViewOrganization />}
				/>
				<Route path="users" element={<ViewCustomers />} />
				<Route path="users/:customerId" element={<ViewMember />} />
				<Route
					path="organizations/:organizationId/members/add"
					element={<AddMembersToOrganization />}
				/>
			</Route>
		</Routes>
	);
}
