import { useId, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
	Query,
	Batch,
	Pagination,
	QueryState,
	capitalize,
	PaginationState,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';
import {
	Table,
	Button,
	TableRow,
	DataCell,
	useAxios,
	Container,
	TableHead,
	TableBody,
	EmptyTable,
	HeaderCell,
	TableLoading,
	ContentWrapper,
	ContentSection,
	ArrowLinkButton,
	HeaderCellWithSearch,
} from '@pangea-lis-apps/ui';

const projectedFields = [
	'_id',
	'name',
	'samples',
	'metadata.created_by.first_name',
	'metadata.created_by.last_name',
	'metadata.date_created',
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewAllBatchesProps {}

export default function ViewAllBatches(props: ViewAllBatchesProps) {
	const toastId = useId();
	const navigate = useNavigate();
	const axios = useAxios(toastId);

	const { assay } = useParams();
	const [query, setQuery] = useState<Query>({});
	const [batches, setBatches] = useState<Batch[] | undefined>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !assay) return;

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/cls/search/batches?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query: { assay, ...query },
						projected_fields: projectedFields,
					}
				);

				if (!unmounted) {
					const batches = JSON.parse(data);
					console.log(batches);

					setBatches(batches);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		axios,
		assay,
		query,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	return (
		<Container>
			<ContentWrapper
				heading="Bladder CARE™ Batches"
				Icon="RectangleGroupIcon"
			>
				<ContentSection
					heading="All batches"
					headingAction={
						<Button
							type="button"
							tier="tertiary"
							Icon="PlusIcon"
							text="Create batch"
							onClick={() =>
								navigate('/cls/create/batch/bladdercare')
							}
						/>
					}
				>
					<AllBatchesTable
						data={batches}
						query={{ query, setQuery }}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}

interface AllBatchesTableProps {
	query: QueryState;
	data: Batch[] | undefined;
	pagination: PaginationState;
}

export function AllBatchesTable(props: AllBatchesTableProps) {
	const navigate = useNavigate();
	const { query, setQuery } = props.query;

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCellWithSearch
					type="text"
					name="name"
					label="Name"
					query={query}
					property="name"
					setQuery={setQuery}
				/>
				<HeaderCell># of samples</HeaderCell>
				<HeaderCell>Created by</HeaderCell>
				<HeaderCell>Date created</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((batch, index) => (
							<TableRow key={index} index={index}>
								<DataCell>
									<ArrowLinkButton
										text={batch.name}
										onClick={() =>
											navigate(
												`/cls/view/batches/${batch._id.$oid}`
											)
										}
									/>
								</DataCell>
								<DataCell>{batch.samples.length}</DataCell>
								<DataCell>
									{capitalize(
										batch.metadata.created_by.first_name
									).concat(
										' ',
										capitalize(
											batch.metadata.created_by.last_name
										)
									)}
								</DataCell>
								<DataCell>
									{new Date(
										batch.metadata.date_created.$date
									).toLocaleString()}
								</DataCell>
							</TableRow>
						))
					) : (
						<EmptyTable
							heading="No batches"
							Icon="Squares2X2Icon"
							description="Start by creating a batch"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
