/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import {
	Dispatch,
	useState,
	FormEvent,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Modal,
	Button,
	FormGroup,
	FormFooter,
	GenericField,
} from '@pangea-lis-apps/ui';
import { Assay, Option, ICD_10_CODES } from '@pangea-lis-apps/utils';

interface AddNewICD10CodeModalProps {
	assay: Assay;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
	formValues: any | undefined;
	setFormValues: Dispatch<SetStateAction<any | undefined>>;
}

export default function AddNewICD10CodeModal(props: AddNewICD10CodeModalProps) {
	const { visible, setVisible } = props.visible;

	const [code, setCode] = useState('');

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		if (!props.formValues) return;
		else if (
			ICD_10_CODES.codes[props.assay].find(
				(x_code) =>
					x_code.value.toLowerCase() === code.trim().toLowerCase()
			)
		) {
			toast.error('This ICD-10 code already exists in the list.');
			return;
		} else if (
			props.formValues.order_icd_10_codes.find(
				(x_code: Option) =>
					x_code.value.toLowerCase() === code.trim().toLowerCase()
			)
		) {
			toast.error(
				'This ICD-10 code already exists in the selected list.'
			);
			return;
		}

		props.setFormValues((prevValues: any) => {
			if (prevValues) {
				// Check if the element exists in the running list
				const exists = prevValues.order_icd_10_codes.find(
					(x_code: Option) =>
						x_code.value.toLowerCase() === code.trim().toLowerCase()
				);

				// If element exists, then remove, otherwise, add
				const newArray = exists
					? prevValues.order_icd_10_codes.filter(
							(x_code: Option) =>
								x_code.value.toLowerCase() !==
								code.trim().toLowerCase()
					  )
					: prevValues.order_icd_10_codes.concat([
							{
								label: code,
								value: code,
							},
					  ]);

				return {
					...prevValues,
					order_icd_10_codes: newArray,
				};
			}

			return prevValues;
		});

		setVisible(false);
		setCode('');
	};

	const handleChange: ChangeEventHandler<HTMLInputElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement;

		if (target && target.name) setCode(target.value);
	};

	return (
		<Modal
			visible={visible}
			customWidth="max-w-sm"
			title="Add ICD-10 Code"
			onClose={() => setVisible(false)}
			description="Before adding a new ICD-10 code, check the existing list."
		>
			<Form handleSubmit={handleSubmit}>
				<FormGroup>
					<div className="sm:col-span-3">
						<GenericField
							required
							type="text"
							name="code"
							label="Code"
							value={code}
							placeholder="e.g., A68.9"
							handleInputChange={handleChange}
						/>
					</div>
				</FormGroup>
				<FormFooter>
					<Button
						text="Add"
						type="submit"
						tier="tertiary"
						Icon="PlusIcon"
					/>
				</FormFooter>
			</Form>
		</Modal>
	);
}
