import {
	Link,
	Table,
	TableRow,
	DataCell,
	TableBody,
	TableHead,
	EmptyTable,
	HeaderCell,
	TableLoading,
} from '@pangea-lis-apps/ui';
import {
	Auth0User,
	capitalize,
	PaginationState,
	getRoleFromPathname,
} from '@pangea-lis-apps/utils';

interface AllUsersTableProps {
	pagination: PaginationState;
	data: Auth0User[] | undefined;
}

export default function AllUsersTable(props: AllUsersTableProps) {
	const role = getRoleFromPathname();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Name</HeaderCell>
				<HeaderCell>Email</HeaderCell>
				<HeaderCell>Email verified</HeaderCell>
				<HeaderCell>Last login</HeaderCell>
				<HeaderCell>Actions</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((user, index) => (
							<TableRow key={index} index={index}>
								<DataCell>
									{user.user_metadata &&
									user.user_metadata['first_name'] &&
									user.user_metadata['last_name']
										? capitalize(
												user.user_metadata['first_name']
										  ).concat(
												' ',
												user.user_metadata['last_name']
										  )
										: user.nickname}
								</DataCell>
								<DataCell>{user.email}</DataCell>
								<DataCell>
									{user.email_verified ? 'Yes' : 'No'}
								</DataCell>
								<DataCell>
									{user.last_login
										? new Date(
												user.last_login
										  ).toLocaleString()
										: null}
								</DataCell>
								<DataCell>
									<Link
										text="Details"
										path={`/${role}/view/users/${user.user_id}`}
									/>
								</DataCell>
							</TableRow>
						))
					) : (
						<EmptyTable
							colSpan={5}
							Icon="UserGroupIcon"
							heading="No users"
							description="Start by creating a user"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
