import './styles.css';
import { Fragment } from 'react';
import * as Sentry from '@sentry/react';
import { Toaster } from 'react-hot-toast';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import {
	Index,
	Button,
	Blocked,
	Results,
	Callback,
	VerifyEmail,
	PublicRoute,
	ScrollToTop,
	LoadingModal,
	PageNotFound,
	AccessPending,
	ProtectedRoute,
	MaintenancePage,
} from '@pangea-lis-apps/ui';

import ContextProviders from './utils/context/providers';
import {
	CLSRoutes,
	LabRoutes,
	AdminRoutes,
	AccountsRoutes,
	ShippingRoutes,
	AccessioningRoutes,
	MedicalBillerRoutes,
	ClientServicesRoutes,
	AccessioningAdminRoutes,
} from './app/views/routes';

import Profile from './app/views/shared/profile';
import toasterOptions from '../../../libs/utils/src/lib/toaster-options.json';

Sentry.init({
	dsn: 'https://f0ba444cf2ba0bdfd14ccd4b9302818e@o4507897152339968.ingest.us.sentry.io/4507897155944448',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const handleTestSentryIntegration = () => {
	throw new Error('Test Error');
};

const siteUnderMaintenance = process.env['NX_UNDER_MAINTENANCE'] === 'TRUE';

root.render(
	<Fragment>
		<Toaster toastOptions={toasterOptions} />

		{/* {process.env['NX_ENV'] === 'DEVELOPMENT' && (
			<div className="text-right bg-gray-50 border border-gray-200 p-6">
				<Button
					type="button"
					tier="tertiary"
					Icon="CheckIcon"
					text="Test Sentry Integration"
					onClick={handleTestSentryIntegration}
				/>
			</div>
		)} */}

		<BrowserRouter>
			<ScrollToTop />
			<ContextProviders>
				<LoadingModal />
				<Routes>
					{siteUnderMaintenance ? (
						<Route path="*" element={<MaintenancePage />} />
					) : (
						<Fragment>
							<Route element={<PublicRoute />}>
								<Route index element={<Index />} />
								<Route path="/results" element={<Results />} />
								<Route
									path="/callback/"
									element={<Callback />}
								/>
								<Route
									path="/access-pending"
									element={<AccessPending />}
								/>
								<Route
									path="/verify-email"
									element={<VerifyEmail />}
								/>
								<Route path="/blocked" element={<Blocked />} />
							</Route>
							<Route
								element={
									<ProtectedRoute profileHref="/user/profile" />
								}
							>
								<Route
									path="/user/profile"
									element={<Profile />}
								/>
								<Route path="/cls/*" element={<CLSRoutes />} />
								<Route
									path="/accounts/*"
									element={<AccountsRoutes />}
								/>
								<Route
									path="/client-services/*"
									element={<ClientServicesRoutes />}
								/>
								<Route
									path="/admin/*"
									element={<AdminRoutes />}
								/>
								<Route
									path="/accessioner/*"
									element={<AccessioningRoutes />}
								/>
								<Route
									path="/accessioner-admin/*"
									element={<AccessioningAdminRoutes />}
								/>
								<Route
									path="/lab-tech/*"
									element={<LabRoutes />}
								/>
								<Route
									path="/shipping/*"
									element={<ShippingRoutes />}
								/>
								<Route
									path="/medical-biller/*"
									element={<MedicalBillerRoutes />}
								/>
							</Route>
							<Route path="*" element={<PageNotFound />} />
						</Fragment>
					)}
				</Routes>
			</ContextProviders>
		</BrowserRouter>
	</Fragment>
);
