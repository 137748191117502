import {
	Table,
	TableRow,
	DataCell,
	TableHead,
	TableBody,
	HeaderCell,
	EmptyTable,
	TableLoading,
} from '@pangea-lis-apps/ui';
import {
	ActionLog,
	formatDate,
	PaginationState,
	getAllRequisitionFormOptions,
} from '@pangea-lis-apps/utils';

interface ActionLogTableProps {
	data: ActionLog[];
	pagination?: PaginationState;
	type?: 'requisition_form' | 'sample';
}

export default function ActionLogTable(props: ActionLogTableProps) {
	const propertyLabels = getAllRequisitionFormOptions('form_error_labels');

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Log</HeaderCell>
				<HeaderCell>Date</HeaderCell>
				<HeaderCell>Agent</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => {
							let note = datum.note;

							if (props.type === 'requisition_form') {
								const noteArr = note.split(' ');

								if (noteArr.includes('from')) {
									const property = noteArr[1];
									const propertyLabel =
										propertyLabels[property];

									noteArr[1] = `[${propertyLabel}]`;
									note = noteArr.join(' ');
								}
							}

							return (
								<TableRow key={index} index={index}>
									<DataCell>{note}</DataCell>
									<DataCell>
										{formatDate(datum.date?.$date, true)}
									</DataCell>
									<DataCell>
										{datum.agent &&
											datum.agent.first_name.concat(
												' ',
												datum.agent.last_name
											)}
									</DataCell>
								</TableRow>
							);
						})
					) : (
						<EmptyTable
							Icon="ListBulletIcon"
							heading="No actions performed"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
