import { ReactNode } from 'react';

import { Button, ButtonTier } from '../buttons/buttons';

export interface ActionCardProps {
	type?: 'red';
	heading: string;
	disabled: boolean;
	actionText: string;
	className?: string;
	children?: ReactNode;
	description?: ReactNode;
	handleSubmit: () => void;
}

export function ActionCard(props: ActionCardProps) {
	const styles =
		props.type === 'red'
			? {
					buttonTier: 'error',
					divStyles: 'bg-red-50',
					iconStyles: 'text-red-400',
					headingStyles: 'text-red-800',
					paragraphStyles: 'text-red-700',
			  }
			: {
					buttonTier: 'primary',
					divStyles: 'bg-blue-50',
					iconStyles: 'text-blue-400',
					headingStyles: 'text-blue-800',
					paragraphStyles: 'text-blue-700',
			  };

	return (
		<div
			className={`flex items-center justify-between rounded-md p-6 ${styles.divStyles} ${props.className}`}
		>
			<div className="pr-16">
				<h3
					className={`"text-sm font-medium leading-tight ${styles.headingStyles}`}
				>
					{props.heading}
				</h3>
				{props.description && (
					<div className="mt-2">
						<p className={`text-sm ${styles.paragraphStyles}`}>
							{props.description}
						</p>
					</div>
				)}
			</div>
			<Button
				type="button"
				text={props.actionText}
				disabled={props.disabled}
				onClick={props.handleSubmit}
				tier={styles.buttonTier as ButtonTier}
			/>
		</div>
	);
}

export default ActionCard;
