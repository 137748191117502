import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, useNavigate } from 'react-router-dom';
import {
	useId,
	useState,
	Fragment,
	Dispatch,
	useEffect,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Link,
	Modal,
	Table,
	Button,
	useAxios,
	DataCell,
	TableRow,
	TableBody,
	TableHead,
	Container,
	FormFooter,
	EmptyTable,
	TextButton,
	HeaderCell,
	LoadingBox,
	SelectField,
	TableLoading,
	EditDataModal,
	ContentSection,
	ContentWrapper,
	DescriptionList,
	DescriptionItem,
	ButtonWithDropdown,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	Option,
	Attribute,
	ORGANIZATIONS,
	BaseRawCustomer,
	BaseOrganization,
	getLabelFromValue,
	OrganizationRoleID,
	getRoleFromPathname,
	BaseHydratedCustomer,
	initialAttributeValue,
	generateCustomerLabel,
	ORGANIZATION_ROLE_MAPPING,
} from '@pangea-lis-apps/utils';

/* eslint-disable-next-line */
export interface ViewOrganizationProps {}

export default function ViewOrganization(props: ViewOrganizationProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const role = getRoleFromPathname();

	const navigate = useNavigate();
	const { organizationId } = useParams();

	const [refresh, setRefresh] = useState(false);
	const [attribute, setAttribute] = useState<Attribute>(
		initialAttributeValue
	);
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [addMemberModalVisible, setAddMemberModalVisible] = useState(false);
	const [members, setMembers] = useState<BaseRawCustomer[] | undefined>(
		undefined
	);
	const [organization, setOrganization] = useState<
		BaseOrganization | undefined
	>(undefined);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !organizationId) return;

			setOrganization(undefined);

			try {
				const [
					{
						data: { data: data1 },
					},
					{
						data: { data: data2 },
					},
				] = await Promise.all([
					(
						await axios
					).get(`/api/${role}/organizations/${organizationId}`),
					(
						await axios
					).get(
						`/api/${role}/organizations/${organizationId}/members/`
					),
				]);

				if (!unmounted) {
					// organization data
					const organization = JSON.parse(data1);
					console.log(organization);

					setOrganization(organization);

					// members data
					const members = JSON.parse(data2);
					console.log(members);

					setMembers(members);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, refresh, role, organizationId]);

	const handleClick = (
		property: string,
		value: string,
		label: string,
		type: 'text' | 'email' | 'select' | 'file',
		selectOptions?: Option[],
		selectLabel?: string,
		fileTypeAccept?: string
	) => {
		setAttribute({
			property,
			value,
			label,
			type,
			selectOptions,
			selectLabel,
			fileTypeAccept,
		});
		setEditModalVisible(true);
	};

	return (
		<Fragment>
			<AddMemberModal
				setRefresh={setRefresh}
				organization={organization}
				visible={addMemberModalVisible}
				setVisible={setAddMemberModalVisible}
			/>
			<EditDataModal
				data={organization}
				attribute={attribute}
				setRefresh={setRefresh}
				heading="Edit organization info"
				visible={{
					visible: editModalVisible,
					setVisible: setEditModalVisible,
				}}
				endpoint={`/api/${role}/organizations/${organization?._id.$oid}`}
			/>
			<Container>
				{!organization || !members ? (
					<LoadingBox />
				) : (
					<ContentWrapper
						Icon="BuildingOfficeIcon"
						heading={organization.name}
						subheading="Organization details"
						description="Add members to the organization and update their permissions."
					>
						<Fragment>
							<ContentSection heading="Details">
								<DescriptionList>
									<DescriptionItem
										withFunction
										term="Logo"
										details={organization.logo_image}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'logo_image',
												organization.logo_image,
												'Logo',
												'file',
												[],
												'',
												'image/*'
											)
										}
									>
										{organization.logo_image && (
											<img
												width="200"
												height="200"
												alt="Organization logo"
												src={organization.logo_image}
												className="rounded-lg shadow-sm"
											/>
										)}
									</DescriptionItem>
									<div className="sm:col-span-2"></div>
									<DescriptionItem
										withFunction
										term="Name"
										details={organization.name}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'name',
												organization.name,
												'Name',
												'text'
											)
										}
									/>
									<DescriptionItem
										withFunction
										term="Priority Level"
										details={getLabelFromValue(
											organization.priority,
											ORGANIZATIONS.priorityLevels
										)}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'priority',
												organization.priority,
												'Priority',
												'select',
												ORGANIZATIONS.priorityLevels
											)
										}
									/>
									{organization.entity === 'clinic' && (
										<DescriptionItem
											withFunction
											term="National Provider Identifier (NPI)"
											details={
												organization.national_provider_identifier
											}
											Icon="PencilIcon"
											handleClick={() =>
												handleClick(
													'national_provider_identifier',
													organization.national_provider_identifier ||
														'-',
													'National Provider Identifer (NPI)',
													'text'
												)
											}
										/>
									)}
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection
								heading="Contact"
								id="contact_information"
							>
								<DescriptionList>
									<DescriptionItem
										withFunction
										term="Email"
										details={organization.email_address}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'email_address',
												organization.email_address,
												'Email',
												'email'
											)
										}
									/>
									<DescriptionItem
										withFunction
										term="Phone Number"
										details={organization.phone_number}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'phone_number',
												organization.phone_number,
												'Phone Number',
												'text'
											)
										}
									/>
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection
								heading="Address"
								id="address_information"
							>
								<DescriptionList>
									<DescriptionItem
										withFunction
										term="Address"
										details={organization.address}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'address',
												organization.address,
												'Address Info - Address',
												'text'
											)
										}
									/>
									<div className="sm:col-span-2"></div>
									<DescriptionItem
										withFunction
										term="City"
										details={organization.city}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'city',
												organization.city,
												'Address Info - City',
												'text'
											)
										}
									/>
									<DescriptionItem
										withFunction
										term="State"
										details={getLabelFromValue(
											organization.state,
											FORMS.states
										)}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'state',
												getLabelFromValue(
													organization.state,
													FORMS.states
												),
												'Address Info - State',
												'select',
												FORMS.states
											)
										}
									/>
									<DescriptionItem
										withFunction
										term="ZIP Code"
										details={organization.zip_code}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'zip_code',
												organization.zip_code,
												'Address Info - ZIP Code',
												'text'
											)
										}
									/>
									<DescriptionItem
										withFunction
										term="Country"
										details={getLabelFromValue(
											organization.country,
											FORMS.countries
										)}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'country',
												getLabelFromValue(
													organization.country,
													FORMS.countries
												),
												'Address Info - Country',
												'select',
												FORMS.countries
											)
										}
									/>
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection
								heading="All members"
								description="Add or remove members from the organization or edit member information."
								headingAction={
									<ButtonWithDropdown
										Icon="PlusIcon"
										mainButtonText="Create member"
										mainClick={() =>
											navigate(
												`/accounts/organizations/${organizationId}/create/member`
											)
										}
										items={[
											{
												name: 'Add member',
												handleClick: () =>
													setAddMemberModalVisible(
														true
													),
											},
										]}
									/>
								}
							>
								<OrganizationMembersTable
									data={members}
									setRefresh={setRefresh}
									organization={organization}
								/>
							</ContentSection>
						</Fragment>
					</ContentWrapper>
				)}
			</Container>
		</Fragment>
	);
}

interface OrganizationMembersTableProps {
	organization: BaseOrganization;
	data: BaseRawCustomer[] | undefined;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

function OrganizationMembersTable(props: OrganizationMembersTableProps) {
	const { user } = useAuth0();
	const role = getRoleFromPathname();
	const clinicOrganization = props.organization.entity === 'clinic';

	const isAccounts =
		user && user['custom_claims/roles'].includes('BITSS_accounts');

	const [visible, setVisible] = useState(false);
	const [customer, setCustomer] = useState<BaseRawCustomer | undefined>(
		undefined
	);

	return (
		<Fragment>
			<RemoveMemberModal
				visible={visible}
				customer={customer}
				setVisible={setVisible}
				setRefresh={props.setRefresh}
			/>
			<Table>
				<TableHead>
					{clinicOrganization && <HeaderCell>NPI</HeaderCell>}
					<HeaderCell>First Name</HeaderCell>
					<HeaderCell>Last Name</HeaderCell>
					{clinicOrganization && <HeaderCell>Roles</HeaderCell>}
					<HeaderCell>Actions</HeaderCell>
				</TableHead>
				<TableBody>
					{props.data ? (
						props.data.length ? (
							props.data.map((datum, index) => (
								<TableRow key={index} index={index}>
									{clinicOrganization && (
										<DataCell>
											{datum.national_provider_identifier}
										</DataCell>
									)}
									<DataCell>{datum.first_name}</DataCell>
									<DataCell>{datum.last_name}</DataCell>
									{clinicOrganization && (
										<DataCell>
											{datum.roles
												.map(
													(
														role: OrganizationRoleID
													) => {
														if (
															role in
															ORGANIZATION_ROLE_MAPPING
														) {
															return ORGANIZATION_ROLE_MAPPING[
																role
															];
														}

														return 'Role label undefined';
													}
												)
												.join(', ')}
										</DataCell>
									)}
									<DataCell>
										<Link
											text="Details"
											path={`/${role}/users/${datum._id.$oid}`}
										/>
										{isAccounts && (
											<TextButton
												color="blue"
												text="Remove"
												type="button"
												className="ml-2"
												onClick={() => {
													setCustomer(datum);
													setVisible(true);
												}}
											/>
										)}
									</DataCell>
								</TableRow>
							))
						) : (
							<EmptyTable
								Icon="UserGroupIcon"
								heading="No members"
								colSpan={clinicOrganization ? 5 : 3}
								description="Start by creating a member"
							/>
						)
					) : (
						<TableLoading />
					)}
				</TableBody>
			</Table>
		</Fragment>
	);
}

interface AddMemberModalProps {
	visible: boolean;
	organization: BaseOrganization | undefined;
	setVisible: Dispatch<SetStateAction<boolean>>;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

const AddMemberModal = (props: AddMemberModalProps) => {
	// Return all members that match the entity type of the organization and those who aren't affiliated with an organization.
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { organizationId } = useParams();

	const [disabled, setDisabled] = useState(false);
	const [selectedCustomer, setSelectedCustomer] = useState('');
	const [customers, setCustomers] = useState<Option[] | undefined>(undefined);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !props.visible || !props.organization) return;

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(
					`/api/accounts/organizationless-customers/?entity=${props.organization.entity}`
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setCustomers(() =>
						parsedData.map((provider: BaseHydratedCustomer) => ({
							label: generateCustomerLabel(provider),
							value: provider._id.$oid,
						}))
					);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, props.visible, props.organization]);

	const handleAddMember = async () => {
		if (disabled || !axios || !organizationId || !selectedCustomer) return;

		setDisabled(true);

		toast.loading('Adding...', toastOptions);

		try {
			await (
				await axios
			).patch(
				`/api/accounts/organizations/${organizationId}/members/add/${selectedCustomer}`,
				null
			);

			toast.dismiss();

			setDisabled(false);
			props.setVisible(false);
			props.setRefresh((value) => !value);
		} catch (_) {
			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLSelectElement;

		if (target && target.name) setSelectedCustomer(target.value);
	};

	return (
		<Modal
			title="Add member"
			customWidth="max-w-sm"
			visible={props.visible}
			onClose={() => props.setVisible(false)}
			description="Search for an existing account. If no accounts exist, then create the member."
		>
			{!customers ? (
				<LoadingBox />
			) : (
				<Form>
					<div className="sm:col-span-3">
						<SelectField
							required
							name="customers"
							label="Accounts"
							options={
								customers.length === 0
									? [
											{
												label: 'No option(s) available',
												value: '',
												disabled: true,
											},
									  ]
									: [
											{
												label: 'Select an Option',
												value: '',
												disabled: true,
											},
											...customers,
									  ]
							}
							value={selectedCustomer}
							handleSelect={handleChange}
						/>
					</div>
					{Boolean(customers.length) && (
						<FormFooter>
							<Button
								text="Add"
								type="button"
								tier="tertiary"
								Icon="CheckIcon"
								disabled={disabled}
								onClick={handleAddMember}
							/>
						</FormFooter>
					)}
				</Form>
			)}
		</Modal>
	);
};

interface RemoveMemberModalProps {
	visible: boolean;
	customer: BaseRawCustomer | undefined;
	setVisible: Dispatch<SetStateAction<boolean>>;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

const RemoveMemberModal = (props: RemoveMemberModalProps) => {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { organizationId } = useParams();

	const [disabled, setDisabled] = useState(false);

	const handleRemove = async () => {
		if (disabled || !axios || !props.customer) return;

		setDisabled(true);

		toast.loading('Removing...', toastOptions);

		try {
			await (
				await axios
			).patch(
				`/api/accounts/organizations/${organizationId}/members/remove/${props.customer._id.$oid}`,
				null
			);

			toast.dismiss();

			setDisabled(false);
			props.setVisible(false);
			props.setRefresh((value) => !value);
		} catch (_) {
			setDisabled(false);
		}
	};

	return (
		<Modal
			customWidth="w-112"
			title="Remove member"
			visible={props.visible}
			onClose={() => props.setVisible(false)}
			description={
				<span>
					This action is irreversible.{' '}
					<span className="italic">All</span> assigned roles will be
					removed.
				</span>
			}
		>
			{!props.customer ? (
				<LoadingBox />
			) : (
				<DescriptionList>
					{props.customer.national_provider_identifier && (
						<Fragment>
							<DescriptionItem
								term="National Provider Identifier (NPI)"
								details={
									props.customer.national_provider_identifier
								}
								customColSpan="sm:col-span-2"
							/>
							<div className="sm:col-span-1"></div>
						</Fragment>
					)}
					<DescriptionItem
						term="First Name"
						details={props.customer.first_name}
					/>
					<DescriptionItem
						term="Last Name"
						details={props.customer.last_name}
					/>
					<DescriptionItem
						term="Roles"
						details={props.customer.roles
							.map((role: OrganizationRoleID) => {
								if (role in ORGANIZATION_ROLE_MAPPING) {
									return ORGANIZATION_ROLE_MAPPING[role];
								}

								return 'Role label undefined!';
							})
							.join(', ')}
						customColSpan="sm:col-span-2"
					/>
				</DescriptionList>
			)}
			<FormFooter>
				<Button
					type="button"
					text="Remove"
					tier="tertiary"
					Icon="CheckIcon"
					disabled={disabled}
					onClick={handleRemove}
				/>
			</FormFooter>
		</Modal>
	);
};
