import {
	Assay,
	Entity,
	BarAction,
	ClinicData,
	NonClinicData,
	REQUISITION_FORM,
	MESSAGE_TEMPLATES,
} from '@pangea-lis-apps/utils';

export const generateMessageTemplateOptions = (
	data: ClinicData | NonClinicData,
	assay: Assay,
	barAction: BarAction | 'reject'
) => {
	return [
		{
			value: '',
			disabled: true,
			label: 'Select an Option',
		},
		...MESSAGE_TEMPLATES['generic'][barAction].options.shared,
		...MESSAGE_TEMPLATES['generic'][barAction].options[
			data['type'] as Entity
		],
		...MESSAGE_TEMPLATES[assay][barAction].options.shared,
		...MESSAGE_TEMPLATES[assay][barAction].options[data['type'] as Entity],
	];
};

export const generateMessageTemplateContent = (
	data: ClinicData | NonClinicData,
	assay: Assay,
	barAction: BarAction | 'reject'
) => {
	return {
		...MESSAGE_TEMPLATES['generic'][barAction].messages[
			data['type'] as Entity
		],
		...MESSAGE_TEMPLATES[assay][barAction].messages[data['type'] as Entity],
	};
};

export const getAccessioningErrorsList = (data: ClinicData | NonClinicData) => {
	const combinedArray = data.metadata.accessioning.completion_errors.concat(
		data.metadata.accessioning.flagged_fields
	);
	const uniqueSet = new Set(combinedArray);
	const finalArray = Array.from(uniqueSet).map((item) => {
		const formErrorLabels =
			REQUISITION_FORM[data.sample.assay]['shared']['form_error_labels'];

		if (item in formErrorLabels) {
			return formErrorLabels[item];
		}

		return item.replace(/_/g, ' ');
	});

	if (finalArray.length) {
		return finalArray;
	}

	return null;
};
