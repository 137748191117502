/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import {
	Outlet,
	useParams,
	useNavigate,
	useLocation,
	useSearchParams,
} from 'react-router-dom';
import {
	useId,
	useRef,
	useState,
	Fragment,
	useEffect,
	FormEvent,
	createContext,
	BaseSyntheticEvent,
} from 'react';

import {
	Assay,
	ClinicData,
	ProgressStep,
	ProcessTRFStepsContextType,
} from '@pangea-lis-apps/utils';
import {
	Steps,
	Button,
	useAxios,
	Container,
	LoadingBox,
	FormFooter,
	LinkButton,
	ContentSection,
	ContentWrapper,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';

import {
	determineRedirectURL,
	normalizeAccessioningFormValues,
} from '../utils/helpers';
import { setStepStyles } from '../../../helpers/form';
import TabSubmitConfirmationModal from './tab-submit-confirmation-modal';

type Step = { id: string; name: string };

export const ProcessTRFStepsContext = createContext<ProcessTRFStepsContextType>(
	{
		refresh: false,
		data: undefined,
		disabled: false,
		setData: () => {},
		formId: 'trf_form',
		setRefresh: () => {},
		redirectPathQuery: '',
		setDisabled: () => {},
		formValues: undefined,
		handleSubmit: () => {},
		setFormValues: () => {},
		formValuesRef: { current: {} },
		handleDownloadAttachment: () => {},
	}
);

function findNextStepId(currentPageId: string, steps: Step[]): string | null {
	let index = 0;

	for (; index < steps.length; index++) {
		if (steps[index].id === currentPageId) {
			break;
		}
	}

	// If current step is the last step, then simply return an empty string.
	// Navigation to the process sample flow is handled by a separate button with a hardcoded redirect URL.
	if (index === steps.length - 1) return null;

	return steps[index + 1].id;
}

export interface FormWrapperProps {
	assay: Assay;
	steps: Step[];
	version: string;
}

export default function FormWrapper(props: FormWrapperProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const formId = 'trf_form';

	const navigate = useNavigate();
	const { dataId } = useParams();

	const { pathname } = useLocation();
	const currentPageId =
		pathname.split('/').slice(-1)[0] || pathname.split('/').slice(-2)[0];

	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');
	const redirectPathQuery = redirectPath
		? `/?redirectPath=${redirectPath}`
		: '';

	// To check if updates were made
	const formValuesRef = useRef();

	// For tab submit confirmation
	const [
		tabSubmitConfirmationModalVisible,
		setTabSubmitConfirmationModalVisible,
	] = useState(false);

	// Data population
	const [refresh, setRefresh] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [formValues, setFormValues] = useState<any>(undefined);
	const [data, setData] = useState<ClinicData | undefined>(undefined);
	const [steps, setSteps] = useState<ProgressStep[] | undefined>(undefined);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !dataId) return;

			setSteps(() => {
				const URLPrefix = `/accessioner/accession/trf/${props.assay}/v${props.version}/data/${dataId}/trf`;
				const initialSteps = props.steps.map(({ id, name }, index) => ({
					id,
					name,
					step: `Step ${index + 1}`,
					href: `${URLPrefix}/${id}${redirectPathQuery}`,
					status: 'upcoming',
				})) as ProgressStep[];

				const styledSteps = setStepStyles(initialSteps, currentPageId);
				return styledSteps;
			});
			setData(undefined);

			try {
				const {
					data: { data },
				} = await (await axios).get(`/api/accessioner/data/${dataId}`);

				if (!unmounted) {
					// Set data
					const parsedData = JSON.parse(data);
					setData(parsedData);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		axios,
		dataId,
		refresh,
		props.steps,
		props.assay,
		props.version,
		currentPageId,
		redirectPathQuery,
	]);

	const handleSubmit = async (event: MouseEvent | FormEvent) => {
		event.preventDefault();

		if (!data || !steps || !axios || disabled || !currentPageId) return;

		// Calculate the nextStepId - the last step doesn't have a next step id
		let nextStepId = findNextStepId(currentPageId, steps);
		let redirectURL = `/accessioner/accession/trf/${data.sample.assay}/v${data.requisition_form.metadata.version}/data/${data._id.$oid}/trf/${nextStepId}${redirectPathQuery}`;

		if (event.type === 'click') {
			// Determine whether submit was triggered by the form button or navigation tab.
			// If triggered by the form button, then continue to the next step.
			// If by navigation tab, then continue to the selected navigation tab.

			const {
				tabSubmit,
				tabSubmitNextStepId,
				confirmationModalSubmitButtonType,
			} = determineRedirectURL(event as BaseSyntheticEvent);

			if (tabSubmit) {
				nextStepId = tabSubmitNextStepId
					? tabSubmitNextStepId
					: nextStepId;

				redirectURL = `/accessioner/accession/trf/${data.sample.assay}/v${data.requisition_form.metadata.version}/data/${data._id.$oid}/trf/${nextStepId}${redirectPathQuery}`;

				if (
					JSON.stringify(formValues) ===
					JSON.stringify(formValuesRef.current)
				) {
					if (nextStepId === currentPageId) setFormValues(undefined);
					navigate(redirectURL);
					return;
				} else {
					if (currentPageId === 'review') {
						navigate(redirectURL);
						return;
					}
				}

				if (tabSubmitConfirmationModalVisible) {
					if (confirmationModalSubmitButtonType === 'continue') {
						setTabSubmitConfirmationModalVisible(false);
						navigate(redirectURL);
						return;
					}
				} else {
					setTabSubmitConfirmationModalVisible(true);
					return;
				}
			}
		}

		// No values were updated.
		if (
			JSON.stringify(formValues) === JSON.stringify(formValuesRef.current)
		) {
			if (nextStepId === currentPageId) setFormValues(undefined);
			navigate(redirectURL);
			return;
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			// Prepare payload
			const normalizedFormValues = normalizeAccessioningFormValues(
				data,
				formValues,
				'requisition_form'
			);

			await (
				await axios
			).patch(`/api/accessioner/data/${dataId}/trf`, {
				requisition_form_data: normalizedFormValues,
			});

			toast.dismiss();

			setDisabled(false);
			setFormValues(undefined);
			setTabSubmitConfirmationModalVisible(false);
			navigate(redirectURL);
		} catch (error) {
			console.log(error);

			setDisabled(false);
			setTabSubmitConfirmationModalVisible(false);
		}
	};

	const handleDownloadAttachment = async (aws_object_key: string) => {
		if (!axios) return;

		toast.loading('Downloading...', toastOptions);

		try {
			const {
				data: { data },
			} = await (
				await axios
			).get(
				`/api/accessioner/data/${dataId}/trf/attachment/?aws_object_key=${encodeURIComponent(
					aws_object_key
				)}`
			);

			toast.dismiss();

			window.open(data, '_blank');
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<ProcessTRFStepsContext.Provider
			value={{
				data,
				formId,
				setData,
				refresh,
				disabled,
				formValues,
				setRefresh,
				setDisabled,
				handleSubmit,
				formValuesRef,
				setFormValues,
				redirectPathQuery,
				handleDownloadAttachment,
			}}
		>
			<TabSubmitConfirmationModal
				handleSubmit={handleSubmit}
				visible={{
					visible: tabSubmitConfirmationModalVisible,
					setVisible: setTabSubmitConfirmationModalVisible,
				}}
			/>

			<Container>
				<ContentWrapper
					Icon="DocumentTextIcon"
					heading="Process requisition form"
					subheading="Accession requisition form"
				>
					<Fragment>
						{steps && (
							<Steps
								steps={steps}
								formId={formId}
								handleSubmit={handleSubmit}
							/>
						)}

						{data ? (
							<Fragment>
								<ContentSectionDivider />

								<Outlet />

								<ContentSection>
									<FormFooter>
										{currentPageId === 'review' ? (
											<LinkButton
												tier="tertiary"
												text="Save and continue"
												path={`/accessioner/accession/sample/${props.assay}/data/${dataId}/sample/update-info${redirectPathQuery}`}
											/>
										) : (
											<Button
												type="submit"
												tier="tertiary"
												formId={formId}
												disabled={disabled}
												text="Save and continue"
											/>
										)}
									</FormFooter>
								</ContentSection>
							</Fragment>
						) : (
							<LoadingBox />
						)}
					</Fragment>
				</ContentWrapper>
			</Container>
		</ProcessTRFStepsContext.Provider>
	);
}
