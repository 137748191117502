import { useParams } from 'react-router-dom';

import STISampleUpdate from './sti';
import FITSampleUpdate from './fit';
import Resp4PlexSampleUpdate from './resp4plex';
import BladderCARESampleUpdate from './bladdercare';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdateWrapperProps {}

export default function UpdateWrapper(props: UpdateWrapperProps) {
	const { assay } = useParams();

	if (assay === 'sti') return <STISampleUpdate />;
	else if (assay === 'resp4plex') return <Resp4PlexSampleUpdate />;
	else if (assay === 'fit') return <FITSampleUpdate />;
	else if (assay === 'bladdercare') return <BladderCARESampleUpdate />;

	return null;
}
