import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	useState,
	Dispatch,
	useEffect,
	FormEvent,
	ChangeEvent,
	SetStateAction,
} from 'react';

import { HydratedOrder } from '@pangea-lis-apps/utils';
import {
	Form,
	Modal,
	Button,
	useAxios,
	FormGroup,
	FormFooter,
	LoadingBox,
	GenericField,
} from '@pangea-lis-apps/ui';

interface UpdateShippingAddressFormValues {
	'shipping.address.address': string;
	'shipping.address.city': string;
	'shipping.address.state': string;
	'shipping.address.zip_code': string;
}

const initialUpdateShippingAddressFormValues = {
	'shipping.address.address': '',
	'shipping.address.city': '',
	'shipping.address.state': '',
	'shipping.address.zip_code': '',
};

interface EditOrderShippingAddressModalProps {
	order: HydratedOrder;
	role: 'organization-shared' | 'shipping';
	setRefresh: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

export function EditOrderShippingAddressModal(
	props: EditOrderShippingAddressModalProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const { visible, setVisible } = props.visible;

	const [disabled, setDisabled] = useState(false);
	const [formValues, setFormValues] =
		useState<UpdateShippingAddressFormValues>(
			initialUpdateShippingAddressFormValues
		);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios || !user || !props.role) return;

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(
				`/api/${props.role}/orders/${props.order._id.$oid}/shipping-address/?auth0_user_id=${user.sub}`,
				{
					user,
					form_data: formValues,
				}
			);

			toast.dismiss();

			setVisible(false);
			setDisabled(false);
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name)
			setFormValues((prevValue) => ({
				...prevValue,
				[target.name]: target.value,
			}));
	};

	useEffect(() => {
		if (props.order && visible)
			setFormValues({
				'shipping.address.address':
					props.order.shipping.address.address,
				'shipping.address.city': props.order.shipping.address.city,
				'shipping.address.state': props.order.shipping.address.state,
				'shipping.address.zip_code':
					props.order.shipping.address.zip_code,
			});
	}, [props.order, visible]);

	return (
		<Modal
			visible={visible}
			customWidth="max-w-lg"
			title="Update shipping address"
			onClose={() => setVisible(false)}
		>
			{!props.order ? (
				<LoadingBox />
			) : (
				<Form handleSubmit={handleSubmit}>
					<FormGroup>
						<div className="sm:col-span-4">
							<GenericField
								required
								type="text"
								label="Address"
								name="shipping.address.address"
								placeholder="e.g., 123 Main St."
								handleInputChange={handleChange}
								value={formValues['shipping.address.address']}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-2">
							<GenericField
								required
								type="text"
								label="City"
								placeholder="e.g., Albany"
								name="shipping.address.city"
								handleInputChange={handleChange}
								value={formValues['shipping.address.city']}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								required
								type="text"
								label="State"
								placeholder="e.g., New York"
								name="shipping.address.state"
								handleInputChange={handleChange}
								value={formValues['shipping.address.state']}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								required
								type="text"
								label="ZIP Code"
								placeholder="e.g., 92830"
								name="shipping.address.zip_code"
								handleInputChange={handleChange}
								value={formValues['shipping.address.zip_code']}
							/>
						</div>
					</FormGroup>
					<FormFooter>
						<Button
							type="submit"
							text="Update"
							tier="tertiary"
							Icon="CheckIcon"
							disabled={disabled}
						/>
					</FormFooter>
				</Form>
			)}
		</Modal>
	);
}

export default EditOrderShippingAddressModal;
