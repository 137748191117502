/* eslint-disable @typescript-eslint/no-empty-function */
import { useParams } from 'react-router-dom';
import {
	useId,
	Dispatch,
	useState,
	useEffect,
	createContext,
	SetStateAction,
} from 'react';

import {
	Link,
	useAxios,
	Container,
	LoadingBox,
	ContentWrapper,
} from '@pangea-lis-apps/ui';
import {
	Auth0Role,
	BaseHydratedCustomer,
	HydratedExtendedPermission,
} from '@pangea-lis-apps/utils';

import ViewMemberDetails from './details';

export type HydratedCustomer = BaseHydratedCustomer & {
	isStaff: boolean;
	roles: Auth0Role[];
	isAssociate: boolean;
	canViewReports: boolean;
	extended_permissions: HydratedExtendedPermission[];
};

export const customerContext = createContext<{
	customer: HydratedCustomer | null;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}>({
	customer: null,
	setRefresh: () => {},
});

const roleExists = (
	roles: Auth0Role[],
	roleName: 'BITSS_organization-associate' | 'BITSS_organization-staff'
) => {
	return roles.some((role: Auth0Role) => role.name === roleName);
};

/* eslint-disable-next-line */
export interface ViewMemberProps {}

export default function ViewMember(props: ViewMemberProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { customerId } = useParams();

	const [refresh, setRefresh] = useState<boolean>(false);
	const [customer, setCustomer] = useState<HydratedCustomer | undefined>(
		undefined
	);

	useEffect(() => {
		let unmounted = false;

		const fetchTabs = async () => {
			if (!axios) return;

			setCustomer(undefined);

			try {
				const {
					data: { data: data1 },
				} = await (
					await axios
				).get(`/api/accounts/customers/${customerId}`);

				if (!unmounted) {
					const parsedData1 = JSON.parse(data1);
					console.log('customer', parsedData1);

					const roles = parsedData1.roles;
					const isStaff = roleExists(
						roles,
						'BITSS_organization-staff'
					);

					let canViewReports = true;

					if (isStaff) {
						canViewReports = false;

						const {
							data: { data: parsedData2 },
						} = await (
							await axios
						).get(
							`/api/accounts/users/auth0|${customerId}/permissions`
						);

						if (parsedData2) {
							parsedData2.map((permission: any) => {
								if (
									permission.permission_name === 'read:report'
								) {
									canViewReports = true;
								}
							});
						}
					}

					setCustomer(() => {
						return {
							isAssociate: roleExists(
								roles,
								'BITSS_organization-associate'
							),
							isStaff,
							canViewReports,
							...parsedData1,
						};
					});
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchTabs();

		return () => {
			unmounted = true;
		};
	}, [axios, refresh, customerId]);

	return (
		<Container>
			{!customer ? (
				<LoadingBox />
			) : (
				<ContentWrapper
					Icon="UserIcon"
					heading={`${customer.first_name} ${customer.last_name}`}
					description={
						<p>
							Member of{' '}
							<Link
								text={customer.organization.name}
								path={`/accounts/view/organizations/${customer.organization._id.$oid}`}
							/>
						</p>
					}
				>
					<customerContext.Provider value={{ customer, setRefresh }}>
						<ViewMemberDetails />
					</customerContext.Provider>
				</ContentWrapper>
			)}
		</Container>
	);
}
