import toast from 'react-hot-toast';
import { useId, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
	Link,
	Table,
	useAxios,
	DataCell,
	TableRow,
	Container,
	TableBody,
	TableHead,
	EmptyTable,
	HeaderCell,
	TableLoading,
	ContentWrapper,
	ContentSection,
	ArrowLinkButton,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ASSAYS,
	QueryState,
	ClinicData,
	Pagination,
	formatDate,
	ORGANIZATIONS,
	NonClinicData,
	PaginationState,
	getSampleStatuses,
	getLabelFromValue,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import TableSearchFiltersForm from '../../../components/tables/accessioning/table-search-filters-form';

const projectedFields = [
	'_id',
	'type',
	'sample.assay',
	'sample.status',
	'sample.priority',
	'sample.ruo',
	'organization.name',
	'customer.first_name',
	'customer.last_name',
	'sample.pangea_id',
	'patient.last_name',
	'sample.specimen_id',
	'sample.collection_kit_id',
	'patient.first_name',
	'patient.date_of_birth',
	'sample.sample_collection_date',
	'requisition_form.metadata.version',
	'requisition_form.sample_collection_date',
];

/* eslint-disable-next-line */
export interface ViewIncompleteSamplesProps {}

export default function ViewIncompleteSamples(
	props: ViewIncompleteSamplesProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [data, setData] = useState<
		(ClinicData | NonClinicData)[] | undefined
	>(undefined);
	const [query, setQuery] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setData(undefined);
				setDisabled(true);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/accessioner/search/samples/incomplete/?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{ query, projected_fields: projectedFields }
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setData(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);

			toast.dismiss();
		};

		fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	return (
		<Container>
			<ContentWrapper
				Icon="DocumentCheckIcon"
				heading="Incomplete Samples"
				description="Samples that have been processed but still need accessioning to be completed."
			>
				<TableSearchFiltersForm
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection heading="Incomplete samples">
					<IncompleteSamplesTable
						data={data}
						query={{ query, setQuery }}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}

interface IncompleteSamplesTableProps {
	query: QueryState;
	pagination: PaginationState;
	data: (ClinicData | NonClinicData)[] | undefined;
}

function IncompleteSamplesTable(props: IncompleteSamplesTableProps) {
	const navigate = useNavigate();

	const { pathname } = useLocation();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Actions</HeaderCell>
				<HeaderCell>Status</HeaderCell>
				<HeaderCell>Assay</HeaderCell>
				<HeaderCell>Priority Level</HeaderCell>
				<HeaderCell>RUO</HeaderCell>
				<HeaderCell>Customer</HeaderCell>
				<HeaderCell>Organization</HeaderCell>
				<HeaderCell>Pangea ID</HeaderCell>
				<HeaderCell>Specimen ID</HeaderCell>
				<HeaderCell>Collection Kit ID</HeaderCell>
				<HeaderCell>Patient First Name</HeaderCell>
				<HeaderCell>Patient Last Name</HeaderCell>
				<HeaderCell>Patient Date of Birth</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => {
							const clinicSample = datum.type === 'clinic';

							return (
								<TableRow key={index} index={index}>
									<DataCell>
										<Link
											text="Update"
											path={
												clinicSample
													? `/accessioner/accession/trf/${datum.sample.assay}/v${datum.requisition_form.metadata.version}/data/${datum._id.$oid}/trf/provider-organization-info/?redirectPath=${pathname}`
													: `/accessioner/accession/sample/${datum.sample.assay}/data/${datum._id.$oid}/non-clinic-sample/?redirectPath=${pathname}`
											}
										/>
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.status,
											getSampleStatuses()
										)}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.assay,
											ASSAYS['assays']
										)}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.priority,
											ORGANIZATIONS.priorityLevels
										)}
									</DataCell>
									<DataCell>
										{datum.sample.ruo === null
											? null
											: datum.sample.ruo
											? 'Yes'
											: 'No'}
									</DataCell>
									<DataCell>
										{datum.customer.first_name.concat(
											' ',
											datum.customer.last_name
										)}
									</DataCell>
									<DataCell>
										{datum.organization.name}
									</DataCell>
									<DataCell>
										{datum.sample.pangea_id && (
											<ArrowLinkButton
												text={datum.sample.pangea_id}
												onClick={() =>
													navigate(
														`/accessioner/view/samples/${datum._id.$oid}`
													)
												}
											/>
										)}
									</DataCell>
									<DataCell>
										{datum.sample.specimen_id}
									</DataCell>
									<DataCell>
										{datum.sample.collection_kit_id}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.first_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.last_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? formatDate(
													datum.patient.date_of_birth
											  )
											: null}
									</DataCell>
									<DataCell>
										{clinicSample
											? formatDate(
													datum.requisition_form
														.sample_collection_date
											  )
											: formatDate(
													datum.sample
														.sample_collection_date
											  )}
									</DataCell>
								</TableRow>
							);
						})
					) : (
						<EmptyTable
							Icon="FingerPrintIcon"
							heading="No reviewed samples"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
