import { Routes, Route } from 'react-router-dom';

import V1_0_ROUTES from './v1-0/routes';

export default function FITTRFFlowRoutes() {
	return (
		<Routes>
			<Route path="/v1.0/*" element={<V1_0_ROUTES />} />
		</Routes>
	);
}
