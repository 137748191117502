import { Routes, Route } from 'react-router-dom';

import ViewBatch from './batches/view-batch';
import CreateBatch from './batches/create-batch';
import SearchReports from './reports/search-reports';
import ViewSamples from './samples/view-all-samples';
import ViewSample from './samples/view-sample/index';
import AddSamplesToBatch from './batches/add-samples';
import PendingReports from './reports/pending-reports';
import ViewAllBatches from './batches/view-all-batches';
import ViewFailedSamples from './samples/view-failed-samples';
import ViewPendingSamples from './samples/view-pending-samples';

import RoleProtectedRoute from '../../../utils/routing';
import ViewRejectedSamples from './samples/view-rejected-samples';

export function CLSRoutes() {
	return (
		<Routes>
			<Route
				element={
					<RoleProtectedRoute
						roles={['BITSS_CLS', 'BITSS_super-admin']}
					/>
				}
			>
				<Route path="search/reports" element={<SearchReports />} />
				<Route path="pending/reports" element={<PendingReports />} />
				<Route path="view/samples/:dataId" element={<ViewSample />} />
				<Route
					path="view/failed-samples"
					element={<ViewFailedSamples />}
				/>
				<Route
					path="view/pending-samples"
					element={<ViewPendingSamples />}
				/>
				<Route
					path="view/rejected-samples"
					element={<ViewRejectedSamples />}
				/>
				<Route path="view/all-samples" element={<ViewSamples />} />
				<Route path="create/batch/:assay" element={<CreateBatch />} />
				<Route
					path="view/batches/assay/:assay"
					element={<ViewAllBatches />}
				/>
				<Route path="view/batches/:batchId" element={<ViewBatch />} />
				<Route
					path="update/batch/:batchId/add-samples"
					element={<AddSamplesToBatch />}
				/>
			</Route>
		</Routes>
	);
}
