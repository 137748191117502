import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState, useId } from 'react';

import {
	useAxios,
	Container,
	ContentWrapper,
	ContentSection,
} from '@pangea-lis-apps/ui';
import {
	Query,
	Pagination,
	getRoleFromPathname,
	BaseHydratedCustomer,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';
import AllCustomersTable from '../../../components/tables/shared/all-customers';
import toast from 'react-hot-toast';

/* eslint-disable-next-line */
export interface ViewCustomersProps {}

export default function ViewCustomers(props: ViewCustomersProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	// const navigate = useNavigate();
	const role = getRoleFromPathname();
	const { getAccessTokenSilently } = useAuth0();

	const [query, setQuery] = useState<Query>({});
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);
	const [customers, setCustomers] = useState<
		BaseHydratedCustomer[] | undefined
	>(undefined);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setCustomers(undefined);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/${role}/search/customers?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query,
						projected_fields: [
							'_id',
							'national_provider_identifier',
							'first_name',
							'last_name',
							'organization.name',
						],
					}
				);

				if (!unmounted) {
					const customers = JSON.parse(data);
					console.log(customers);

					setCustomers(customers);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			toast.dismiss();
		};

		fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [
		role,
		query,
		axios,
		pagination.page_number,
		getAccessTokenSilently,
		pagination.entries_per_page,
	]);

	return (
		<Container>
			<ContentWrapper Icon="UserGroupIcon" heading="View Customers">
				<ContentSection id="form" heading="All customers">
					<AllCustomersTable
						data={customers}
						query={{ query, setQuery }}
						pagination={{
							pagination,
							setPagination,
						}}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
