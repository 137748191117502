import { useState, ReactNode } from 'react';
import { Combobox as HeadlessCombobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { Label } from '@pangea-lis-apps/ui';
import { Option, classNames } from '@pangea-lis-apps/utils';

export interface ComboboxProps {
	name?: string;
	value: string;
	label?: string;
	options: Option[];
	required?: boolean;
	disabled?: boolean;
	fieldAction?: ReactNode;
	handleSelect: (options: any) => void;
}

export function Combobox(props: ComboboxProps) {
	const [query, setQuery] = useState('');
	const [selectedOption, setSelectedOption] = useState<Option | undefined>(
		props.options[0]
	);

	const filteredOptions =
		query === ''
			? props.options
			: props.options.filter((option) => {
					return option.label
						.toLowerCase()
						.includes(query.toLowerCase());
			  });

	return (
		<div>
			{props.label && props.name && (
				<div className="flex items-center justify-between">
					<Label
						name={props.name}
						label={props.label}
						required={props.required}
					/>
					{props.fieldAction && props.fieldAction}
				</div>
			)}
			<HeadlessCombobox
				as="div"
				value={selectedOption}
				disabled={props.disabled}
				onChange={(option) => {
					props.handleSelect(option.value);
					setSelectedOption(option);
				}}
			>
				<div className="relative mt-1">
					<HeadlessCombobox.Input
						displayValue={(option: Option | undefined) =>
							props.value
								? (() => {
										const foundElement = props.options.find(
											(option) =>
												option.value === props.value
										);

										if (foundElement)
											return foundElement.label;

										return 'No label found';
								  })()
								: option
								? option.label
								: 'Select an Option'
						}
						onChange={(event) => setQuery(event.target.value)}
						className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm disabled:bg-gray-100 disabled:text-gray-400 disabled:font-medium disabled:hover:cursor-not-allowed"
					/>
					<HeadlessCombobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
						<ChevronUpDownIcon
							className="h-5 w-5 text-gray-500"
							aria-hidden="true"
						/>
					</HeadlessCombobox.Button>

					{filteredOptions.length > 0 && (
						<HeadlessCombobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
							{filteredOptions.map((option, index) => {
								return (
									<HeadlessCombobox.Option
										key={index}
										value={option}
										disabled={option.disabled}
										className={({ active }) =>
											classNames(
												'relative cursor-default select-none py-2 pl-3 pr-9',
												active
													? 'bg-blue-600 text-white'
													: 'text-gray-900'
											)
										}
									>
										{({ active }) => {
											const selected =
												props.value &&
												props.value === option.value;

											return (
												<>
													<span
														className={classNames(
															'block truncate',
															selected
																? 'font-semibold'
																: ''
														)}
													>
														{option.label}
													</span>

													{selected && (
														<span
															className={classNames(
																'absolute inset-y-0 right-0 flex items-center pr-4',
																active
																	? 'text-white'
																	: 'text-blue-600'
															)}
														>
															<CheckIcon
																className="h-5 w-5"
																aria-hidden="true"
															/>
														</span>
													)}
												</>
											);
										}}
									</HeadlessCombobox.Option>
								);
							})}
						</HeadlessCombobox.Options>
					)}
				</div>
			</HeadlessCombobox>
		</div>
	);
}

export interface MultipleComboboxProps {
	name?: string;
	label?: string;
	value: Option[];
	options: Option[];
	required?: boolean;
	fieldAction?: ReactNode;
	showRequiredAsterisk?: boolean;
	handleSelect: (options: Option[]) => void;
}

export function MultipleCombobox(props: MultipleComboboxProps) {
	const [query, setQuery] = useState('');

	const filteredOptions =
		query === ''
			? props.options
			: props.options.filter((option) => {
					return option.label
						.toLowerCase()
						.includes(query.toLowerCase());
			  });

	return (
		<div>
			{props.label && props.name && (
				<div className="flex items-center justify-between">
					<Label
						name={props.name}
						label={props.label}
						required={props.required || props.showRequiredAsterisk}
					/>
					{props.fieldAction && props.fieldAction}
				</div>
			)}
			<HeadlessCombobox
				as="div"
				multiple
				name={props.name}
				value={props.value}
				onChange={props.handleSelect}
			>
				<div className="relative mt-1">
					<HeadlessCombobox.Input
						displayValue={(options: Option[]) =>
							options && Boolean(options.length)
								? options.slice(-1)[0].label
								: 'Select option(s)'
						}
						onChange={(event) => setQuery(event.target.value)}
						className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
					/>
					<HeadlessCombobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
						<ChevronUpDownIcon
							className="h-5 w-5 text-gray-500"
							aria-hidden="true"
						/>
					</HeadlessCombobox.Button>

					{filteredOptions.length > 0 && (
						<HeadlessCombobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
							{filteredOptions.map((option, index) => (
								<HeadlessCombobox.Option
									key={index}
									value={option}
									disabled={option.disabled}
									className={({ active }) =>
										classNames(
											'relative cursor-default select-none py-2 pl-3 pr-9',
											active
												? 'bg-blue-600 text-white'
												: 'text-gray-900',
											option.disabled
												? 'bg-gray-50 text-gray-400 cursor-not-allowed'
												: ''
										)
									}
								>
									{({ active }) => {
										const selected = props.value.find(
											(value) =>
												value.value === option.value
										);

										return (
											<>
												<span
													className={classNames(
														'block truncate',
														selected
															? 'font-semibold'
															: ''
													)}
												>
													{option.label}
												</span>

												{selected && (
													<span
														className={classNames(
															'absolute inset-y-0 right-0 flex items-center pr-4',
															active
																? 'text-white'
																: 'text-blue-600'
														)}
													>
														<CheckIcon
															aria-hidden="true"
															className="h-5 w-5"
														/>
													</span>
												)}
											</>
										);
									}}
								</HeadlessCombobox.Option>
							))}
						</HeadlessCombobox.Options>
					)}
				</div>
			</HeadlessCombobox>
		</div>
	);
}
