/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import {
	useId,
	Fragment,
	useState,
	useEffect,
	ChangeEvent,
	ChangeEventHandler,
} from 'react';

import {
	Button,
	useAxios,
	EmptyBox,
	Container,
	FormGroup,
	LoadingBox,
	SelectField,
	EditDataModal,
	ContentWrapper,
	ContentSection,
	DescriptionList,
	DescriptionItem,
} from '@pangea-lis-apps/ui';
import {
	Rack,
	Option,
	Attribute,
	initialAttributeValue,
} from '@pangea-lis-apps/utils';

import RackTable from '../../../../components/rack/rack-table';
import CreateRackModal from '../../../../components/modals/create-rack-modal';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ViewHeldSamplesRackViewProps {}

export default function ViewHeldSamplesRackView(
	props: ViewHeldSamplesRackViewProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [refresh, setRefresh] = useState(false);
	const [loadingRack, setLoadingRack] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [rack, setRack] = useState<Rack | undefined>(undefined);
	const [selectedRackId, setSelectedRackId] = useState<string>('');
	const [racks, setRacks] = useState<Option[] | undefined>(undefined);

	// For editing rack
	const [attribute, setAttribute] = useState<Attribute>(
		initialAttributeValue
	);
	const [editRackModalVisible, setEditRackModalVisible] = useState(false);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !selectedRackId) return;

			setLoadingRack(true);

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(`/api/accessioner/racks/${selectedRackId}`);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setRack(parsedData);
				}
			} catch (error) {
				console.log(error);
			} finally {
				setLoadingRack(false);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, selectedRackId]);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).get(`/api/accessioner/racks/hold/?truncated=TRUE`);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData, total_entries);

					setRacks(
						parsedData.map((rack: Rack) => ({
							label: `${rack.name} (${rack.rows}Rx${rack.columns}C)`,
							value: rack._id.$oid,
						}))
					);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [axios, refresh]);

	const handleChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLSelectElement;

		if (target && target.name) {
			setSelectedRackId(target.value);
			setRack(undefined);
		}
	};

	const handleClick = (
		property: string,
		value: string,
		label: string,
		type: 'text'
	) => {
		setAttribute({
			property,
			value,
			label,
			type,
		});
		setEditRackModalVisible(true);
	};

	return (
		<Fragment>
			<EditDataModal
				data={rack}
				attribute={attribute}
				setRefresh={setRefresh}
				heading="Edit rack info"
				visible={{
					visible: editRackModalVisible,
					setVisible: setEditRackModalVisible,
				}}
				endpoint={`/api/accessioner/racks/${rack?._id.$oid}`}
			/>
			<Container>
				<ContentWrapper
					heading="Samples on Hold"
					Icon="ArchiveBoxArrowDownIcon"
					description="List of all the held samples, or those pending billing verification."
				>
					{!racks ? (
						<LoadingBox />
					) : (
						<Fragment>
							<CreateRackModal
								rackType="hold"
								setRefresh={setRefresh}
								visible={{
									visible: modalVisible,
									setVisible: setModalVisible,
								}}
							/>
							<ContentSection
								className={!rack ? 'space-y-3' : ''}
							>
								<div className="flex items-end justify-between">
									<FormGroup>
										<div className="sm:col-span-3">
											<SelectField
												name="rack"
												options={
													racks.length
														? [
																{
																	value: '',
																	disabled:
																		true,
																	label: 'Select an Option',
																},
																...racks,
														  ]
														: [
																{
																	value: '',
																	disabled:
																		true,
																	label: 'No option(s) available',
																},
														  ]
												}
												label="Existing racks"
												value={selectedRackId}
												handleSelect={handleChange}
											/>
										</div>
									</FormGroup>
									<Button
										type="button"
										Icon="PlusIcon"
										tier="tertiary"
										text="Create new hold rack"
										onClick={() => setModalVisible(true)}
									/>
								</div>
								{loadingRack ? (
									<LoadingBox />
								) : rack ? (
									<Fragment>
										<hr className="mt-6" />

										<div className="mt-6 space-y-4">
											<DescriptionList>
												<DescriptionItem
													term="Name"
													withFunction
													Icon="PencilIcon"
													details={rack.name}
													handleClick={() =>
														handleClick(
															'name',
															rack.name,
															'Rack name',
															'text'
														)
													}
												/>
												<DescriptionItem
													term="Dimensions (RxC)"
													details={`${rack.rows}x${rack.columns}`}
												/>
												<DescriptionItem
													term="Type"
													details="Hold"
												/>
											</DescriptionList>
											<RackTable rack={rack} />
										</div>
									</Fragment>
								) : (
									<EmptyBox
										Icon="Squares2X2Icon"
										heading="No rack selected"
										description="Start by selecting an existing rack or a new rack"
									/>
								)}
							</ContentSection>
						</Fragment>
					)}
				</ContentWrapper>
			</Container>
		</Fragment>
	);
}
