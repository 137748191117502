import { useNavigate } from 'react-router-dom';
import { useId, useState, useEffect } from 'react';

import {
	Query,
	ClinicData,
	formatDate,
	QueryState,
	Pagination,
	PaginationState,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';
import {
	Table,
	useAxios,
	DataCell,
	TableRow,
	TableBody,
	TableHead,
	Container,
	EmptyTable,
	TableLoading,
	ContentWrapper,
	ContentSection,
	ArrowLinkButton,
	HeaderCellWithSearch,
} from '@pangea-lis-apps/ui';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewAllHeldSamplesProps {}

export default function ViewAllHeldSamples(props: ViewAllHeldSamplesProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [query, setQuery] = useState<Query>({});
	const [data, setData] = useState<ClinicData[] | undefined>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/medical-biller/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}&logicalOperation=OR`,
					{
						'sample.status': 'hold',
						'requisition_form.billing_verified': false,
						...query,
					}
				);

				if (!unmounted) {
					console.log(JSON.parse(data), total_entries);

					setData(JSON.parse(data));
					setPagination((prev) => ({
						...prev,
						total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	return (
		<Container>
			<ContentWrapper
				heading="Held Samples"
				Icon="ArchiveBoxArrowDownIcon"
				description="List of all held samples requiring billing verification."
			>
				<ContentSection heading="All Samples">
					<HeldSamplesToReviewTable
						data={data}
						query={{ query, setQuery }}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}

interface HeldSamplesToReviewTableProps {
	query: QueryState;
	data: ClinicData[] | undefined;
	pagination: PaginationState;
}

const HeldSamplesToReviewTable = (props: HeldSamplesToReviewTableProps) => {
	const navigate = useNavigate();
	const { query, setQuery } = props.query;

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCellWithSearch
					type="text"
					name="pangea_id"
					label="Pangea ID"
					query={query}
					setQuery={setQuery}
					property="sample.pangea_id"
				/>
				<HeaderCellWithSearch
					type="text"
					name="specimen_id"
					label="Specimen ID"
					query={query}
					setQuery={setQuery}
					property="sample.specimen_id"
				/>
				<HeaderCellWithSearch
					type="text"
					name="patient_first_name"
					label="First Name"
					query={query}
					setQuery={setQuery}
					property="patient.first_name"
				/>
				<HeaderCellWithSearch
					type="text"
					name="patient_last_name"
					label="Last Name"
					query={query}
					setQuery={setQuery}
					property="patient.last_name"
				/>
				<HeaderCellWithSearch
					type="date"
					name="patient_date_of_birth"
					query={query}
					label="Date of Birth"
					setQuery={setQuery}
					property="patient.date_of_birth"
				/>
				<HeaderCellWithSearch
					type="date"
					name="sample_collection_date"
					query={query}
					label="Collection Date"
					setQuery={setQuery}
					property="requisition_form.sample_collection_date"
				/>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => (
							<TableRow key={index} index={index}>
								<DataCell>
									{datum.sample.pangea_id && (
										<ArrowLinkButton
											text={datum.sample.pangea_id}
											onClick={() =>
												navigate(
													`/medical-biller/view/samples/${datum._id.$oid}`
												)
											}
										/>
									)}
								</DataCell>
								<DataCell>{datum.sample.specimen_id}</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.first_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.last_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? formatDate(
												datum.patient.date_of_birth
										  )
										: null}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.requisition_form
											.sample_collection_date
									)}
								</DataCell>
							</TableRow>
						))
					) : (
						<EmptyTable
							Icon="FingerPrintIcon"
							heading="No samples on hold"
							description="Switch to rack view"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
};
