export const FORM_STEPS = [
	{
		id: 'provider-organization-info',
		name: 'Provider and Organization Info',
	},
	{
		id: 'order-sample-info',
		name: 'Order and Sample Info',
	},
	{
		id: 'patient-info',
		name: 'Patient Info',
	},
	{
		id: 'billing-info',
		name: 'Billing Info',
	},
	{
		id: 'authorization-attachments',
		name: 'Misc and Attachments',
	},
	{
		id: 'review',
		name: 'Review',
	},
];
