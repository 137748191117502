import toast from 'react-hot-toast';
import { useAuth0, User } from '@auth0/auth0-react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useId, useState, Dispatch, FormEvent, SetStateAction } from 'react';

import {
	Modal,
	Table,
	Button,
	useAxios,
	TableRow,
	DataCell,
	TableHead,
	TableBody,
	TextButton,
	LoadingBox,
	FormFooter,
	HeaderCell,
	EmptyTable,
	TableLoading,
	DescriptionItem,
	DescriptionList,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	Patient,
	formatDate,
	ClinicData,
	PaginationState,
	getLabelFromValue,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

interface ModalProps {
	patients: Patient[] | undefined;
	patient: {
		first_name: string;
		last_name: string;
		date_of_birth: string;
		middle_name: string;
	};
	data: ClinicData | undefined;
	paginationState: PaginationState;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	modalVisible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

export default function SearchPatientsModal({
	data,
	patient,
	patients,
	setRefresh,
	modalVisible,
	paginationState,
}: ModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();

	const { visible, setVisible } = modalVisible;
	const [disabled, setDisabled] = useState(false);
	const { pagination, setPagination } = paginationState;
	const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>(
		undefined
	);

	const handleSelectPatient = (patient: Patient | undefined) => {
		setSelectedPatient(patient);
	};

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios || !data || !user) return;

		setDisabled(true);

		toast.loading('Updating...');

		try {
			let payload: {
				user: User;
				patient_id: string | null;
				last_name?: string;
				first_name?: string;
				date_of_birth?: string;
				middle_name?: string;
			} = {
				user,
				patient_id: selectedPatient ? selectedPatient._id.$oid : null,
			};

			if (!selectedPatient) {
				payload = {
					...payload,
					last_name: patient.last_name,
					first_name: patient.first_name,
					date_of_birth: patient.date_of_birth,
					middle_name: patient.middle_name,
				};
			}

			await (
				await axios
			).patch(
				`/api/accessioner/data/${data._id.$oid}/trf/identify-patient`,
				payload
			);

			handleClose();
			setRefresh((value) => !value);
		} catch (error) {
			console.log(error);
			setDisabled(false);
		}

		toast.dismiss();
	};

	const handleClose = () => {
		setVisible(false);
		setDisabled(false);
	};

	return (
		<Modal
			visible={visible}
			onClose={handleClose}
			title="Patient search"
			customWidth="max-w-7xl"
		>
			{!(patients && data) ? (
				<LoadingBox />
			) : (
				<div>
					<div className="space-y-6">
						<DescriptionList
							heading="Search criteria"
							description="To start a new search, close the modal and provide new patient details."
						>
							<DescriptionItem
								term="First Name"
								details={patient.first_name}
							/>
							<DescriptionItem
								term="Middle Name"
								details={patient.middle_name}
							/>
							<DescriptionItem
								term="Last Name"
								details={patient.last_name}
							/>
							<DescriptionItem
								term="Date of Birth"
								details={formatDate(patient.date_of_birth)}
							/>
						</DescriptionList>

						<hr />

						<PatientsTable
							data={data}
							patients={patients}
							selectedPatient={selectedPatient}
							handleSelectPatient={handleSelectPatient}
							pagination={{ pagination, setPagination }}
						/>
					</div>
					<FormFooter>
						{selectedPatient ? (
							<Button
								type="submit"
								text="Continue"
								tier="tertiary"
								onClick={handleSubmit}
								Icon="ArrowSmallRightIcon"
							/>
						) : (
							<Button
								type="submit"
								text="Cancel"
								tier="tertiary"
								onClick={handleClose}
							/>
						)}
					</FormFooter>
				</div>
			)}
		</Modal>
	);
}

interface PatientsTableProps {
	data: ClinicData;
	patients: Patient[];
	pagination: PaginationState;
	selectedPatient: Patient | undefined;
	handleSelectPatient: (patient: Patient | undefined) => void;
}

const PatientsTable = (props: PatientsTableProps) => {
	return (
		<Table heading="Matching patient records" pagination={props.pagination}>
			<TableHead>
				<HeaderCell customWidth="w-64">Actions</HeaderCell>
				<HeaderCell>First Name</HeaderCell>
				<HeaderCell>Middle Name</HeaderCell>
				<HeaderCell>Last Name</HeaderCell>
				<HeaderCell>Date of Birth</HeaderCell>
				<HeaderCell>Sex</HeaderCell>
				<HeaderCell>Phone Number</HeaderCell>
				<HeaderCell>Medical Record Number (MRN)</HeaderCell>
				<HeaderCell>Address</HeaderCell>
			</TableHead>
			<TableBody>
				{props.patients ? (
					props.patients.length ? (
						props.patients.map((datum, index) => {
							const savedPatient =
								props.data.patient &&
								props.data.patient._id.$oid === datum._id.$oid;

							const newlySelectedPatient =
								props.selectedPatient &&
								props.selectedPatient._id.$oid ===
									datum._id.$oid;

							return (
								<TableRow
									key={index}
									index={index}
									className={
										newlySelectedPatient
											? '!bg-blue-50'
											: ''
									}
								>
									<DataCell>
										{savedPatient ? (
											<div className="flex items-center space-x-1">
												<p className="text-blue-600 font-medium">
													Saved selection
												</p>
												<CheckIcon className="w-5 h-5 text-blue-600" />
											</div>
										) : newlySelectedPatient ? (
											<div className="flex items-center justify-between">
												<p className="text-blue-600 font-medium">
													New selection
												</p>
												<TextButton
													color="gray"
													type="button"
													text="Deselect"
													className="group-hover:text-gray-700"
													onClick={() =>
														props.handleSelectPatient(
															undefined
														)
													}
												/>
											</div>
										) : (
											<TextButton
												color="gray"
												type="button"
												text="Select"
												onClick={() =>
													props.handleSelectPatient(
														datum
													)
												}
											/>
										)}
									</DataCell>
									<DataCell>{datum.first_name}</DataCell>
									<DataCell>{datum.middle_name}</DataCell>
									<DataCell>{datum.last_name}</DataCell>
									<DataCell>
										{formatDate(datum.date_of_birth)}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sex,
											FORMS.sex_options
										)}
									</DataCell>
									<DataCell>{datum.phone_number}</DataCell>
									<DataCell>
										{datum.medical_record_number}
									</DataCell>
									<DataCell>
										<span>{datum.address}</span>
										<br />
										<span>
											{datum.city}, {datum.state}{' '}
											{datum.zip_code}
										</span>
									</DataCell>
								</TableRow>
							);
						})
					) : (
						<EmptyTable
							colSpan={8}
							Icon="UserGroupIcon"
							heading="No patients"
							description="Create a new patient if one doesn't exist"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
};
