/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
	useId,
	useRef,
	Fragment,
	useState,
	useEffect,
	ChangeEvent,
	KeyboardEvent,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Button,
	useAxios,
	FormGroup,
	DateField,
	Container,
	FormFooter,
	LoadingBox,
	TextButton,
	GenericField,
	ContentSection,
	ContentWrapper,
} from '@pangea-lis-apps/ui';
import { NonClinicData, normalizeFormValues } from '@pangea-lis-apps/utils';

import IdOverwriteConfirmationModal from '../../helpers/specimen-id-overwrite-confirmation-modal';

interface FormValues {
	[key: string]: string;
	specimen_id: string;
	sample_received_date: string;
	sample_collection_date: string;
}

const initialFormValues = {
	specimen_id: '',
	sample_received_date: '',
	sample_collection_date: '',
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProcessNonClinicPrecisionBIOMEWoundSampleProps {}

export default function ProcessNonClinicPrecisionBIOMEWoundSample(
	props: ProcessNonClinicPrecisionBIOMEWoundSampleProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const navigate = useNavigate();
	const { dataId } = useParams();
	const [searchParams] = useSearchParams();

	const redirectPath = searchParams.get('redirectPath');
	const redirectPathQuery = redirectPath
		? `/?redirectPath=${redirectPath}`
		: '';

	const [disabled, setDisabled] = useState(false);
	const [data, setData] = useState<NonClinicData | undefined>(undefined);
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

	// For overwriting existing specimen id
	const pangeaIdOverwriteConfirmationRef = useRef(false);
	const [idToOverwrite, setIdToOverwrite] = useState<
		'specimen_id' | 'pangea_id'
	>('specimen_id');
	const [
		showIdOverwriteConfirmationModal,
		setShowIdOverwriteConfirmationModal,
	] = useState(false);

	// For checking updates
	const formValuesRef = useRef<FormValues>();

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: { data },
				} = await (await axios).get(`/api/accessioner/data/${dataId}`);

				if (!unmounted) {
					const parsedData: NonClinicData = JSON.parse(data);
					console.log(parsedData);

					setData(parsedData);
					setFormValues(() => {
						const populatedFormValues = {
							sample_received_date:
								parsedData.sample.sample_received_date,
							specimen_id: parsedData.sample.specimen_id || '',
							sample_collection_date:
								parsedData.sample.sample_collection_date,
						};

						formValuesRef.current = populatedFormValues;
						return populatedFormValues;
					});
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, dataId]);

	const handleChange: ChangeEventHandler<
		HTMLSelectElement | HTMLInputElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLSelectElement | HTMLInputElement;

		if (target && target.name)
			setFormValues((prevValues) => {
				return {
					...prevValues,
					[target.name]: target.value,
				};
			});
	};

	const handleSubmit = async () => {
		const redirectURL = `/accessioner/accession/sample/precision_biome_wound/data/${dataId}/non-clinic-sample/print-barcode${redirectPathQuery}`;

		if (disabled || !axios) return;
		else if (
			JSON.stringify(formValuesRef.current) === JSON.stringify(formValues)
		) {
			navigate(redirectURL);
			return;
		} else if (
			formValues['sample_received_date'] &&
			formValues['sample_collection_date'] &&
			new Date(formValues['sample_received_date']) <
				new Date(formValues['sample_collection_date'])
		) {
			toast.error(
				'Invalid received date! Received Date must be after the collection date.'
			);
			return;
		} else if (data) {
			if (
				data.sample.specimen_id &&
				formValues['specimen_id'] !== data.sample.specimen_id
			) {
				if (!pangeaIdOverwriteConfirmationRef.current) {
					setIdToOverwrite('specimen_id');
					setShowIdOverwriteConfirmationModal(true);
					return;
				}

				pangeaIdOverwriteConfirmationRef.current = false;
				setShowIdOverwriteConfirmationModal(false);
			}
		}

		console.log(formValues);

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			const normalizedFormValues = normalizeFormValues(formValues);

			await (
				await axios
			).patch(`/api/accessioner/data/${dataId}/sample`, {
				assay: 'precision_biome_wound',
				form_data: normalizedFormValues,
			});

			toast.dismiss();

			setFormValues(initialFormValues);
			navigate(redirectURL);
		} catch (error) {
			setDisabled(false);
		}
	};

	return (
		<Container>
			<ContentWrapper
				Icon="FingerPrintIcon"
				heading="Process non-clinic PrecisionBIOME® wound sample"
			>
				{!formValues || !data ? (
					<LoadingBox />
				) : (
					<Fragment>
						<IdOverwriteConfirmationModal
							data={data}
							idToOverwrite={idToOverwrite}
							id={formValues[`sample.${idToOverwrite}`]}
							handleUpdateSample={handleSubmit}
							resetId={() =>
								setFormValues((prevValue) => ({
									...prevValue,
									[idToOverwrite]: data.sample[idToOverwrite],
								}))
							}
							idOverwriteConfirmationRef={
								pangeaIdOverwriteConfirmationRef
							}
							visible={{
								visible: showIdOverwriteConfirmationModal,
								setVisible: setShowIdOverwriteConfirmationModal,
							}}
						/>
						<ContentSection id="form">
							<Form>
								<FormGroup heading="Sample information">
									<div className="sm:col-span-2">
										<DateField
											showRequiredAsterisk={true}
											label="Received Date"
											name="sample.sample_received_date"
											handleInputChange={handleChange}
											value={
												formValues[
													'sample_received_date'
												]
											}
										/>
									</div>
									<div className="sm:col-span-2"></div>
									<Fragment>
										<div className="sm:col-span-2">
											<GenericField
												type="text"
												name="sample.specimen_id"
												label="Specimen ID"
												placeholder="e.g., XZ12345678"
												handleKeyDown={(
													event: KeyboardEvent<HTMLInputElement>
												) => {
													if (event.code === 'Enter')
														event.preventDefault();
												}}
												value={
													formValues['specimen_id']
												}
												handleInputChange={handleChange}
											/>
										</div>
										<div className="sm:col-span-2">
											<DateField
												name="sample.sample_collection_date"
												label="Sample Collection Date"
												handleInputChange={handleChange}
												value={
													formValues[
														'sample_collection_date'
													]
												}
											/>
										</div>
									</Fragment>
								</FormGroup>
								<FormFooter className="flex items-center justify-between">
									<TextButton
										color="gray"
										type="button"
										onClick={() => {
											navigate(
												redirectPath
													? redirectPath
													: `/accessioner/accession/sessions/${data.accessioning_session._id.$oid}/preprocess`
											);
										}}
										text={
											<span>
												&#8592; Return to provider and
												assay selection
											</span>
										}
									/>
									<Button
										type="button"
										tier="tertiary"
										disabled={disabled}
										onClick={handleSubmit}
										text="Save and continue"
									/>
								</FormFooter>
							</Form>
						</ContentSection>
					</Fragment>
				)}
			</ContentWrapper>
		</Container>
	);
}
