import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	Fragment,
	useState,
	Dispatch,
	useEffect,
	FormEvent,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Modal,
	Table,
	Button,
	DataCell,
	TableRow,
	useAxios,
	TableHead,
	TableBody,
	FormGroup,
	EmptyTable,
	HeaderCell,
	FormFooter,
	TextButton,
	TextareaField,
	ContentSection,
} from '@pangea-lis-apps/ui';
import { Note, formatDate, PaginationState } from '@pangea-lis-apps/utils';

interface NotesTableProps {
	notes: Note[];
	dataId: string;
	pagination: PaginationState;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export default function NotesTable(props: NotesTableProps) {
	const { user } = useAuth0();
	const [addNoteModal, setAddNoteModal] = useState(false);

	const [note, setNote] = useState<Note>();
	const [editNoteModal, setEditNoteModal] = useState(false);

	return (
		<Fragment>
			<AddNoteModal
				dataId={props.dataId}
				setRefresh={props.setRefresh}
				visible={{
					visible: addNoteModal,
					setVisible: setAddNoteModal,
				}}
			/>
			<EditNoteModal
				note={note}
				dataId={props.dataId}
				setRefresh={props.setRefresh}
				visible={{
					visible: editNoteModal,
					setVisible: setEditNoteModal,
				}}
			/>
			<ContentSection
				heading="Notes"
				headingAction={
					<Button
						type="button"
						tier="tertiary"
						Icon="PlusIcon"
						text="Add note"
						onClick={() => setAddNoteModal(true)}
					/>
				}
			>
				<Table pagination={props.pagination}>
					<TableHead>
						<HeaderCell>Note</HeaderCell>
						<HeaderCell>Date</HeaderCell>
						<HeaderCell>Agent</HeaderCell>
						<HeaderCell>Action</HeaderCell>
					</TableHead>
					<TableBody>
						{props.notes.length ? (
							props.notes.map((datum, index) => {
								return (
									<TableRow key={index} index={index}>
										<DataCell>
											{datum.content}
											{datum.edited && (
												<span className="italic">
													{' '}
													Edited
												</span>
											)}
										</DataCell>
										<DataCell>
											{formatDate(
												datum.metadata.date_modified
													.$date,
												true
											)}
										</DataCell>
										<DataCell>
											{datum.metadata.created_by &&
												datum.metadata.created_by.first_name.concat(
													' ',
													datum.metadata.created_by
														.last_name
												)}
										</DataCell>
										<DataCell>
											{datum.metadata.created_by.id ===
												user?.sub && (
												<TextButton
													text="Edit"
													color="blue"
													type="button"
													onClick={() => {
														setNote(datum);
														setEditNoteModal(true);
													}}
												/>
											)}
										</DataCell>
									</TableRow>
								);
							})
						) : (
							<EmptyTable
								Icon="ListBulletIcon"
								heading="No notes"
							/>
						)}
					</TableBody>
				</Table>
			</ContentSection>
		</Fragment>
	);
}

interface ModalProps {
	dataId: string;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

function AddNoteModal(props: ModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { visible, setVisible } = props.visible;

	const [note, setNote] = useState('');
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios || !props.dataId) return;

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).post(`/api/cls/batches/${props.dataId}/note`, {
				note,
			});

			toast.dismiss();

			setNote('');
			setDisabled(false);
			setVisible((value) => !value);
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLTextAreaElement;

		if (target && target.name) setNote(target.value);
	};

	return (
		<Modal
			title="Add note"
			visible={visible}
			customWidth="max-w-md"
			onClose={() => setVisible((value) => !value)}
		>
			<Form>
				<FormGroup>
					<div className="sm:col-span-6">
						<TextareaField
							required
							name="note"
							value={note}
							label="Note"
							maxLength={220}
							handleInputChange={handleChange}
							placeholder="e.g., We decided to push the run scheduled for Wed, Oct 10, to tomorrow."
						/>
					</div>
				</FormGroup>
				<FormFooter>
					<Button
						text="Add"
						type="button"
						tier="tertiary"
						Icon="PlusIcon"
						disabled={disabled}
						onClick={handleSubmit}
					/>
				</FormFooter>
			</Form>
		</Modal>
	);
}

function EditNoteModal(props: ModalProps & { note: Note | undefined }) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { visible, setVisible } = props.visible;

	const [note, setNote] = useState('');
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		if (props.note && props.note.content) setNote(props.note.content);
	}, [props.note]);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios || !props.dataId || !props.note) return;

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(`/api/cls/batches/${props.dataId}/note`, {
				note_id: props.note.id,
				note,
			});

			toast.dismiss();

			setNote('');
			setDisabled(false);
			setVisible((value) => !value);
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLTextAreaElement;

		if (target && target.name) setNote(target.value);
	};

	return (
		<Modal
			title="Update note"
			visible={visible}
			customWidth="max-w-md"
			onClose={() => setVisible((value) => !value)}
		>
			<Form>
				<FormGroup>
					<div className="sm:col-span-6">
						<TextareaField
							required
							name="note"
							value={note}
							label="Note"
							maxLength={220}
							handleInputChange={handleChange}
							placeholder="e.g., We decided to push the run scheduled for Wed, Oct 10, to tomorrow."
						/>
					</div>
				</FormGroup>
				<FormFooter>
					<Button
						text="Update"
						type="button"
						tier="tertiary"
						Icon="CheckIcon"
						disabled={disabled}
						onClick={handleSubmit}
					/>
				</FormFooter>
			</Form>
		</Modal>
	);
}
