import {
	Link,
	Table,
	TableRow,
	DataCell,
	TableBody,
	TableHead,
	EmptyTable,
	HeaderCell,
	TableLoading,
	HeaderCellWithSearch,
} from '@pangea-lis-apps/ui';
import {
	SHARED,
	QueryState,
	PaginationState,
	getRoleFromPathname,
	BaseHydratedCustomer,
} from '@pangea-lis-apps/utils';

import { useOrganizations } from '../../../../utils/hooks/index';

interface AllCustomersTableProps {
	query: QueryState;
	pagination: PaginationState;
	data: BaseHydratedCustomer[] | undefined;
}

export default function AllCustomersTable(props: AllCustomersTableProps) {
	const role = getRoleFromPathname();

	const { query, setQuery } = props.query;
	const organizations = useOrganizations();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCellWithSearch
					type="text"
					name="national_provider_identifier"
					label="NPI"
					query={query}
					property="national_provider_identifier"
					setQuery={setQuery}
				/>
				<HeaderCellWithSearch
					type="text"
					name="first_name"
					label="First Name"
					query={query}
					property="first_name"
					setQuery={setQuery}
				/>
				<HeaderCellWithSearch
					type="text"
					name="last_name"
					label="Last Name"
					query={query}
					property="last_name"
					setQuery={setQuery}
				/>
				<HeaderCellWithSearch
					query={query}
					type="combobox"
					name="organization"
					label="Organization"
					setQuery={setQuery}
					property="organization._id"
					options={organizations || SHARED.no_options}
				/>
				<HeaderCell>Actions</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((customer, index) => (
							<TableRow key={index} index={index}>
								<DataCell>
									{customer.national_provider_identifier}
								</DataCell>
								<DataCell>{customer.first_name}</DataCell>
								<DataCell>{customer.last_name}</DataCell>
								<DataCell>
									{customer.organization &&
										customer.organization.name}
								</DataCell>
								<DataCell>
									<Link
										text="Details"
										path={`/${role}/users/${customer._id.$oid}`}
									/>
								</DataCell>
							</TableRow>
						))
					) : (
						<EmptyTable
							Icon="UserGroupIcon"
							heading="No customers"
							description="Wait for a purchase"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
