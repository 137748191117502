import { Ref, ChangeEventHandler } from 'react';

import { Label } from '../label/label';

export interface NumberFieldProps {
	min?: number;
	max?: number;
	name: string;
	value: number;
	label?: string;
	required?: true;
	placeholder?: string;
	inputFieldRef?: Ref<HTMLInputElement> | undefined;
	handleInputChange: ChangeEventHandler<HTMLInputElement> | undefined;
}

export function NumberField(props: NumberFieldProps) {
	return (
		<div>
			{props.label && (
				<Label
					name={props.name}
					label={props.label}
					required={props.required}
				/>
			)}
			<input
				type="number"
				id={props.name}
				min={props.min}
				max={props.max}
				name={props.name}
				value={props.value}
				ref={props.inputFieldRef}
				required={props.required}
				placeholder={props.placeholder}
				onChange={props.handleInputChange}
				className="mt-1 block w-full shadow-sm sm:text-sm rounded-md focus:ring-2 focus:ring-blue-200 focus:border-blue-500 border-gray-300 placeholder:italic placeholder:text-gray-400"
			/>
		</div>
	);
}

export default NumberField;
