/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	Outlet,
	useParams,
	useLocation,
	useSearchParams,
} from 'react-router-dom';
import {
	useId,
	Fragment,
	useState,
	Dispatch,
	useEffect,
	createContext,
	SetStateAction,
} from 'react';

import {
	Steps,
	useAxios,
	Container,
	LoadingBox,
	TRFComponents,
	ContentSection,
	ContentWrapper,
	AccessioningStatus,
	ContentSectionDivider,
	SpecimenInfo,
} from '@pangea-lis-apps/ui';
import { ClinicData, ProgressStep } from '@pangea-lis-apps/utils';

import { setStepStyles } from '../../helpers/form';
import Notes from '../../../../../../components/notes';

interface ContextType {
	redirectPath: string | null;
	data: ClinicData | undefined;
	canUpdateSampleInfo: boolean;
	setData: Dispatch<SetStateAction<ClinicData | undefined>>;
}

export const ProcessSampleContext = createContext<ContextType>({
	data: undefined,
	setData: () => {},
	redirectPath: null,
	canUpdateSampleInfo: false,
});

export interface ProcessSampleProps {}

export default function ProcessSample(props: ProcessSampleProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { pathname } = useLocation();
	const currentPageId =
		pathname.split('/').slice(-1)[0] || pathname.split('/').slice(-2)[0];

	const { assay, dataId } = useParams();
	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');

	const [refresh, setRefresh] = useState(false);
	const [data, setData] = useState<ClinicData | undefined>(undefined);
	const [steps, setSteps] = useState<ProgressStep[] | undefined>([
		{
			id: 'update-info',
			step: 'Step 1',
			name: 'Update sample info',
			href: `/accessioner/accession/sample/${assay}/data/${dataId}/sample/update-info`,
			status: 'upcoming',
		},
		{
			id: 'print-barcode',
			step: 'Step 2',
			name: 'Print barcode',
			href: `/accessioner/accession/sample/${assay}/data/${dataId}/sample/print-barcode`,
			status: 'upcoming',
		},
		{
			id: 'verify-sample',
			step: 'Step 3',
			name: 'Verify sample',
			href: `/accessioner/accession/sample/${assay}/data/${dataId}/sample/verify-sample`,
			status: 'upcoming',
		},
		{
			id: 'hold',
			step: 'Step 3(a)',
			name: 'Hold sample',
			status: 'upcoming',
		},
		{
			id: 'flag',
			step: 'Step 3(b)',
			name: 'Flag sample',
			status: 'upcoming',
		},
		{
			id: 'review',
			step: 'Step 3(c)',
			name: 'Request to review sample',
			status: 'upcoming',
		},
	]);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			setData(undefined);

			try {
				const {
					data: { data },
				} = await (await axios).get(`/api/accessioner/data/${dataId}`);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setData(parsedData);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [steps, axios, dataId, refresh, pathname]);

	useEffect(() => {
		// For setting the styles on the navigation bar
		setSteps((prevValues) => {
			if (prevValues) return setStepStyles(prevValues, currentPageId);
		});
	}, [pathname, currentPageId]);

	return (
		<ProcessSampleContext.Provider
			value={{
				data,
				setData,
				redirectPath,
				canUpdateSampleInfo: Boolean(
					data &&
						![
							'pending',
							'pending_verification',
							'verified',
						].includes(data.sample.status)
				),
			}}
		>
			<Container>
				<ContentWrapper
					Icon="FingerPrintIcon"
					heading="Update sample info"
					subheading="Accession sample"
				>
					{steps && (
						<Steps steps={steps} disableTabNavigation={true} />
					)}

					{!data ? (
						<LoadingBox />
					) : (
						<Fragment>
							<Outlet />

							<AccessioningStatus data={data} />

							<ContentSectionDivider />

							<SpecimenInfo data={data} />

							<ContentSectionDivider />

							<TRFComponents.PatientInformation data={data} />

							<ContentSectionDivider />

							<TRFComponents.BillingInformation data={data} />

							<ContentSectionDivider />

							<ContentSection heading="Notes">
								<Notes
									id={dataId}
									collection="data"
									data={data.notes}
									setRefresh={setRefresh}
									receiveDepartment="accessioning"
								/>
							</ContentSection>
						</Fragment>
					)}
				</ContentWrapper>
			</Container>
		</ProcessSampleContext.Provider>
	);
}
