/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext } from 'react';

import { Form, LoadingBox, ContentSection } from '@pangea-lis-apps/ui';

import { usePopulateFormValues } from '../../utils/helpers';
import { ProcessTRFStepsContext } from '../../components/form-wrapper';
import {
	BillingInfoFields,
	initialResp4PlexBillingInfoFormValues,
} from '../../components/billing-information-fields';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BillingInfoProps {}

export default function BillingInfo(props: BillingInfoProps) {
	const {
		data,
		formId,
		formValues,
		formValuesRef,
		setFormValues,
		handleSubmit,
	} = useContext(ProcessTRFStepsContext);

	usePopulateFormValues(
		data,
		formValuesRef,
		setFormValues,
		initialResp4PlexBillingInfoFormValues,
		'requisition_form'
	);

	return !formValues || !data ? (
		<LoadingBox />
	) : (
		<ContentSection>
			<Form id={formId} handleSubmit={handleSubmit}>
				<BillingInfoFields
					data={data}
					formValues={formValues}
					setFormValues={setFormValues}
				/>
			</Form>
		</ContentSection>
	);
}
