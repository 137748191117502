import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	useId,
	Fragment,
	Dispatch,
	useState,
	FormEvent,
	useEffect,
	ChangeEvent,
	SetStateAction,
} from 'react';

import {
	Link,
	Form,
	Alert,
	Modal,
	Table,
	Button,
	DataCell,
	useAxios,
	TableRow,
	TableBody,
	FormGroup,
	TableHead,
	Container,
	EmptyTable,
	TextButton,
	HeaderCell,
	LoadingBox,
	FormFooter,
	GenericField,
	TableLoading,
	// EditDataModal,
	ContentWrapper,
	ContentSection,
	DescriptionList,
	ArrowLinkButton,
	DescriptionItem,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ASSAYS,
	// Attribute,
	formatDate,
	ClinicData,
	ORGANIZATIONS,
	NonClinicData,
	getSampleStatuses,
	getLabelFromValue,
	// initialAttributeValue,
	HydratedAccessioningSession,
} from '@pangea-lis-apps/utils';

/* eslint-disable-next-line */
export interface ViewAccessioningSessionProps {}

export default function ViewAccessioningSession(
	props: ViewAccessioningSessionProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const navigate = useNavigate();
	const { accessioningSessionID } = useParams();

	const [refresh, setRefresh] = useState(false);
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState<HydratedAccessioningSession | undefined>(
		undefined
	);

	// // For editing session details
	// const [attribute, setAttribute] = useState<Attribute>(
	// 	initialAttributeValue
	// );
	// const [editBatchModalVisible, setEditBatchModalVisible] = useState(false);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !accessioningSessionID) return;
			else if (!unmounted) setData(undefined);

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(
					`/api/accessioner/accessioning-sessions/${accessioningSessionID}`
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					setData(parsedData);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, refresh, accessioningSessionID]);

	// const handleClick = (
	// 	property: string,
	// 	value: string,
	// 	label: string,
	// 	type: 'text'
	// ) => {
	// 	setAttribute({
	// 		property,
	// 		value,
	// 		label,
	// 		type,
	// 	});
	// 	setEditBatchModalVisible(true);
	// };

	return (
		<Fragment>
			<MarkCompletedModal
				setRefresh={setRefresh}
				visible={{
					visible,
					setVisible,
				}}
			/>
			{/* <EditDataModal
				data={data}
				attribute={attribute}
				setRefresh={setRefresh}
				heading="Edit accessioning session info"
				visible={{
					visible: editBatchModalVisible,
					setVisible: setEditBatchModalVisible,
				}}
				endpoint={`/api/accessioner/accessioning-sessions/${data?._id.$oid}`}
			/> */}
			<Container>
				<ContentWrapper
					Icon="CubeIcon"
					heading="Session details"
					description="The session must be marked as completed for providers to receive an email regarding discrepant or rejected samples."
				>
					{!data ? (
						<LoadingBox />
					) : (
						<Fragment>
							<ContentSection heading="Details">
								<DescriptionList>
									<DescriptionItem
										term="Session completed?"
										details={
											data.completed.value ? 'Yes' : 'No'
										}
									/>
									<div className="sm:col-span-2"></div>
									<DescriptionItem
										term="Name"
										// withFunction
										details={data.name}
										// Icon="PencilIcon"
										// handleClick={() => {
										// 	handleClick(
										// 		'name',
										// 		data.name,
										// 		'Name',
										// 		'text'
										// 	);
										// }}
									/>
									<DescriptionItem
										term="Created by"
										details={data.metadata.created_by.first_name.concat(
											' ',
											data.metadata.created_by.last_name
										)}
									/>
									<DescriptionItem
										term="Start time"
										details={formatDate(
											data.metadata.date_created.$date
										)}
									/>
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection
								id="form"
								heading="All samples"
								headingAction={
									!data.completed.value && (
										<Button
											type="button"
											tier="tertiary"
											Icon="PlusIcon"
											text="Add sample"
											onClick={() => {
												navigate(
													`/accessioner/accession/sessions/${data._id.$oid}/preprocess`
												);
											}}
										/>
									)
								}
							>
								<SessionSamplesTable
									data={data.samples}
									setRefresh={setRefresh}
								/>
								{Boolean(data.samples.length) &&
									!data.completed.value && (
										<FormFooter>
											<Button
												type="button"
												tier="primary"
												Icon="CheckIcon"
												text="Mark as completed"
												onClick={() => setVisible(true)}
											/>
										</FormFooter>
									)}
							</ContentSection>
						</Fragment>
					)}
				</ContentWrapper>
			</Container>
		</Fragment>
	);
}

interface SessionSamplesTableProps {
	setRefresh: Dispatch<SetStateAction<boolean>>;
	data: (ClinicData | NonClinicData)[] | undefined;
}

// function SessionSamplesList(props: SessionSamplesTableProps) {
// 	const navigate = useNavigate();
// 	const { pathname } = useLocation();

// 	const [visible, setVisible] = useState(false);
// 	const [selectedData, setSelectedData] = useState<
// 		ClinicData | NonClinicData | undefined
// 	>(undefined);

// 	return (
// 		<Fragment>
// 			<DeleteSampleModal
// 				selectedData={selectedData}
// 				setRefresh={props.setRefresh}
// 				visible={{
// 					visible,
// 					setVisible,
// 				}}
// 			/>
// 			<ul className="space-y-3">
// 				{props.data ? (
// 					props.data.length ? (
// 						props.data.map((datum, index) => {
// 							const clinicSample = datum.type === 'clinic';

// 							return (
// 								<li
// 									key={index}
// 									className="overflow-hidden bg-white px-4 py-4 shadow-sm sm:rounded-md sm:px-6 border border-gray-200"
// 								>
// 									<DescriptionList
// 										heading={datum.sample.pangea_id}
// 										headingAction={
// 											<Fragment>
// 												<Link
// 													text="Update"
// 													className="text-sm"
// 													path={
// 														clinicSample
// 															? `/accessioner/accession/trf/${datum.sample.assay}/v${datum.requisition_form.metadata.version}/data/${datum._id.$oid}/trf/provider-organization-info`
// 															: `/accessioner/accession/sample/${datum.sample.assay}/data/${datum._id.$oid}/non-clinic-sample/?redirectPath=${pathname}`
// 													}
// 												/>
// 												{[
// 													'received',
// 												].includes(
// 													datum.sample.status
// 												) && (
// 													<TextButton
// 														color="gray"
// 														text="Delete"
// 														type="button"
// 														className="group-hover:text-gray-700"
// 														onClick={() => {
// 															setVisible(true);
// 															setSelectedData(
// 																datum
// 															);
// 														}}
// 													/>
// 												)}
// 											</Fragment>
// 										}
// 									>
// 										<DescriptionItem
// 											term="Status"
// 											details={getLabelFromValue(
// 												datum.sample.status,
// 												getSampleStatuses(),
// 											)}
// 										/>
// 										<DescriptionItem
// 											term="Priority Level"
// 											details={getLabelFromValue(
// 												datum.sample.priority,
// 												ORGANIZATIONS.priorityLevels
// 											)}
// 										/>
// 										<div className="sm:col-span-1"></div>
// 										<DescriptionItem term="Pangea Id">
// 											<ArrowLinkButton
// 												text={datum.sample.pangea_id}
// 												onClick={() =>
// 													navigate(
// 														`/accessioner/view/samples/${datum._id.$oid}`
// 													)
// 												}
// 											/>
// 										</DescriptionItem>
// 										<DescriptionItem
// 											term="Specimen Id"
// 											details={datum.sample.specimen_id}
// 										/>
// 										<DescriptionItem
// 											term="Assay"
// 											details={getLabelFromValue(
// 												datum.sample.assay,
// 												ASSAYS["assays"]
// 											)}
// 										/>
// 										<DescriptionItem
// 											term="Entity"
// 											details={getLabelFromValue(
// 												datum.type,
// 												ORGANIZATIONS.types
// 											)}
// 										/>
// 										<DescriptionItem
// 											term="Provider"
// 											details={datum.customer.first_name.concat(
// 												' ',
// 												datum.customer.last_name
// 											)}
// 										/>
// 										<DescriptionItem
// 											term="Organization"
// 											details={datum.organization.name}
// 										/>
// 										<DescriptionItem
// 											term="Collection Date"
// 											details={formatDate(
// 												datum.sample
// 													.sample_collection_date
// 											)}
// 										/>
// 										<DescriptionItem
// 											term="Received Date"
// 											details={formatDate(
// 												datum.sample.sample_received_date
// 													? datum.sample.sample_received_date
// 													: undefined
// 											)}
// 										/>
// 										{datum.patient && (
// 											<Fragment>
// 												<div className="sm:col-span-1"></div>
// 												<DescriptionItem
// 													term="First Name"
// 													details={
// 														datum.patient.first_name
// 													}
// 												/>
// 												<DescriptionItem
// 													term="Last Name"
// 													details={
// 														datum.patient.last_name
// 													}
// 												/>
// 												<DescriptionItem
// 													term="Date of Birth"
// 													details={
// 														datum.patient
// 															.date_of_birth
// 													}
// 												/>
// 											</Fragment>
// 										)}
// 									</DescriptionList>
// 								</li>
// 							);
// 						})
// 					) : (
// 						<EmptyTable
// 							colSpan={14}
// 							heading="No samples"
// 							Icon="DocumentTextIcon"
// 							description="Start by accessioning a sample"
// 						/>
// 					)
// 				) : (
// 					<TableLoading />
// 				)}
// 			</ul>
// 		</Fragment>
// 	);
// }

function SessionSamplesTable(props: SessionSamplesTableProps) {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const [visible, setVisible] = useState(false);
	const [selectedData, setSelectedData] = useState<
		ClinicData | NonClinicData | undefined
	>(undefined);

	return (
		<Fragment>
			<DeleteSampleModal
				selectedData={selectedData}
				setRefresh={props.setRefresh}
				visible={{
					visible,
					setVisible,
				}}
			/>
			<Table>
				<TableHead>
					<HeaderCell>Actions</HeaderCell>
					<HeaderCell>Priority Level</HeaderCell>
					<HeaderCell>Sample status</HeaderCell>
					<HeaderCell customWidth="w-64">Assay</HeaderCell>
					<HeaderCell>Entity</HeaderCell>
					<HeaderCell>RUO</HeaderCell>
					<HeaderCell>Pangea id</HeaderCell>
					<HeaderCell>Specimen id</HeaderCell>
					<HeaderCell>Collection Date</HeaderCell>
					<HeaderCell>Received Date</HeaderCell>
					<HeaderCell>Entity</HeaderCell>
					<HeaderCell>Provider</HeaderCell>
					<HeaderCell>Organization</HeaderCell>
					<HeaderCell>Patient First Name</HeaderCell>
					<HeaderCell>Patient Last Name</HeaderCell>
					<HeaderCell>Patient Date of Birth</HeaderCell>
				</TableHead>
				<TableBody>
					{props.data ? (
						props.data.length ? (
							props.data.map((datum, index) => {
								const clinicSample = datum.type === 'clinic';

								return (
									<TableRow key={index} index={index}>
										<DataCell>
											{datum.sample.status !==
												'verified' && (
												<Link
													text="Update"
													path={
														clinicSample
															? `/accessioner/accession/trf/${datum.sample.assay}/v${datum.requisition_form.metadata.version}/data/${datum._id.$oid}/trf/provider-organization-info?redirectPath=${pathname}`
															: `/accessioner/accession/sample/${datum.sample.assay}/data/${datum._id.$oid}/non-clinic-sample/?redirectPath=${pathname}`
													}
												/>
											)}
											{datum.sample.status ===
												'received' && (
												<TextButton
													color="gray"
													text="Delete"
													type="button"
													className="ml-2 group-hover:text-gray-700"
													onClick={() => {
														setVisible(true);
														setSelectedData(datum);
													}}
												/>
											)}
										</DataCell>
										<DataCell>
											{getLabelFromValue(
												datum.sample.priority,
												ORGANIZATIONS.priorityLevels
											)}
										</DataCell>
										<DataCell>
											{getLabelFromValue(
												datum.sample.status,
												getSampleStatuses()
											)}
										</DataCell>
										<DataCell>
											{getLabelFromValue(
												datum.sample.assay,
												ASSAYS['assays']
											)}
										</DataCell>
										<DataCell>
											{getLabelFromValue(
												datum.type,
												ORGANIZATIONS.types
											)}
										</DataCell>
										<DataCell>
											{datum.sample.ruo === null
												? null
												: datum.sample.ruo
												? 'Yes'
												: 'No'}
										</DataCell>
										<DataCell>
											<ArrowLinkButton
												text={datum.sample.pangea_id}
												onClick={() =>
													navigate(
														`/accessioner/view/samples/${datum._id.$oid}`
													)
												}
											/>
										</DataCell>
										<DataCell>
											{datum.sample.specimen_id}
										</DataCell>
										<DataCell>
											{formatDate(
												datum.sample
													.sample_collection_date
											)}
										</DataCell>
										<DataCell>
											{formatDate(
												datum.sample
													.sample_received_date
													? datum.sample
															.sample_received_date
													: undefined
											)}
										</DataCell>
										<DataCell>
											{getLabelFromValue(
												datum.type,
												ORGANIZATIONS.types
											)}
										</DataCell>
										<DataCell>
											{datum.customer.first_name.concat(
												' ',
												datum.customer.last_name
											)}
										</DataCell>
										<DataCell>
											{datum.organization.name}
										</DataCell>
										<DataCell>
											{datum.patient
												? datum.patient.first_name
												: null}
										</DataCell>
										<DataCell>
											{datum.patient
												? datum.patient.last_name
												: null}
										</DataCell>
										<DataCell>
											{datum.patient
												? formatDate(
														datum.patient
															.date_of_birth
												  )
												: null}
										</DataCell>
									</TableRow>
								);
							})
						) : (
							<EmptyTable
								heading="No samples"
								Icon="DocumentTextIcon"
								description="Start by accessioning a sample"
							/>
						)
					) : (
						<TableLoading />
					)}
				</TableBody>
			</Table>
		</Fragment>
	);
}

function DeleteSampleModal(props: {
	setRefresh: Dispatch<SetStateAction<boolean>>;
	selectedData: ClinicData | NonClinicData | undefined;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { visible, setVisible } = props.visible;

	const [confirm, setConfirm] = useState('');
	const [disabled, setDisabled] = useState(false);

	const handleDelete = async (event: FormEvent) => {
		event.preventDefault();

		if (!axios || !confirm || !props.selectedData) return;
		else if (confirm !== 'Yes') {
			toast.error('Enter Yes to proceed!');
			return;
		}

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).delete(`/api/accessioner/data/${props.selectedData._id.$oid}`);

			toast.dismiss();

			handleClose();
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);
		}
	};

	const handleChange = (event: ChangeEvent) => {
		const target = event.target as HTMLSelectElement;

		if (target && target.name) setConfirm(target.value);
	};

	const handleClose = () => {
		setConfirm('');
		setVisible(false);
		setDisabled(false);
	};

	return (
		<Modal
			visible={visible}
			onClose={handleClose}
			title="Delete sample"
			customWidth="max-w-sm"
			description={
				<span>
					This action is irreversible. All the associated notes Enter{' '}
					<span className="italic">Yes</span> to confirm deletion.
				</span>
			}
		>
			<Form handleSubmit={handleDelete}>
				{/* <Alert
					type="warning"
					heading="Confirm deletion"
					description="This action is irreversible."
				/> */}
				<FormGroup>
					<div className="sm:col-span-6">
						<GenericField
							required
							type="text"
							name="confirm"
							value={confirm}
							placeholder="Yes"
							label={
								<span>
									Enter <span className="italic">Yes</span> to
									proceed
								</span>
							}
							handleInputChange={handleChange}
						/>
					</div>
				</FormGroup>
				<FormFooter>
					<Button
						type="submit"
						text="Confirm"
						tier="tertiary"
						Icon="CheckIcon"
						disabled={disabled}
					/>
				</FormFooter>
			</Form>
		</Modal>
	);
}

function MarkCompletedModal(props: {
	setRefresh: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { accessioningSessionID } = useParams();
	const { visible, setVisible } = props.visible;

	const [confirm, setConfirm] = useState('');
	const [disabled, setDisabled] = useState(false);

	const handleCreateSession = async (event: FormEvent) => {
		event.preventDefault();

		if (!axios || !confirm) return;
		else if (confirm !== 'Yes') {
			toast.error('Enter Yes to proceed!');
			return;
		}

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(
				`/api/accessioner/accessioning-sessions/${accessioningSessionID}/mark-as-completed`,
				null
			);

			toast.dismiss();

			handleClose();
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);
		}
	};

	const handleChange = (event: ChangeEvent) => {
		const target = event.target as HTMLSelectElement;

		if (target && target.name) setConfirm(target.value);
	};

	const handleClose = () => {
		setConfirm('');
		setVisible(false);
		setDisabled(false);
	};

	return (
		<Modal
			visible={visible}
			onClose={handleClose}
			customWidth="max-w-md"
			title="Mark as completed"
			description="Confirm that you wish to end the session."
		>
			<Form handleSubmit={handleCreateSession}>
				<Alert
					type="info"
					heading="Attention needed"
					description="All samples must either be resolved, i.e., approved, flagged, rejected, held, or put up for review. For flagged and rejected samples, emails will be sent to their respective providers."
				/>
				<FormGroup>
					<div className="sm:col-span-6">
						<GenericField
							required
							type="text"
							name="confirm"
							value={confirm}
							placeholder="Yes"
							label={
								<span>
									Enter <span className="italic">Yes</span> to
									proceed
								</span>
							}
							handleInputChange={handleChange}
						/>
					</div>
				</FormGroup>
				<FormFooter>
					<Button
						type="submit"
						text="Confirm"
						tier="tertiary"
						Icon="CheckIcon"
						disabled={disabled}
					/>
				</FormFooter>
			</Form>
		</Modal>
	);
}
