import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	Fragment,
	Dispatch,
	useState,
	FormEvent,
	useEffect,
	ChangeEvent,
	SetStateAction,
	MouseEventHandler,
	ChangeEventHandler,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
	Query,
	SHARED,
	ClinicData,
	formatDate,
	QueryState,
	Pagination,
	PaginationState,
	getLabelFromValue,
	initialPaginationValues,
	getAllRequisitionFormOptions,
} from '@pangea-lis-apps/utils';
import {
	Table,
	Modal,
	Alert,
	Button,
	useAxios,
	DataCell,
	TableRow,
	TableBody,
	TableHead,
	Container,
	EmptyTable,
	HeaderCell,
	FormFooter,
	TableLoading,
	ContentWrapper,
	ContentSection,
	useTabsContext,
	ArrowLinkButton,
	HeaderCellWithSearch,
} from '@pangea-lis-apps/ui';

import { useOrganizations, useCustomers } from '../../../../utils/hooks/index';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewHeldSamplesByTypeProps {}

export default function ViewHeldSamplesByType(
	props: ViewHeldSamplesByTypeProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { type } = useParams();

	const [refresh, setRefresh] = useState(false);
	const [query, setQuery] = useState<Query>({});
	const [disabled, setDisabled] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const [mainCheckbox, setMainCheckbox] = useState(false);
	const [data, setData] = useState<
		(ClinicData & { checked: boolean })[] | undefined
	>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			setData(undefined);

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				)(
					type === 'review'
						? {
								method: 'post',
								url: `/api/medical-biller/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
								data: {
									'requisition_form.billing_verified': false,
									// 'sample.status': 'hold',
									// 'sample.accessioning_approval.hold.reviewed.value': false,
									...query,
								},
						  }
						: {
								method: 'post',
								url: `/api/medical-biller/search/samples/hold/follow-up/?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
								data: { ...query },
						  }
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData, total_entries);

					setData(
						parsedData.map((x_data: ClinicData) => ({
							...x_data,
							checked: false,
						}))
					);
					setPagination((prev) => ({
						...prev,
						total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		type,
		axios,
		query,
		refresh,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	// Checkbox functions
	const handleSelectRow = (sampleId: string) => {
		setData((prev) => {
			if (prev) {
				const newArray = prev.map((x_data) => {
					if (x_data._id.$oid === sampleId)
						return {
							...x_data,
							checked: !x_data.checked,
						};
					else return x_data;
				});

				return newArray;
			}

			return;
		});
	};

	const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
		if (data) {
			setMainCheckbox(true);

			setData(() =>
				data.map((x_data) => {
					return {
						...x_data,
						checked: event.target.checked,
					};
				})
			);
		}
	};

	useEffect(() => {
		if (data) {
			let count = 0;

			for (const user of data) {
				if (user.checked) count += 1;
			}

			setMainCheckbox(Boolean(data.length) && count === data.length);
			setShowButton(Boolean(count));
		}
	}, [data]);

	const handleDownload: MouseEventHandler = async (event) => {
		event.preventDefault();

		if (disabled || !axios) return;

		setDisabled(true);

		toast.loading('Downloading...', toastOptions);

		try {
			const { data } = await (
				await axios
			).get(
				`/api/medical-biller/samples/download/?sampleType=${
					type === 'review'
						? 'reviewable_samples'
						: 'follow_up_samples'
				}`,
				{
					responseType: 'arraybuffer',
				}
			);

			toast.dismiss();

			const blob = new Blob([data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});

			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`${
					type === 'review'
						? 'Reviewable samples'
						: 'Follow up samples'
				} - ${new Date().toDateString()}.xlsx`
			);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);

			setDisabled(false);
		} catch (error) {
			console.log(error);
			setDisabled(false);
		}
	};

	return (
		<Fragment>
			<ConfirmVerificationModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: showModal,
					setVisible: setShowModal,
				}}
			/>
			<Container>
				<ContentWrapper
					heading={
						type === 'review'
							? 'Samples to Review'
							: 'Samples to Follow Up On'
					}
					Icon="ArchiveBoxArrowDownIcon"
					description={
						type === 'review'
							? 'List of all the held samples that have not been reviewed.'
							: 'List of all the held samples to follow up on.'
					}
				>
					<ContentSection
						heading={
							type === 'review'
								? 'All reviewable samples'
								: 'Samples to follow up on'
						}
						description="Download all samples as an Excel file, assign sections across the medical billers, process the samples, and update them on the portal."
						headingAction={
							<Button
								type="button"
								tier="tertiary"
								text="Download all"
								onClick={handleDownload}
								Icon="ArrowDownCircleIcon"
							/>
						}
					>
						<HeldSamplesTable
							data={data}
							query={{ query, setQuery }}
							mainCheckbox={mainCheckbox}
							handleSelectRow={handleSelectRow}
							handleSelectAll={handleSelectAll}
							pagination={{ pagination, setPagination }}
						/>
						{showButton && (
							<FormFooter>
								<Button
									type="button"
									tier="primary"
									Icon="CheckIcon"
									text="Mark selected as verified"
									onClick={() => setShowModal(true)}
								/>
							</FormFooter>
						)}
					</ContentSection>
				</ContentWrapper>
			</Container>
		</Fragment>
	);
}

interface ModalProps {
	setRefresh: Dispatch<SetStateAction<boolean>>;
	data: (ClinicData & { checked: boolean })[] | undefined;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

const ConfirmVerificationModal = (props: ModalProps) => {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();

	const { setRefreshTabs } = useTabsContext();
	const { visible, setVisible } = props.visible;
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !props.data || !axios || !user) return;

		const selectedSamples = props.data
			.filter((datum) => datum.checked)
			.map((datum) => datum._id.$oid);

		if (!selectedSamples.length) {
			toast.error('No samples selected!');
			return;
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(`/api/medical-biller/data/verify`, {
				user,
				data_ids: selectedSamples,
			});

			toast.dismiss();

			setRefreshTabs((value) => !value);
			props.setRefresh((value) => !value);
			handleClose();
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleClose = () => {
		setDisabled(false);
		setVisible((value) => !value);
	};

	return (
		<Modal
			visible={visible}
			onClose={handleClose}
			customWidth="max-w-md"
			title="Mark selected samples as verified"
			description="Once verified, the accessioning team will go ahead and finish the accessioning process."
		>
			<div className="space-y-4">
				<Alert
					type="warning"
					heading="Attention"
					description={
						<span>
							Make sure to update any incorrect information before
							marking a sample as verified. Once reviewed and
							updated, confirm verification. Furthermore, if you
							need to add any notes, please do so before verifying
							the sample.
						</span>
					}
				/>

				<FormFooter>
					<Button
						type="submit"
						tier="tertiary"
						onClick={handleSubmit}
						Icon="ArrowSmallRightIcon"
						text="Confirm verification"
					/>
				</FormFooter>
			</div>
		</Modal>
	);
};

interface HeldSamplesTableProps {
	query: QueryState;
	mainCheckbox: boolean;
	pagination: PaginationState;
	handleSelectRow: (sampleId: string) => void;
	data: (ClinicData & { checked: boolean })[] | undefined;
	handleSelectAll: ChangeEventHandler<HTMLInputElement>;
}

const HeldSamplesTable = (props: HeldSamplesTableProps) => {
	const navigate = useNavigate();
	const { query, setQuery } = props.query;

	const customers = useCustomers();
	const organizations = useOrganizations();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell customWidth="w-12">
					<div className="flex items-center justify-center">
						<input
							type="checkbox"
							checked={props.mainCheckbox}
							onChange={props.handleSelectAll}
							className="w-4 h-4 cursor-pointer border border-gray-300 rounded"
						/>
					</div>
				</HeaderCell>
				<HeaderCellWithSearch
					type="text"
					name="pangea_id"
					label="Pangea ID"
					query={query}
					setQuery={setQuery}
					property="sample.pangea_id"
				/>
				<HeaderCellWithSearch
					type="text"
					name="specimen_id"
					label="Specimen ID"
					query={query}
					setQuery={setQuery}
					property="sample.specimen_id"
				/>
				<HeaderCellWithSearch
					type="select"
					query={query}
					setQuery={setQuery}
					label="Billing Method"
					name="Billing Method"
					property="requisition_form.billing_method"
					options={Object.values(
						getAllRequisitionFormOptions(
							'billing_information_payer_options',
							'array'
						)
					)}
				/>
				<HeaderCellWithSearch
					type="combobox"
					query={query}
					name="customer"
					label="Customer"
					property="customer._id"
					setQuery={setQuery}
					options={customers || SHARED.no_options}
				/>
				<HeaderCellWithSearch
					type="combobox"
					query={query}
					name="organization"
					setQuery={setQuery}
					label="Organization"
					property="organization._id"
					options={organizations || SHARED.no_options}
				/>
				<HeaderCellWithSearch
					type="text"
					name="patient_first_name"
					label="First Name"
					query={query}
					setQuery={setQuery}
					property="patient.first_name"
				/>
				<HeaderCellWithSearch
					type="text"
					name="patient_last_name"
					label="Last Name"
					query={query}
					setQuery={setQuery}
					property="patient.last_name"
				/>
				<HeaderCellWithSearch
					type="date"
					name="patient_date_of_birth"
					query={query}
					label="Date of Birth"
					setQuery={setQuery}
					property="patient.date_of_birth"
				/>
				<HeaderCellWithSearch
					type="date"
					name="sample_collection_date"
					query={query}
					label="Collection Date"
					setQuery={setQuery}
					property="requisition_form.sample_collection_date"
				/>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => (
							<TableRow key={index} index={index}>
								<DataCell>
									<div className="flex items-center justify-center">
										<input
											type="checkbox"
											checked={datum.checked}
											onChange={() =>
												props.handleSelectRow(
													datum._id.$oid
												)
											}
											className="w-4 h-4 cursor-pointer border border-gray-300 rounded"
										/>
									</div>
								</DataCell>
								<DataCell>
									{datum.sample.pangea_id && (
										<ArrowLinkButton
											text={datum.sample.pangea_id}
											onClick={() =>
												navigate(
													`/medical-biller/view/samples/${datum._id.$oid}`
												)
											}
										/>
									)}
								</DataCell>
								<DataCell>{datum.sample.specimen_id}</DataCell>
								<DataCell>
									{getLabelFromValue(
										datum.requisition_form.billing_method,
										Object.values(
											getAllRequisitionFormOptions(
												'billing_information_payer_options',
												'array'
											)
										)
									)}
								</DataCell>
								<DataCell>
									{datum.customer.first_name.concat(
										' ',
										datum.customer.last_name
									)}
								</DataCell>
								<DataCell>{datum.organization.name}</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.first_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.last_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? formatDate(
												datum.patient.date_of_birth
										  )
										: null}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.requisition_form
											.sample_collection_date
									)}
								</DataCell>
							</TableRow>
						))
					) : (
						<EmptyTable
							Icon="FingerPrintIcon"
							heading="No samples on hold"
							description="Switch to rack view"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
};
