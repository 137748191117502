import { Routes, Route } from 'react-router-dom';

import UpdateBillingInfo from './update-billing-info';
import ViewHeldSample from './samples/view-held-sample';
import ViewAllHeldSamples from './samples/view-all-held-samples';
import ViewHeldSamplesByType from './samples/view-held-samples-by-type';

import RoleProtectedRoute from '../../../utils/routing';

export function MedicalBillerRoutes() {
	return (
		<Routes>
			<Route
				element={
					<RoleProtectedRoute
						roles={['BITSS_medical-biller', 'BITSS_super-admin']}
					/>
				}
			>
				<Route
					path="view/samples/hold"
					element={<ViewAllHeldSamples />}
				/>
				<Route
					path="view/samples/hold/:type"
					element={<ViewHeldSamplesByType />}
				/>
				<Route
					path="view/samples/:dataId"
					element={<ViewHeldSample />}
				/>
				<Route
					element={<UpdateBillingInfo />}
					path="view/samples/:dataId/update-billing-info"
				/>
			</Route>
		</Routes>
	);
}
