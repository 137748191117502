import { Option } from './common-types';

interface FormValues {
	[key: string]: any;
}

const isOptionType = (value: any): value is Option[] => {
	if (!Array.isArray(value)) {
		return false;
	}

	for (const item of value) {
		if (typeof item !== 'object' || item === null) {
			return false;
		}

		if (!('label' in item) || !('value' in item)) {
			return false;
		}
	}

	return true;
};

export const normalizeFormValues = (formValues: FormValues): FormValues => {
	const normalizedValues: FormValues = {};

	for (const key in formValues) {
		if (Object.prototype.hasOwnProperty.call(formValues, key)) {
			let value = formValues[key];

			if (typeof value === 'string') {
				value = value.trim();
			}

			if (value === 'true') {
				normalizedValues[key] = true;
			} else if (value === 'false') {
				normalizedValues[key] = false;
			} else if (value === '') {
				normalizedValues[key] = null;
			} else if (isOptionType(value)) {
				normalizedValues[key] = formValues[key].map(
					(test: Option) => test.value
				);
			} else {
				normalizedValues[key] = value;
			}
		}
	}

	return normalizedValues;
};
