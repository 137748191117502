import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useId, useState, useEffect } from 'react';

import {
	Table,
	DataCell,
	useAxios,
	TableRow,
	Container,
	TableHead,
	TableBody,
	HeaderCell,
	EmptyTable,
	TableLoading,
	ContentWrapper,
	ContentSection,
	ArrowLinkButton,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ASSAYS,
	ClinicData,
	formatDate,
	Pagination,
	ORGANIZATIONS,
	NonClinicData,
	PaginationState,
	getSpecimenTypes,
	getLabelFromValue,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';
import TableSearchFiltersForm from '../../components/tables/lab-tech/table-search-filters-form';

const projectedFields = [
	'type',
	'sample.pangea_id',
	'_id',
	'customer.first_name',
	'customer.last_name',
	'organization.name',
	'sample.ruo',
	'sample.priority',
	'sample.specimen_id',
	'sample.assay',
	'sample.sample_specimen_type',
	'requisition_form.sample_collection_date',
	'sample.sample_collection_date',
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewRejectedSamplesProps {}

export default function ViewRejectedSamples(props: ViewRejectedSamplesProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [query, setQuery] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [data, setData] = useState<
		(ClinicData | NonClinicData)[] | undefined
	>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setData(undefined);
				setDisabled(true);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/lab-tech/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query: {
							...query,
							'sample.status': 'reject',
						},
						projected_fields: projectedFields,
					}
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData, total_entries);

					setData(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);

			toast.dismiss();
		};

		fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [
		axios,
		query,
		setQuery,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	return (
		<Container>
			<ContentWrapper Icon="FingerPrintIcon" heading="Rejected Samples">
				<TableSearchFiltersForm
					tableType="rejected-samples"
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>
				<ContentSectionDivider />
				<ContentSection heading="All rejected samples">
					<SamplesTable
						data={data}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}

interface SamplesTableProps {
	pagination: PaginationState;
	data: (ClinicData | NonClinicData)[] | undefined;
}

function SamplesTable(props: SamplesTableProps) {
	const navigate = useNavigate();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Assay</HeaderCell>
				<HeaderCell>Priority Level</HeaderCell>
				<HeaderCell>RUO</HeaderCell>
				<HeaderCell>Customer</HeaderCell>
				<HeaderCell>Organization</HeaderCell>
				<HeaderCell>Pangea ID</HeaderCell>
				<HeaderCell>Specimen ID</HeaderCell>
				<HeaderCell>Specimen Type</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => {
							const clinicSample = datum.type === 'clinic';

							return (
								<TableRow key={index} index={index}>
									<DataCell>
										{getLabelFromValue(
											datum.sample.assay,
											ASSAYS['assays']
										)}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.priority,
											ORGANIZATIONS.priorityLevels
										)}
									</DataCell>
									<DataCell>
										{datum.sample.ruo ? 'Yes' : 'No'}
									</DataCell>
									<DataCell>
										{datum.customer.first_name.concat(
											' ',
											datum.customer.last_name
										)}
									</DataCell>
									<DataCell>
										{datum.organization.name}
									</DataCell>
									<DataCell>
										<ArrowLinkButton
											text={datum.sample.pangea_id}
											onClick={() =>
												navigate(
													`/lab-tech/view/samples/${datum._id.$oid}`
												)
											}
										/>
									</DataCell>
									<DataCell>
										{datum.sample.specimen_id}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.sample_specimen_type,
											getSpecimenTypes()
										)}
									</DataCell>
									<DataCell>
										{clinicSample
											? formatDate(
													datum.requisition_form
														.sample_collection_date
											  )
											: formatDate(
													datum.sample
														.sample_collection_date
											  )}
									</DataCell>
								</TableRow>
							);
						})
					) : (
						<EmptyTable
							Icon="FingerPrintIcon"
							heading="No rejected samples"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
