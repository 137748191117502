import { useParams } from 'react-router-dom';
import { useState, Fragment, Dispatch, SetStateAction } from 'react';

import {
	FormFooter,
	LoadingBox,
	SpecimenInfo,
	ContentSection,
	DescriptionList,
	DescriptionItem,
	AccessioningStatus,
	ContentSectionDivider,
	DataComponents,
} from '@pangea-lis-apps/ui';
import { ClinicData, NonClinicData } from '@pangea-lis-apps/utils';

import {
	RerunModal,
	AddNoteModal,
	AddCommentModal,
	initialModalValue,
} from './modals';
import Notes from '../../../../components/notes';
import SampleActionsButton from './sample-actions-button';
import RejectSampleModal from '../../../../components/modals/reject-sample-modal';

export interface ViewPrecisionBIOMEWoundSampleProps {
	data: ClinicData | NonClinicData | undefined;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export default function ViewPrecisionBIOMEWoundSample({
	data,
	setRefresh,
}: ViewPrecisionBIOMEWoundSampleProps) {
	const { dataId } = useParams();
	const [modalVisible, setModalVisible] = useState<{
		[key: string]: boolean;
	}>(initialModalValue);

	// // Regenerate report
	// const [disabled, setDisabled] = useState(false);

	// const handleGenerateReport = async () => {
	// 	if (!axios) return;

	// 	setDisabled(true);

	// 	toast.loading('Generating...', toastOptions);

	// 	try {
	// 		await (
	// 			await axios
	// 		).post(`/api/cls/data/${dataId}/report/generate`, null);

	// 		toast.success('Report generated!', toastOptions);

	// 		setRefresh((value) => !value);
	// 	} catch (error) {
	// 		console.log(error);
	// 	} finally {
	// 		setDisabled(false);
	// 	}
	// };

	return (
		<Fragment>
			<RejectSampleModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<RerunModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<AddNoteModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<AddCommentModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			{/* <VerifyModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/> */}
			{!data ? (
				<LoadingBox />
			) : (
				<Fragment>
					<AccessioningStatus data={data} />

					<ContentSectionDivider />

					<SpecimenInfo data={data} />

					{'patient' in data && (
						<Fragment>
							<ContentSectionDivider />

							<DataComponents.PatientInformation data={data} />
						</Fragment>
					)}

					<ContentSectionDivider />

					<DataComponents.ProviderInformation data={data} />

					<ContentSectionDivider />

					<DataComponents.OrganizationInformation data={data} />

					<ContentSectionDivider />

					<DataComponents.ReportInformation
						data={data}
						setRefresh={setRefresh}
					/>

					<ContentSectionDivider />

					<ContentSection heading="Miscellaneous">
						<DescriptionList>
							<DescriptionItem
								term="Notes"
								customColSpan="sm:col-span-3"
							>
								<Notes
									id={dataId}
									collection="data"
									data={data.notes}
									setRefresh={setRefresh}
									receiveDepartment="cls"
								/>
							</DescriptionItem>
						</DescriptionList>
					</ContentSection>

					<ContentSection>
						<FormFooter>
							<SampleActionsButton
								data={data}
								setModalVisible={setModalVisible}
							/>
						</FormFooter>
					</ContentSection>
				</Fragment>
			)}
		</Fragment>
	);
}
