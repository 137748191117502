import { Link } from 'react-router-dom';
import { MouseEventHandler } from 'react';

import { ContentSection } from '@pangea-lis-apps/ui';
import { ProgressStep } from '@pangea-lis-apps/utils';

export interface StepsProps {
	formId?: string;
	steps: ProgressStep[];
	disableTabNavigation?: boolean;
	handleSubmit?: MouseEventHandler;
}

export function Steps({
	steps,
	formId,
	disableTabNavigation,
	handleSubmit,
}: StepsProps) {
	return (
		<ContentSection>
			<nav
				aria-label="Progress"
				className="rounded-md p-4 border border-gray-200 shadow-sm"
			>
				<ol className="space-y-4 md:flex md:space-y-0 md:space-x-2">
					{steps.map((step) => (
						<li key={step.name} className="md:flex-1">
							{step.status === 'complete' ? (
								formId ? (
									<button
										type="button"
										form={formId}
										onClick={handleSubmit}
										data-step-id={step.id}
										data-trigger="tab_button"
										disabled={disableTabNavigation}
										className="group w-full flex flex-col border-l-2 border-blue-600 py-2 pl-4 hover:border-blue-800 md:border-l-0 md:border-t-2 md:pl-0 md:pt-2 md:pb-0"
									>
										<span className="text-xs font-base text-blue-600 group-hover:text-blue-800 pointer-events-none">
											{step.step}
										</span>
										<span className="text-xs font-medium text-left pointer-events-none">
											{step.name}
										</span>
									</button>
								) : (
									<Link
										to={
											disableTabNavigation
												? '#'
												: step.href || '#'
										}
										className="group w-full flex flex-col border-l-2 border-blue-600 py-2 pl-4 hover:border-blue-800 md:border-l-0 md:border-t-2 md:pl-0 md:pt-2 md:pb-0"
									>
										<span className="text-xs font-base text-blue-600 group-hover:text-blue-800">
											{step.step}
										</span>
										<span className="text-xs font-medium text-left">
											{step.name}
										</span>
									</Link>
								)
							) : step.status === 'current' ? (
								formId ? (
									<button
										type="button"
										form={formId}
										onClick={handleSubmit}
										data-step-id={step.id}
										data-trigger="tab_button"
										disabled={disableTabNavigation}
										className="w-full flex flex-col border-l-2 border-blue-600 py-2 pl-4 md:border-l-0 md:border-t-2 md:pl-0 md:pt-2 md:pb-0"
									>
										<span className="text-xs font-base text-blue-600 pointer-events-none">
											{step.step}
										</span>
										<span className="text-xs font-medium text-left pointer-events-none">
											{step.name}
										</span>
									</button>
								) : (
									<Link
										to={
											disableTabNavigation
												? '#'
												: step.href || '#'
										}
										className="w-full flex flex-col border-l-2 border-blue-600 py-2 pl-4 md:border-l-0 md:border-t-2 md:pl-0 md:pt-2 md:pb-0"
									>
										<span className="text-xs font-base text-blue-600">
											{step.step}
										</span>
										<span className="text-xs font-medium text-left">
											{step.name}
										</span>
									</Link>
								)
							) : formId ? (
								<button
									type="button"
									form={formId}
									onClick={handleSubmit}
									data-step-id={step.id}
									data-trigger="tab_button"
									disabled={disableTabNavigation}
									className={`w-full group flex flex-col border-l-2 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-2 md:pl-0 md:pt-2 md:pb-0 ${
										!step.href &&
										'cursor-not-allowed !border-gray-100 hover:!border-gray-100'
									}`}
								>
									<span
										className={`text-xs font-base text-gray-500 group-hover:text-gray-700 ${
											!step.href &&
											'text-gray-400 group-hover:!text-gray-400'
										} pointer-events-none`}
									>
										{step.step}
									</span>
									<span
										className={`text-xs font-medium text-left ${
											!step.href && 'text-gray-400'
										} pointer-events-none`}
									>
										{step.name}
									</span>
								</button>
							) : (
								<Link
									to={
										disableTabNavigation
											? '#'
											: step.href || '#'
									}
									className={`w-full group flex flex-col border-l-2 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-2 md:pl-0 md:pt-2 md:pb-0 ${
										!step.href &&
										'cursor-not-allowed !border-gray-100 hover:!border-gray-100'
									}`}
								>
									<span
										className={`text-xs font-base text-gray-500 group-hover:text-gray-700 ${
											!step.href &&
											'text-gray-400 group-hover:!text-gray-400'
										}`}
									>
										{step.step}
									</span>
									<span
										className={`text-xs font-medium text-left ${
											!step.href && 'text-gray-400'
										}`}
									>
										{step.name}
									</span>
								</Link>
							)}
						</li>
					))}
				</ol>
			</nav>
		</ContentSection>
	);
}

export default Steps;
