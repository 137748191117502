/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	Outlet,
	useParams,
	useLocation,
	useSearchParams,
} from 'react-router-dom';
import {
	useId,
	Fragment,
	useState,
	Dispatch,
	useEffect,
	createContext,
	SetStateAction,
} from 'react';

import {
	Steps,
	useAxios,
	Container,
	LoadingBox,
	SpecimenInfo,
	ContentSection,
	ContentWrapper,
	DescriptionItem,
	DescriptionList,
	AccessioningStatus,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import { ProgressStep, NonClinicData } from '@pangea-lis-apps/utils';

import { setStepStyles } from '../../helpers/form';
import Notes from '../../../../../../components/notes';

const STEPS: ProgressStep[] = [
	{
		id: 'print-barcode',
		step: 'Step 1',
		name: 'Print barcode',
		status: 'upcoming',
	},
	{
		id: 'verify-priority',
		step: 'Step 2',
		name: 'Verify priority level',
		status: 'upcoming',
	},
	{
		id: 'verify-sample',
		step: 'Step 3',
		name: 'Verify sample',
		status: 'upcoming',
	},
	{
		id: 'flag',
		step: 'Step 3(a)',
		name: 'Flag sample',
		status: 'upcoming',
	},
	{
		id: 'review',
		step: 'Step 3(b)',
		name: 'Request to review sample',
		status: 'upcoming',
	},
];

interface ContextType {
	redirectPath: string | null;
	canUpdateSampleInfo: boolean;
	data: NonClinicData | undefined;
	setData: Dispatch<SetStateAction<NonClinicData | undefined>>;
}

export const ProcessSampleContext = createContext<ContextType>({
	data: undefined,
	setData: () => {},
	redirectPath: null,
	canUpdateSampleInfo: false,
});

export interface ProcessSampleLayoutProps {}

export default function ProcessSampleLayout(props: ProcessSampleLayoutProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { dataId } = useParams();
	const { pathname } = useLocation();
	const currentPageId =
		pathname.split('/').slice(-1)[0] || pathname.split('/').slice(-2)[0];

	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');

	const [refresh, setRefresh] = useState(false);
	const [steps, setSteps] = useState<ProgressStep[] | undefined>(STEPS);
	const [data, setData] = useState<NonClinicData | undefined>(undefined);

	useEffect(() => {
		// For setting the styles on the navigation bar
		setSteps((prevValues) => {
			if (prevValues) return setStepStyles(prevValues, currentPageId);
		});
	}, [pathname, currentPageId]);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			setData(undefined);

			try {
				const {
					data: { data },
				} = await (await axios).get(`/api/accessioner/data/${dataId}`);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					setData(parsedData);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, dataId, refresh, pathname]);

	return (
		<ProcessSampleContext.Provider
			value={{
				data,
				setData,
				redirectPath,
				canUpdateSampleInfo: Boolean(
					data &&
						![
							'pending',
							'pending_verification',
							'verified',
						].includes(data.sample.status)
				),
			}}
		>
			<Container>
				<ContentWrapper
					Icon="FingerPrintIcon"
					heading="Verify sample"
					subheading="Accession sample"
				>
					{steps && <Steps steps={steps} />}

					{!data ? (
						<LoadingBox />
					) : (
						<Fragment>
							<Outlet />

							<AccessioningStatus data={data} />

							<ContentSectionDivider />

							<SpecimenInfo data={data} />

							<ContentSectionDivider />

							<ContentSection heading="Provider info">
								<DescriptionList>
									<DescriptionItem
										term="Provider"
										details={data.customer.first_name.concat(
											' ',
											data.customer.last_name
										)}
									/>
									<DescriptionItem
										term="Organization"
										details={data.organization.name}
									/>
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection heading="Notes">
								<Notes
									id={dataId}
									collection="data"
									data={data.notes}
									setRefresh={setRefresh}
									receiveDepartment="accessioning"
								/>
							</ContentSection>
						</Fragment>
					)}
				</ContentWrapper>
			</Container>
		</ProcessSampleContext.Provider>
	);
}
