import { useParams } from 'react-router-dom';
import { useId, useState, Fragment, useEffect } from 'react';

import {
	Button,
	useAxios,
	Container,
	LoadingBox,
	ContentSection,
	ContentWrapper,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ActionLog,
	ClinicData,
	Pagination,
	NonClinicData,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import ViewAlinityMSample from './alinity_m';
import ViewBladderCARESample from './bladdercare';
import ViewPrecisionBIOMEWoundSample from './precision_biome_wound';
import ViewFITSample from './fit'

import ActionLogTable from '../../../../components/tables/action-logs-table';



/* eslint-disable-next-line */
export interface ViewSampleProps {}

export default function ViewSample(props: ViewSampleProps) {
	
	const toastId = useId();
	const axios = useAxios(toastId);

	const { dataId } = useParams();

	const [refresh, setRefresh] = useState(false);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);
	const [data, setData] = useState<ClinicData | NonClinicData | undefined>(
		undefined
	);
	const [sampleHistory, setSampleHistory] = useState<ActionLog[] | undefined>(
		undefined
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !dataId) return;

			setData(undefined);

			try {
				const [
					{
						data: { data: data1 },
					},
					{
						data: { data: data2 },
					},
				] = await Promise.all([
					(await axios).get(`/api/cls/data/${dataId}`),
					(
						await axios
					).get(`/api/cls/data/${dataId}/action-history?type=sample`),
				]);

				if (!unmounted) {
					const parsedData = JSON.parse(data1);
					setData(parsedData);

					const parsedSampleHistory = JSON.parse(data2);
					setSampleHistory(parsedSampleHistory);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [dataId, axios, refresh]);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			setSampleHistory(undefined);

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).get(
					`/api/cls/data/${dataId}/action-history?type=sample&page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setSampleHistory(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		axios,
		dataId,
		refresh,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	return (
		<Container>
			<ContentWrapper Icon="FingerPrintIcon" heading="Sample Details">
				{!data ? (
					<LoadingBox />
				) : (
					<Fragment>
						{(() => {
							const assay = data.sample.assay;

							if (assay === 'sti' || assay === 'resp4plex') {
								return (
									<ViewAlinityMSample
										data={data}
										setRefresh={setRefresh}
									/>
								);
							} else if (assay === 'bladdercare') {
								return (
									<ViewBladderCARESample
										data={data}
										setRefresh={setRefresh}
									/>
								);
							} else if (assay === 'precision_biome_wound') {
								return (
									<ViewPrecisionBIOMEWoundSample
										data={data}
										setRefresh={setRefresh}
									/>
								);
							} else if (assay === 'fit') {
								return (
									<ViewFITSample
										data={data}
										setRefresh={setRefresh}
									/>
								)
							} else {
								return null;
							}
						})()}
					</Fragment>
				)}

				<ContentSectionDivider />

				{sampleHistory && (
					<ContentSection
						heading="Sample history"
						description="These are actions performed on the sample as it's being processed through the facility."
					>
						<ActionLogTable
							type="sample"
							pagination={{
								pagination,
								setPagination,
							}}
							data={sampleHistory}
						/>
					</ContentSection>
				)}
			</ContentWrapper>
		</Container>
	);
}
