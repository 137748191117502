import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
	useId,
	Fragment,
	useState,
	Dispatch,
	useContext,
	SetStateAction,
} from 'react';

import {
	Alert,
	Modal,
	Button,
	useAxios,
	LoadingBox,
	FormFooter,
	TextButton,
	ContentSection,
	DescriptionList,
	DescriptionItem,
	ButtonWithDropdown,
} from '@pangea-lis-apps/ui';
import {
	ASSAYS,
	IconName,
	ClinicData,
	BAR_ACTIONS,
	getLabelFromValue,
} from '@pangea-lis-apps/utils';

import { ProcessSampleContext } from './process-sample';
import RejectSampleModal from '../../../../../../components/modals/reject-sample-modal';
import RemoveSampleFromRackModal from '../../../../../../components/modals/remove-sample-from-rack-modal';

interface ActionItem {
	name: string;
	Icon: IconName;
	handleClick: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AccessionVerifySampleProps {}

export default function AccessionVerifySample(
	props: AccessionVerifySampleProps
) {
	const navigate = useNavigate();
	const { assay, dataId } = useParams();

	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');
	const redirectPathQuery = redirectPath
		? `/?redirectPath=${redirectPath}`
		: '';

	const { user } = useAuth0();
	const isAccessioningAdmin =
		user && user['custom_claims/roles'].includes('BITSS_accessioner-admin');

	const { data, canUpdateSampleInfo } = useContext(ProcessSampleContext);

	const [modalVisible, setModalVisible] = useState<{
		[key: string]: boolean;
	}>({
		approve: false,
		reject: false,
	});
	const [showNotificationModal, setShowNotificationModal] = useState(false);

	return data ? (
		<Fragment>
			<ApproveSampleModal
				data={data}
				setNotificationModalVisible={setShowNotificationModal}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<RejectSampleModal
				data={data}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<RemoveSampleFromRackModal
				data={data}
				visible={{
					visible: showNotificationModal,
					setVisible: setShowNotificationModal,
				}}
			/>
			<ContentSection className="bg-gray-50 border border-gray-200 py-8 rounded-lg">
				<div className="flex items-center justify-between">
					<TextButton
						color="gray"
						type="button"
						onClick={() =>
							navigate(
								`/accessioner/accession/sample/${assay}/data/${dataId}/sample/print-barcode${redirectPathQuery}`
							)
						}
						text={<span>&#8592; Back</span>}
					/>
					{(() => {
						if (canUpdateSampleInfo || isAccessioningAdmin) {
							const actions: {
								[key: string]: ActionItem;
							} = {
								FLAG: {
									name: 'Flag',
									Icon: 'FlagIcon',
									handleClick: () =>
										navigate(
											`/accessioner/accession/sample/${assay}/data/${dataId}/sample/bar/flag${redirectPathQuery}`
										),
								},
								REJECT: {
									name: 'Reject',
									Icon: 'XCircleIcon',
									handleClick: () =>
										setModalVisible((prevValue) => ({
											...prevValue,
											reject: true,
										})),
								},
								HOLD: {
									name: 'Hold',
									Icon: 'InboxArrowDownIcon',
									handleClick: () =>
										navigate(
											`/accessioner/accession/sample/${assay}/data/${dataId}/sample/bar/hold${redirectPathQuery}`
										),
								},
								APPROVE: {
									name: 'Approve',
									Icon: 'CheckIcon',
									handleClick: () =>
										setModalVisible((prevValue) => ({
											...prevValue,
											approve: true,
										})),
								},
								REQUEST_TO_REVIEW: {
									Icon: 'DocumentCheckIcon',
									name: 'Request to review',
									handleClick: () =>
										navigate(
											`/accessioner/accession/sample/${assay}/data/${dataId}/sample/bar/review${redirectPathQuery}`
										),
								},
							};

							const items: ActionItem[] = [actions['FLAG']];

							if (data.sample.status !== 'reject') {
								items.push(actions['REJECT']);
							}

							const accessioning_completed =
								data.metadata.accessioning.completed.value;

							if (!data.requisition_form.billing_verified) {
								items.push(actions['HOLD']);
							}

							if (
								(![
									'pending',
									'pending_verification',
									'verified',
								].includes(data.sample.status) &&
									accessioning_completed) ||
								(data.sample.status === 'reviewed' &&
									!data.sample.accessioning_approval.reviewed
										.reject)
							) {
								items.unshift(actions['APPROVE']);
							}

							// if (
							// 	!accessioning_completed &&
							// 	data.sample.status !== 'reviewed' &&
							// 	data.sample.sample_received_date
							// ) {
							// 	items.push(actions['REQUEST_TO_REVIEW']);
							// }

							// // If the sample is unstable, then simply reject, no other options
							// if (
							// 	data.sample.misc.sample_stability
							// 		.stable_until &&
							// 	new Date(
							// 		data.sample.misc.sample_stability.stable_until.$date
							// 	).setHours(0, 0, 0, 0) <
							// 		new Date().setHours(0, 0, 0, 0) &&
							// 	data.sample.status !== 'reject'
							// ) {
							// 	items = [actions['REJECT']];
							// }

							// Always show request to review
							items.push(actions['REQUEST_TO_REVIEW']);

							return (
								<ButtonWithDropdown
									Icon={items[0].Icon}
									items={items.slice(1)}
									mainButtonText={items[0].name}
									mainClick={items[0].handleClick}
								/>
							);
						}

						return (
							<Button
								type="button"
								tier="tertiary"
								text="Continue"
								onClick={() => {
									if (redirectPath) {
										navigate(redirectPath);
										return;
									}

									navigate(
										`/accessioner/accession/sessions/${data.accessioning_session._id.$oid}/preprocess`
									);
								}}
							/>
						);
					})()}
				</div>
			</ContentSection>
		</Fragment>
	) : null;
}

interface ModalProps {
	data: ClinicData | undefined;
	setNotificationModalVisible: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: { [key: string]: boolean };
		setVisible: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
	};
}

export function ApproveSampleModal(props: ModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const navigate = useNavigate();
	const { dataId } = useParams();

	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');

	const { visible, setVisible } = props.visible;
	const [disabled, setDisabled] = useState(false);

	const handleApprove = async () => {
		if (disabled || !props.data || !axios) return;

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(`/api/accessioner/data/${dataId}/sample/approve`, {
				user,
			});

			toast.dismiss();

			setDisabled(false);

			if (BAR_ACTIONS.includes(props.data.sample.status)) {
				props.setNotificationModalVisible(true);
				setVisible((prevValue) => ({
					...prevValue,
					approve: false,
				}));
				return;
			}

			navigate(
				redirectPath
					? redirectPath
					: `/accessioner/accession/sessions/${props.data.accessioning_session._id.$oid}/preprocess`
			);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	return (
		<Modal
			title="Approve sample"
			customWidth="max-w-md"
			onClose={() =>
				setVisible((prevValue) => ({
					...prevValue,
					approve: false,
				}))
			}
			visible={visible['approve']}
		>
			{!props.data ? (
				<LoadingBox />
			) : (
				<Fragment>
					<Alert
						type="info"
						className="mb-4"
						heading="Confirm approval"
						description="Review the details and confirm approval"
					/>

					<DescriptionList>
						<DescriptionItem
							term="Assay"
							details={getLabelFromValue(
								props.data.sample.assay,
								ASSAYS['assays']
							)}
							customColSpan="sm:col-span-3"
						/>
						<DescriptionItem
							term="Pangea ID"
							details={props.data.sample.pangea_id}
						/>
						<DescriptionItem
							term="Specimen ID"
							details={props.data.sample.specimen_id}
						/>
						<div className="sm:col-span-1"></div>
						<DescriptionItem
							term="Provider"
							details={props.data.customer.first_name.concat(
								' ',
								props.data.customer.last_name
							)}
						/>
						<DescriptionItem
							term="Organization"
							details={props.data.organization.name}
						/>
					</DescriptionList>

					<FormFooter>
						<Button
							type="button"
							text="Approve"
							tier="tertiary"
							Icon="CheckIcon"
							disabled={disabled}
							onClick={handleApprove}
						/>
					</FormFooter>
				</Fragment>
			)}
		</Modal>
	);
}
