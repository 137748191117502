import { toast } from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import {
	useId,
	useState,
	useEffect,
	MouseEvent,
	ChangeEvent,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Button,
	useAxios,
	FormGroup,
	Container,
	FormFooter,
	LoadingBox,
	GenericField,
	ContentSection,
	ContentWrapper,
	MultipleCombobox,
} from '@pangea-lis-apps/ui';
import {
	Option,
	Auth0Role,
	Auth0UserWithRoles,
	normalizeFormValues,
} from '@pangea-lis-apps/utils';

interface FormValues {
	first_name: string;
	last_name: string;
	email_address: string;
	roles: Option[];
}

const initialFormValues = {
	first_name: '',
	last_name: '',
	email_address: '',
	roles: [],
};

/* eslint-disable-next-line */
export interface CreateCustomerProps {}

export default function CreateCustomer(props: CreateCustomerProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const navigate = useNavigate();

	const isSuperAdmin =
		user && user['custom_claims/roles'].includes('BITSS_super-admin');
	const isAdmin = user && user['custom_claims/roles'].includes('BITSS_admin');

	const [disabled, setDisabled] = useState(false);
	const [admin, setAdmin] = useState<Auth0UserWithRoles | undefined>(
		undefined
	);
	const [roles, setRoles] = useState<Option[] | undefined>(undefined);
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!user || !axios) return;

			try {
				const [
					{
						data: { data: data1 },
					},
					{
						data: { data: data2 },
					},
				] = await Promise.all([
					(await axios).get(`/api/admin/users/${user?.sub}`),
					(await axios).get('/api/admin/roles'),
				]);

				if (!unmounted) {
					console.log(data1);
					setAdmin(data1);

					console.log(data2);
					setRoles(() =>
						data2
							.filter(
								(role: Auth0Role) =>
									!role.name.includes('organization') &&
									!role.name.includes('super-admin') &&
									!role.name.includes('customer')
							)
							.map((role: Auth0Role) => ({
								label: role.description.split(' - ')[1],
								value: role.id,
							}))
					);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [user, axios]);

	const handleSubmit = async (event: MouseEvent) => {
		event.preventDefault();

		// Validation
		if (disabled || !axios) return;
		else if (process.env['NX_ENV'] === 'development') {
			const [username, domain] = formValues.email_address.split('@');

			if (
				![
					'pangealab.com',
					'zymoresearch.com',
					'epimorphy.com',
				].includes(domain)
			) {
				if (username.includes('test.pangealab')) {
					// pass
				} else {
					toast.error(
						'User must be a Pangea Lab, Epimorphy, or Zymo Research associate!'
					);
					return;
				}
			}
		}

		setDisabled(true);

		toast.loading('Creating...', toastOptions);

		try {
			const normalizedFormValues = normalizeFormValues(formValues);

			const {
				data: { data: auth0_user_id },
			} = await (
				await axios
			).post('/api/admin/user', {
				user,
				user_data: {
					...normalizedFormValues,
					roles: formValues.roles.map((role) => role.value),
				},
			});

			toast.dismiss();

			navigate(`/admin/view/users/${auth0_user_id}`);
		} catch (error) {
			console.log(error);

			setDisabled(false);
			setFormValues(initialFormValues);
		}
	};

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as
			| HTMLTextAreaElement
			| HTMLInputElement
			| HTMLSelectElement;

		if (target && target.name) {
			setFormValues((prevValues) => ({
				...prevValues,
				[target.name]: target.value,
			}));
		}
	};

	return (
		<Container>
			<ContentWrapper
				Icon="UserPlusIcon"
				heading="Create user"
				description="Once the account is created, the user will receive an email to reset their password."
			>
				{!admin || !roles ? (
					<LoadingBox />
				) : (
					<ContentSection>
						<Form>
							<FormGroup heading="User data">
								<div className="sm:col-span-2">
									<GenericField
										required
										type="text"
										name="first_name"
										label="First Name"
										placeholder="e.g., John"
										value={formValues.first_name}
										handleInputChange={handleChange}
									/>
								</div>
								<div className="sm:col-span-2">
									<GenericField
										required
										type="text"
										name="last_name"
										label="Last Name"
										value={formValues.last_name}
										handleInputChange={handleChange}
										placeholder="e.g., Kim"
									/>
								</div>
								<div className="sm:col-span-2">
									<GenericField
										required
										type="email"
										label="Email"
										name="email_address"
										value={formValues.email_address}
										handleInputChange={handleChange}
										placeholder="e.g., contact@fairlifemedical.com"
									/>
								</div>
							</FormGroup>
							<FormGroup heading="Assign roles">
								<div className="sm:col-span-2">
									<MultipleCombobox
										required
										name="roles"
										label="Roles"
										value={formValues.roles}
										handleSelect={(
											selectedOptions: Option[]
										) => {
											setFormValues((prevValues) => {
												if (prevValues) {
													return {
														...prevValues,
														roles: selectedOptions,
													};
												}

												return prevValues;
											});
										}}
										options={(() => {
											if (isSuperAdmin) {
												if (roles)
													return [
														{
															value: '',
															disabled: true,
															label: 'Select option(s)',
														},
														...roles,
													];
											}

											if (isAdmin && admin) {
												const hold = admin.roles
													.filter(
														(role: Auth0Role) =>
															role.name.includes(
																'BITSS'
															) &&
															!role.name.includes(
																'admin'
															)
													)
													.map((role: Auth0Role) => ({
														label: role.description.split(
															' - '
														)[1],
														value: role.id,
													}));

												return [
													{
														value: '',
														disabled: true,
														label: 'Select option(s)',
													},
													...hold,
												];
											}

											return [
												{
													value: '',
													disabled: true,
													label: 'No option(s) available',
												},
											];
										})()}
									/>
								</div>
							</FormGroup>
							<FormFooter>
								<Button
									type="button"
									text="Create"
									tier="tertiary"
									Icon="PlusIcon"
									disabled={disabled}
									onClick={handleSubmit}
								/>
							</FormFooter>
						</Form>
					</ContentSection>
				)}
			</ContentWrapper>
		</Container>
	);
}
