import { useId, useState, useEffect } from 'react';

import { useAxios } from '@pangea-lis-apps/ui';
import {
	Option,
	getRoleFromPathname,
	BaseHydratedCustomer,
	generateCustomerLabel,
	CLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID,
	NONCLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID,
} from '@pangea-lis-apps/utils';

export function useCustomers(customerRoles?: string[]) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const role = getRoleFromPathname();

	const [customers, setCustomers] = useState<Option[] | undefined>(undefined);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !role || customers) return;

			try {
				const {
					data: {
						data: { data },
					},
				} = await (
					await axios
				).post(`/api/${role}/search/customers`, {
					query: {
						roles: customerRoles
							? customerRoles
							: [
									CLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID,
									NONCLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID,
							  ], // returns clinic and non-clinic organization associates
					},
					// go through all the /search/customers and add projected fields and query
					projected_fields: [
						'_id',
						'first_name',
						'last_name',
						'entity',
						'organization.name',
						'national_provider_identifer',
					],
				});

				if (!unmounted) {
					const customers = JSON.parse(data);
					console.log(customers);

					setCustomers(() => {
						const hold = customers.map(
							(provider: BaseHydratedCustomer) => ({
								label: generateCustomerLabel(provider),
								value: provider._id.$oid,
							})
						);

						if (hold.length)
							return [
								{
									value: '',
									disabled: true,
									label: 'Select an Option',
								},
								...hold,
							];

						return [
							{
								value: '',
								disabled: true,
								label: 'No option(s) available',
							},
						];
					});
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [role, axios, customerRoles, customers]);

	return customers;
}
