import { BaseHydratedCustomer } from './types/types';

export const generateCustomerLabel = (customer: BaseHydratedCustomer) => {
	const full_name = customer.first_name.concat(' ', customer.last_name);

	if (customer.entity === 'non_clinic') {
		return full_name.concat(' - ', customer.organization.name);
	} else if (customer.entity === 'clinic') {
		if (customer.national_provider_identifier)
			return full_name
				.concat(' - ', customer.national_provider_identifier)
				.concat(', ', customer.organization.name);

		return full_name.concat(' - ', customer.organization.name);
	}

	return full_name;
};
