import toast from 'react-hot-toast';
import {
	useId,
	useState,
	useEffect,
	MouseEvent,
	ChangeEvent,
	MouseEventHandler,
} from 'react';

import {
	Button,
	useAxios,
	Combobox,
	Container,
	DateField,
	FormGroup,
	FormFooter,
	SelectField,
	GenericField,
	ContentWrapper,
	ContentSection,
	SearchFiltersForm,
	AssaySpecificFilters,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	ASSAYS,
	SHARED,
	DateRange,
	ClinicData,
	Pagination,
	ORGANIZATIONS,
	TableSearchFunctions,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import ViewReportsTable from '../../components/tables/view-reports';
import { useCustomers, useOrganizations } from '../../../utils/hooks';

const projectedFields = [
	'type',
	'sample.pangea_id',
	'_id',
	'sample.ruo',
	'sample.priority',
	'sample.specimen_id',
	'sample.collection_kit_id',
	'customer.first_name',
	'customer.last_name',
	'organization.name',
	'sample.assay',
	'sample.sample_collection_date',
	'sample.sample_received_date',
	'sample.report.verified.value',
	'sample.report.verified.metadata.date',
	'patient.first_name',
	'patient.last_name',
	'patient.date_of_birth',
	'sample.sample_specimen_type',
	'sample.results',
];

interface FormValues {
	'customer._id'?: string;
	'organization._id'?: string;
	'sample.ruo'?: string;
	'sample.assay'?: string;
	'sample.priority'?: string;
	'sample.pangea_id'?: string;
	'sample.specimen_id'?: string;
	'sample.collection_kit_id'?: string;
	'patient.last_name'?: string;
	'patient.first_name'?: string;
	'patient.date_of_birth'?: string;
	'sample.sample_specimen_type'?: string;
	'sample.sample_received_date'?: string | DateRange;
	'sample.sample_collection_date'?: string | DateRange;
	[key: string]: string | string[] | undefined | DateRange;
	'sample.report.verified.metadata.date'?: string | DateRange;
}

const initialFormValues = {
	'customer._id': '',
	'organization._id': '',
	'sample.ruo': '',
	'sample.assay': '',
	'sample.priority': '',
	'sample.pangea_id': '',
	'sample.specimen_id': '',
	'sample.collection_kit_id': '',
	'patient.last_name': '',
	'patient.first_name': '',
	'patient.date_of_birth': '',
	'sample.sample_specimen_type': '',
	'sample.sample_received_date': '',
	'sample.sample_collection_date': '',
	'sample.report.verified.metadata.date': '',
};

const initialDateRangesDisplayValues = {
	'sample.sample_received_date': false,
	'sample.sample_collection_date': false,
	'sample.report.verified.metadata.date': false,
};

const dateRangeProperties = [
	'sample.sample_received_date',
	'sample.sample_collection_date',
	'sample.report.verified.metadata.date',
];

const searchReportDefaultFilters = {
	'sample.status': ['verified', 'reject'],
	'sample.report.generated.value': true,
};

/* eslint-disable-next-line */
export interface SearchReportsProps {}

export default function SearchReports(props: SearchReportsProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const customers = useCustomers();
	const organizations = useOrganizations();
	const [disabled, setDisabled] = useState(false);
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
	const [dateRangesDisplay, setDateRangesDisplay] = useState<{
		[key: string]: boolean;
	}>(initialDateRangesDisplayValues);

	const [data, setData] = useState<ClinicData[] | undefined>([]);
	const [query, setQuery] = useState<FormValues | undefined>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !query) return;

			if (!unmounted) {
				setDisabled(true);
				setData(undefined);
			}

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/client-services/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query: { ...query, ...searchReportDefaultFilters },
						projected_fields: projectedFields,
					}
				);

				toast.dismiss();

				const parsedData = JSON.parse(data);
				console.log(parsedData, total_entries);

				if (!unmounted) {
					setData(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);
		};

		if (!unmounted) fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	const handleSubmit = async (event: MouseEvent) => {
		event.preventDefault();

		if (!TableSearchFunctions.hasAtLeastOneNonNullValue(formValues)) {
			toast.error('Provide a value for at least one filter criterion.');
			return;
		}

		const normalizedFormValues =
			TableSearchFunctions.normalizeFormValues(formValues);

		setQuery(normalizedFormValues);
		setPagination((prevValue) => ({
			...prevValue,
			page_number: 1,
		}));
	};

	const handleChange = (event: ChangeEvent) =>
		TableSearchFunctions.handleChange(
			event,
			setFormValues,
			dateRangeProperties,
			dateRangesDisplay
		);

	const handleChangeDateDisplay = (propName: string) =>
		TableSearchFunctions.handleChangeDateDisplay(
			propName,
			setDateRangesDisplay,
			setFormValues
		);

	const handleCustomerSelect = (customerId: string) => {
		if (customers) {
			const customer = customers.filter(
				(customer) => customer.value === customerId
			)[0];

			if (customer)
				setFormValues((prevValue) => ({
					...prevValue,
					'customer._id': customer.value,
				}));
		}
	};

	const handleOrganizationSelect = (organizationId: string) => {
		if (organizations) {
			const organization = organizations.filter(
				(organization) => organization.value === organizationId
			)[0];

			if (organization)
				setFormValues((prevValue) => ({
					...prevValue,
					'organization._id': organization.value,
				}));
		}
	};

	// Download results as Excel
	const [downloadDisabled, setDownloadDisabled] = useState(false);

	const handleDownload: MouseEventHandler = async (event) => {
		event.preventDefault();

		if (downloadDisabled || !axios) return;

		let query = {};

		if (TableSearchFunctions.hasAtLeastOneNonNullValue(formValues))
			query = TableSearchFunctions.normalizeFormValues(formValues);

		setDownloadDisabled(true);

		toast.loading('Downloading...', toastOptions);

		try {
			const { data } = await (
				await axios
			).post(
				'/api/client-services/search/data/download',
				{
					query: { ...query, ...searchReportDefaultFilters },
				},
				{
					responseType: 'arraybuffer',
				}
			);

			toast.dismiss();

			const blob = new Blob([data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});

			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`Specimen Reports List ${new Date().toLocaleString('en-US', {
					month: '2-digit',
					day: '2-digit',
					year: 'numeric',
					hour: '2-digit',
					minute: '2-digit',
					hour12: true,
				})}.xlsx`
			);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);

			setDownloadDisabled(false);
		} catch (error) {
			console.log(error);
			setDownloadDisabled(false);
		}
	};

	return (
		<Container>
			<ContentWrapper heading="Search Reports" Icon="MagnifyingGlassIcon">
				<SearchFiltersForm
					disabled={disabled}
					handleSubmit={handleSubmit}
					clearFilters={() => {
						setQuery(undefined);
						setFormValues(initialFormValues);
						setPagination(initialPaginationValues);
						setDateRangesDisplay(initialDateRangesDisplayValues);
					}}
				>
					<FormGroup>
						<div className="sm:col-span-2">
							<Combobox
								name="organization._id"
								label="Organization"
								disabled={!organizations}
								value={formValues['organization._id'] || ''}
								handleSelect={handleOrganizationSelect}
								options={organizations || SHARED.no_options}
							/>
						</div>
						<div className="sm:col-span-2">
							<Combobox
								name="customer._id"
								label="Customer"
								disabled={!customers}
								handleSelect={handleCustomerSelect}
								value={formValues['customer._id'] || ''}
								options={customers || SHARED.no_options}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Pangea ID"
								name="sample.pangea_id"
								placeholder="e.g., XZ12345678"
								handleInputChange={handleChange}
								value={formValues['sample.pangea_id'] || ''}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Specimen ID"
								name="sample.specimen_id"
								placeholder="e.g., XZ12345678"
								handleInputChange={handleChange}
								value={formValues['sample.specimen_id'] || ''}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Collection Kit ID"
								name="sample.collection_kit_id"
								placeholder="e.g., XZ12345678"
								handleInputChange={handleChange}
								value={
									formValues['sample.collection_kit_id'] || ''
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<SelectField
								label="Assay"
								name="sample.assay"
								handleSelect={handleChange}
								value={formValues['sample.assay'] || ''}
								options={[
									{
										value: '',
										label: 'Select an Option',
									},
									...ASSAYS['assays'],
								]}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Specimen Type"
								placeholder="e.g., Urine"
								name="sample.sample_specimen_type"
								handleInputChange={handleChange}
								value={
									formValues['sample.sample_specimen_type'] ||
									''
								}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-2">
							<SelectField
								label="Priority Level"
								name="sample.priority"
								handleSelect={handleChange}
								options={ORGANIZATIONS.priorityLevels}
								value={formValues['sample.priority'] || ''}
							/>
						</div>
						<div className="sm:col-span-2">
							<SelectField
								label="RUO"
								name="sample.ruo"
								handleSelect={handleChange}
								options={FORMS.yes_no_options}
								value={formValues['sample.ruo'] || ''}
							/>
						</div>
						<div className="sm:col-span-2"></div>
						<div className="sm:col-span-2">
							<DateField
								label="Collection Date"
								range={
									dateRangesDisplay[
										'sample.sample_collection_date'
									]
								}
								handleChangeDateDisplay={
									handleChangeDateDisplay
								}
								name="sample.sample_collection_date"
								handleInputChange={handleChange}
								value={
									formValues[
										'sample.sample_collection_date'
									] || ''
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<DateField
								label="Received Date"
								range={
									dateRangesDisplay[
										'sample.sample_received_date'
									]
								}
								handleChangeDateDisplay={
									handleChangeDateDisplay
								}
								name="sample.sample_received_date"
								handleInputChange={handleChange}
								value={
									formValues['sample.sample_received_date'] ||
									''
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<DateField
								label="Report date"
								range={
									dateRangesDisplay[
										'sample.report.verified.metadata.date'
									]
								}
								handleChangeDateDisplay={
									handleChangeDateDisplay
								}
								handleInputChange={handleChange}
								name="sample.report.verified.metadata.date"
								value={
									formValues[
										'sample.report.verified.metadata.date'
									] || ''
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								placeholder="e.g., John"
								label="Patient first name"
								handleInputChange={handleChange}
								name="patient.first_name"
								value={formValues['patient.first_name'] || ''}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								placeholder="e.g., Kim"
								label="Patient last name"
								handleInputChange={handleChange}
								name="patient.last_name"
								value={formValues['patient.last_name'] || ''}
							/>
						</div>
						<div className="sm:col-span-2">
							<DateField
								label="Patient date of birth"
								name="patient.date_of_birth"
								handleInputChange={handleChange}
								value={
									formValues['patient.date_of_birth'] || ''
								}
							/>
						</div>
					</FormGroup>

					<FormGroup
						heading="Assay-specific filters"
						description="Depending on the assay, different filters will populate"
					>
						<div className="sm:col-span-2">
							<SelectField
								label="Assay"
								name="sample.assay"
								handleSelect={handleChange}
								value={formValues['sample.assay'] || ''}
								options={[
									{
										value: '',
										label: 'Select an Option',
									},
									...ASSAYS['assays'],
								]}
							/>
						</div>
						<AssaySpecificFilters
							formValues={formValues}
							handleChange={handleChange}
							assay={formValues['sample.assay'] || ''}
						/>
					</FormGroup>
				</SearchFiltersForm>

				<ContentSectionDivider />

				<ContentSection heading="Available reports">
					<ViewReportsTable
						data={data}
						pagination={{ pagination, setPagination }}
					/>

					<FormFooter>
						<Button
							type="submit"
							tier="tertiary"
							text="Download"
							onClick={handleDownload}
							Icon="ArrowDownCircleIcon"
							disabled={
								downloadDisabled || (data && data.length === 0)
							}
						/>
					</FormFooter>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
