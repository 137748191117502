import {
	Fragment,
	Dispatch,
	useState,
	useEffect,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Modal,
	Button,
	FormGroup,
	FormFooter,
	LoadingBox,
	NumberField,
	DescriptionItem,
	DescriptionList,
} from '@pangea-lis-apps/ui';
import {
	ASSAYS,
	ClinicData,
	NonClinicData,
	formatDate,
	getLabelFromValue,
} from '@pangea-lis-apps/utils';

declare const window: any;

interface PrintBarcodeModalProps {
	handleAfterPrint?: () => void;
	data: ClinicData | NonClinicData | undefined;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

export default function PrintBarcodeModal(props: PrintBarcodeModalProps) {
	const { visible, setVisible } = props.visible;

	const [labelCopies, setLabelCopies] = useState<number>(
		props.data && props.data.sample.assay === 'bladdercare' ? 2 : 1
	);
	const [selected_device, setSelect_device] = useState<any>(null);

	const handleChange: ChangeEventHandler<
		HTMLSelectElement | HTMLInputElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLSelectElement | HTMLInputElement;
		setLabelCopies(parseInt(target.value));
	};

	useEffect(() => {
		const setup = () => {
			// if (process.env['NX_ENV'] === 'PRODUCTION')
			window.BrowserPrint.getDefaultDevice(
				'printer',
				function (device: any) {
					setSelect_device(device);
				},
				function (error: any) {
					alert(error);
				}
			);
		};

		setup();
	}, []);

	const writeToSelectedPrinter = (dataToWrite: string) => {
		console.log('Printing...', selected_device);

		if (selected_device !== null) {
			for (let i = 0; i < labelCopies; i++) {
				selected_device.send(
					dataToWrite,
					undefined,
					(errorMessage: string) => {
						alert('Error: ' + errorMessage);
					}
				);
			}

			setVisible(false);
		} else console.log('Printer not connected...');

		console.log('Print finished!');
	};

	const handlePrint = () => {
		if (!props.data) return;

		const barcode_size = 260; //260
		const info_size = 260; //240

		writeToSelectedPrinter(
			`^XA^FO${barcode_size},105
			^BY2,2,50
			^BCN,70,Y,N,N^FD${
				props.data.sample.pangea_id
			}^FS^FO${info_size},20^ADN,18,10^FD${props.data.sample.assay.toUpperCase()}^FS^FO${info_size},45^ADN,18,10^FD${
				props.data.patient
					? props.data.patient.first_name
						? props.data.patient.first_name
						: ''
					: ''
			} ${
				props.data.patient
					? props.data.patient.middle_name
						? props.data.patient.middle_name
						: ''
					: ''
			}  ${
				props.data.patient
					? props.data.patient.last_name
						? props.data.patient.last_name
						: ''
					: ''
			}^FS^FO${info_size},70^ADN,18,10^FD${
				props.data.patient
					? props.data.patient.date_of_birth
						? formatDate(props.data.patient.date_of_birth)
						: ''
					: ''
			}^FS^XZ`
		);

		if (props.handleAfterPrint) props.handleAfterPrint();
	};

	return (
		<Modal
			visible={visible}
			title="Print barcode"
			customWidth="max-w-md"
			onClose={() => setVisible(false)}
			description="Please select how many barcode copies to print."
		>
			{!props.data ? (
				<LoadingBox />
			) : (
				<Fragment>
					<DescriptionList className="mb-6">
						<DescriptionItem
							term="Pangea ID"
							details={props.data.sample.pangea_id}
						/>
						<DescriptionItem
							term="Assay"
							details={getLabelFromValue(
								props.data.sample.assay,
								ASSAYS['assays']
							)}
							customColSpan="sm:col-span-3"
						/>
					</DescriptionList>
					<Form>
						<FormGroup>
							<div className="sm:col-span-2">
								<NumberField
									min={1}
									max={5}
									name="Copies"
									value={labelCopies}
									label="Number of copies"
									handleInputChange={handleChange}
								/>
							</div>
						</FormGroup>
						<FormFooter>
							<Button
								text="Print"
								type="button"
								tier="tertiary"
								Icon="PrinterIcon"
								onClick={handlePrint}
							/>
						</FormFooter>
					</Form>
				</Fragment>
			)}
		</Modal>
	);
}
