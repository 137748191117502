import { Dispatch, SetStateAction, MutableRefObject } from 'react';

import {
	Alert,
	Modal,
	Button,
	FormFooter,
	DescriptionList,
	DescriptionItem,
} from '@pangea-lis-apps/ui';
import { ClinicData, NonClinicData } from '@pangea-lis-apps/utils';

interface IdOverwriteConfirmationModalProps {
	id: string;
	resetId?: () => void;
	handleUpdateSample: () => void;
	data: ClinicData | NonClinicData;
	idToOverwrite: 'pangea_id' | 'specimen_id';
	idOverwriteConfirmationRef: MutableRefObject<boolean>;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

export default function IdOverwriteConfirmationModal(
	props: IdOverwriteConfirmationModalProps
) {
	const { visible, setVisible } = props.visible;
	const label =
		props.idToOverwrite === 'pangea_id' ? 'Pangea ID' : 'specimen ID';

	return (
		<Modal
			visible={visible}
			customWidth="w-112"
			title={`Overwrite ${label}`}
			onClose={() => {
				props.idOverwriteConfirmationRef.current = false;
				if (props.resetId) props.resetId();
				setVisible(false);
			}}
		>
			<div className="space-y-6">
				<Alert
					type="warning"
					heading="Confirmation required"
					description={`A ${label} has already been assigned to this sample. If you do not wish to overwrite the ${label}, close this modal.`}
				/>
				<DescriptionList
					heading={`${label} info`}
					description="Review the following information carefully."
				>
					<DescriptionItem
						term="Current id"
						details={props.data.sample[props.idToOverwrite]}
					/>
					<DescriptionItem term="New id" details={props.id} />
				</DescriptionList>
			</div>

			<FormFooter>
				<Button
					type="button"
					tier="tertiary"
					Icon="CheckIcon"
					text="Overwrite"
					onClick={() => {
						props.idOverwriteConfirmationRef.current = true;
						props.handleUpdateSample();
					}}
				/>
			</FormFooter>
		</Modal>
	);
}
