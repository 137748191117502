import { Routes, Route } from 'react-router';

import { FORM_STEPS } from './steps';
import ProcessTRFComponents from '../../components/index';

import ProviderOrganizationInfo from './provider-organization-info';
import OrderSampleInfo from './order-sample-info';
import PatientInfo from './patient-info';
import BillingInfo from './billing-info';
import AuthorizationAndAttachments from './authorization-attachments';
import Review from './review';

export default function V1_0_ROUTES() {
	return (
		<Routes>
			<Route
				path="/data/:dataId/trf"
				element={
					<ProcessTRFComponents.FormWrapper
						assay="sti"
						version="1.0"
						steps={FORM_STEPS}
					/>
				}
			>
				<Route
					path="provider-organization-info"
					element={<ProviderOrganizationInfo />}
				/>
				<Route path="order-sample-info" element={<OrderSampleInfo />} />
				<Route path="patient-info" element={<PatientInfo />} />
				<Route path="billing-info" element={<BillingInfo />} />
				<Route
					path="authorization-attachments"
					element={<AuthorizationAndAttachments />}
				/>
				<Route path="review" element={<Review />} />
			</Route>
		</Routes>
	);
}
