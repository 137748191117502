/* eslint-disable @typescript-eslint/no-empty-function */
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	useState,
	Fragment,
	Dispatch,
	useEffect,
	FormEvent,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Alert,
	Modal,
	Button,
	useAxios,
	Container,
	LoadingBox,
	FormFooter,
	TextButton,
	TextareaField,
	EditDataModal,
	ContentSection,
	ContentWrapper,
	DescriptionList,
	DescriptionItem,
	OrderItemsSummary,
	EditOrderItemsModal,
	ContentSectionDivider,
	EditOrderShippingAddressModal,
} from '@pangea-lis-apps/ui';
import {
	Option,
	Attribute,
	formatDate,
	capitalize,
	HydratedOrder,
	getLabelFromValue,
	initialAttributeValue,
	SHIPPING,
} from '@pangea-lis-apps/utils';

import Notes from '../../../components/notes';

/* eslint-disable-next-line */
export interface ViewOrderProps {}

export default function ViewOrder(props: ViewOrderProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { orderId } = useParams();

	const [refresh, setRefresh] = useState(false);
	const [attribute, setAttribute] = useState<Attribute>(
		initialAttributeValue
	);
	const [modalVisible, setModalVisible] = useState(false);
	const [order, setOrder] = useState<
		(HydratedOrder & { editable: boolean }) | undefined
	>(undefined);
	const [confirmShippingModalVisible, setConfirmShippingModalVisible] =
		useState(false);
	const [orderItemsModalVisible, setOrderItemsModalVisible] = useState(false);
	const [archiveOrderModalVisible, setArchiveOrderModalVisible] =
		useState(false);
	const [editOrderShippingAddressModal, setEditOrderShippingAddressModal] =
		useState(false);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: { data },
				} = await (await axios).get(`/api/shipping/orders/${orderId}`);

				if (!unmounted) {
					const order = JSON.parse(data);
					console.log(order);

					setOrder(() => ({
						...order,
						editable: order.status !== 'archived',
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, orderId, refresh]);

	const handleClick = (
		property: string,
		value: string,
		label: string,
		type: 'text' | 'select' | 'date' | 'email' | 'textarea',
		selectOptions?: Option[],
		selectLabel?: string
	) => {
		setAttribute({
			property,
			value,
			type,
			label,
			selectOptions,
			selectLabel,
		});
		setModalVisible(true);
	};

	return (
		<Fragment>
			{order && (
				<Fragment>
					<EditDataModal
						data={order}
						attribute={attribute}
						setRefresh={setRefresh}
						heading="Edit order details"
						visible={{
							visible: modalVisible,
							setVisible: setModalVisible,
						}}
						endpoint={`/api/shipping/orders/${order?._id.$oid}`}
					/>
					<EditOrderShippingAddressModal
						order={order}
						setRefresh={setRefresh}
						// eslint-disable-next-line jsx-a11y/aria-role
						role="shipping"
						visible={{
							visible: editOrderShippingAddressModal,
							setVisible: setEditOrderShippingAddressModal,
						}}
					/>
					<EditOrderItemsModal
						order={order}
						setRefresh={setRefresh}
						// eslint-disable-next-line jsx-a11y/aria-role
						role="shipping"
						visible={{
							visible: orderItemsModalVisible,
							setVisible: setOrderItemsModalVisible,
						}}
					/>
					<ConfirmShippingModal
						order={order}
						setRefresh={setRefresh}
						visible={{
							visible: confirmShippingModalVisible,
							setVisible: setConfirmShippingModalVisible,
						}}
					/>
					<ArchiveOrderModal
						order={order}
						setRefresh={setRefresh}
						visible={{
							visible: archiveOrderModalVisible,
							setVisible: setArchiveOrderModalVisible,
						}}
					/>
				</Fragment>
			)}
			<Container>
				<ContentWrapper
					Icon="TruckIcon"
					heading="Order details"
					description="Edit the tracking information once available. Update any incorrect shipping information."
				>
					{!order ? (
						<LoadingBox />
					) : (
						<Fragment>
							<ContentSection heading="General info">
								<DescriptionList>
									<DescriptionItem
										term="Order #"
										details={order.id.toUpperCase()}
									/>
									<DescriptionItem
										term="Shipping status"
										details={capitalize(
											order.status.replace(/_/g, ' ')
										)}
									/>
									{order.status === 'archived' ? (
										<DescriptionItem
											term="Archived reason"
											details={order.archived.reason}
											withFunction={order.editable}
											Icon="PencilIcon"
											handleClick={() =>
												handleClick(
													'archived.reason',
													order.archived.reason,
													'Status - Archived reason',
													'textarea'
												)
											}
										/>
									) : (
										<div className="sm:col-span-1"></div>
									)}
									<DescriptionItem
										Icon="PencilIcon"
										term="Shipping address"
										withFunction={order.editable}
										handleClick={() =>
											setEditOrderShippingAddressModal(
												true
											)
										}
									>
										{order.shipping.address.address} <br />
										{order.shipping.address.city},{' '}
										{order.shipping.address.state},{' '}
										{order.shipping.address.zip_code}
									</DescriptionItem>
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection
								heading="Tracking info"
								headingAction={
									order.status !== 'shipped' &&
									order.shipping.tracking_number &&
									order.shipping.carrier &&
									order.shipping.shipped.date && (
										<TextButton
											color="blue"
											type="button"
											text="Mark order as shipped"
											className="group-hover:text-gray-700"
											onClick={() =>
												setConfirmShippingModalVisible(
													true
												)
											}
										/>
									)
								}
							>
								<DescriptionList>
									<DescriptionItem
										withFunction={order.editable}
										term="Tracking #"
										details={order.shipping.tracking_number}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'shipping.tracking_number',
												order.shipping.tracking_number,
												'Tracking Info - Tracking #',
												'text'
											)
										}
									/>
									<DescriptionItem
										term="Carrier"
										details={getLabelFromValue(
											order.shipping.carrier,
											SHIPPING.carriers
										)}
										withFunction={order.editable}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'shipping.carrier',
												order.shipping.carrier || '',
												'Tracking Info - Carrier',
												'select',
												SHIPPING.carriers,
												order.shipping.carrier || ''
											)
										}
									/>
									<div className="sm:col-span-1"></div>
									<DescriptionItem
										term="Date shipped"
										details={
											order.shipping.shipped.date &&
											formatDate(
												order.shipping.shipped.date
											)
										}
										withFunction={order.editable}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'shipping.shipped.date',
												order.shipping.shipped.date
													? order.shipping.shipped
															.date
													: '',
												'Tracking Info - Ship date',
												'date'
											)
										}
									/>
									<DescriptionItem
										term="Shipped by"
										details={
											order.shipping.shipped.by &&
											order.shipping.shipped.by.first_name.concat(
												' ',
												order.shipping.shipped.by
													.last_name
											)
										}
									/>
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection heading="Provider Info">
								<DescriptionList>
									<DescriptionItem
										term="First Name"
										details={order.customer.first_name}
									/>
									<DescriptionItem
										term="Last Name"
										details={order.customer.last_name}
									/>
									<DescriptionItem
										term="National Provider Identification (NPI)"
										details={
											order.customer
												.national_provider_identifier
										}
									/>
									<DescriptionItem
										term="Phone Number"
										details={order.customer.phone_number}
									/>
									<DescriptionItem
										term="Email Address"
										details={order.customer.email_address}
									/>
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection heading="Order info">
								<DescriptionList>
									<DescriptionItem
										term="Items"
										customColSpan="sm:col-span-3"
										withFunction={order.editable}
										Icon="PencilIcon"
										handleClick={() =>
											setOrderItemsModalVisible(true)
										}
									>
										{order.items && order.items.length && (
											<OrderItemsSummary
												// eslint-disable-next-line jsx-a11y/aria-role
												role="associate"
												minified={false}
												items={order.items}
											/>
										)}
									</DescriptionItem>
								</DescriptionList>
							</ContentSection>

							<ContentSection heading="Miscellaneous">
								<Notes
									id={orderId}
									data={order.notes}
									collection="orders"
									setRefresh={setRefresh}
								/>
							</ContentSection>

							{order.editable && (
								<FormFooter>
									<Button
										type="button"
										text="Archive"
										tier="tertiary"
										Icon="ArchiveBoxIcon"
										onClick={() =>
											setArchiveOrderModalVisible(true)
										}
									/>
								</FormFooter>
							)}
						</Fragment>
					)}
				</ContentWrapper>
			</Container>
		</Fragment>
	);
}

interface ArchiveOrderModalProps {
	order: HydratedOrder;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

const ArchiveOrderModal = (props: ArchiveOrderModalProps) => {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const { visible, setVisible } = props.visible;

	const [reason, setReason] = useState('');
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios) return;

		setDisabled(true);

		toast.loading('Archiving order...', toastOptions);

		try {
			await (
				await axios
			).post(`/api/shipping/archive/orders/${props.order._id.$oid}`, {
				user,
				archive_reason: reason,
			});

			toast.dismiss();

			setVisible(false);
			setDisabled(false);
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLTextAreaElement;

		if (target && target.name) setReason(target.value);
	};

	return (
		<Modal
			visible={visible}
			title="Archive order"
			customWidth="max-w-md"
			onClose={() => setVisible(false)}
			description={
				<span>
					This action is irreversible. Please provide a reason for
					archiving the order.
				</span>
			}
		>
			{!props.order ? (
				<LoadingBox />
			) : (
				<Form handleSubmit={handleSubmit}>
					<TextareaField
						required
						name="reason"
						value={reason}
						handleInputChange={handleChange}
						label="Reason for archiving order"
						placeholder="e.g., Order made by mistake."
					/>
					<FormFooter>
						<Button
							type="button"
							text="Archive"
							tier="tertiary"
							Icon="ArchiveBoxIcon"
							disabled={disabled}
							onClick={handleSubmit}
						/>
					</FormFooter>
				</Form>
			)}
		</Modal>
	);
};

interface ConfirmShippingModalProps {
	order: HydratedOrder;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

const ConfirmShippingModal = (props: ConfirmShippingModalProps) => {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const { visible, setVisible } = props.visible;

	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios) return;

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(
				`/api/shipping/orders/${props.order._id.$oid}/mark-as-shipped`,
				{
					user,
				}
			);

			toast.dismiss();

			setVisible(false);
			setDisabled(false);
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	return (
		<Modal
			visible={visible}
			customWidth="max-w-md"
			title="Mark order as shipped"
			onClose={() => setVisible(false)}
		>
			{!props.order ? (
				<LoadingBox />
			) : (
				<div className="space-y-6">
					<Alert
						type="info"
						heading="Confirm tracking information"
						description={
							<span>
								Make sure the tracking information is correct.
								Update any incorrect information if necessary.
							</span>
						}
					/>
					<DescriptionList>
						<DescriptionItem
							term="Tracking #"
							details={props.order.shipping.tracking_number}
						/>
						<DescriptionItem
							term="Carrier"
							details={getLabelFromValue(
								props.order.shipping.carrier,
								SHIPPING.carriers
							)}
						/>
						<DescriptionItem
							term="Date shipped"
							details={
								props.order.shipping.shipped.date &&
								formatDate(props.order.shipping.shipped.date)
							}
						/>
					</DescriptionList>
					<FormFooter>
						<Button
							type="button"
							tier="tertiary"
							Icon="CheckIcon"
							disabled={disabled}
							onClick={handleSubmit}
							text="Mark order as shipped"
						/>
					</FormFooter>
				</div>
			)}
		</Modal>
	);
};
