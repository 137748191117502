import { Option } from '@pangea-lis-apps/utils';
import { Dispatch, SetStateAction } from 'react';

/**
 * Handles the select logic for multiple comboboxes.
 *
 * @param {Option[]} selectedOptions: The updated list of selected options.
 */
export const handleMultipleComboboxSelect = (
	selectedOptions: Option[],
	property: string,
	setFormValues: any,
	setOptions: Dispatch<SetStateAction<Option[]>>,
	setShowOtherModal?: Dispatch<SetStateAction<boolean>>
) => {
	setFormValues((prevValues: any) => {
		if (prevValues) {
			let newArray = selectedOptions;
			const allDisablingElementExists = selectedOptions.some(
				(el) => el.disables_all
			);

			// If an all-disabling element exists in the array
			if (allDisablingElementExists) {
				// Remove all the other elements
				let disablingElementValue: string;

				newArray = newArray.filter((el) => {
					disablingElementValue = el.value;

					return el.disables_all;
				});

				// Disable all non-all-disabling elements
				setOptions((prevValue) =>
					prevValue.map((val) => {
						if (val.value === disablingElementValue) return val;

						return {
							...val,
							disabled: true,
						};
					})
				);
			} else {
				// Get the new element
				const newElement = selectedOptions.slice(-1)[0];

				if (
					newElement &&
					newElement.value === 'other' &&
					setShowOtherModal
				) {
					setShowOtherModal(true);
					return prevValues;
				}

				// Check if the element exists in the running list
				const exists =
					newElement &&
					prevValues[property].find(
						(element: Option) => element.value === newElement.value
					);

				// If element exists, then remove, otherwise, add
				newArray = exists
					? prevValues[property].filter(
							(element: Option) =>
								element.value !== newElement.value
					  )
					: selectedOptions.map((option) => option);

				// If an all-disabling element doesn't exist in the array, then enable all the non-all-disabling elements in the array
				setOptions((prevValue) =>
					prevValue.map((val) => {
						if (val.value === '') return val;

						return {
							...val,
							disabled: false,
						};
					})
				);
			}

			return {
				...prevValues,
				[property]: newArray,
			};
		}

		return prevValues;
	});
};

/**
 * Finds the non-intersecting elements from two arrays of Option objects.
 *
 * @param {Option[]} array1
 * @param {Option[]} array2
 * @returns The non-intersecting elements from two arrays.
 */
export const findNonIntersectingElements = (
	array1: Option[],
	array2: Option[]
): Option[] => {
	return array1.filter(
		(item1) => !array2.some((item2) => item1.value === item2.value)
	);
};

/**
 * Remove duplicates from an array of Option objects.
 *
 * @param {Option[]} array: An array of Option objects.
 * @returns A unique array of Option objects.
 */
export const removeDuplicatesFromOptionsArray = (array: Option[]): Option[] => {
	const uniqueArray: Option[] = [];
	const valuesSet = new Set();

	array.forEach((item) => {
		if (!valuesSet.has(item.value)) {
			valuesSet.add(item.value);
			uniqueArray.push(item);
		}
	});

	return uniqueArray;
};
