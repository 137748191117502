/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import {
	useId,
	useRef,
	useContext,
	ChangeEvent,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Button,
	useAxios,
	DateField,
	FormGroup,
	FileField,
	TextButton,
	LoadingBox,
	SelectField,
	ContentSection,
} from '@pangea-lis-apps/ui';
import { FileAttachment, FORMS } from '@pangea-lis-apps/utils';

import ProcessTRFComponents from '../../components/index';
import { usePopulateFormValues } from '../../utils/helpers';
import { ProcessTRFStepsContext } from '../../components/form-wrapper';

function base64ToFile(base64String: string, filename: string) {
	// Decode the Base64 string
	const byteString = atob(base64String.split(',')[1]);

	// Create an ArrayBuffer and a typed array view (Uint8Array)
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	// Create a Blob from the ArrayBuffer
	const blob = new Blob([ia], { type: 'application/octet-stream' });

	// Convert the Blob into a File object
	const file = new File([blob], filename, {
		type: 'application/octet-stream',
	});

	return file;
}

interface FormValues {
	patient_authorization_date: string;
	patient_authorization_signature_provided: string;
	attachments: { file: File; key: string | number }[];
	flag: {
		attachments: boolean;
		patient_authorization_date: boolean;
		patient_authorization_signature_provided: boolean;
	};
}

const initialFormValues = {
	attachments: [],
	patient_authorization_date: '',
	patient_authorization_signature_provided: '',
	flag: {
		attachments: false,
		patient_authorization_date: false,
		patient_authorization_signature_provided: false,
	},
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProcessRequisitionFormProps {}

export default function ProcessRequisitionForm(
	props: ProcessRequisitionFormProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const {
		data,
		formId,
		setRefresh,
		formValues,
		handleSubmit,
		formValuesRef,
		setFormValues,
		handleDownloadAttachment,
	} = useContext(ProcessTRFStepsContext);

	const fileInputRef = useRef<HTMLInputElement>(null);

	usePopulateFormValues(
		data,
		formValuesRef,
		setFormValues,
		initialFormValues,
		'requisition_form'
	);

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
	> = (event: ChangeEvent) => {
		const target = event.target as
			| HTMLInputElement
			| HTMLSelectElement
			| HTMLTextAreaElement;

		if (target && target.name)
			setFormValues((prevValues: FormValues) => {
				if (prevValues)
					return {
						...prevValues,
						[target.name]: target.value,
					};

				return prevValues;
			});
	};

	const handleFileChange = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement;

		if (target && target.files && formValues) {
			const uploadedFiles = target.files;

			if (uploadedFiles.length === 0) {
				setFormValues((prevValues: FormValues) => {
					if (prevValues) {
						return {
							...prevValues,
							attachments: [],
						};
					}

					return prevValues;
				});

				return;
			}

			for (let i = 0; i < uploadedFiles.length; i++) {
				const file = uploadedFiles[i];

				if (file) {
					const reader = new FileReader();
					reader.onloadend = () => {
						const base64String = reader.result as string;

						if (base64String) {
							setFormValues((prevValues: FormValues) => {
								if (prevValues) {
									return {
										...prevValues,
										attachments: [
											...prevValues['attachments'],
											{
												file: base64String,
												key: Date.now() + i,
												file_name: file.name,
											},
										],
									};
								}

								return prevValues;
							});
						}
					};

					reader.readAsDataURL(file);
				}
			}
		}
	};

	const handleRemoveFile = (file_key: string | number) => {
		// Update state
		if (formValues && formValues['attachments']) {
			const updatedFiles = [...formValues['attachments']];
			const newFiles = updatedFiles.filter(
				(file) => file.key !== file_key
			);

			setFormValues((prevValues: FormValues) => {
				if (prevValues) {
					return {
						...prevValues,
						attachments: newFiles,
					};
				}

				return prevValues;
			});

			// Update fileInput
			if (fileInputRef && fileInputRef.current) {
				// Reconstruct the input value based on the remaining files
				const remainingFiles = newFiles.map((fileItem) => ({
					base64String: fileItem.file,
					fileName: fileItem.file_name,
				}));

				const dataTransfer = new DataTransfer();
				remainingFiles.forEach((file) => {
					const parsedFile = base64ToFile(
						file.base64String,
						file.fileName
					);

					dataTransfer.items.add(parsedFile);
				});
				fileInputRef.current.files = dataTransfer.files;
			}
		}
	};

	// Work on removing files.
	const handleRemoveExistingFile = async (aws_object_key: string) => {
		if (!axios || !data) return;

		toast.loading('Removing...', toastOptions);

		try {
			await (
				await axios
			).post(
				`/api/accessioner/data/${
					data._id.$oid
				}/delete-trf-attachment/?aws_object_key=${encodeURIComponent(
					aws_object_key
				)}`,
				null
			);

			setRefresh((value) => !value);
			toast.dismiss();
		} catch (error) {
			console.log(error);
		}
	};

	return !formValues || !data ? (
		<LoadingBox />
	) : (
		<ContentSection>
			<Form id={formId} handleSubmit={handleSubmit}>
				<FormGroup heading="Patient Authorization, Assignment of Benefits (AOB), and Financial Responsibilities">
					<div className="sm:col-span-2">
						<SelectField
							required
							name="patient_authorization_signature_provided"
							label="Patient Signature Provided"
							value={
								formValues.patient_authorization_signature_provided
							}
							handleSelect={handleChange}
							options={FORMS.yes_no_options}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_authorization_signature_provided"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<DateField
							label="Date Provided"
							showRequiredAsterisk={true}
							handleInputChange={handleChange}
							name="patient_authorization_date"
							value={formValues.patient_authorization_date}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="patient_authorization_date"
								/>
							}
						/>
					</div>
				</FormGroup>
				<FormGroup
					heading="Attachments"
					description={
						<span>
							To upload multiple files, press{' '}
							<span className="italic">Ctrl + Click</span>.
						</span>
					}
				>
					<div className="sm:col-span-2">
						<FileField
							multiple={true}
							ref={fileInputRef}
							name="attachments"
							label="Attachments"
							showRequiredAsterisk={true}
							handleInputChange={handleFileChange}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									property="attachments"
									formValues={formValues}
									setFormValues={setFormValues}
								/>
							}
						/>
					</div>

					{data.requisition_form.attachments && (
						<div className="sm:col-span-6 space-y-4">
							{data.requisition_form.attachments.map(
								(
									attachment: {
										aws_object_key: string;
										file_name: string;
									},
									index: number
								) => {
									return (
										<div
											key={index}
											className="flex items-center space-x-4"
										>
											<Button
												type="button"
												tier="tertiary"
												text="Download"
												Icon="ArrowDownTrayIcon"
												onClick={() =>
													handleDownloadAttachment(
														attachment.aws_object_key
													)
												}
											/>
											<p className="text-sm">
												{attachment.file_name}
											</p>
											<TextButton
												color="gray"
												type="button"
												text="Remove"
												onClick={() =>
													handleRemoveExistingFile(
														attachment.aws_object_key
													)
												}
											/>
										</div>
									);
								}
							)}
						</div>
					)}

					{formValues['attachments'] &&
						formValues['attachments'].length > 0 && (
							<div className="sm:col-span-6 space-y-10">
								{formValues['attachments'].map(
									(file: FileAttachment) => (
										<div
											key={file.key}
											className="space-y-2"
										>
											<div className="flex items-center justify-between">
												<p>
													File name: {file.file_name}
												</p>
												<TextButton
													color="gray"
													type="button"
													text="Remove"
													className="group-hover:text-gray-700"
													onClick={() =>
														handleRemoveFile(
															file.key
														)
													}
												/>
											</div>
											<object
												width="500"
												height="700"
												type="application/pdf"
												data={file.file}
											>
												<p>
													Unable to display preview.
												</p>
											</object>
										</div>
									)
								)}
							</div>
						)}
				</FormGroup>
			</Form>
		</ContentSection>
	);
}
