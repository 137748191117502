import { Routes, Route } from 'react-router-dom';

import V4_1_ROUTES from './v4-1/routes';

export default function BladderCARETRFFlowRoutes() {
	return (
		<Routes>
			<Route path="/v4.1/*" element={<V4_1_ROUTES />} />
		</Routes>
	);
}
