import { useId, useEffect, useState } from 'react';

import {
	useAxios,
	Container,
	ContentWrapper,
	ContentSection,
} from '@pangea-lis-apps/ui';
import {
	Query,
	ClinicData,
	Pagination,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import AllRequisitionFormsTable from '../../../components/tables/all-requisition-forms';

const projectedFields = [
	'requisition_form.requisition_form_received_date',
	'requisition_form.sample_collection_date',
	'sample.pangea_id',
	'_id',
	'sample.specimen_id',
	'customer.first_name',
	'customer.last_name',
	'organization.name',
	'requisition_form.patient_first_name',
	'requisition_form.patient_last_name',
	'requisition_form.patient_date_of_birth',
	'metadata.created_by.first_name',
	'metadata.created_by.last_name',
];

/* eslint-disable-next-line */
export interface ViewRequisitionFormsProps {}

export default function ViewRequisitionForms(props: ViewRequisitionFormsProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [query, setQuery] = useState<Query>({});
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);
	const [forms, setForms] = useState<ClinicData[] | undefined>(undefined);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			console.log(query);

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/accessioner/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query: {
							...query,
							type: 'clinic',
							'metadata.accessioning.completed.value': true,
						},
						projected_fields: projectedFields,
					}
				);

				if (!unmounted) {
					const forms = JSON.parse(data);
					console.log(forms);

					setForms(forms);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	return (
		<Container>
			<ContentWrapper
				Icon="DocumentTextIcon"
				heading="View Requisition Forms"
				description="All requisition forms that have completed accessioning."
			>
				<ContentSection id="form" heading="All forms">
					<AllRequisitionFormsTable
						data={forms}
						query={{ query, setQuery }}
						pagination={{
							pagination,
							setPagination,
						}}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
