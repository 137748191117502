import { useAuth0 } from '@auth0/auth0-react';
import { useId, useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import {
	Button,
	useAxios,
	Container,
	LoadingBox,
	TextButton,
	FormFooter,
	SpecimenInfo,
	DataComponents,
	ContentSection,
	ContentWrapper,
	DescriptionList,
	DescriptionItem,
	AccessioningStatus,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ActionLog,
	ClinicData,
	Pagination,
	formatDate,
	NonClinicData,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import Notes from '../../../components/notes';
import ActionLogTable from '../../../components/tables/action-logs-table';
import RejectSampleModal from '../../../components/modals/reject-sample-modal';

/* eslint-disable-next-line */
export interface ViewSampleProps {}

export default function ViewSample(props: ViewSampleProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();
	const isAccessioningAdmin =
		user && user['custom_claims/roles'].includes('BITSS_accessioner-admin');

	const { dataId } = useParams();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const [refresh, setRefresh] = useState(false);
	const [modalVisible, setModalVisible] = useState<{
		[key: string]: boolean;
	}>({
		reject: false,
	});
	const [data, setData] = useState<ClinicData | NonClinicData | undefined>(
		undefined
	);

	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);
	const [sampleHistory, setSampleHistory] = useState<ActionLog[] | undefined>(
		undefined
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: { data: data1 },
				} = await (await axios).get(`/api/accessioner/data/${dataId}`);

				if (!unmounted) {
					const parsedData = JSON.parse(data1);
					console.log(parsedData);

					setData(parsedData);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, dataId, refresh]);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			setSampleHistory(undefined);

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).get(
					`/api/accessioner/data/${dataId}/action-history?type=sample&page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setSampleHistory(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		axios,
		dataId,
		refresh,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	return (
		<Fragment>
			<RejectSampleModal
				data={data}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<Container>
				<ContentWrapper Icon="FingerPrintIcon" heading="Sample Details">
					{!data ? (
						<LoadingBox />
					) : (
						<Fragment>
							{data.accessioning_session && (
								<Fragment>
									<ContentSection heading="Accessioning session">
										<DescriptionList>
											<DescriptionItem term="Name">
												<TextButton
													color="blue"
													type="button"
													onClick={() =>
														navigate(
															`/accessioner/accession/sessions/${data.accessioning_session._id.$oid}`
														)
													}
													text={
														data
															.accessioning_session
															.name
													}
												/>
											</DescriptionItem>
											<DescriptionItem
												term="Completed?"
												details={
													data.accessioning_session
														.completed.value
														? 'Yes'
														: 'No'
												}
											/>
										</DescriptionList>
									</ContentSection>

									<ContentSectionDivider />
								</Fragment>
							)}

							<AccessioningStatus data={data} />

							<ContentSectionDivider />

							<SpecimenInfo
								data={data}
								headingAction={
									<TextButton
										type="button"
										color="blue"
										text="Update"
										onClick={() =>
											navigate(
												data.type === 'non_clinic'
													? `/accessioner/accession/sample/${data.sample.assay}/data/${data._id.$oid}/non-clinic-sample/?redirectPath=${pathname}`
													: `/accessioner/accession/sample/${data.sample.assay}/data/${data._id.$oid}/sample/update/?redirectPath=${pathname}`
											)
										}
									/>
								}
							/>

							<ContentSectionDivider />

							{data.patient && (
								<Fragment>
									<DataComponents.PatientInformation
										data={data}
									/>

									<ContentSectionDivider />
								</Fragment>
							)}

							<DataComponents.ProviderInformation data={data} />

							<ContentSectionDivider />

							<DataComponents.OrganizationInformation
								data={data}
							/>

							<ContentSectionDivider />

							<ContentSection heading="Requisition form Info">
								<DescriptionList>
									<DescriptionItem term="Requisition Form">
										<TextButton
											text="View"
											color="blue"
											type="button"
											onClick={() => {
												window.open(
													`accessioner/view/trfs/${data._id.$oid}`,
													'_blank'
												);
											}}
										/>
									</DescriptionItem>
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection heading="Report Details">
								<DescriptionList>
									<DescriptionItem
										term="Verified?"
										details={
											data.sample.report.verified.value
												? 'Yes'
												: 'No'
										}
									/>
									<DescriptionItem
										term="Verification Date"
										details={formatDate(
											data.sample.report.verified.metadata
												.date &&
												data.sample.report.verified
													.metadata.date.$date
										)}
									/>
									<DescriptionItem
										term="Verifier"
										details={
											data.sample.report.verified.metadata
												.by &&
											data.sample.report.verified.metadata.by.first_name.concat(
												' ',
												data.sample.report.verified
													.metadata.by.last_name
											)
										}
									/>
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							{sampleHistory && (
								<Fragment>
									<ContentSection
										heading="Sample history"
										description="These are actions performed on the sample as it's being processed through the facility."
									>
										<ActionLogTable
											type="sample"
											pagination={{
												pagination,
												setPagination,
											}}
											data={sampleHistory}
										/>
									</ContentSection>

									<ContentSectionDivider />
								</Fragment>
							)}

							<ContentSection heading="Miscellaneous">
								<DescriptionList>
									<DescriptionItem
										term="Notes"
										customColSpan="sm:col-span-3"
									>
										<Notes
											id={dataId}
											collection="data"
											setRefresh={setRefresh}
											data={data.notes}
											receiveDepartment="accessioning"
										/>
									</DescriptionItem>
								</DescriptionList>
							</ContentSection>

							{![
								'reject',
								'pending_verification',
								'verified',
							].includes(data.sample.status) &&
								isAccessioningAdmin && (
									<FormFooter>
										<Button
											type="button"
											text="Reject"
											tier="tertiary"
											Icon="XCircleIcon"
											onClick={() =>
												setModalVisible(
													(prevValue) => ({
														...prevValue,
														reject: true,
													})
												)
											}
										/>
									</FormFooter>
								)}
						</Fragment>
					)}
				</ContentWrapper>
			</Container>
		</Fragment>
	);
}
