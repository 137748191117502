/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Buffer } from 'buffer';
import toast from 'react-hot-toast';
import {
	useId,
	useRef,
	useState,
	useContext,
	ChangeEvent,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Button,
	useAxios,
	FormGroup,
	FileField,
	TextButton,
	LoadingBox,
	SelectField,
	GenericField,
	ContentSection,
	MultipleCombobox,
} from '@pangea-lis-apps/ui';
import { Option, BLADDERCARE, FileAttachment } from '@pangea-lis-apps/utils';

import ProcessTRFComponents from '../../components/index';
import { usePopulateFormValues } from '../../utils/helpers';
import { ProcessTRFStepsContext } from '../../components/form-wrapper';
import { handleMultipleComboboxSelect } from '../../../../../../../../../utils/helpers/forms';

function base64ToFile(base64String: string, filename: string) {
	// Decode the Base64 string
	const byteString = atob(base64String.split(',')[1]);

	// Create an ArrayBuffer and a typed array view (Uint8Array)
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	// Create a Blob from the ArrayBuffer
	const blob = new Blob([ia], { type: 'application/octet-stream' });

	// Convert the Blob into a File object
	const file = new File([blob], filename, {
		type: 'application/octet-stream',
	});

	return file;
}

interface FormValues {
	collection_kit_shipping_option: string;
	physician_report_delivery_methods: Option[];
	physician_report_delivery_contact_fax: string;
	physician_report_delivery_contact_mail: string;
	physician_report_delivery_contact_email: string;
	attachments: FileAttachment[];
	flag: {
		attachments: boolean;
		collection_kit_shipping_options: boolean;
		physician_report_delivery_methods: boolean;
		physician_report_delivery_contact_fax: boolean;
		physician_report_delivery_contact_mail: boolean;
		physician_report_delivery_contact_email: boolean;
	};
}

const initialFormValues = {
	attachments: [],
	collection_kit_shipping_option: '',
	physician_report_delivery_methods: [],
	physician_report_delivery_contact_fax: '',
	physician_report_delivery_contact_mail: '',
	physician_report_delivery_contact_email: '',
	flag: {
		attachments: false,
		collection_kit_shipping_options: false,
		physician_report_delivery_methods: false,
		physician_report_delivery_contact_fax: false,
		physician_report_delivery_contact_mail: false,
		physician_report_delivery_contact_email: false,
	},
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProcessRequisitionFormProps {}

export default function ProcessRequisitionForm(
	props: ProcessRequisitionFormProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const {
		data,
		formId,
		setRefresh,
		formValues,
		handleSubmit,
		setFormValues,
		formValuesRef,
		handleDownloadAttachment,
	} = useContext(ProcessTRFStepsContext);

	const fileInputRef = useRef<HTMLInputElement>(null);
	const [
		physicianReportDeliveryMethodOptions,
		setPhysicianReportDeliveryMethodOptions,
	] = useState(BLADDERCARE['4.1'].physician_report_delivery_method_options);

	usePopulateFormValues(
		data,
		formValuesRef,
		setFormValues,
		initialFormValues,
		'requisition_form'
	);

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setFormValues((prevValues: FormValues) => {
				if (prevValues) {
					return {
						...prevValues,
						[target.name]: target.value,
					};
				}

				return prevValues;
			});
		}
	};

	const handleFileChange = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement;

		if (target && target.files && formValues) {
			const uploadedFiles = target.files;

			if (uploadedFiles.length === 0) {
				setFormValues((prevValues: FormValues) => {
					if (prevValues) {
						return {
							...prevValues,
							attachments: [],
						};
					}

					return prevValues;
				});
			} else {
				for (let i = 0; i < uploadedFiles.length; i++) {
					const file = uploadedFiles[i];

					if (file) {
						const reader = new FileReader();
						reader.readAsDataURL(file);
						reader.onloadend = () => {
							const base64String = reader.result as string;

							if (base64String) {
								setFormValues((prevValues: FormValues) => {
									if (prevValues) {
										return {
											...prevValues,
											attachments: [
												...prevValues['attachments'],
												{
													file: base64String,
													key: Date.now() + i,
													file_name: file.name,
												},
											],
										};
									}

									return prevValues;
								});
							}
						};
					}
				}
			}
		}
	};

	const handleRemoveFile = (file_key: string | number) => {
		// Update state
		if (formValues && formValues['attachments']) {
			const updatedFiles = [...formValues['attachments']];
			const newFiles = updatedFiles.filter(
				(file) => file.key !== file_key
			);

			setFormValues((prevValues: FormValues) => {
				if (prevValues) {
					return {
						...prevValues,
						attachments: newFiles,
					};
				}

				return prevValues;
			});

			// Update fileInput
			if (fileInputRef && fileInputRef.current) {
				// Reconstruct the input value based on the remaining files
				const remainingFiles = newFiles.map((fileItem) => ({
					base64String: fileItem.file,
					fileName: fileItem.file_name,
				}));

				const dataTransfer = new DataTransfer();
				remainingFiles.forEach((file) => {
					const parsedFile = base64ToFile(
						file.base64String,
						file.fileName
					);

					dataTransfer.items.add(parsedFile);
				});
				fileInputRef.current.files = dataTransfer.files;
			}
		}
	};

	// Work on removing files.
	const handleRemoveExistingFile = async (aws_object_key: string) => {
		if (!axios || !data) return;

		toast.loading('Removing...', toastOptions);

		try {
			await (
				await axios
			).post(
				`/api/accessioner/data/${
					data._id.$oid
				}/delete-trf-attachment/?aws_object_key=${encodeURIComponent(
					aws_object_key
				)}`,
				null
			);

			setRefresh((value) => !value);
			toast.dismiss();
		} catch (error) {
			console.log(error);
		}
	};

	return !formValues || !data ? (
		<LoadingBox />
	) : (
		<ContentSection>
			<Form id={formId} handleSubmit={handleSubmit}>
				<FormGroup heading="Miscellaneous">
					<div className="sm:col-span-2">
						<SelectField
							name="collection_kit_shipping_option"
							label="Collection Kit Shipping Option"
							value={formValues['collection_kit_shipping_option']}
							handleSelect={handleChange}
							options={
								BLADDERCARE['4.1']
									.collection_kit_shipping_options
							}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="collection_kit_shipping_option"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-4"></div>
					<div className="sm:col-span-2">
						<MultipleCombobox
							showRequiredAsterisk={true}
							name="physician_report_delivery_methods"
							label="Physician Report Delivery Method(s)"
							value={
								formValues['physician_report_delivery_methods']
							}
							handleSelect={(selectedOptions: Option[]) => {
								handleMultipleComboboxSelect(
									selectedOptions,
									'physician_report_delivery_methods',
									setFormValues,
									setPhysicianReportDeliveryMethodOptions
								);
							}}
							options={physicianReportDeliveryMethodOptions}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="physician_report_delivery_methods"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-4"></div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							showRequiredAsterisk={
								formValues[
									'physician_report_delivery_methods'
								] &&
								formValues[
									'physician_report_delivery_methods'
								].some(
									(option: Option) => option.value === 'fax'
								)
							}
							placeholder="e.g., Fax Number"
							handleInputChange={handleChange}
							name="physician_report_delivery_contact_fax"
							label="Physician Report Delivery Fax Contact"
							value={
								formValues[
									'physician_report_delivery_contact_fax'
								]
							}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="physician_report_delivery_contact_fax"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							showRequiredAsterisk={
								formValues[
									'physician_report_delivery_methods'
								] &&
								formValues[
									'physician_report_delivery_methods'
								].some(
									(option: Option) => option.value === 'mail'
								)
							}
							handleInputChange={handleChange}
							name="physician_report_delivery_contact_mail"
							label="Physician Report Delivery Mail Contact"
							placeholder="e.g., 123 Main St. Tustin, CA 91892"
							value={
								formValues[
									'physician_report_delivery_contact_mail'
								]
							}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="physician_report_delivery_contact_mail"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="email"
							showRequiredAsterisk={
								formValues[
									'physician_report_delivery_methods'
								] &&
								formValues[
									'physician_report_delivery_methods'
								].some(
									(option: Option) => option.value === 'email'
								)
							}
							handleInputChange={handleChange}
							placeholder="e.g., jdoe@pangealab.com"
							name="physician_report_delivery_contact_email"
							label="Physician Report Delivery Email Contact"
							value={
								formValues[
									'physician_report_delivery_contact_email'
								]
							}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="physician_report_delivery_contact_email"
								/>
							}
						/>
					</div>
				</FormGroup>
				<FormGroup
					heading="Attachments"
					description={
						<span>
							To upload multiple files, press{' '}
							<span className="italic">Ctrl + Click</span>.
						</span>
					}
				>
					<div className="sm:col-span-2">
						<FileField
							multiple={true}
							ref={fileInputRef}
							name="attachments"
							label="Attachments"
							showRequiredAsterisk={true}
							handleInputChange={handleFileChange}
							fieldAction={
								<ProcessTRFComponents.FlagButton
									property="attachments"
									formValues={formValues}
									setFormValues={setFormValues}
								/>
							}
						/>
					</div>

					{data.requisition_form.attachments && (
						<div className="sm:col-span-6 space-y-4">
							{data.requisition_form.attachments.map(
								(
									attachment: {
										aws_object_key: string;
										file_name: string;
									},
									index: number
								) => {
									return (
										<div
											key={index}
											className="flex items-center space-x-4"
										>
											<Button
												type="button"
												tier="tertiary"
												text="Download"
												Icon="ArrowDownTrayIcon"
												onClick={() =>
													handleDownloadAttachment(
														attachment.aws_object_key
													)
												}
											/>
											<p className="text-sm">
												{attachment.file_name}
											</p>
											<TextButton
												color="gray"
												type="button"
												text="Remove"
												onClick={() =>
													handleRemoveExistingFile(
														attachment.aws_object_key
													)
												}
											/>
										</div>
									);
								}
							)}
						</div>
					)}

					{formValues['attachments'] &&
						formValues['attachments'].length > 0 && (
							<div className="sm:col-span-6 space-y-10">
								{formValues['attachments'].map(
									(file: FileAttachment) => {
										const byteCharacters = Buffer.from(
											file.file.split(',')[1],
											'base64'
										).toString('binary');
										const byteNumbers = new Array(
											byteCharacters.length
										)
											.fill(0)
											.map((_, i) =>
												byteCharacters.charCodeAt(i)
											);
										const byteArray = new Uint8Array(
											byteNumbers
										);
										const blob = new Blob([byteArray], {
											type: 'application/pdf',
										});
										const objectURL =
											URL.createObjectURL(blob);

										return (
											<div
												key={file.key}
												className="space-y-2"
											>
												<div className="flex items-center justify-between">
													<p>
														File name:{' '}
														{file.file_name}
													</p>
													<TextButton
														color="gray"
														type="button"
														text="Remove"
														className="group-hover:text-gray-700"
														onClick={() =>
															handleRemoveFile(
																file.key
															)
														}
													/>
												</div>
												<iframe
													width="1000"
													height="1000"
													src={objectURL}
													title={file.file_name}
												/>
											</div>
										);
									}
								)}
							</div>
						)}
				</FormGroup>
			</Form>
		</ContentSection>
	);
}
