import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	Fragment,
	Dispatch,
	useState,
	FormEvent,
	useEffect,
	ChangeEvent,
	SetStateAction,
} from 'react';

import {
	Form,
	Modal,
	Button,
	useAxios,
	Container,
	FormGroup,
	FormFooter,
	LoadingBox,
	SpecimenInfo,
	TextareaField,
	ContentWrapper,
	ContentSection,
	DescriptionList,
	DescriptionItem,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import { ClinicData, formatDate, NonClinicData } from '@pangea-lis-apps/utils';

import Notes from '../../components/notes';

/* eslint-disable-next-line */
export interface ViewSampleProps {}

export default function ViewSample(props: ViewSampleProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { dataId } = useParams();

	const [refresh, setRefresh] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [data, setData] = useState<ClinicData | NonClinicData | undefined>(
		undefined
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const data = await (
					await axios
				).get(`/api/lab-tech/data/${dataId}`);

				if (!unmounted && data) {
					const parsedData = JSON.parse(data.data.data);
					console.log(parsedData);

					setData(parsedData);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, refresh, dataId]);

	return (
		<Fragment>
			<RejectSampleModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<Container>
				<ContentWrapper
					Icon="FingerPrintIcon"
					heading="Sample Details"
					description="If there is an issue with the sample such that a recollection is required, reject the sample. Accessioners will be automatically notified."
				>
					{!data ? (
						<LoadingBox />
					) : (
						<Fragment>
							<SpecimenInfo data={data} />

							<ContentSectionDivider />

							<ContentSection heading="Miscellaneous">
								<DescriptionList>
									<DescriptionItem
										term="Notes"
										customColSpan="sm:col-span-3"
									>
										<Notes
											id={dataId}
											collection="data"
											data={data.notes}
											setRefresh={setRefresh}
											receiveDepartment="lab_techs"
										/>
									</DescriptionItem>
								</DescriptionList>
							</ContentSection>

							{data.sample.status !== 'reject' && (
								<FormFooter>
									<Button
										type="button"
										text="Reject"
										tier="tertiary"
										Icon="XCircleIcon"
										onClick={() => setModalVisible(true)}
									/>
								</FormFooter>
							)}
						</Fragment>
					)}
				</ContentWrapper>
			</Container>
		</Fragment>
	);
}

interface RejectSampleModalProps {
	data: ClinicData | NonClinicData | undefined;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

function RejectSampleModal(props: RejectSampleModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const { visible, setVisible } = props.visible;

	const [disabled, setDisabled] = useState(false);
	const [rejectionReason, setRejectionReason] = useState('');

	useEffect(() => {
		if (
			visible &&
			props.data &&
			props.data.sample.accessioning_approval.reject.reason.value
		) {
			setRejectionReason(
				props.data.sample.accessioning_approval.reject.reason.value
			);
		}
	}, [visible, props.data]);

	const handleFormSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !props.data || !axios) return;

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(`/api/lab-tech/data/${props.data._id.$oid}/sample/reject`, {
				user,
				form_data: {
					rejection_reason: rejectionReason,
				},
			});

			toast.dismiss();

			handleClose();
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleInputChange = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement;

		if (target && target.name) setRejectionReason(target.value);
	};

	const handleClose = () => {
		setRejectionReason('');
		setDisabled(false);
		setVisible(false);
	};

	return (
		<Modal
			visible={visible}
			title="Reject sample"
			onClose={handleClose}
			customWidth="max-w-md"
			description="If there is an issue with the sample such that a recollection is required, reject the sample. Accessioners will be automatically notified."
		>
			{!props.data ? (
				<LoadingBox />
			) : (
				<Fragment>
					<DescriptionList>
						<DescriptionItem
							term="Pangea ID"
							details={props.data.sample.pangea_id}
						/>
						<DescriptionItem
							term="Specimen ID"
							details={props.data.sample.specimen_id}
						/>
						{props.data.type === 'clinic' && (
							<Fragment>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="First Name"
									details={
										props.data.patient
											? props.data.patient.first_name
											: null
									}
								/>
								<DescriptionItem
									term="Last Name"
									details={
										props.data.patient
											? props.data.patient.last_name
											: null
									}
								/>
								<DescriptionItem
									term="Date of Birth"
									details={
										props.data.patient
											? formatDate(
													props.data.patient
														.date_of_birth
											  )
											: null
									}
								/>
							</Fragment>
						)}
					</DescriptionList>

					<hr className="my-6" />

					<Form handleSubmit={handleFormSubmit}>
						<FormGroup>
							<div className="sm:col-span-6">
								<TextareaField
									required
									name="reject_reason"
									value={rejectionReason}
									label="Rejection reason"
									handleInputChange={handleInputChange}
									placeholder="e.g., Insufficient quantity"
								/>
							</div>
						</FormGroup>
						<FormFooter>
							<Button
								type="submit"
								text="Reject"
								tier="tertiary"
								Icon="CheckIcon"
								disabled={disabled}
							/>
						</FormFooter>
					</Form>
				</Fragment>
			)}
		</Modal>
	);
}
