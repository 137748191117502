import { useNavigate } from 'react-router-dom';

import {
	Table,
	TableRow,
	DataCell,
	TableBody,
	TableHead,
	HeaderCell,
	EmptyTable,
	TableLoading,
	ArrowLinkButton,
} from '@pangea-lis-apps/ui';
import {
	ASSAYS,
	ClinicData,
	QueryState,
	formatDate,
	ORGANIZATIONS,
	NonClinicData,
	PaginationState,
	getLabelFromValue,
} from '@pangea-lis-apps/utils';

interface RejectedSamplesTableProps {
	query: QueryState;
	pagination: PaginationState;
	data: (ClinicData | NonClinicData)[] | undefined;
}

export default function RejectedSamplesTable(props: RejectedSamplesTableProps) {
	const navigate = useNavigate();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Assay</HeaderCell>
				<HeaderCell>Priority Level</HeaderCell>
				<HeaderCell>RUO</HeaderCell>
				<HeaderCell>Customer</HeaderCell>
				<HeaderCell>Organization</HeaderCell>
				<HeaderCell>Pangea ID</HeaderCell>
				<HeaderCell>Specimen ID</HeaderCell>
				<HeaderCell>Collection Kit ID</HeaderCell>
				<HeaderCell>Patient First Name</HeaderCell>
				<HeaderCell>Patient Last Name</HeaderCell>
				<HeaderCell>Patient Date of Birth</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
				<HeaderCell customWidth="w-68">Reason</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => {
							const clinicSample = datum.type === 'clinic';

							return (
								<TableRow key={index} index={index}>
									<DataCell>
										{getLabelFromValue(
											datum.sample.assay,
											ASSAYS['assays']
										)}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.priority,
											ORGANIZATIONS.priorityLevels
										)}
									</DataCell>
									<DataCell>
										{datum.sample.ruo === null
											? null
											: datum.sample.ruo
											? 'Yes'
											: 'No'}
									</DataCell>
									<DataCell>
										{datum.customer.first_name.concat(
											' ',
											datum.customer.last_name
										)}
									</DataCell>
									<DataCell>
										{datum.organization.name}
									</DataCell>
									<DataCell>
										{datum.sample.pangea_id && (
											<ArrowLinkButton
												text={datum.sample.pangea_id}
												onClick={() =>
													navigate(
														`/accessioner/view/samples/${datum._id.$oid}`
													)
												}
											/>
										)}
									</DataCell>
									<DataCell>
										{datum.sample.specimen_id}
									</DataCell>
									<DataCell>
										{datum.sample.collection_kit_id}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.first_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.last_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? formatDate(
													datum.patient.date_of_birth
											  )
											: null}
									</DataCell>
									<DataCell>
										{clinicSample
											? formatDate(
													datum.requisition_form
														.sample_collection_date
											  )
											: formatDate(
													datum.sample
														.sample_collection_date
											  )}
									</DataCell>
									<DataCell>
										{
											datum.sample.accessioning_approval
												.reject.reason.value
										}
									</DataCell>
								</TableRow>
							);
						})
					) : (
						<EmptyTable
							Icon="FingerPrintIcon"
							heading="No rejected samples"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
