import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	Fragment,
	useState,
	Dispatch,
	FormEvent,
	ChangeEvent,
	SetStateAction,
} from 'react';

import {
	Form,
	Modal,
	Button,
	useAxios,
	EmptyBox,
	ItemCard,
	FormGroup,
	FormFooter,
	NumberField,
	SelectField,
} from '@pangea-lis-apps/ui';
import { Item, SHIPPING } from '@pangea-lis-apps/utils';

const initialItemValue = {
	sku: '',
	name: '',
	quantity: 1,
};

const initialForm = {
	items: [],
};

interface RecordShipmentModalProps {
	customer: string;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

export default function RecordShipmentModal(props: RecordShipmentModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const { visible, setVisible } = props.visible;

	const [disabled, setDisabled] = useState(false);
	const [item, setItem] = useState<Item>(initialItemValue);
	const [form, setForm] = useState<{ items: Item[] }>(initialForm);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios) return;
		else if (!form.items.length) {
			toast.error('Select an item and quantity!', toastOptions);
			return;
		}

		setDisabled(true);

		toast.loading('Recording shipment...', toastOptions);

		try {
			await (
				await axios
			).post('/api/accessioner/shipment', {
				user,
				shipment_data: {
					items: form.items.map((item) => {
						const foundItem = SHIPPING.items.find(
							(x) => x.value === item.sku
						);

						return {
							sku: foundItem?.value,
							name: foundItem?.label,
							quantity: item.quantity,
						};
					}),
					customer: props.customer,
				},
			});

			toast.dismiss();

			handleCloseModal();
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleItemChange = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name)
			setItem((prevVal) => ({
				...prevVal,
				[target.name]: target.value,
			}));
	};

	const handleRemoveItem = (sku: string) => {
		setForm((prevVal) => {
			const new_items = prevVal.items.filter((item) => item.sku !== sku);

			return {
				...prevVal,
				items: new_items,
			};
		});
	};

	const handleAddItem = (event: FormEvent) => {
		event.preventDefault();

		const { sku, quantity } = item;

		const items = form.items;
		const idx = items.findIndex((item) => item.sku === sku);

		if (idx === -1) items.push(item);
		else items[idx].quantity = quantity;

		setForm((prevVal) => ({
			...prevVal,
			items: [...items],
		}));

		setItem(initialItemValue);
	};

	const handleCloseModal = () => {
		setItem(initialItemValue);
		setForm(initialForm);
		setDisabled(false);
		setVisible(false);
	};

	return (
		<Modal
			visible={visible}
			title="Record shipment"
			customWidth="max-w-2xl"
			onClose={handleCloseModal}
			description="Record all kits received in the customer package."
		>
			<Fragment>
				<Form handleSubmit={handleAddItem}>
					<FormGroup
						heading="Select items"
						description="Select a kit and a quantity."
					>
						<div className="sm:col-span-2">
							<SelectField
								required
								name="sku"
								label="Name"
								value={item.sku}
								handleSelect={handleItemChange}
								options={[
									{
										value: '',
										label: 'Select an Option',
									},
									...SHIPPING.items
										.filter((item) => item.for_sale)
										.map(({ label, value }) => ({
											label,
											value,
										})),
								]}
							/>
						</div>
						<div className="sm:col-span-1">
							<NumberField
								min={1}
								required
								max={500}
								name="quantity"
								label="Quantity"
								value={item.quantity}
								handleInputChange={handleItemChange}
							/>
						</div>
						<div className="sm:col-span-2 flex items-end">
							<Button
								type="submit"
								Icon="PlusIcon"
								text="Add item"
								tier="secondary"
							/>
						</div>
					</FormGroup>
				</Form>

				<hr className="my-10" />

				<Form handleSubmit={handleSubmit}>
					<FormGroup
						heading="Received items"
						description="Items received in the current package."
					>
						<div className="sm:col-span-6">
							{form.items && form.items.length ? (
								<div className="space-x-2">
									{form.items.map((item, index) => (
										<ItemCard
											item={item}
											key={index}
											handleRemove={handleRemoveItem}
										/>
									))}
								</div>
							) : (
								<EmptyBox
									heading="No items"
									Icon="PlusSmallIcon"
									description="Add items to get started"
								/>
							)}
						</div>
					</FormGroup>

					<FormFooter>
						<Button
							type="submit"
							text="Submit"
							tier="tertiary"
							Icon="CheckIcon"
							disabled={disabled}
						/>
					</FormFooter>
				</Form>
			</Fragment>
		</Modal>
	);
}
