import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
	useId,
	useRef,
	useState,
	Fragment,
	Dispatch,
	useEffect,
	ChangeEvent,
	SetStateAction,
} from 'react';

import {
	Alert,
	Modal,
	Button,
	useAxios,
	FormFooter,
	LoadingBox,
	SelectField,
	SpecimenInfo,
	SampleResults,
	DataComponents,
	ContentSection,
	useTabsContext,
	DescriptionList,
	DescriptionItem,
	AccessioningStatus,
	ContentSectionDivider,
	AlinityMSampleResults,
} from '@pangea-lis-apps/ui';
import {
	ClinicData,
	NonClinicData,
	AlinityMResultStatus,
} from '@pangea-lis-apps/utils';

import {
	RerunModal,
	AddNoteModal,
	AddCommentModal,
	initialModalValue,
	ModalWithRefreshProps,
} from './modals';
import Notes from '../../../../components/notes';
import { Attachments } from '@pangea-lis-apps/ui';
import SampleActionsButton from './sample-actions-button';
import RejectSampleModal from '../../../../components/modals/reject-sample-modal';

export interface ViewAlinityMSampleProps {
	data: ClinicData | NonClinicData | undefined;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export default function ViewAlinityMSample({
	data,
	setRefresh,
}: ViewAlinityMSampleProps) {
	const { dataId } = useParams();
	// const [disabled, setDisabled] = useState(false);
	const [modalVisible, setModalVisible] =
		useState<Record<string, boolean>>(initialModalValue);

	// const handleGenerateReport = async () => {
	// 	if (!axios) return;

	// 	setDisabled(true);

	// 	toast.loading('Generating...', toastOptions);

	// 	try {
	// 		await (
	// 			await axios
	// 		).post(`/api/cls/data/${dataId}/report/generate`, null);

	// 		toast.success('Report generated!', toastOptions);

	// 		setRefresh((value) => !value);
	// 	} catch (error) {
	// 		console.log(error);
	// 	} finally {
	// 		setDisabled(false);
	// 	}
	// };

	return (
		<Fragment>
			<RejectSampleModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<RerunModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<AddNoteModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<EditResultsModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<AddCommentModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<VerifyModal
				data={data}
				setRefresh={setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			{!data ? (
				<LoadingBox />
			) : (
				<Fragment>
					<AccessioningStatus data={data} />

					<ContentSectionDivider />

					<SpecimenInfo data={data} />

					{'patient' in data && (
						<Fragment>
							<ContentSectionDivider />

							<DataComponents.PatientInformation data={data} />
						</Fragment>
					)}

					<ContentSectionDivider />

					<DataComponents.ProviderInformation data={data} />

					<ContentSectionDivider />

					<DataComponents.OrganizationInformation data={data} />

					<ContentSectionDivider />

					{data.type === 'clinic' && (
						<ContentSection heading="Requisition form info">
							<DescriptionList>
								<Attachments
									data={data}
									entity="cls"
									showRequiredStyles={true}
								/>
							</DescriptionList>
						</ContentSection>
					)}

					<DataComponents.ReportInformation
						data={data}
						setRefresh={setRefresh}
					>
						<DescriptionItem term="All results">
							<SampleResults
								data={data}
								alinityMAllOrOrdered="all"
								valuesToHighlight={[
									null,
									'Detected',
									'Invalid',
								]}
							/>
						</DescriptionItem>
						<DescriptionItem term="Ordered results">
							<SampleResults
								data={data}
								alinityMAllOrOrdered="ordered"
								valuesToHighlight={[
									null,
									'Detected',
									'Invalid',
								]}
							/>
						</DescriptionItem>
						<div className="sm:col-span-2"></div>
					</DataComponents.ReportInformation>

					<ContentSectionDivider />

					<ContentSection heading="Miscellaneous">
						<DescriptionList>
							<DescriptionItem
								term="Notes"
								customColSpan="sm:col-span-3"
							>
								<Notes
									id={dataId}
									collection="data"
									data={data.notes}
									setRefresh={setRefresh}
									receiveDepartment="cls"
								/>
							</DescriptionItem>
						</DescriptionList>
					</ContentSection>

					<ContentSection>
						<FormFooter>
							<SampleActionsButton
								data={data}
								setModalVisible={setModalVisible}
							/>
						</FormFooter>
					</ContentSection>
				</Fragment>
			)}
		</Fragment>
	);
}

export function VerifyModal(props: ModalWithRefreshProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { setRefreshTabs } = useTabsContext();
	const {
		visible: { verify: visible },
		setVisible,
	} = props.visible;
	const isClinicSample = props.data && props.data.type === 'clinic';

	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async () => {
		if (disabled || !axios || !props.data) return;

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(
				`/api/cls/data/${props.data._id.$oid}/sample/report/verify`,
				{}
			);

			toast.dismiss(toastId);

			props.setRefresh((value) => !value);
			setRefreshTabs((value) => !value);
			setVisible((prevValue) => ({
				...prevValue,
				verify: false,
			}));
		} catch (error) {
			console.log(error);
		}

		setDisabled(false);
	};

	return (
		<Modal
			visible={visible}
			title={isClinicSample ? 'Sign report' : 'Verify report'}
			onClose={() =>
				setVisible((prevValue) => ({
					...prevValue,
					verify: false,
				}))
			}
		>
			{!props.data ? (
				<LoadingBox />
			) : (
				<Fragment>
					<Alert
						type="info"
						heading="Attention needed"
						description={`Please review the results carefully. ${
							isClinicSample ? 'Signing' : 'Verifying'
						} the report will release the report to the customer.`}
					/>
					<DescriptionList className="mt-4">
						<DescriptionItem
							term="All results"
							customColSpan="sm:col-span-3"
						>
							<SampleResults
								data={props.data}
								alinityMAllOrOrdered="all"
								valuesToHighlight={[
									null,
									'Detected',
									'Invalid',
								]}
							/>
						</DescriptionItem>
						<DescriptionItem
							term="Ordered results"
							customColSpan="sm:col-span-3"
						>
							<SampleResults
								data={props.data}
								alinityMAllOrOrdered="ordered"
								valuesToHighlight={[
									null,
									'Detected',
									'Invalid',
								]}
							/>
						</DescriptionItem>
					</DescriptionList>
					<FormFooter>
						<Button
							type="button"
							tier="tertiary"
							Icon="CheckIcon"
							disabled={disabled}
							onClick={handleSubmit}
							text={isClinicSample ? 'Sign off' : 'Verify'}
						/>
					</FormFooter>
				</Fragment>
			)}
		</Modal>
	);
}

const resultOptions = [
	{
		value: '',
		disabled: true,
		label: 'Select an Option',
	},
	{
		value: 'Detected',
		label: 'Detected',
	},
	{
		value: 'Not_Detected',
		label: 'Not detected',
	},
	{
		value: 'Invalid',
		label: 'Invalid',
	},
	{
		value: 'Null',
		label: 'Null',
		disabled: true,
	},
];

const TARGETS = {
	sti: ['CT', 'NG', 'TV', 'MG'],
	resp4plex: ['FLUA_4PEUA', 'FLUB_4PEUA', 'RSV_4PEUA', 'COV2_4PEUA'],
};

interface ModalProps {
	data: ClinicData | NonClinicData | undefined;
	visible: {
		visible: Record<string, boolean>;
		setVisible: Dispatch<SetStateAction<Record<string, boolean>>>;
	};
}

type Results = Record<string, string | null>;

interface EditResultsModalProps extends ModalProps {
	data: ClinicData | NonClinicData | undefined;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

function EditResultsModal(props: EditResultsModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { setRefreshTabs } = useTabsContext();
	const {
		visible: { editResults: visible },
		setVisible,
	} = props.visible;

	const [page, setPage] = useState(1);
	const resultsRef = useRef<Results>();
	const [disabled, setDisabled] = useState(false);
	const [results, setResults] = useState<Results>({});

	const handleSubmit = async (confirmed: boolean) => {
		if (disabled || !axios || !props.data || !results) return;
		else if (
			// Values weren't changed
			JSON.stringify(resultsRef.current) === JSON.stringify(results)
		) {
			toast.error('No values were changed!');
			return;
		} else {
			// Values were changed
			if (props.data.sample.status === 'verified') {
				if (page === 2) {
					// Sample wasn't confirmed
					if (!confirmed) return;
				} else {
					setPage(2);
					return;
				}
			}
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(`/api/cls/data/${props.data._id.$oid}/sample/results`, {
				results,
			});

			toast.dismiss(toastId);

			handleClose();
			props.setRefresh((value) => !value);
			setRefreshTabs((value) => !value);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (visible && props.data) {
			let res: Record<string, string> = {};

			res = props.data.sample.results.all;

			resultsRef.current = res;
			setResults(res);
		}
	}, [visible, props.data]);

	const handleChange = (event: ChangeEvent) => {
		const target = event.target as HTMLSelectElement;

		if (target && target.name)
			setResults((prevVal) => ({
				...prevVal,
				[target.name]: target.value === 'Null' ? null : target.value,
			}));
	};

	const handleClose = () => {
		setPage(1);
		setDisabled(false);
		setVisible((prevValue) => ({
			...prevValue,
			editResults: false,
		}));
	};

	return (
		<Modal visible={visible} title="Edit results" onClose={handleClose}>
			{props.data ? (
				page === 1 ? (
					<EditResultsModalResultsPage
						setPage={setPage}
						results={results}
						data={props.data}
						disabled={disabled}
						handleSubmit={handleSubmit}
						handleChange={handleChange}
					/>
				) : (
					<EditResultsModalConfirmationPage
						setPage={setPage}
						results={results}
						data={props.data}
						disabled={disabled}
						handleSubmit={handleSubmit}
					/>
				)
			) : (
				<LoadingBox />
			)}
		</Modal>
	);
}

interface EditResultsModalResultsPageProps {
	disabled: boolean;
	results: Results;
	data: ClinicData | NonClinicData;
	setPage: Dispatch<SetStateAction<number>>;
	handleChange: (event: ChangeEvent) => void;
	handleSubmit: (confirmed: boolean) => void;
}

const EditResultsModalResultsPage = (
	props: EditResultsModalResultsPageProps
) => {
	return (
		<div>
			<ul className="grid grid-cols-2 gap-4">
				{TARGETS[props.data.sample.assay as 'sti' | 'resp4plex'].map(
					(target, index) => (
						<li key={index}>
							<SelectField
								required
								name={target}
								label={target}
								handleSelect={props.handleChange}
								value={
									props.results[target] === null
										? 'Null'
										: // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
										  props.results[target]!
								}
								options={resultOptions}
							/>
						</li>
					)
				)}
			</ul>
			<FormFooter>
				<Button
					type="button"
					text="Update"
					tier="tertiary"
					disabled={props.disabled}
					Icon="ArrowSmallRightIcon"
					onClick={() => props.handleSubmit(false)}
				/>
			</FormFooter>
		</div>
	);
};

const areOrderedResultsUpdated = (
	results: Results,
	data: ClinicData | NonClinicData
) => {
	for (const key in data.sample.results.ordered) {
		if (results[key] !== data.sample.results.ordered[key]) {
			return true;
		}
	}

	return false;
};

interface EditResultsModalConfirmationPageProps {
	results: Results;
	disabled: boolean;
	data: ClinicData | NonClinicData;
	setPage: Dispatch<SetStateAction<number>>;
	handleSubmit: (confirmed: boolean) => void;
}

const EditResultsModalConfirmationPage = (
	props: EditResultsModalConfirmationPageProps
) => {
	const isClinicSample = props.data.type === 'clinic';
	const orderedResultsUpdated = areOrderedResultsUpdated(
		props.results,
		props.data
	);

	return (
		<div>
			{orderedResultsUpdated && (
				<Alert
					type="warning"
					heading="A corrective report will be generated"
					description="A new report will be generated with a corrective comment and be released."
				/>
			)}
			<DescriptionList className="mt-4">
				<DescriptionItem
					term="Current results"
					customColSpan="sm:col-span-3"
				>
					<SampleResults
						data={props.data}
						alinityMAllOrOrdered="all"
						valuesToHighlight={[null, 'Detected', 'Invalid']}
					/>
				</DescriptionItem>
				<DescriptionItem
					term="New results"
					customColSpan="sm:col-span-3"
				>
					<AlinityMSampleResults
						data={
							props.results as {
								[key: string]: AlinityMResultStatus;
							}
						}
						valuesToHighlight={[null, 'Detected', 'Invalid']}
					/>
				</DescriptionItem>
			</DescriptionList>
			<FormFooter className="!justify-between">
				<Button
					type="button"
					text="Back"
					tier="tertiary"
					Icon="ArrowSmallLeftIcon"
					onClick={() => props.setPage(1)}
				/>
				<Button
					type="button"
					tier="tertiary"
					Icon="CheckIcon"
					disabled={props.disabled}
					onClick={() => props.handleSubmit(true)}
					text={isClinicSample ? 'Sign off' : 'Verify'}
				/>
			</FormFooter>
		</div>
	);
};
