import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
	useId,
	useState,
	Fragment,
	Dispatch,
	useEffect,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Alert,
	Modal,
	Button,
	useAxios,
	DateField,
	FormFooter,
	LoadingBox,
	SelectField,
	GenericField,
	TextareaField,
	DescriptionItem,
	MultipleSelectField,
} from '@pangea-lis-apps/ui';
import { Attribute } from '@pangea-lis-apps/utils';

import { IPayLoad } from '../data-types';

export interface UpdateNonResultModalProps {
	data: any;
	heading: string;
	endpoint: string;
	subheading?: string;
	attribute: Attribute;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
	customWidth?: string;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function UpdateNonResultModal(props: UpdateNonResultModalProps) {
	const { visible, setVisible } = props.visible;
	const [selectedOption, setSelectedOption] = useState('');
	const [newValue, setNewValue] = useState('');
	const [disabled, setDisabled] = useState(false);
	const [multipleSelectNewValue, setMultipleSelectNewValue] = useState<
		string[]
	>([]);

	const handleInputChange = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;
		if (target && target.name) setNewValue(target.value);
	};

	const handleSelectionChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setSelectedOption(target.value);
			setNewValue(target.value === 'other' ? '' : target.value);
		}
	};

	const handleMultipleSelectChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLSelectElement;

		if (target && target.name)
			setMultipleSelectNewValue(
				Array.from(target.selectedOptions, (item) => item.value)
			);
	};

	const handleCloseModal = () => {
		setNewValue('');
		setVisible(false);
		setDisabled(false);
		setSelectedOption('');
	};

	const handleSubmit = () => {
		// If has validateFn
		if (props.attribute.validateFn) {
			const {status, errMessage} = props.attribute.validateFn(newValue)
			// Not Pass Validate fn return with error message
			if(status === false) {
				toast.error(errMessage)
				return
			}
		}

		// If Pass validateFn keep going for the rest of code
		props.data[props.attribute.property] = newValue;
		props.setRefresh(true);
		handleCloseModal();
	};


	return (
		<Modal
			visible={visible}
			title={props.heading}
			onClose={handleCloseModal}
			description={props.subheading}
			customWidth={props.customWidth ? props.customWidth : 'w-92'}
		>
			<form>
				<div className="space-y-4">
					<DescriptionItem
						term={props.attribute.label}
						details={
							props.attribute.selectLabel
								? props.attribute.selectLabel
								: props.attribute.value
						}
					/>
					{(() => {
						const { type, selectOptions } = props.attribute;

						if (type === 'select') {
							return (
								<Fragment>
									<SelectField
										required
										name="new_value"
										label="New value"
										value={selectedOption}
										options={selectOptions || []}
										handleSelect={handleSelectionChange}
									/>
									{selectedOption === 'other' && (
										<GenericField
											required
											type="text"
											name="new_value"
											value={newValue}
											label="If other, please specify"
											placeholder="Enter other value"
											handleInputChange={
												handleInputChange
											}
										/>
									)}
								</Fragment>
							);
						} else if (type === 'multiple_select') {
							return (
								<MultipleSelectField
									required
									name="new_value"
									label="New value"
									options={selectOptions || []}
									value={multipleSelectNewValue}
									handleSelect={handleMultipleSelectChange}
								/>
							);
						} else if (type === 'date') {
							return (
								<DateField
									required
									name="new_value"
									value={newValue}
									label="New value"
									handleInputChange={handleInputChange}
								/>
							);
						} else if (type === 'textarea') {
							return (
								<TextareaField
									required
									name="new_value"
									value={newValue}
									label="New value"
									placeholder="Enter text"
									handleInputChange={handleInputChange}
								/>
							);
						} else {
							return (
								<GenericField
									required
									type={type}
									name="new_value"
									value={newValue}
									label="New value"
									placeholder="Enter value"
									handleInputChange={handleInputChange}
								/>
							);
						}
					})()}
				</div>
			</form>
			<FormFooter>
				<Button
					type="submit"
					text="Update"
					tier="tertiary"
					Icon="CheckIcon"
					disabled={disabled}
					onClick={() => handleSubmit()}
				/>
			</FormFooter>
		</Modal>
	);
}

interface CommentsModal {
	attribute: object;
	data: IPayLoad;
	subheading?: string;
	heading: string;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
	customWidth?: string;
}

export function CommentsModal(props: CommentsModal) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const { dataId } = useParams();

	const { visible, setVisible } = props.visible;
	const [newValue, setNewValue] = useState(props.data.revision_comment);
	const [disabled, setDisabled] = useState(false);
	const [showLoading, setShowLoading] = useState(false);

	const handleInputChange = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;
		if (target && target.name) {
			setNewValue(target.value);
		}
	};

	const handleCloseModal = () => {
		console.log('close');
		setVisible(false);
		setDisabled(false);
	};

	const handleSubmit = async () => {
		props.data.revision_comment = newValue;

		setDisabled(true);
		if (!axios) return;

		try {
			setShowLoading(true);

			await await (
				await axios
			).patch(`/api/client-services/data/${dataId}/report`, props.data);

			toast.success('Update Sccessful.');
			props.setRefresh(true);
		} catch (err) {
			console.log(err);
			toast.error('Update Failed.');
		} finally {
			setShowLoading(false);
			setDisabled(false);
			setVisible(false);
		}
	};

	useEffect(() => {
		setNewValue(props.data.revision_comment);
	}, [props]);

	return (
		<Modal
			visible={visible}
			title={props.heading}
			onClose={handleCloseModal}
			description={props.subheading}
			customWidth={props.customWidth ? props.customWidth : 'w-140'}
		>
			<form>
				<Alert
					type="warning"
					className="mb-4"
					heading="New report will be generated"
					description="The report has already been verified. A new report will be generated with the provided comment."
				/>

				{showLoading ? (
					<LoadingBox />
				) : (
					<TextareaField
						required
						name="new_value"
						value={newValue}
						label="New Comments"
						placeholder={
							props.data ? props.data.revision_comment : ''
						}
						handleInputChange={handleInputChange}
					/>
				)}
			</form>

			<FormFooter>
				<Button
					type="submit"
					text="Submit"
					tier="tertiary"
					Icon="CheckIcon"
					disabled={disabled}
					onClick={() => handleSubmit()}
				/>
			</FormFooter>
		</Modal>
	);
}

export function FindDiff(oldObject: any, newObject: any) {
	let revisionKeys = '';
	const diff = Object();
	const samplesKeyList = [
		'sample_received_date',
		'specimen_id',
		'sample_collection_date',
	];

	for (const key in newObject) {
		if (newObject[key] !== oldObject[key]) {
			const fieldName = key.toLowerCase().split('_').join(' ');

			console.log('Field names~~~', fieldName);
			revisionKeys =
				revisionKeys === ''
					? fieldName
					: revisionKeys.concat(', ', fieldName);
			const prefix = samplesKeyList.includes(key)
				? 'sample.'
				: 'requisition_form.';
			const diffkey = prefix + key;
			diff[diffkey] = newObject[key];
		}
	}

	const revisedReportDate = new Date().toLocaleString().split(',')[0];
	const revisionComment = `REVISED REPORT for ${revisionKeys}. THE RESULTS REMAIN UNCHANGED. ${revisedReportDate}`;

	if (Object.keys(diff).length === 0) {
		console.log('Nothing change');
		return;
	}

	const payload = {
		updated_values: diff,
		revision_comment: revisionComment,
	};

	return payload;
}
