import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useId, useState, useEffect } from 'react';

import {
	ClinicData,
	QueryState,
	Pagination,
	formatDate,
	PaginationState,
	initialPaginationValues,
	getLabelFromValue,
	ASSAYS,
	ORGANIZATIONS,
} from '@pangea-lis-apps/utils';
import {
	Link,
	Table,
	Badge,
	Button,
	useAxios,
	DataCell,
	TableRow,
	TableBody,
	TableHead,
	Container,
	EmptyTable,
	HeaderCell,
	TableLoading,
	ContentWrapper,
	ContentSection,
	ArrowLinkButton,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';

import TableSearchFiltersForm from '../../../../components/tables/accessioning/table-search-filters-form';

const projectedFields = [
	'_id',
	'sample.ruo',
	'sample.priority',
	'sample.assay',
	'customer.first_name',
	'customer.last_name',
	'organization.name',
	'requisition_form.metadata.version',
	'requisition_form.billing_verified',
	'sample.pangea_id',
	'sample.specimen_id',
	'sample.collection_kit_id',
	'sample.accessioning_approval.hold.selected_well.rack_name',
	'sample.accessioning_approval.hold.selected_well.location',
	'patient.first_name',
	'patient.last_name',
	'patient.date_of_birth',
	'requisition_form.sample_collection_date',
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewHeldSamplesTableViewProps {}

export default function ViewHeldSamplesTableView(
	props: ViewHeldSamplesTableViewProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const navigate = useNavigate();

	const [query, setQuery] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [data, setData] = useState<ClinicData[] | undefined>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setDisabled(true);
				setData(undefined);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/accessioner/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query: {
							...query,
							'sample.status': 'hold',
						},
						projected_fields: projectedFields,
					}
				);

				if (!unmounted) {
					console.log(JSON.parse(data), total_entries);

					setData(JSON.parse(data));
					setPagination((prev) => ({
						...prev,
						total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);

			toast.dismiss();
		};

		fetchData();

		return () => {
			toast.dismiss();
			unmounted = true;
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	return (
		<Container>
			<ContentWrapper
				heading="Samples on Hold"
				Icon="ArchiveBoxArrowDownIcon"
			>
				<TableSearchFiltersForm
					tableType="hold"
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection
					heading="All samples on hold"
					description="Switch to rack view to see the well location of each held sample."
					headingAction={
						<Button
							type="button"
							tier="tertiary"
							text="Rack View"
							Icon="Squares2X2Icon"
							onClick={() =>
								navigate('/accessioner/view/held-samples/rack')
							}
						/>
					}
				>
					<HeldSamplesTable
						data={data}
						query={{ query, setQuery }}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}

interface HeldSamplesTableProps {
	query: QueryState;
	data: ClinicData[] | undefined;
	pagination: PaginationState;
}

const HeldSamplesTable = (props: HeldSamplesTableProps) => {
	const navigate = useNavigate();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Actions</HeaderCell>
				<HeaderCell>Billing Verified?</HeaderCell>
				<HeaderCell>Rack Name</HeaderCell>
				<HeaderCell>Well</HeaderCell>
				<HeaderCell>Assay</HeaderCell>
				<HeaderCell>Priority Level</HeaderCell>
				<HeaderCell>RUO</HeaderCell>
				<HeaderCell>Customer</HeaderCell>
				<HeaderCell>Organization</HeaderCell>
				<HeaderCell>Pangea ID</HeaderCell>
				<HeaderCell>Specimen ID</HeaderCell>
				<HeaderCell>Collection Kit ID</HeaderCell>
				<HeaderCell>Patient First Name</HeaderCell>
				<HeaderCell>Patient Last Name</HeaderCell>
				<HeaderCell>Patient Date of Birth</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => (
							<TableRow key={index} index={index}>
								<DataCell>
									<Link
										text="Update"
										path={`/accessioner/accession/trf/${datum.sample.assay}/v${datum.requisition_form.metadata.version}/data/${datum._id.$oid}/trf/review`}
									/>
								</DataCell>
								<DataCell>
									{datum.requisition_form.billing_verified ? (
										<Badge color="green" content="Yes" />
									) : (
										<Badge color="red" content="No" />
									)}
								</DataCell>
								<DataCell>
									{
										datum.sample.accessioning_approval.hold
											.selected_well.rack_name
									}
								</DataCell>
								<DataCell>
									{
										datum.sample.accessioning_approval.hold
											.selected_well.location
									}
								</DataCell>
								<DataCell>
									{getLabelFromValue(
										datum.sample.assay,
										ASSAYS['assays']
									)}
								</DataCell>
								<DataCell>
									{getLabelFromValue(
										datum.sample.priority,
										ORGANIZATIONS.priorityLevels
									)}
								</DataCell>
								<DataCell>
									{datum.sample.ruo === null
										? null
										: datum.sample.ruo
										? 'Yes'
										: 'No'}
								</DataCell>
								<DataCell>
									{datum.customer.first_name.concat(
										' ',
										datum.customer.last_name
									)}
								</DataCell>
								<DataCell>{datum.organization.name}</DataCell>
								<DataCell>
									{datum.sample.pangea_id && (
										<ArrowLinkButton
											text={datum.sample.pangea_id}
											onClick={() =>
												navigate(
													`/accessioner/view/samples/${datum._id.$oid}`
												)
											}
										/>
									)}
								</DataCell>
								<DataCell>{datum.sample.specimen_id}</DataCell>
								<DataCell>
									{datum.sample.collection_kit_id}
								</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.first_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.last_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? formatDate(
												datum.patient.date_of_birth
										  )
										: null}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.requisition_form
											.sample_collection_date
									)}
								</DataCell>
							</TableRow>
						))
					) : (
						<EmptyTable
							Icon="FingerPrintIcon"
							heading="No samples on hold"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
};
