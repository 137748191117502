import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	Fragment,
	useState,
	Dispatch,
	FormEvent,
	useEffect,
	ChangeEvent,
	SetStateAction,
} from 'react';

import {
	Form,
	Modal,
	Button,
	ItemCard,
	EmptyBox,
	useAxios,
	FormGroup,
	FormFooter,
	NumberField,
	SelectField,
} from '@pangea-lis-apps/ui';
import { Item, Order, SHIPPING } from '@pangea-lis-apps/utils';

const initialItemValue = {
	sku: '',
	name: '',
	quantity: 1,
};

interface EditOrderItemsModalProps {
	order: Order;
	role: 'shipping' | 'organization-shared';
	setRefresh: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

export function EditOrderItemsModal(props: EditOrderItemsModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const { visible, setVisible } = props.visible;

	const [disabled, setDisabled] = useState(false);
	const [item, setItem] = useState<Item>(initialItemValue);
	const [form, setForm] = useState<{ items: Item[] }>({
		items: props.order.items,
	});

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios || !user) return;
		else if (!form.items.length) {
			toast.error('Please add items to the order!');
			return;
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(
				`/api/${props.role}/orders/${props.order._id.$oid}/?auth0_user_id=${user.sub}`,
				{
					user,
					update_data: {
						property: 'items',
						new_value: form.items.map((item) => {
							const foundItem = SHIPPING.items.find(
								(x) => x.value === item.sku
							);

							return {
								sku: foundItem?.value,
								name: foundItem?.label,
								quantity: item.quantity,
							};
						}),
						old_value: props.order.items,
					},
				}
			);

			toast.dismiss();

			setVisible(false);
			setDisabled(false);
			props.setRefresh((value) => !value);
		} catch (error) {
			setDisabled(false);
		}
	};

	useEffect(() => {
		if (props.order && visible)
			setForm({
				items: props.order.items,
			});
	}, [props.order, visible]);

	const handleItemChange = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name)
			setItem((prevVal) => ({
				...prevVal,
				[target.name]: target.value,
			}));
	};

	const handleRemoveItem = (sku: string) => {
		setForm((prevVal) => {
			const new_items = prevVal.items.filter((item) => item.sku !== sku);

			return {
				...prevVal,
				items: new_items,
			};
		});
	};

	const handleAddItem = (event: FormEvent) => {
		event.preventDefault();

		const { sku, quantity } = item;

		const items = form.items;
		const idx = items.findIndex((item) => item.sku === sku);

		if (idx === -1) items.push(item);
		else items[idx].quantity = quantity;

		setForm((prevVal) => ({
			...prevVal,
			items: [...items],
		}));

		setItem(initialItemValue);
	};

	return (
		<Modal
			visible={visible}
			customWidth="max-w-3xl"
			title="Edit order items"
			onClose={() => setVisible(false)}
			description="Delete or add items to the order"
		>
			{props.order && (
				<Fragment>
					<Form handleSubmit={handleAddItem}>
						<FormGroup
							heading="Select order items"
							description="Select a kit and a quantity."
						>
							<div className="sm:col-span-2">
								<SelectField
									required
									name="sku"
									label="Name"
									value={item.sku}
									handleSelect={handleItemChange}
									options={[
										{
											value: '',
											label: 'Select an Option',
										},
										...SHIPPING.items
											.filter((item) => item.for_sale)
											.map(({ label, value }) => ({
												label,
												value,
											})),
									]}
								/>
							</div>
							<div className="sm:col-span-1">
								<NumberField
									min={1}
									required
									max={500}
									name="quantity"
									label="Quantity"
									value={item.quantity}
									handleInputChange={handleItemChange}
								/>
							</div>
							<div className="sm:col-span-2 flex items-end">
								<Button
									type="submit"
									Icon="PlusIcon"
									text="Add item"
									tier="tertiary"
								/>
							</div>
						</FormGroup>
					</Form>

					<hr className="my-10" />

					<Form handleSubmit={handleSubmit}>
						<FormGroup
							heading="Selected item(s)"
							description="These are the items that will constitute your order."
						>
							<div className="sm:col-span-6">
								{form.items && form.items.length ? (
									<div className="space-x-2">
										{form.items.map((item, index) => (
											<ItemCard
												item={item}
												key={index}
												handleRemove={handleRemoveItem}
											/>
										))}
									</div>
								) : (
									<EmptyBox
										Icon="PlusSmallIcon"
										heading="No items"
										description="Add items from below to get started"
									/>
								)}
							</div>
						</FormGroup>

						<FormFooter>
							<Button
								type="submit"
								text="Update"
								tier="tertiary"
								Icon="CheckIcon"
								disabled={disabled}
							/>
						</FormFooter>
					</Form>
				</Fragment>
			)}
		</Modal>
	);
}

export default EditOrderItemsModal;
