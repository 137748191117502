import toast from 'react-hot-toast';
import { useId, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
	Table,
	TableRow,
	useAxios,
	DataCell,
	TableBody,
	TableHead,
	EmptyTable,
	TextButton,
	HeaderCell,
	TableLoading,
	HeaderCellWithSearch,
} from '@pangea-lis-apps/ui';
import {
	SHARED,
	QueryState,
	PaginationState,
	BaseHydratedCustomer,
} from '@pangea-lis-apps/utils';

import { useOrganizations } from '../../../../utils/hooks/index';

interface AddMembersTableProps {
	query: QueryState;
	pagination: PaginationState;
	data: BaseHydratedCustomer[] | undefined;
}

export default function AddMembersTable(props: AddMembersTableProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const navigate = useNavigate();
	const { organizationId } = useParams();
	const { query, setQuery } = props.query;

	const [disabled, setDisabled] = useState(false);

	const organizations = useOrganizations();

	const handleAddMember = async (customerId: string) => {
		if (disabled || !axios) return;

		setDisabled(true);

		toast.loading('Adding member...', toastOptions);

		try {
			await (
				await axios
			).patch(
				`/api/accounts/organizations/${organizationId}/members/add/${customerId}`,
				null
			);

			toast.dismiss();

			navigate(`/accounts/view/organizations/${organizationId}`);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCellWithSearch
					type="text"
					name="national_provider_identifier"
					label="NPI"
					query={query}
					property="national_provider_identifier"
					setQuery={setQuery}
				/>
				<HeaderCellWithSearch
					type="text"
					name="first_name"
					label="First Name"
					query={query}
					property="first_name"
					setQuery={setQuery}
				/>
				<HeaderCellWithSearch
					type="text"
					name="last_name"
					label="Last Name"
					query={query}
					property="last_name"
					setQuery={setQuery}
				/>
				<HeaderCellWithSearch
					type="combobox"
					query={query}
					name="organization"
					label="Organization"
					setQuery={setQuery}
					property="organization._id"
					options={organizations || SHARED.no_options}
				/>
				<HeaderCell>Actions</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((customer, index) => (
							<TableRow key={index} index={index}>
								<DataCell>
									{customer.national_provider_identifier}
								</DataCell>
								<DataCell>{customer.first_name}</DataCell>
								<DataCell>{customer.last_name}</DataCell>
								<DataCell>
									{customer.organization &&
										customer.organization.name}
								</DataCell>
								<DataCell>
									{!customer.organization ? (
										<TextButton
											text="Add"
											color="blue"
											type="button"
											onClick={() =>
												handleAddMember(
													customer._id.$oid
												)
											}
										/>
									) : null}
								</DataCell>
							</TableRow>
						))
					) : (
						<EmptyTable
							colSpan={4}
							Icon="UserGroupIcon"
							heading="No customers"
							description="Start by creating a customer"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
