import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useId, Fragment, useState, useEffect } from 'react';

import {
	Button,
	useAxios,
	Container,
	TextButton,
	LoadingBox,
	FormFooter,
	EditDataModal,
	ContentWrapper,
	ContentSection,
	DescriptionItem,
	DescriptionList,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	Option,
	Auth0User,
	Attribute,
	initialAttributeValue,
} from '@pangea-lis-apps/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProfileProps {}

export function Profile(props: ProfileProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user: auth0_user, logout } = useAuth0();
	const apiPrefix =
		process.env['NX_PORTAL_TYPE'] === 'CUSTOMER'
			? '/api/organization-user'
			: '/api/user';

	const [refresh, setRefresh] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [user, setUser] = useState<Auth0User | undefined>(undefined);
	const [attribute, setAttribute] = useState<Attribute>(
		initialAttributeValue
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !auth0_user) return;

			setUser(undefined);

			try {
				const {
					data: { data },
				} = await (await axios).get(`${apiPrefix}/user-info`);

				if (!unmounted) {
					setUser(JSON.parse(data));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [apiPrefix, axios, refresh, auth0_user]);

	const handleClick = (
		property: string,
		value: string,
		label: string,
		type: 'text' | 'select' | 'date' | 'multiple_select' | 'file' | 'email',
		selectOptions?: Option[],
		selectLabel?: string
	) => {
		setAttribute({
			property,
			value,
			type,
			label,
			selectOptions,
			selectLabel,
		});
		setModalVisible(true);
	};

	const handleChangePassword = async () => {
		if (!axios) return;

		toast.loading('Loading...', toastOptions);

		try {
			await (await axios).patch(`${apiPrefix}/change-password`, null);

			toast.success(
				'Check your email to reset your password!',
				toastOptions
			);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Fragment>
			<EditDataModal
				data={user}
				attribute={attribute}
				setRefresh={setRefresh}
				heading="Edit info"
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
				endpoint={`${apiPrefix}/user-info`}
			/>
			<Container>
				<ContentWrapper>
					{!(auth0_user && user) ? (
						<LoadingBox />
					) : (
						<Fragment>
							<ContentSection>
								<div className="pt-8">
									<h1 className="text-2xl font-bold text-gray-900">
										{user.user_metadata['first_name']}{' '}
										{user.user_metadata['last_name']}
									</h1>
								</div>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection heading="Info">
								<DescriptionList>
									<DescriptionItem
										withFunction
										Icon="PencilIcon"
										term="First Name"
										details={
											user.user_metadata['first_name']
										}
										handleClick={() =>
											handleClick(
												'first_name',
												user.user_metadata[
													'first_name'
												],
												'First Name',
												'text'
											)
										}
									/>
									<DescriptionItem
										withFunction
										Icon="PencilIcon"
										term="Last Name"
										details={
											user.user_metadata['last_name']
										}
										handleClick={() =>
											handleClick(
												'last_name',
												user.user_metadata['last_name'],
												'Last Name',
												'text'
											)
										}
									/>
									<div className="sm:col-span-1"></div>
									<DescriptionItem
										withFunction
										Icon="PencilIcon"
										term="Email Address"
										details={user.email}
										handleClick={() =>
											handleClick(
												'email_address',
												user.email,
												'Email Address',
												'email'
											)
										}
									/>
									<DescriptionItem term="Password">
										<TextButton
											color="blue"
											type="button"
											text="Change"
											onClick={handleChangePassword}
										/>
									</DescriptionItem>
								</DescriptionList>
							</ContentSection>
						</Fragment>
					)}

					<FormFooter>
						<Button
							type="button"
							text="Log Out"
							tier="tertiary"
							Icon="ArrowRightOnRectangleIcon"
							onClick={() =>
								logout({
									returnTo: window.location.origin,
								})
							}
						/>
					</FormFooter>
				</ContentWrapper>
			</Container>
		</Fragment>
	);
}

export default Profile;
