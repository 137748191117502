import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useId, useState, useEffect } from 'react';

import {
	Button,
	useAxios,
	Container,
	ContentWrapper,
	ContentSection,
} from '@pangea-lis-apps/ui';
import {
	Query,
	Pagination,
	HydratedOrder,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import AllOrdersTable from '../../../components/tables/shipping/all-orders';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewOrdersProps {}

export default function ViewOrders(props: ViewOrdersProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();

	const [query, setQuery] = useState<Query>({ status: 'pending' });
	const [orders, setOrders] = useState<HydratedOrder[] | undefined>(
		undefined
	);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/shipping/search/orders/?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					query
				);

				if (!unmounted) {
					const orders = JSON.parse(data);
					console.log(orders);

					setOrders(orders);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			toast.dismiss();
			unmounted = true;
		};
	}, [
		user,
		query,
		axios,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	const handleTestSentryIntegration = () => {
		throw new Error('Test Error');
	};

	return (
		<Container>
			<ContentWrapper
				Icon="TruckIcon"
				heading="View Orders"
				description="All orders made by customers."
			>
				{/* {process.env['NX_ENV'] === 'TESTING' && (
					<div className="text-right bg-gray-50 border border-gray-200 p-6">
						<Button
							type="button"
							tier="tertiary"
							Icon="CheckIcon"
							text="Test Sentry Integration"
							onClick={handleTestSentryIntegration}
						/>
					</div>
				)} */}

				<ContentSection
					id="orders_table"
					heading="All orders"
					description="Filter by shipping status to see orders of a specific status. By default, the table shows all pending orders that need to be fulfilled."
				>
					<AllOrdersTable
						query={{ query, setQuery }}
						data={orders}
						pagination={{
							pagination,
							setPagination,
						}}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
