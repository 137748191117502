import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useId, useState, useEffect } from 'react';

import {
	Table,
	DataCell,
	useAxios,
	TableRow,
	TableBody,
	Container,
	TableHead,
	HeaderCell,
	EmptyTable,
	TableLoading,
	ContentWrapper,
	ContentSection,
	ArrowLinkButton,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ASSAYS,
	ClinicData,
	Pagination,
	formatDate,
	ORGANIZATIONS,
	NonClinicData,
	PaginationState,
	getLabelFromValue,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';
import TableSearchFiltersForm from '../../../components/tables/accessioning/table-search-filters-form';

const projectedFields = [
	'sample.ruo',
	'sample.assay',
	'sample.priority',
	'sample.sample_received_date',
	'organization.name',
	'customer.first_name',
	'customer.last_name',
	'sample.pangea_id',
	'_id',
	'sample.specimen_id',
	'sample.collection_kit_id',
	'patient.first_name',
	'patient.last_name',
	'patient.date_of_birth',
	'sample.sample_collection_date',
];

/* eslint-disable-next-line */
export interface ViewOverdueSamplesProps {}

export default function ViewOverdueSamples(props: ViewOverdueSamplesProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [data, setData] = useState<
		(ClinicData | NonClinicData)[] | undefined
	>(undefined);
	const [query, setQuery] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setDisabled(true);
				setData(undefined);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/accessioner/search/samples/overdue?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query,
						projected_fields: projectedFields,
					}
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setData(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);

			toast.dismiss();
		};

		fetchData();

		return () => {
			toast.dismiss();
			unmounted = true;
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	return (
		<Container>
			<ContentWrapper
				Icon="CalendarDaysIcon"
				heading="Overdue Samples"
				description="Samples that did not meet the turnaround time (TAT)."
			>
				<TableSearchFiltersForm
					tableType="overdue"
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection heading="Overdue samples">
					<OverdueSamplesTable
						data={data}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}

interface OverdueSamplesTableProps {
	pagination: PaginationState;
	data: (ClinicData | NonClinicData)[] | undefined;
}

export function OverdueSamplesTable(props: OverdueSamplesTableProps) {
	const navigate = useNavigate();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Received Date</HeaderCell>
				<HeaderCell>Days since received</HeaderCell>
				<HeaderCell>Assay</HeaderCell>
				<HeaderCell>Priority Level</HeaderCell>
				<HeaderCell>RUO</HeaderCell>
				<HeaderCell>Customer</HeaderCell>
				<HeaderCell>Organization</HeaderCell>
				<HeaderCell>Pangea ID</HeaderCell>
				<HeaderCell>Specimen ID</HeaderCell>
				<HeaderCell>Collection Kit ID</HeaderCell>
				<HeaderCell>Patient First Name</HeaderCell>
				<HeaderCell>Patient Last Name</HeaderCell>
				<HeaderCell>Patient Date of Birth</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => {
							const daysSinceCollection = datum.sample
								.sample_received_date
								? `${Math.round(
										(new Date().getTime() -
											new Date(
												datum.sample.sample_received_date
											).getTime()) /
											(1000 * 3600 * 24)
								  )} Days`
								: 'N/A';

							return (
								<TableRow key={index} index={index}>
									<DataCell>
										{formatDate(
											datum.sample.sample_received_date
										)}
									</DataCell>
									<DataCell>{daysSinceCollection}</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.assay,
											ASSAYS['assays']
										)}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.priority,
											ORGANIZATIONS.priorityLevels
										)}
									</DataCell>
									<DataCell>
										{datum.sample.ruo === null
											? null
											: datum.sample.ruo
											? 'Yes'
											: 'No'}
									</DataCell>
									<DataCell>
										{datum.customer.first_name.concat(
											' ',
											datum.customer.last_name
										)}
									</DataCell>
									<DataCell>
										{datum.organization.name}
									</DataCell>
									<DataCell>
										{datum.sample.pangea_id && (
											<ArrowLinkButton
												text={datum.sample.pangea_id}
												onClick={() =>
													navigate(
														`/accessioner/view/samples/${datum._id.$oid}`
													)
												}
											/>
										)}
									</DataCell>
									<DataCell>
										{datum.sample.specimen_id}
									</DataCell>
									<DataCell>
										{datum.sample.collection_kit_id}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.first_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.last_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? formatDate(
													datum.patient.date_of_birth
											  )
											: null}
									</DataCell>
									<DataCell>
										{formatDate(
											datum.sample.sample_collection_date
										)}
									</DataCell>
								</TableRow>
							);
						})
					) : (
						<EmptyTable
							Icon="FingerPrintIcon"
							heading="No overdue samples"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
