/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	Fragment,
	useState,
	useEffect,
	useContext,
	ChangeEvent,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	FormGroup,
	LoadingBox,
	SelectField,
	GenericField,
	ContentSection,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	Option,
	BLADDERCARE,
	REQUISITION_FORM,
} from '@pangea-lis-apps/utils';

import { usePopulateFormValues } from '../../utils/helpers';
import { ProcessTRFStepsContext } from '../../components/form-wrapper';
import FlagButton from '../../../../../../../../components/flag-button';

export interface FormValues {
	[key: string]:
		| string
		| boolean
		| { [key: string]: boolean | undefined }
		| undefined;
	billing_verified: string;
	billing_method: string;
	billing_client_existing_client_billing_method: string;
	billing_patient_payment_received: string;
	billing_patient_payment_payee: string;
	billing_client_client_name: string;
	billing_client_client_institution: string;
	billing_client_billing_contact_name: string;
	billing_client_billing_contact_institution: string;
	billing_client_billing_contact_address: string;
	billing_client_billing_contact_city: string;
	billing_client_billing_contact_state: string;
	billing_client_billing_contact_zip_code: string;
	billing_client_billing_contact_country: string;
	billing_client_billing_contact_phone_number: string;
	billing_client_billing_contact_fax_number: string;
	billing_client_billing_contact_email_address: string;
	billing_insurance_name: string;
	billing_insurance_address: string;
	billing_insurance_city: string;
	billing_insurance_state: string;
	billing_insurance_zip_code: string;
	billing_insurance_country: string;
	billing_insurance_phone_number: string;
	billing_insurance_fax_number: string;
	billing_insurance_email_address: string;
	billing_insurance_subscriber_first_name: string;
	billing_insurance_subscriber_last_name: string;
	billing_insurance_subscriber_policy_number: string;
	billing_insurance_subscriber_group_number: string;
	flag: {
		[key: string]: boolean | undefined;
		billing_verified: boolean;
		billing_method: boolean;
		billing_patient_payment_received: boolean;
		billing_patient_payment_payee: boolean;
		billing_client_client_name: boolean;
		billing_client_client_institution: boolean;
		billing_client_billing_contact_name: boolean;
		billing_client_billing_contact_institution: boolean;
		billing_client_billing_contact_address: boolean;
		billing_client_billing_contact_city: boolean;
		billing_client_billing_contact_state: boolean;
		billing_client_billing_contact_zip_code: boolean;
		billing_client_billing_contact_country: boolean;
		billing_client_billing_contact_phone_number: boolean;
		billing_client_billing_contact_fax_number: boolean;
		billing_client_billing_contact_email_address: boolean;
		billing_insurance_name: boolean;
		billing_insurance_address: boolean;
		billing_insurance_city: boolean;
		billing_insurance_state: boolean;
		billing_insurance_zip_code: boolean;
		billing_insurance_country: boolean;
		billing_insurance_phone_number: boolean;
		billing_insurance_fax_number?: boolean;
		billing_insurance_email_address?: boolean;
		billing_insurance_subscriber_first_name: boolean;
		billing_insurance_subscriber_last_name: boolean;
		billing_insurance_subscriber_policy_number: boolean;
		billing_insurance_subscriber_group_number: boolean;
	};
}

const initialFormValues = {
	billing_verified: '',
	billing_method: '',
	billing_client_existing_client_billing_method: '',
	billing_patient_payment_received: '',
	billing_patient_payment_payee: '',
	billing_client_client_name: '',
	billing_client_client_institution: '',
	billing_client_billing_contact_name: '',
	billing_client_billing_contact_institution: '',
	billing_client_billing_contact_address: '',
	billing_client_billing_contact_city: '',
	billing_client_billing_contact_state: '',
	billing_client_billing_contact_zip_code: '',
	billing_client_billing_contact_country: '',
	billing_client_billing_contact_phone_number: '',
	billing_client_billing_contact_fax_number: '',
	billing_client_billing_contact_email_address: '',
	billing_insurance_name: '',
	billing_insurance_address: '',
	billing_insurance_city: '',
	billing_insurance_state: '',
	billing_insurance_zip_code: '',
	billing_insurance_country: '',
	billing_insurance_phone_number: '',
	billing_insurance_fax_number: '',
	billing_insurance_email_address: '',
	billing_insurance_subscriber_first_name: '',
	billing_insurance_subscriber_last_name: '',
	billing_insurance_subscriber_policy_number: '',
	billing_insurance_subscriber_group_number: '',
	flag: {
		billing_verified: false,
		billing_method: false,
		billing_patient_payment_received: false,
		billing_patient_payment_payee: false,
		billing_client_client_name: false,
		billing_client_client_institution: false,
		billing_client_billing_contact_name: false,
		billing_client_billing_contact_institution: false,
		billing_client_billing_contact_address: false,
		billing_client_billing_contact_city: false,
		billing_client_billing_contact_state: false,
		billing_client_billing_contact_zip_code: false,
		billing_client_billing_contact_country: false,
		billing_client_billing_contact_phone_number: false,
		billing_client_billing_contact_fax_number: false,
		billing_client_billing_contact_email_address: false,
		billing_insurance_name: false,
		billing_insurance_address: false,
		billing_insurance_city: false,
		billing_insurance_state: false,
		billing_insurance_zip_code: false,
		billing_insurance_country: false,
		billing_insurance_phone_number: false,
		billing_insurance_fax_number: false,
		billing_insurance_email_address: false,
		billing_insurance_subscriber_first_name: false,
		billing_insurance_subscriber_last_name: false,
		billing_insurance_subscriber_policy_number: false,
		billing_insurance_subscriber_group_number: false,
	},
};

const initialDropdownSelections = {
	billing_insurance_name: '',
	billing_client_billing_contact_state: '',
	billing_insurance_state: '',
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BillingInfoProps {}

export default function BillingInfo(props: BillingInfoProps) {
	const {
		data,
		formId,
		formValues,
		handleSubmit,
		formValuesRef,
		setFormValues,
	} = useContext(ProcessTRFStepsContext);

	usePopulateFormValues(
		data,
		formValuesRef,
		setFormValues,
		initialFormValues,
		'requisition_form'
	);

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name)
			setFormValues((prevValues: FormValues) => {
				if (prevValues) {
					const value = target.value;

					if (target.name === 'billing_method') {
						// Reset all the values
						setDropdownSelections(initialDropdownSelections);

						return {
							...initialFormValues,
							[target.name]: value,
						};
					} else if (
						target.name ===
							'billing_client_existing_client_billing_method' &&
						data
					) {
						if (target.value) {
							const billing_method =
								data.customer.billing_methods.filter(
									(method) => method._id.$oid === target.value
								)[0];

							return {
								...prevValues,
								[target.name]: target.value,
								billing_client_client_name:
									billing_method.client_name,
								billing_client_client_institution:
									billing_method.client_institution,
								billing_client_billing_contact_name:
									billing_method.billing_contact_name,
								billing_client_billing_contact_institution:
									billing_method.billing_contact_institution,
								billing_client_billing_contact_address:
									billing_method.billing_contact_address,
								billing_client_billing_contact_city:
									billing_method.billing_contact_city,
								billing_client_billing_contact_state:
									billing_method.billing_contact_state,
								billing_client_billing_contact_zip_code:
									billing_method.billing_contact_zip_code,
								billing_client_billing_contact_country:
									billing_method.billing_contact_country,
								billing_client_billing_contact_phone_number:
									billing_method.billing_contact_phone_number,
								billing_client_billing_contact_fax_number:
									billing_method.billing_contact_fax_number,
								billing_client_billing_contact_email_address:
									billing_method.billing_contact_email_address,
							};
						}

						return {
							...prevValues,
							[target.name]: target.value,
							billing_client_client_name: '',
							billing_client_client_institution: '',
							billing_client_billing_contact_name: '',
							billing_client_billing_contact_institution: '',
							billing_client_billing_contact_address: '',
							billing_client_billing_contact_city: '',
							billing_client_billing_contact_state: '',
							billing_client_billing_contact_zip_code: '',
							billing_client_billing_contact_country: '',
							billing_client_billing_contact_phone_number: '',
							billing_client_billing_contact_fax_number: '',
							billing_client_billing_contact_email_address: '',
						};
					}

					return {
						...prevValues,
						[target.name]: value,
					};
				}

				return prevValues;
			});
	};

	const [dropdownSelections, setDropdownSelections] = useState(
		initialDropdownSelections
	);

	useEffect(() => {
		if (formValues) {
			// billing insurance name
			if (!dropdownSelections['billing_insurance_name']) {
				if (
					!REQUISITION_FORM[
						'shared'
					].billing_common_insurance_companies
						.map((option: Option) => option.value)
						.includes(formValues['billing_insurance_name'])
				)
					setDropdownSelections((prevVal) => ({
						...prevVal,
						billing_insurance_name: 'other',
					}));
				else
					setDropdownSelections((prevVal) => ({
						...prevVal,
						billing_insurance_name:
							formValues['billing_insurance_name'],
					}));
			}

			// billing info - client's billing contact address, state
			if (!dropdownSelections['billing_client_billing_contact_state']) {
				if (
					!FORMS.states
						.map((option: Option) => option.value)
						.includes(
							formValues['billing_client_billing_contact_state']
						)
				)
					setDropdownSelections((prevVal) => ({
						...prevVal,
						billing_client_billing_contact_state: 'other',
					}));
				else
					setDropdownSelections((prevVal) => ({
						...prevVal,
						billing_client_billing_contact_state:
							formValues['billing_client_billing_contact_state'],
					}));
			}

			// billing info - insurance address, state
			if (!dropdownSelections['billing_insurance_state']) {
				if (
					!FORMS.states
						.map((option: Option) => option.value)
						.includes(formValues['billing_insurance_state'])
				)
					setDropdownSelections((prevVal) => ({
						...prevVal,
						billing_insurance_state: 'other',
					}));
				else
					setDropdownSelections((prevVal) => ({
						...prevVal,
						billing_insurance_state:
							formValues['billing_insurance_state'],
					}));
			}
		}
	}, [
		formValues,
		dropdownSelections['billing_insurance_name'],
		dropdownSelections['billing_client_billing_contact_state'],
		dropdownSelections['billing_insurance_state'],
	]);

	const handleDropdownSelectionChange: ChangeEventHandler<
		HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setDropdownSelections((prevValue) => ({
				...prevValue,
				[target.name]: target.value,
			}));

			setFormValues((prevValues: FormValues) => {
				if (prevValues) {
					return {
						...prevValues,
						[target.name]:
							target.value === 'other' ? '' : target.value,
					};
				}

				return prevValues;
			});
		}
	};

	return !formValues || !data ? (
		<LoadingBox />
	) : (
		<ContentSection>
			<Form id={formId} handleSubmit={handleSubmit}>
				<FormGroup heading="Billing information">
					<div className="sm:col-span-2">
						<SelectField
							name="billing_method"
							label="Billing Method"
							showRequiredAsterisk={true}
							value={formValues['billing_method']}
							handleSelect={handleChange}
							options={
								BLADDERCARE['4.1'][
									'billing_information_payer_options'
								]
							}
							fieldAction={
								<FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="billing_method"
								/>
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<SelectField
							name="billing_verified"
							label="Billing Verified?"
							showRequiredAsterisk={true}
							value={formValues['billing_verified']}
							handleSelect={handleChange}
							options={FORMS.yes_no_options}
							fieldAction={
								<FlagButton
									formValues={formValues}
									setFormValues={setFormValues}
									property="billing_verified"
								/>
							}
						/>
					</div>
				</FormGroup>
				{formValues['billing_method'] === 'client' && (
					<Fragment>
						<FormGroup
							heading="Client information"
							description="Choose from the existing billing methods. New billing methods will automatically be saved to the provider's customer profile. For new billing methods, you must provide the client's name and the billing contact's institution or company name."
						>
							{formValues['billing_method'] === 'client' && (
								<Fragment>
									<div className="sm:col-span-2">
										<SelectField
											label="Existing client billing methods"
											name="billing_client_existing_client_billing_method"
											value={
												formValues[
													'billing_client_existing_client_billing_method'
												]
											}
											handleSelect={handleChange}
											options={(() => {
												if (
													data.customer
														.billing_methods &&
													data.customer
														.billing_methods.length
												)
													return [
														{
															value: '',
															label: 'Select an Option',
														},
														...data.customer.billing_methods.map(
															(
																billing_method
															) => ({
																label:
																	billing_method.client_name +
																	' - ' +
																	billing_method.billing_contact_institution,
																value: billing_method
																	._id.$oid,
															})
														),
													];

												return [
													{
														value: '',
														label: 'None available',
													},
												];
											})()}
										/>
									</div>
									<div className="sm:col-span-4"></div>
								</Fragment>
							)}
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="Name"
									showRequiredAsterisk={true}
									name="billing_client_client_name"
									placeholder="e.g., George Harman"
									value={
										formValues['billing_client_client_name']
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_client_name"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									showRequiredAsterisk={true}
									label="Institution or Company"
									name="billing_client_client_institution"
									placeholder="e.g., City of Irvine"
									value={
										formValues[
											'billing_client_client_institution'
										]
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_client_institution"
										/>
									}
								/>
							</div>
						</FormGroup>

						<FormGroup heading="Client's billing contact information">
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="Name"
									name="billing_client_billing_contact_name"
									placeholder="e.g., Alex Weisman"
									value={
										formValues[
											'billing_client_billing_contact_name'
										]
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_name"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									showRequiredAsterisk={true}
									label="Institution or Company"
									name="billing_client_billing_contact_institution"
									placeholder="e.g., City of Irvine"
									value={
										formValues[
											'billing_client_billing_contact_institution'
										]
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_institution"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2"></div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="Address"
									showRequiredAsterisk={true}
									name="billing_client_billing_contact_address"
									placeholder="e.g., 1234 Orange Ave."
									value={
										formValues[
											'billing_client_billing_contact_address'
										]
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_address"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-4"></div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="City"
									showRequiredAsterisk={true}
									name="billing_client_billing_contact_city"
									placeholder="e.g., Irvine"
									value={
										formValues[
											'billing_client_billing_contact_city'
										]
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_city"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2">
								<SelectField
									label="State"
									showRequiredAsterisk={true}
									name="billing_client_billing_contact_state"
									value={
										dropdownSelections[
											'billing_client_billing_contact_state'
										]
									}
									options={FORMS.states}
									handleSelect={handleDropdownSelectionChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_state"
										/>
									}
								/>
							</div>
							{dropdownSelections[
								'billing_client_billing_contact_state'
							] === 'other' ? (
								<div className="sm:col-span-2">
									<GenericField
										required
										type="text"
										label="If other, please specify"
										name="billing_client_billing_contact_state"
										placeholder="e.g., New York"
										value={
											formValues[
												'billing_client_billing_contact_state'
											]
										}
										handleInputChange={handleChange}
									/>
								</div>
							) : (
								<div className="sm:col-span-2"></div>
							)}
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="ZIP Code"
									showRequiredAsterisk={true}
									name="billing_client_billing_contact_zip_code"
									placeholder="e.g., 92780"
									value={
										formValues[
											'billing_client_billing_contact_zip_code'
										]
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_zip_code"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2">
								<SelectField
									label="Country"
									options={FORMS.countries}
									handleSelect={handleChange}
									showRequiredAsterisk={true}
									name="billing_client_billing_contact_country"
									value={
										formValues[
											'billing_client_billing_contact_country'
										]
									}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_country"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									showRequiredAsterisk={true}
									label="Primary Phone Number"
									name="billing_client_billing_contact_phone_number"
									placeholder="e.g., 2137893645"
									value={
										formValues[
											'billing_client_billing_contact_phone_number'
										]
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_phone_number"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="Fax Number"
									name="billing_client_billing_contact_fax_number"
									placeholder="e.g., 2137893645"
									value={
										formValues[
											'billing_client_billing_contact_fax_number'
										] || ''
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_fax_number"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="Email Address"
									name="billing_client_billing_contact_email_address"
									placeholder="e.g., cityofirvine@gmail.com"
									value={
										formValues[
											'billing_client_billing_contact_email_address'
										] || ''
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_client_billing_contact_email_address"
										/>
									}
								/>
							</div>
						</FormGroup>
					</Fragment>
				)}
				{formValues['billing_method'] === 'insurance' && (
					<Fragment>
						<FormGroup heading="Insurance information">
							<div className="sm:col-span-2">
								<SelectField
									showRequiredAsterisk={true}
									name="billing_insurance_name"
									label="Name"
									value={
										dropdownSelections[
											'billing_insurance_name'
										]
									}
									handleSelect={handleDropdownSelectionChange}
									options={
										REQUISITION_FORM['shared']
											.billing_common_insurance_companies
									}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_insurance_name"
										/>
									}
								/>
							</div>
							{dropdownSelections['billing_insurance_name'] ===
							'other' ? (
								<Fragment>
									<div className="sm:col-span-2">
										<GenericField
											required
											type="text"
											label="If other, please specify"
											name="billing_insurance_name"
											placeholder="e.g., City of Irvine"
											value={
												formValues[
													'billing_insurance_name'
												]
											}
											handleInputChange={handleChange}
										/>
									</div>
									<div className="sm:col-span-2"></div>
								</Fragment>
							) : (
								<div className="sm:col-span-4"></div>
							)}
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="Address"
									name="billing_insurance_address"
									placeholder="e.g., 1234 Orange Ave."
									value={
										formValues['billing_insurance_address']
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_insurance_address"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-4"></div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="City"
									name="billing_insurance_city"
									placeholder="e.g., Irvine"
									value={formValues['billing_insurance_city']}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_insurance_city"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2">
								<SelectField
									label="State"
									name="billing_insurance_state"
									value={
										dropdownSelections[
											'billing_insurance_state'
										]
									}
									options={FORMS.states}
									handleSelect={handleDropdownSelectionChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_insurance_state"
										/>
									}
								/>
							</div>
							{dropdownSelections['billing_insurance_state'] ===
							'other' ? (
								<div className="sm:col-span-2">
									<GenericField
										required
										type="text"
										label="If other, please specify"
										name="billing_insurance_state"
										placeholder="e.g., New York"
										value={
											formValues[
												'billing_insurance_state'
											]
										}
										handleInputChange={handleChange}
									/>
								</div>
							) : (
								<div className="sm:col-span-2"></div>
							)}
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="ZIP Code"
									name="billing_insurance_zip_code"
									placeholder="e.g., 92780"
									value={
										formValues['billing_insurance_zip_code']
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_insurance_zip_code"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2">
								<SelectField
									label="Country"
									options={FORMS.countries}
									handleSelect={handleChange}
									name="billing_insurance_country"
									value={
										formValues['billing_insurance_country']
									}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_insurance_country"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="Primary Phone Number"
									showRequiredAsterisk={true}
									name="billing_insurance_phone_number"
									placeholder="e.g., 2137893645"
									value={
										formValues[
											'billing_insurance_phone_number'
										]
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_insurance_phone_number"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="Fax Number"
									name="billing_insurance_fax_number"
									placeholder="e.g., 2137893645"
									value={
										formValues[
											'billing_insurance_fax_number'
										] || ''
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_insurance_fax_number"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="Email Address"
									name="billing_insurance_email_address"
									placeholder="e.g., cityofirvine@gmail.com"
									value={
										formValues[
											'billing_insurance_email_address'
										] || ''
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_insurance_email_address"
										/>
									}
								/>
							</div>
						</FormGroup>
						<FormGroup heading="Subscriber information">
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="First Name"
									name="billing_insurance_subscriber_first_name"
									placeholder="e.g., Edward"
									value={
										formValues[
											'billing_insurance_subscriber_first_name'
										]
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_insurance_subscriber_first_name"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="Last Name"
									name="billing_insurance_subscriber_last_name"
									placeholder="e.g., Garcia"
									value={
										formValues[
											'billing_insurance_subscriber_last_name'
										]
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_insurance_subscriber_last_name"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2"></div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="Policy Number"
									showRequiredAsterisk={true}
									name="billing_insurance_subscriber_policy_number"
									placeholder="e.g., XZ1234567890"
									value={
										formValues[
											'billing_insurance_subscriber_policy_number'
										]
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_insurance_subscriber_policy_number"
										/>
									}
								/>
							</div>
							<div className="sm:col-span-2">
								<GenericField
									type="text"
									label="Group Number"
									name="billing_insurance_subscriber_group_number"
									placeholder="e.g., XZ1234567890"
									value={
										formValues[
											'billing_insurance_subscriber_group_number'
										]
									}
									handleInputChange={handleChange}
									fieldAction={
										<FlagButton
											formValues={formValues}
											setFormValues={setFormValues}
											property="billing_insurance_subscriber_group_number"
										/>
									}
								/>
							</div>
						</FormGroup>
					</Fragment>
				)}
				{formValues['billing_method'] === 'patient_self_pay' && (
					<FormGroup heading="Payment Verification">
						<div className="sm:col-span-2">
							<SelectField
								required
								showRequiredAsterisk={true}
								name="billing_patient_payment_received"
								label="Patient Payment Received?"
								handleSelect={handleChange}
								value={
									formValues[
										'billing_patient_payment_received'
									]
								}
								options={FORMS.yes_no_options}
								fieldAction={
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_patient_payment_received"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<SelectField
								required
								disabled={
									formValues[
										'billing_patient_payment_received'
									] !== 'true'
								}
								showRequiredAsterisk={true}
								name="billing_patient_payment_payee"
								label="Payment payee"
								handleSelect={handleChange}
								value={
									formValues['billing_patient_payment_payee']
								}
								options={
									REQUISITION_FORM['shared']
										.patient_payment_payee_options
								}
								fieldAction={
									<FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="billing_patient_payment_payee"
									/>
								}
							/>
						</div>
					</FormGroup>
				)}
			</Form>
		</ContentSection>
	);
}
