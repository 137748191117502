import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useId, useState, useEffect } from 'react';

import {
	Link,
	Table,
	Button,
	DataCell,
	TableRow,
	useAxios,
	Container,
	TableHead,
	TableBody,
	EmptyTable,
	HeaderCell,
	TableLoading,
	ContentSection,
	ContentWrapper,
	HeaderCellWithSearch,
} from '@pangea-lis-apps/ui';
import {
	Query,
	QueryState,
	Pagination,
	Organization,
	ORGANIZATIONS,
	PaginationState,
	getLabelFromValue,
	getRoleFromPathname,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

const projectedFields = ['_id', 'name', 'entity'];

/* eslint-disable-next-line */
export interface ViewOrganizationsProps {}

export default function ViewOrganizations(props: ViewOrganizationsProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();
	const navigate = useNavigate();
	const role = getRoleFromPathname();

	const isAccounts =
		user && user['custom_claims/roles'].includes('BITSS_accounts');
	const isSuperAdmin =
		user && user['custom_claims/roles'].includes('BITSS_super-admin');

	const [query, setQuery] = useState<Query>({});
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);
	const [organizations, setOrganizations] = useState<
		Organization[] | undefined
	>(undefined);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				toast.loading('Loading...');
				setOrganizations(undefined);
			}

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/${role}/search/organizations?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{ query, projected_fields: projectedFields }
				);

				if (!unmounted) {
					const organizations = JSON.parse(data);
					console.log(organizations);

					setOrganizations(organizations);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			toast.dismiss();
		};

		fetchData();

		return () => {
			toast.dismiss();
			unmounted = true;
		};
	}, [
		role,
		axios,
		query,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	return (
		<Container>
			<ContentWrapper
				Icon="BuildingOffice2Icon"
				heading="View Organizations"
				description="Click on each organization to view specific details."
			>
				<ContentSection
					id="form"
					heading="All organizations"
					description="To add members to an organization, click on Details to get started."
					headingAction={
						(isAccounts || isSuperAdmin) && (
							<Button
								type="button"
								tier="tertiary"
								Icon="PlusIcon"
								text="Create organization"
								onClick={() =>
									navigate('/accounts/create/organization')
								}
							/>
						)
					}
				>
					<AllOrganizationsTable
						data={organizations}
						query={{ query, setQuery }}
						pagination={{
							pagination,
							setPagination,
						}}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}

interface AllOrganizationsTableProps {
	query: QueryState;
	pagination: PaginationState;
	data: Organization[] | undefined;
}

function AllOrganizationsTable(props: AllOrganizationsTableProps) {
	const role = getRoleFromPathname();
	const { query, setQuery } = props.query;

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCellWithSearch
					type="text"
					name="name"
					label="Name"
					query={query}
					property="name"
					setQuery={setQuery}
				/>
				<HeaderCellWithSearch
					type="select"
					name="entity"
					label="Entity"
					query={query}
					property="entity"
					setQuery={setQuery}
					options={ORGANIZATIONS.types}
				/>
				<HeaderCell>Actions</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((organization, index) => (
							<TableRow key={index} index={index}>
								<DataCell>{organization.name}</DataCell>
								<DataCell>
									{getLabelFromValue(
										organization.entity,
										ORGANIZATIONS.types
									)}
								</DataCell>
								<DataCell>
									<Link
										text="Details"
										path={`/${role}/view/organizations/${organization._id.$oid}`}
									/>
								</DataCell>
							</TableRow>
						))
					) : (
						<EmptyTable
							Icon="UserGroupIcon"
							heading="No organizations"
							description="Start by creating an organization"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
