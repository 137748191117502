import { Children, Fragment, ReactNode } from 'react';

import { ContentSectionDivider } from '../divider/divider';

interface ListProps {
	children: ReactNode;
}

export function List({ children }: ListProps) {
	console.log(children);
	const components = Children.toArray(children);

	return (
		<Fragment>
			{components.map((child, index) => (
				<Fragment key={index}>
					{child}
					{index < components.length - 1 && <ContentSectionDivider />}
				</Fragment>
			))}
		</Fragment>
	);
}

export default List;
