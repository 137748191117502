/* eslint-disable @typescript-eslint/no-empty-function */
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	useState,
	useEffect,
	useContext,
	ChangeEvent,
	ChangeEventHandler,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
	Form,
	Button,
	useAxios,
	FormGroup,
	FormFooter,
	TextButton,
	SelectField,
	ContentSection,
} from '@pangea-lis-apps/ui';
import { ORGANIZATIONS } from '@pangea-lis-apps/utils';

import { ProcessSampleContext } from './process-sample';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AccessionVerifyPriorityLevelProps {}

export default function AccessionVerifyPriorityLevel(
	props: AccessionVerifyPriorityLevelProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const navigate = useNavigate();
	const { assay, dataId } = useParams();

	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');
	const redirectPathQuery = redirectPath
		? `/?redirectPath=${redirectPath}`
		: '';
	const redirectURL = `/accessioner/accession/sample/${assay}/data/${dataId}/sample/verify-sample${redirectPathQuery}`;
	const handleRedirect = () => {
		navigate(redirectURL);
	};

	const { data } = useContext(ProcessSampleContext);

	// Priority Level
	const [disabled, setDisabled] = useState(false);
	const [priorityLevel, setPriorityLevel] = useState('');

	const handleChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLSelectElement;

		if (target && target.name) setPriorityLevel(target.value);
	};

	useEffect(() => {
		if (data) setPriorityLevel(data.sample.priority);
	}, [data]);

	const handleSubmit = async () => {
		if (disabled || !data || !axios) return;

		if (
			!priorityLevel ||
			(priorityLevel && priorityLevel === data.sample.priority)
		) {
			handleRedirect();
			return;
		}

		// A new priority level is selected for the sample
		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(`/api/accessioner/data/${dataId}/sample/priority-level`, {
				user,
				priority: priorityLevel,
			});

			toast.dismiss();

			setDisabled(false);
			handleRedirect();
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	return (
		<ContentSection
			heading="Update sample info"
			className="bg-gray-50 border border-gray-200 py-10 rounded-lg"
		>
			<Form handleSubmit={handleSubmit}>
				<FormGroup>
					<div className="sm:col-span-2">
						<SelectField
							name="priority_level"
							value={priorityLevel}
							handleSelect={handleChange}
							label="Change Priority Level?"
							options={ORGANIZATIONS.priorityLevels}
						/>
					</div>
				</FormGroup>
			</Form>
			<FormFooter className="flex items-center justify-between">
				<TextButton
					color="gray"
					type="button"
					onClick={() =>
						navigate(
							`/accessioner/accession/sample/${assay}/data/${dataId}/sample/print-barcode${redirectPathQuery}`
						)
					}
					text={<span>&#8592; Back</span>}
				/>
				<Button
					type="button"
					tier="tertiary"
					text="Continue"
					onClick={handleSubmit}
				/>
			</FormFooter>
		</ContentSection>
	);
}
