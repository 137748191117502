import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	useState,
	Fragment,
	Dispatch,
	FormEvent,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Modal,
	Button,
	useAxios,
	EmptyBox,
	FormGroup,
	FormFooter,
	TextButton,
	GenericField,
	ContentSection,
	DescriptionList,
	DescriptionItem,
} from '@pangea-lis-apps/ui';
import { BaseHydratedCustomer } from '@pangea-lis-apps/utils';

interface OutboundEmailAddressesProps {
	customer: BaseHydratedCustomer;
	outboundEmailAddresses: string[];
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function OutboundEmailAddresses({
	customer,
	setRefresh,
	outboundEmailAddresses,
}: OutboundEmailAddressesProps) {
	const [addModalVisible, setAddModalVisible] = useState(false);
	const [removeModalVisible, setRemoveModalVisible] = useState(false);
	const [emailAddressToRemove, setEmailAddressToRemove] = useState('');

	return (
		<Fragment>
			<RemoveConfirmationModal
				customer={customer}
				setRefresh={setRefresh}
				emailAddress={emailAddressToRemove}
				outboundEmailAddresses={outboundEmailAddresses}
				visible={{
					visible: removeModalVisible,
					setVisible: setRemoveModalVisible,
				}}
			/>
			<AddEmailAddressModal
				customer={customer}
				setRefresh={setRefresh}
				emailAddress={emailAddressToRemove}
				outboundEmailAddresses={outboundEmailAddresses}
				visible={{
					visible: addModalVisible,
					setVisible: setAddModalVisible,
				}}
			/>
			<ContentSection
				heading="Outbound Email Addresses"
				headingAction={
					<Button
						type="button"
						tier="tertiary"
						Icon="PlusIcon"
						text="Add email address"
						onClick={() => setAddModalVisible(true)}
					/>
				}
			>
				{outboundEmailAddresses.length === 1 ? (
					<ul className="list-none space-y-3 divide-y divide-gray-200">
						{outboundEmailAddresses.map((emailAddress, index) => (
							<li
								key={index}
								className="pt-3 text-base flex items-center justify-between"
							>
								<span className="text-sm text-gray-900">
									{emailAddress}
								</span>
							</li>
						))}
					</ul>
				) : outboundEmailAddresses.length > 0 ? (
					<ul className="list-none space-y-3 divide-y divide-gray-200">
						{outboundEmailAddresses.map((emailAddress, index) => (
							<li
								key={index}
								className="pt-3 text-base flex items-center justify-between"
							>
								<span className="text-sm text-gray-900">
									{emailAddress}
								</span>
								<TextButton
									text="Remove"
									color="blue"
									type="button"
									onClick={() => {
										setEmailAddressToRemove(emailAddress);
										setRemoveModalVisible(true);
									}}
								/>
							</li>
						))}
					</ul>
				) : (
					<EmptyBox
						Icon="NoSymbolIcon"
						heading="No outbound email addresses"
					/>
				)}
			</ContentSection>
		</Fragment>
	);
}

interface ModalProps {
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
	emailAddress: string;
	customer: BaseHydratedCustomer;
	outboundEmailAddresses: string[];
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function RemoveConfirmationModal(props: ModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();

	const { visible, setVisible } = props.visible;
	const [disabled, setDisabled] = useState(false);
	const [confirmation, setConfirmation] = useState('');

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		// Remove outbound email address
		if (!axios || !props.customer) return;
		else if (confirmation !== 'Yes') {
			toast.error("Enter 'Yes' to confirm removal.");
			return;
		}

		toast.loading('Updating...');
		setDisabled(true);

		const filteredOutboundEmailAddresses =
			props.outboundEmailAddresses.filter((email) => {
				return email !== props.emailAddress;
			});

		try {
			await (
				await axios
			).patch(`/api/accounts/customers/${props.customer?._id.$oid}`, {
				user,
				update_data: {
					new_value: filteredOutboundEmailAddresses,
					old_value: props.outboundEmailAddresses,
					property: 'outbound_emails',
				},
			});

			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);
		}

		toast.dismiss();
		setDisabled(false);
	};

	const handleChange: ChangeEventHandler<HTMLInputElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement;

		if (target && target.name) setConfirmation(target.value);
	};

	return (
		<Modal
			visible={visible}
			customWidth="max-w-sm"
			title="Confirm removal"
			onClose={() => setVisible(false)}
			description="Confirm that you want to remove the selected email address."
		>
			<div className="space-y-4">
				<DescriptionList>
					<DescriptionItem
						term="Email Address"
						customColSpan="sm:col-span-3"
						details={props.emailAddress}
					/>
				</DescriptionList>

				<Form handleSubmit={handleSubmit}>
					<FormGroup>
						<div className="sm:col-span-9">
							<GenericField
								required
								type="text"
								name="confirmation"
								value={confirmation}
								label="Confirm removal"
								placeholder="i.e., Yes"
								handleInputChange={handleChange}
							/>
						</div>
					</FormGroup>
					<FormFooter>
						<Button
							type="submit"
							text="Confirm"
							tier="tertiary"
							Icon="CheckIcon"
							disabled={disabled}
						/>
					</FormFooter>
				</Form>
			</div>
		</Modal>
	);
}

export default function AddEmailAddressModal(props: ModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();

	const { visible, setVisible } = props.visible;
	const [disabled, setDisabled] = useState(false);
	const [emailAddress, setEmailAddress] = useState('');

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		// Remove outbound email address
		if (!axios || !props.customer) return;
		else if (!emailAddress) {
			toast.error('Enter a valid email address!');
			return;
		} else if (props.outboundEmailAddresses.includes(emailAddress)) {
			toast.error('Email address already exists!');
			return;
		}

		toast.loading('Updating...');
		setDisabled(true);

		const updatedOutboundEmailAddresses = [
			...props.outboundEmailAddresses,
			emailAddress,
		];

		console.log(updatedOutboundEmailAddresses);

		try {
			await (
				await axios
			).patch(`/api/accounts/customers/${props.customer?._id.$oid}`, {
				user,
				update_data: {
					new_value: updatedOutboundEmailAddresses,
					old_value: props.outboundEmailAddresses,
					property: 'outbound_emails',
				},
			});

			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);
		}

		toast.dismiss();
		setDisabled(true);
	};

	const handleChange: ChangeEventHandler<HTMLInputElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement;

		if (target && target.name) setEmailAddress(target.value);
	};

	return (
		<Modal
			visible={visible}
			customWidth="max-w-sm"
			title="Add email address"
			onClose={() => setVisible(false)}
			description="This email address will the be added to the list of outbound email addresses for the client."
		>
			<Form handleSubmit={handleSubmit}>
				<FormGroup>
					<div className="sm:col-span-9">
						<GenericField
							required
							type="email"
							name="emailAddress"
							value={emailAddress}
							label="Email address"
							handleInputChange={handleChange}
							placeholder="i.e., test.pangealab+jamesKim@gmail.com"
						/>
					</div>
				</FormGroup>
				<FormFooter>
					<Button
						text="Add"
						type="submit"
						tier="tertiary"
						Icon="PlusIcon"
						disabled={disabled}
					/>
				</FormFooter>
			</Form>
		</Modal>
	);
}
