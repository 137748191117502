import { TextButton } from '@pangea-lis-apps/ui';

import { useNavigate, useParams } from 'react-router-dom';

interface INegivateToUpdatePage {
	assay: string;
	type: string;
	version?: string;
}

export function NegivateToUpdatePage(props: INegivateToUpdatePage) {
	const navigate = useNavigate();
	const { dataId } = useParams();

	const handleUpdateNonResultData = () => {
		if (props.type === 'non_clinic') {
			navigate(
				`/client-services/view/non-clinic-samples-update-report/${dataId}`
			);
		} else if (props.type === 'clinic') {
			const assayVersion = `${props.assay}-${props.version}`;

			switch (assayVersion) {
				case `sti-1.0`:
					navigate(
						`/client-services/view/samples-update-report/sti-1.0/${dataId}`
					);
					break;
				case `resp4plex-1.0`:
					navigate(
						`/client-services/view/samples-update-report/resp4plex-1.0/${dataId}`
					);
					break;
				case `bladdercare-4.1`:
					navigate(
						`/client-services/view/samples-update-report/bladdercare-4.1/${dataId}`
					);
					break;
				case `fit-1.0`:
						navigate(
							`/client-services/view/samples-update-report/fit-1.0/${dataId}`
						);
						break;
				default:
					navigate(
						`/client-services/view/samples-update-report/${dataId}`
					);
			}
		} else {
			return;
		}
	};

	return (
		<TextButton
			color="blue"
			type="button"
			text="Update Data"
			onClick={handleUpdateNonResultData}
		/>
	);
}
