import { Routes, Route } from 'react-router-dom';

import ViewSample from './view-sample';
import ViewPendingSamples from './view-pending-samples';
import ViewRejectedSamples from './view-rejected-samples';

import RoleProtectedRoute from '../../../utils/routing';

export function LabRoutes() {
	return (
		<Routes>
			<Route
				element={
					<RoleProtectedRoute
						roles={['BITSS_lab-tech', 'BITSS_super-admin']}
					/>
				}
			>
				<Route path="/view/samples/:dataId" element={<ViewSample />} />
				<Route
					path="/view/pending-samples"
					element={<ViewPendingSamples />}
				/>
				<Route
					path="/view/rejected-samples"
					element={<ViewRejectedSamples />}
				/>
			</Route>
		</Routes>
	);
}
