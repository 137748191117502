/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, Fragment, useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Button, TextButton, ContentSection } from '@pangea-lis-apps/ui';

import { ProcessSampleContext } from './process-sample-layout';
import PrintBarcodeModal from '../../../../../../components/modals/print-barcode-modal';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AccessionPrintBarcodeProps {}

export default function AccessionPrintBarcode(
	props: AccessionPrintBarcodeProps
) {
	const navigate = useNavigate();
	const { assay, dataId } = useParams();

	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');
	const redirectPathQuery = redirectPath
		? `/?redirectPath=${redirectPath}`
		: '';
	const redirectURL = `/accessioner/accession/sample/${assay}/data/${dataId}/non-clinic-sample/verify-priority${redirectPathQuery}`;
	const handleRedirect = () => {
		navigate(redirectURL);
	};

	const { data } = useContext(ProcessSampleContext);
	const [modalVisible, setModalVisible] = useState(false);

	return (
		<Fragment>
			<PrintBarcodeModal
				data={data}
				handleAfterPrint={handleRedirect}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<ContentSection className="bg-gray-50 border border-gray-200 py-8 rounded-lg">
				<div className="flex items-center justify-between">
					<TextButton
						color="gray"
						type="button"
						onClick={() =>
							navigate(
								`/accessioner/accession/sample/${assay}/data/${dataId}/non-clinic-sample`
							)
						}
						text={<span>&#8592; Return to sample info</span>}
					/>
					<div className="flex items-center justify-between space-x-4">
						<TextButton
							text="Skip"
							color="gray"
							type="button"
							onClick={handleRedirect}
						/>
						<Button
							text="Print"
							type="button"
							tier="tertiary"
							Icon="PrinterIcon"
							onClick={() => setModalVisible(true)}
						/>
					</div>
				</div>
			</ContentSection>
		</Fragment>
	);
}
