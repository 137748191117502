import toast from 'react-hot-toast';
import { useId, useState, useEffect } from 'react';

import {
	useAxios,
	Container,
	ContentWrapper,
	ContentSection,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ClinicData,
	Pagination,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import PendingReportsTable from '../../../components/tables/cls/pending-reports';
import TableSearchFiltersForm from '../../../components/tables/cls/table-search-filters-form';

const projectedFields = [
	'_id',
	'sample.priority',
	'metadata.accessioning.completed.value',
	'customer.first_name',
	'customer.last_name',
	'organization.name',
	'sample.pangea_id',
	'sample.specimen_id',
	'sample.collection_kit_id',
	'sample.assay',
	'patient.first_name',
	'patient.last_name',
	'sample.sample_collection_date',
];

/* eslint-disable-next-line */
export interface PendingReportsProps {}

export default function PendingReports(props: PendingReportsProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [query, setQuery] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [data, setData] = useState<
		(ClinicData & { checked: boolean })[] | undefined
	>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setData(undefined);
				setDisabled(true);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/cls/search/data/pending-reports/?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query,
						projected_fields: projectedFields,
					}
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setData(
						parsedData.map((x_data: ClinicData) => ({
							...x_data,
							checked: false,
						}))
					);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);

			toast.dismiss();
		};

		if (!unmounted) fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	return (
		<Container>
			<ContentWrapper
				heading="Pending Reports"
				Icon="DocumentChartBarIcon"
				description="These are all the samples that have a report generated but have not been verified."
			>
				<TableSearchFiltersForm
					tableType="pending-reports"
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection heading="All samples with pending reports">
					<PendingReportsTable
						data={data}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
