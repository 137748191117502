import { useId, useEffect, useState } from 'react';

import {
	useAxios,
	Container,
	ContentWrapper,
	ContentSection,
} from '@pangea-lis-apps/ui';
import {
	Query,
	Pagination,
	BaseHydratedCustomer,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import AddMembersTable from '../../../components/tables/organizations/add-members';

/* eslint-disable-next-line */
export interface AddMembersToOrganizationProps {}

export default function AddMembersToOrganization(
	props: AddMembersToOrganizationProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [query, setQuery] = useState<Query>({});
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);
	const [customers, setCustomers] = useState<
		BaseHydratedCustomer[] | undefined
	>(undefined);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/accounts/customers/?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					query
				);

				if (!unmounted) {
					const customers = JSON.parse(data);
					console.log(customers);

					setCustomers(customers);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	return (
		<Container>
			<ContentWrapper
				Icon="UserIcon"
				heading="Add members to organization"
				description="Search for a customer to add to the organization."
			>
				<ContentSection
					id="form"
					heading="All customers"
					description="Use the search icons to filter for specific customers. If the customer already exists in an organization, then first remove them from the organization."
				>
					<AddMembersTable
						data={customers}
						query={{ query, setQuery }}
						pagination={{
							pagination,
							setPagination,
						}}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
