/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Fragment, FormEvent, useContext, BaseSyntheticEvent } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import {
	Form,
	LoadingBox,
	TextButton,
	ContentSection,
	TRFComponents,
	AccessioningStatus,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';

import { determineRedirectURL } from '../../utils/helpers';
import { ProcessTRFStepsContext } from '../../components/form-wrapper';

/* eslint-disable-next-line */
export interface ReviewProps {}

export default function Review(props: ReviewProps) {
	const navigate = useNavigate();
	const { dataId } = useParams();
	const [searchParams] = useSearchParams();
	const redirectPath = searchParams.get('redirectPath');

	const { data, formId, redirectPathQuery } = useContext(
		ProcessTRFStepsContext
	);

	const handleSetStep = (stepId: string) => {
		if (!data) return;

		navigate(
			`/accessioner/accession/trf/${data.sample.assay}/v${
				data.requisition_form.metadata.version
			}/data/${dataId}/trf/${stepId}${
				redirectPath ? `/?redirectPath=${redirectPath}` : ''
			}`
		);
	};

	const handleSubmit = (event: MouseEvent | FormEvent) => {
		event.preventDefault();

		if (!data || !dataId) return;

		let redirectURL = `/accessioner/accession/sample/${data.sample.assay}/data/${dataId}/sample/update`;

		if (event.type === 'click') {
			const { tabSubmit, tabSubmitNextStepId } = determineRedirectURL(
				event as BaseSyntheticEvent
			);

			if (tabSubmit)
				redirectURL = `/accessioner/accession/trf/${data.sample.assay}/v${data.requisition_form.metadata.version}/data/${dataId}/trf/${tabSubmitNextStepId}${redirectPathQuery}`;
		}

		navigate(redirectURL);
	};

	return !data ? (
		<LoadingBox />
	) : (
		<Fragment>
			<AccessioningStatus data={data} />

			<ContentSectionDivider />

			<TRFComponents.ProviderInformation
				data={data}
				headingAction={
					<TextButton
						color="blue"
						type="button"
						text="Update"
						onClick={() =>
							handleSetStep('provider-organization-info')
						}
					/>
				}
			/>

			<ContentSectionDivider />

			<TRFComponents.OrganizationInformation
				data={data}
				headingAction={
					<TextButton
						color="blue"
						type="button"
						text="Update"
						onClick={() =>
							handleSetStep('provider-organization-info')
						}
					/>
				}
			/>

			<ContentSectionDivider />

			<TRFComponents.OrderAndSpecimenInformation
				data={data}
				headingAction={
					<TextButton
						type="button"
						text="Update"
						color="blue"
						onClick={() => handleSetStep('order-sample-info')}
					/>
				}
			/>

			<ContentSectionDivider />

			<TRFComponents.PatientInformation
				data={data}
				headingAction={
					<TextButton
						type="button"
						text="Update"
						color="blue"
						onClick={() => handleSetStep('patient-info')}
					/>
				}
			/>

			<ContentSectionDivider />

			<TRFComponents.RelevantClinicalInformation
				data={data}
				headingAction={
					<TextButton
						type="button"
						text="Update"
						color="blue"
						onClick={() => handleSetStep('relevant-clinical-info')}
					/>
				}
			/>

			<ContentSectionDivider />

			<TRFComponents.BillingInformation
				data={data}
				headingAction={
					<TextButton
						color="blue"
						type="button"
						text="Update"
						onClick={() => handleSetStep('billing-info')}
					/>
				}
			/>

			<ContentSectionDivider />

			<TRFComponents.MiscAndAttachments
				data={data}
				entity="accessioner"
				headingAction={
					<TextButton
						type="button"
						text="Update"
						color="blue"
						onClick={() => handleSetStep('misc-attachments')}
					/>
				}
			/>

			<ContentSection>
				<Form id={formId} handleSubmit={handleSubmit}>
					{null}
				</Form>
			</ContentSection>
		</Fragment>
	);
}
