import { Routes, Route } from 'react-router-dom';

import ViewUser from '../shared/admin/view-user';
import ViewUsers from '../shared/admin/view-users';
import CreateUser from '../shared/admin/create-user';

import RoleProtectedRoute from '../../../utils/routing';

export function AdminRoutes() {
	return (
		<Routes>
			<Route
				element={
					<RoleProtectedRoute
						roles={['BITSS_admin', 'BITSS_super-admin']}
					/>
				}
			>
				<Route path="/view/users" element={<ViewUsers />} />
				<Route path="/create/user" element={<CreateUser />} />
				<Route path="/view/users/:auth0UserID" element={<ViewUser />} />
			</Route>
		</Routes>
	);
}
