import { useNavigate } from 'react-router-dom';

import {
	Table,
	DataCell,
	TableRow,
	TableHead,
	TableBody,
	EmptyTable,
	HeaderCell,
	TableLoading,
	ArrowLinkButton,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	ASSAYS,
	ClinicData,
	formatDate,
	ORGANIZATIONS,
	NonClinicData,
	PaginationState,
	getLabelFromValue,
	getRoleFromPathname,
	getAllRequisitionFormOptions,
} from '@pangea-lis-apps/utils';

interface PendingSamplesTableProps {
	pagination: PaginationState;
	data: (ClinicData | NonClinicData)[] | undefined;
}

export default function PendingSamplesTable(props: PendingSamplesTableProps) {
	const navigate = useNavigate();
	const role = getRoleFromPathname();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Assay</HeaderCell>
				<HeaderCell>Priority Level</HeaderCell>
				<HeaderCell>RUO</HeaderCell>
				<HeaderCell>Customer</HeaderCell>
				<HeaderCell>Organization</HeaderCell>
				<HeaderCell>Pangea ID</HeaderCell>
				<HeaderCell>Specimen ID</HeaderCell>
				<HeaderCell>Collection kit ID</HeaderCell>
				<HeaderCell>Accessioning completed?</HeaderCell>
				<HeaderCell>In batch?</HeaderCell>
				<HeaderCell>Transport Storage Condition</HeaderCell>
				<HeaderCell>Received Date</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
				<HeaderCell>Transport by date</HeaderCell>
				<HeaderCell>Stable by date</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => {
							return (
								<TableRow key={index} index={index}>
									<DataCell>
										{getLabelFromValue(
											datum.sample.assay,
											ASSAYS['assays']
										)}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.priority,
											ORGANIZATIONS.priorityLevels
										)}
									</DataCell>
									<DataCell>
										{datum.sample.ruo ? 'Yes' : 'No'}
									</DataCell>
									<DataCell>
										{datum.customer.first_name.concat(
											' ',
											datum.customer.last_name
										)}
									</DataCell>
									<DataCell>
										{datum.organization.name}
									</DataCell>
									<DataCell>
										<ArrowLinkButton
											text={datum.sample.pangea_id}
											onClick={() =>
												navigate(
													`/${role}/view/samples/${datum._id.$oid}`
												)
											}
										/>
									</DataCell>
									<DataCell>
										{datum.sample.specimen_id}
									</DataCell>
									<DataCell>
										{datum.sample.collection_kit_id}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.metadata.accessioning.completed.value.toString(),
											FORMS.yes_no_options
										)}
									</DataCell>
									<DataCell>
										{'batch' in datum.sample
											? datum.sample.batch
												? datum.sample.batch.name
												: null
											: null}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample
												.sample_transport_storage_condition,
											Object.values(
												getAllRequisitionFormOptions(
													'transport_storage_conditions',
													'array'
												)
											)
										)}
									</DataCell>
									<DataCell>
										{formatDate(
											datum.sample.sample_received_date
												? datum.sample
														.sample_received_date
												: undefined,
											true
										)}
									</DataCell>
									<DataCell>
										{formatDate(
											datum.sample.sample_collection_date
										)}
									</DataCell>
									<DataCell>
										{formatDate(
											datum.sample.misc
												.transport_time_requirement
												.transport_by &&
												datum.sample.misc
													.transport_time_requirement
													.transport_by.$date
										)}
									</DataCell>
									<DataCell>
										{formatDate(
											datum.sample.misc.sample_stability
												.stable_until &&
												datum.sample.misc
													.sample_stability
													.stable_until.$date
										)}
									</DataCell>
								</TableRow>
							);
						})
					) : (
						<EmptyTable
							Icon="FingerPrintIcon"
							heading="No pending samples"
							description="Wait for samples to be approved by accessioning"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
