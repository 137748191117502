import { useNavigate } from 'react-router-dom';

import {
	Table,
	TableRow,
	DataCell,
	TableBody,
	TableHead,
	EmptyTable,
	HeaderCell,
	TableLoading,
	OrderItemsSummary,
	HeaderCellWithSearch,
	ArrowLinkButton,
} from '@pangea-lis-apps/ui';
import {
	QueryState,
	classNames,
	capitalize,
	HydratedOrder,
	PaginationState,
	getLabelFromValue,
	ORDERS,
	SHIPPING,
	SHARED,
} from '@pangea-lis-apps/utils';

import { useCustomers } from '../../../../utils/hooks/use-customers';

interface AllOrdersTableProps {
	query: QueryState;
	hideActions?: boolean;
	pagination: PaginationState;
	data: HydratedOrder[] | undefined;
}

export default function AllOrdersTable(props: AllOrdersTableProps) {
	const navigate = useNavigate();
	const { query, setQuery } = props.query;

	const customers = useCustomers();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCellWithSearch
					type="text"
					property="id"
					query={query}
					name="orderID"
					label="Order ID"
					setQuery={setQuery}
				/>
				<HeaderCellWithSearch
					type="select"
					query={query}
					property="status"
					setQuery={setQuery}
					name="shipping_status"
					label="Shipping status"
					options={[
						{ label: 'Select an Option', value: '' },
						...ORDERS.statuses,
					]}
				/>
				<HeaderCellWithSearch
					type="select"
					query={query}
					name="carrier"
					label="Carrier"
					setQuery={setQuery}
					property="shipping.carrier"
					options={SHIPPING.carriers}
				/>
				<HeaderCellWithSearch
					type="text"
					query={query}
					name="tracking"
					setQuery={setQuery}
					label="Tracking No."
					property="shipping.tracking_number"
				/>
				<HeaderCellWithSearch
					type="date"
					query={query}
					setQuery={setQuery}
					name="date_shipped"
					label="Date shipped"
					property="shipping.shipped.date"
				/>
				<HeaderCell>Shipped by</HeaderCell>
				<HeaderCellWithSearch
					type="combobox"
					query={query}
					name="customer"
					property="customer._id"
					setQuery={setQuery}
					label="Select customer"
					customHeaderWidth="w-80"
					options={customers || SHARED.no_options}
				/>
				<HeaderCell>Address</HeaderCell>
				<HeaderCell>Items</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((order, index) => (
							<TableRow key={index} index={index}>
								<DataCell>
									{props.hideActions ? (
										order.id.toUpperCase()
									) : (
										<ArrowLinkButton
											text={order.id.toUpperCase()}
											onClick={() =>
												navigate(
													`/shipping/view/orders/${order._id.$oid}`
												)
											}
										/>
									)}
								</DataCell>
								<DataCell>
									<p
										className={classNames(
											'text-xs px-2 py-1 inline rounded-md border font-semibold tracking-wide uppercase',
											order.status === 'pending'
												? 'bg-amber-100 border-amber-200 text-amber-600'
												: '',
											order.status === 'deleted'
												? 'bg-red-100 border-red-200 text-red-600'
												: '',
											order.status === 'shipped'
												? 'bg-blue-100 border-blue-200 text-blue-600'
												: ''
										)}
									>
										{capitalize(order.status)}
									</p>
								</DataCell>
								<DataCell>
									{getLabelFromValue(
										order.shipping.carrier,
										SHIPPING.carriers
									)}
								</DataCell>
								<DataCell>
									{order.shipping.tracking_number}
								</DataCell>
								<DataCell>
									{order.shipping.shipped.date &&
										order.shipping.shipped.date}
								</DataCell>
								<DataCell>
									{order.shipping.shipped.by &&
										order.shipping.shipped.by.first_name.concat(
											' ',
											order.shipping.shipped.by.last_name
										)}
								</DataCell>
								<DataCell>
									<b>Name</b>:{' '}
									{order.customer.first_name.concat(
										' ',
										order.customer.last_name
									)}{' '}
									<br />
									<b>Phone</b>: {order.customer.phone_number}
									<br />
									<b>Email</b>: {order.customer.email_address}
									<br />
									<b>NPI</b>:{' '}
									{order.customer.national_provider_identifier
										? order.customer
												.national_provider_identifier
										: null}
								</DataCell>
								<DataCell>
									{order.shipping.address.address} <br />
									{order.shipping.address.city},{' '}
									{order.shipping.address.state},{' '}
									{order.shipping.address.zip_code}
								</DataCell>
								<DataCell>
									<OrderItemsSummary
										minified={true}
										// eslint-disable-next-line jsx-a11y/aria-role
										role="associate"
										items={order.items}
									/>
								</DataCell>
							</TableRow>
						))
					) : (
						<EmptyTable Icon="TruckIcon" heading="No orders" />
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
