/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import {
	useId,
	Fragment,
	useState,
	Dispatch,
	useEffect,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Button,
	useAxios,
	EmptyBox,
	FormGroup,
	SelectField,
} from '@pangea-lis-apps/ui';
import {
	Rack,
	Option,
	SelectedWell,
	PaginationState,
} from '@pangea-lis-apps/utils';

import RackTable from './rack-table';
import CreateRackModal from '../modals/create-rack-modal';
import { convertIndexToWellLocation } from '../../../utils/helpers/wells';

interface RackWellSelectInputFieldProps {
	required?: boolean;
	selectedWell: SelectedWell;
	pagination: PaginationState;
	rackType: 'hold' | 'flag' | 'review' | string;
	setSelectedWell: Dispatch<SetStateAction<SelectedWell>>;
}

export default function RackWellSelectInputField(
	props: RackWellSelectInputFieldProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [refresh, setRefresh] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [rack, setRack] = useState<Rack | undefined>(undefined);
	const [selectedRackId, setSelectedRackId] = useState<string>('');
	const [racks, setRacks] = useState<Option[] | undefined>(undefined);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !selectedRackId) return;

			toast.loading('Loading rack...');

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(`/api/accessioner/racks/${selectedRackId}`);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setRack(parsedData);
				}
			} catch (error) {
				console.log(error);
			}

			toast.dismiss();
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, selectedRackId]);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !props.rackType) return;

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).get(
					`/api/accessioner/racks/${props.rackType}/?truncated=TRUE`
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData, total_entries);

					setRacks(
						parsedData.map((rack: Rack) => ({
							label: `${rack.name} (${rack.rows}Rx${rack.columns}C)`,
							value: rack._id.$oid,
						}))
					);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [axios, refresh, props.rackType]);

	const handleSelectWell = (occupiedStatus: number, index: number) => {
		const adjustedIndex = index - 1;

		if (props.selectedWell.location && occupiedStatus !== 1) {
			// If another well has already been selected, then deselect that well and select this well.
			setRack((prevRack) => {
				if (prevRack) {
					prevRack.sequence[
						props.selectedWell.index - 1
					].occupied_status = 0;

					return prevRack;
				}

				return prevRack;
			});

			props.setSelectedWell({
				index: index,
				location: '',
				deselect: true,
				rack_name: rack!.name,
			});
		}

		if (occupiedStatus === 1) {
			return;
		} else if (occupiedStatus === 2) {
			// Well already selected.
			props.setSelectedWell({
				index: index,
				location: '',
				deselect: true,
				rack_name: rack!.name,
			});

			setRack((prevRack) => {
				if (prevRack) {
					prevRack.sequence[adjustedIndex].occupied_status = 0;

					return prevRack;
				}

				return prevRack;
			});
		} else {
			// Well not occupied.
			props.setSelectedWell({
				index: index,
				deselect: false,
				rack_name: rack!.name,
				location: convertIndexToWellLocation(index - 1, rack!.columns),
			});

			setRack((prevRack) => {
				if (prevRack) {
					prevRack!.sequence[adjustedIndex].occupied_status = 2;

					return prevRack;
				}

				return prevRack;
			});
		}
	};

	const handleChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLSelectElement;

		if (target && target.name) setSelectedRackId(target.value);
	};

	return (
		<Fragment>
			<CreateRackModal
				setRefresh={setRefresh}
				rackType={props.rackType}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<div className="space-y-4">
				<div className="flex items-end justify-between">
					<FormGroup>
						<div className="sm:col-span-3">
							<SelectField
								name="rack"
								options={
									racks && racks.length
										? [
												{
													value: '',
													disabled: true,
													label: 'Select an Option',
												},
												...racks,
										  ]
										: [
												{
													value: '',
													disabled: true,
													label: 'No option(s) available',
												},
										  ]
								}
								disabled={!racks}
								label="Existing racks"
								value={selectedRackId}
								handleSelect={handleChange}
							/>
						</div>
					</FormGroup>
					<Button
						type="button"
						Icon="PlusIcon"
						tier="tertiary"
						onClick={() => setModalVisible(true)}
						text={`Create new ${props.rackType} rack`}
					/>
				</div>
				{rack ? (
					<RackTable
						rack={rack}
						handleSelectWell={handleSelectWell}
					/>
				) : (
					<EmptyBox
						Icon="Squares2X2Icon"
						heading="No rack selected"
						description="Start by selecting an existing rack or a new rack"
					/>
				)}
			</div>
		</Fragment>
	);
}
