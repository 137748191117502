import { useParams } from 'react-router-dom';
import { SetStateAction, Fragment, Dispatch } from 'react';

import { TRFSectionStiV1 } from '../update-data-versions/sti-v1';
import { TRFSectionResp4plexV1 } from '../update-data-versions/resp4plex-v1';
import { TRFSectionBladdercareV41 } from '../update-data-versions/bladdercare-v4_1';
import { TRFSectionFitV1 } from '../update-data-versions/fit-v1';

interface ITRFVersionFilter {
	data: any;
	payload: any;
	originalData: any;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function TRFVersionFilter(props: ITRFVersionFilter) {
	const { assayVersion } = useParams();

	return (
		<Fragment>
			{assayVersion === 'sti-1.0' && (
				<TRFSectionStiV1
					data={props.data}
					originalData={props.originalData}
					payload={props.payload}
					setRefresh={props.setRefresh}
				/>
			)}
			{assayVersion === 'bladdercare-4.1' && (
				<TRFSectionBladdercareV41
					data={props.data}
					originalData={props.originalData}
					payload={props.payload}
					setRefresh={props.setRefresh}
				/>
			)}
			{assayVersion === 'resp4plex-1.0' && (
				<TRFSectionResp4plexV1
					data={props.data}
					originalData={props.originalData}
					payload={props.payload}
					setRefresh={props.setRefresh}
				/>
			)}
			{assayVersion === 'fit-1.0' && (
				<TRFSectionFitV1
					data={props.data}
					originalData={props.originalData}
					payload={props.payload}
					setRefresh={props.setRefresh}
				/>
			)}
		</Fragment>
	);
}
