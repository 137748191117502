import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
	useId,
	Dispatch,
	Fragment,
	useState,
	useEffect,
	FormEvent,
	useContext,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Alert,
	Modal,
	Button,
	useAxios,
	FormGroup,
	LoadingBox,
	FormFooter,
	GenericField,
	ActionCard,
	EditDataModal,
	ContentSection,
	DescriptionList,
	DescriptionItem,
	AddBillingMethodModal,
	ContentSectionDivider,
	CustomerBillingMethods,
	BillingMethodFormValues,
	OutboundEmailAddresses,
	EmptyBox,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	Option,
	Auth0Role,
	Attribute,
	capitalize,
	getLabelFromValue,
	initialAttributeValue,
	Auth0User,
} from '@pangea-lis-apps/utils';

import { customerContext, HydratedCustomer } from './index';

interface Roles {
	organizationRoles: Option[];
	customerRoles: { value: string; display: string };
}

const initialRolesValue = {
	organizationRoles: [
		{
			label: 'No option(s) available',
			value: '',
		},
	],
	customerRoles: { value: '', display: '' },
};

interface AddBillingMethodModalState {
	updateId: string;
	visible: boolean;
	action: 'create' | 'update' | string;
}

const initialAddBillingMethodModalState = {
	updateId: '',
	visible: false,
	action: 'create',
};

/* eslint-disable-next-line */
export interface ViewMemberDetailsProps {}

export default function ViewMemberDetails(props: ViewMemberDetailsProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { customer, setRefresh } = useContext(customerContext);

	const [attribute, setAttribute] = useState<Attribute>(
		initialAttributeValue
	);
	const [modalVisible, setModalVisible] = useState(false);
	const [roles, setRoles] = useState<Roles>(initialRolesValue);
	const [billingModalState, setBillingModalState] =
		useState<AddBillingMethodModalState>(initialAddBillingMethodModalState);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !customer) return;

			try {
				const {
					data: { data },
				} = await (await axios).get(`/api/accounts/roles`);

				if (!unmounted) {
					// roles data
					console.log(data);

					setRoles(() => {
						const relevantCustomerRoles = customer.roles.filter(
							(role: Auth0Role) =>
								role.name.includes('BITSS_organization')
						);

						const organizationRoles = [
							{
								value: '',
								disabled: true,
								label: 'Select an Option',
							},
							...data
								.filter((role: Auth0Role) =>
									role.name.includes('BITSS_organization')
								)
								.map((role: Auth0Role) => ({
									label: role.description.split(' - ')[1],
									value: role.id,
								})),
						];

						return {
							organizationRoles,
							customerRoles: {
								value: relevantCustomerRoles
									.map((role: Auth0Role) => role.id)
									.join(', '),
								display: relevantCustomerRoles
									.map((role: Auth0Role) => {
										const parsedRole = role.name
											.split('_')[1]
											.replace('organization-', '');

										return capitalize(parsedRole);
									})
									.join(', '),
							},
						};
					});
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, customer]);

	const handleClick = (
		property: string,
		value: string,
		label: string,
		type: 'text' | 'email' | 'select' | 'multiple_select',
		selectOptions?: Option[],
		selectLabel?: string
	) => {
		setAttribute({
			property,
			value,
			label,
			type,
			selectOptions,
			selectLabel,
		});
		setModalVisible(true);
	};

	const handleUpdateBillingMethods = async (
		event: FormEvent<Element>,
		formValues: BillingMethodFormValues
	) => {
		event.preventDefault();

		if (!axios || !customer) return;

		toast.loading('Updating...');

		try {
			await (
				await axios
			).patch(
				`/api/accounts/customers/${customer._id.$oid}/billing-methods`,
				{
					action: billingModalState.action,
					update_id: billingModalState.updateId,
					billing_details: { ...formValues },
				}
			);

			setRefresh((value) => !value);
		} catch (error) {
			console.log(error);
		}

		toast.dismiss();
	};

	return (
		<Fragment>
			<EditDataModal
				data={customer}
				attribute={attribute}
				setRefresh={setRefresh}
				heading="Edit user info"
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
				endpoint={`/api/accounts/customers/${customer?._id.$oid}`}
			/>
			{!customer ? (
				<LoadingBox />
			) : (
				<Fragment>
					<AddBillingMethodModal
						modalState={billingModalState}
						setModalState={setBillingModalState}
						handleSubmit={handleUpdateBillingMethods}
						billingMethods={customer.billing_methods}
					/>

					<ContentSection heading="Info">
						{!customer.account_activated && (
							<Alert
								type="warning"
								className="mb-6"
								heading="Account not yet activated"
								description="A valid email address must be provided before account activation. First name, last name, and roles can be updated after account activation."
							/>
						)}

						<DescriptionList>
							<DescriptionItem
								term="Organization"
								details={
									customer.organization &&
									customer.organization.name
								}
							/>
							<div className="sm:col-span-2"></div>
							<DescriptionItem
								withFunction
								term="Title"
								details={getLabelFromValue(
									customer.title,
									FORMS.titles
								)}
								Icon="PencilIcon"
								handleClick={() =>
									handleClick(
										'title',
										getLabelFromValue(
											customer.title,
											FORMS.titles
										),
										'Title',
										'select',
										FORMS.titles
									)
								}
							/>
							<div className="sm:col-span-2"></div>
							<DescriptionItem
								withFunction={customer.account_activated}
								term="First Name"
								details={customer.first_name}
								Icon="PencilIcon"
								handleClick={() =>
									handleClick(
										'first_name',
										customer.first_name,
										'First Name',
										'text'
									)
								}
							/>
							<DescriptionItem
								withFunction
								term="Middle Name"
								details={customer.middle_name}
								Icon="PencilIcon"
								handleClick={() =>
									handleClick(
										'middle_name',
										customer.middle_name,
										'Middle Name',
										'text'
									)
								}
							/>
							<DescriptionItem
								withFunction={customer.account_activated}
								term="Last Name"
								details={customer.last_name}
								Icon="PencilIcon"
								handleClick={() =>
									handleClick(
										'last_name',
										customer.last_name,
										'Last Name',
										'text'
									)
								}
							/>
							{customer.entity === 'clinic' && (
								<Fragment>
									{customer.isAssociate && (
										<DescriptionItem
											withFunction
											term="National Provider Identifier (NPI)"
											details={
												customer.national_provider_identifier
											}
											Icon="PencilIcon"
											handleClick={() => {
												handleClick(
													'national_provider_identifier',
													customer.national_provider_identifier ||
														'',
													'National Provider Identifier (NPI)',
													'text'
												);
											}}
										/>
									)}
									<DescriptionItem
										withFunction={
											customer.account_activated
										}
										term="Roles"
										details={roles.customerRoles.display}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'roles',
												roles.customerRoles.value,
												'Roles',
												'select',
												roles.organizationRoles,
												roles.customerRoles.display
											)
										}
									/>
									{customer.isStaff && (
										<DescriptionItem
											withFunction={
												customer.account_activated
											}
											term="Can view reports?"
											details={customer.canViewReports}
											Icon="PencilIcon"
											handleClick={() =>
												handleClick(
													'can_view_reports',
													customer.canViewReports
														? 'true'
														: 'false',
													'Can view reports?',
													'select',
													FORMS.yes_no_options,
													customer.canViewReports
														? 'Yes'
														: 'No'
												)
											}
										/>
									)}
								</Fragment>
							)}
						</DescriptionList>
					</ContentSection>

					<ContentSectionDivider />

					<ContentSection
						heading="Contact"
						id="contact_information"
						description="After updating the email address, the user must verify it before accessing their account."
					>
						<DescriptionList>
							<DescriptionItem
								withFunction
								term="Email Address"
								details={customer.email_address}
								Icon="PencilIcon"
								handleClick={() =>
									handleClick(
										'email_address',
										customer.email_address,
										'Email Address',
										'email'
									)
								}
							/>
							<DescriptionItem
								withFunction
								term="Phone Number"
								details={customer.phone_number}
								Icon="PencilIcon"
								handleClick={() =>
									handleClick(
										'phone_number',
										customer.phone_number,
										'Phone Number',
										'text'
									)
								}
							/>
						</DescriptionList>
					</ContentSection>

					<ContentSectionDivider />

					<ContentSection heading="Address" id="address_information">
						<DescriptionList>
							<DescriptionItem
								withFunction
								term="Address"
								details={customer.address}
								Icon="PencilIcon"
								handleClick={() =>
									handleClick(
										'address',
										customer.address,
										'Address Info - Address',
										'text'
									)
								}
							/>
							<DescriptionItem
								withFunction
								term="Address Line 2"
								details={customer.address2}
								Icon="PencilIcon"
								handleClick={() =>
									handleClick(
										'address2',
										customer.address2,
										'Address Info - Address Line 2',
										'text'
									)
								}
							/>
							<div className="sm:col-span-1"></div>
							<DescriptionItem
								withFunction
								term="City"
								details={customer.city}
								Icon="PencilIcon"
								handleClick={() =>
									handleClick(
										'city',
										customer.city,
										'Address Info - City',
										'text'
									)
								}
							/>
							<DescriptionItem
								withFunction
								term="State"
								details={getLabelFromValue(
									customer.state,
									FORMS.states
								)}
								Icon="PencilIcon"
								handleClick={() =>
									handleClick(
										'state',
										getLabelFromValue(
											customer.state,
											FORMS.states
										),
										'Address Info - State',
										'select',
										FORMS.states
									)
								}
							/>
							<DescriptionItem
								withFunction
								term="ZIP Code"
								details={customer.zip_code}
								Icon="PencilIcon"
								handleClick={() =>
									handleClick(
										'zip_code',
										customer.zip_code,
										'Address Info - ZIP Code',
										'text'
									)
								}
							/>
							<DescriptionItem
								withFunction
								term="Country"
								details={getLabelFromValue(
									customer.country,
									FORMS.countries
								)}
								Icon="PencilIcon"
								handleClick={() =>
									handleClick(
										'country',
										getLabelFromValue(
											customer.country,
											FORMS.countries
										),
										'Address Info - Country',
										'select',
										FORMS.countries
									)
								}
							/>
						</DescriptionList>
					</ContentSection>

					<ContentSectionDivider />

					<OutboundEmailAddresses
						customer={customer}
						setRefresh={setRefresh}
						outboundEmailAddresses={customer.outbound_emails}
					/>

					{customer.entity === 'clinic' && customer.isAssociate && (
						<Fragment>
							<ContentSectionDivider />

							<CustomerBillingMethods
								billingMethods={customer.billing_methods}
								setBillingModalState={setBillingModalState}
							/>
						</Fragment>
					)}

					<ContentSectionDivider />

					<AccountActions
						customer={customer}
						setRefresh={setRefresh}
					/>
				</Fragment>
			)}
		</Fragment>
	);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AccountActionsProps {
	customer: HydratedCustomer;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

function AccountActions(props: AccountActionsProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { customerId } = useParams();

	const [disabled, setDisabled] = useState(false);
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);
	const [user, setUser] = useState<Auth0User | null | undefined>(undefined);
	const [action, setAction] = useState<'Block' | 'Unblock' | 'Activate'>(
		'Block'
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !props.customer) return;

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(`/api/accounts/users/auth0|${props.customer._id.$oid}`);

				if (!unmounted) {
					console.log(data);
					setUser(data);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, props.customer]);

	const handleActivate = async (confirmActivate: boolean) => {
		if (disabled || !axios || !customerId) return;

		if (confirmModalVisible) {
			if (!confirmActivate) {
				toast.loading('Please confirm action!');
				return;
			}
		} else {
			setConfirmModalVisible(true);
			return;
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).post(`/api/accounts/activate/customer`, {
				customer_id: customerId,
			});

			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);
		}

		setDisabled(false);
		toast.dismiss();
	};

	const handleBlock = async (
		confirmBlock: boolean,
		blockStatus: 'Block' | 'Unblock'
	) => {
		if (disabled || !axios || !customerId) return;

		if (confirmModalVisible) {
			if (!confirmBlock) {
				toast.loading('Please confirm action!');
				return;
			}
		} else {
			setConfirmModalVisible(true);
			return;
		}

		setDisabled(true);

		toast.loading('Update...', toastOptions);

		try {
			await (
				await axios
			).post(`/api/accounts/block/customer`, {
				customer_id: customerId,
				block: blockStatus === 'Block',
			});

			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);
		}

		setConfirmModalVisible(false);
		setDisabled(false);
		toast.dismiss();
	};

	return (
		<Fragment>
			{action &&
				(() => {
					let options = {
						modalTitle: '',
						modalDescription: '',
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						handleSubmit: () => {},
					};

					if (action === 'Block') {
						options = {
							modalTitle: 'Block account',
							modalDescription:
								'The user will be blocked from logging into the client portal.',
							handleSubmit: () => handleBlock(true, action),
						};
					} else if (action === 'Unblock') {
						options = {
							modalTitle: 'Unblock account',
							modalDescription:
								'The user will be unblocked from logging into the client portal.',
							handleSubmit: () => handleBlock(true, action),
						};
					} else {
						options = {
							modalTitle: 'Activate account',
							modalDescription:
								'Activate (or reactivate) the account if the customer is encountering the following issues: cannot log into account, receiving access pending page, or has not received an email to change their password.',
							handleSubmit: () => handleActivate(true),
						};
					}

					return (
						<ConfirmationModal
							options={{
								modalTitle: options.modalTitle,
								modalDescription: options.modalDescription,
								handleSubmit: options.handleSubmit,
								modalVisible: confirmModalVisible,
								setModalVisible: setConfirmModalVisible,
								confirmationText: action,
							}}
						/>
					);
				})()}
			<ContentSection heading="Account actions" className="space-y-4">
				{/* <ActionCard
					disabled={disabled}
					actionText="Activate"
					heading="Activate account"
					handleSubmit={() => {
						setAction('Activate');
						setConfirmModalVisible(true);
					}}
					description="Activate (or reactivate) the account if the customer is encountering the following issues: cannot log into account, receiving access pending page, or has not received an email to change their password."
				/> */}
				{user === undefined ? (
					<LoadingBox />
				) : user === null ? (
					<EmptyBox
						Icon="UserIcon"
						heading="Account not activated"
						description="Activate the account to get started"
					/>
				) : user.blocked ? (
					<ActionCard
						disabled={disabled}
						actionText="Unblock"
						heading="Unblock account"
						handleSubmit={() => {
							setAction('Unblock');
							setConfirmModalVisible(true);
						}}
						description="The user will be unblocked from logging into the client portal."
					/>
				) : (
					<ActionCard
						type="red"
						actionText="Block"
						disabled={disabled}
						heading="Block account"
						handleSubmit={() => {
							setAction('Block');
							setConfirmModalVisible(true);
						}}
						description="The user will be blocked from logging into the client portal."
					/>
				)}
			</ContentSection>
		</Fragment>
	);
}

const ConfirmationModal = ({
	options: {
		modalTitle,
		handleSubmit,
		modalVisible,
		setModalVisible,
		confirmationText,
		modalDescription,
	},
}: {
	options: {
		modalVisible: boolean;
		confirmationText: string;
		setModalVisible: Dispatch<SetStateAction<boolean>>;
		handleSubmit: () => void;
		modalTitle: string;
		modalDescription: string;
	};
}) => {
	const [confirmation, setConfirmation] = useState('');

	const handleChange: ChangeEventHandler<HTMLInputElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement;

		if (target && target.name) setConfirmation(target.value);
	};

	const handleFormSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (!confirmation || confirmation !== confirmationText) {
			toast.error(`Enter ${confirmationText} to confirm!`);
			return;
		}

		handleSubmit();
	};

	return (
		<Modal
			title={modalTitle}
			customWidth="max-w-md"
			visible={modalVisible}
			description={modalDescription}
			onClose={() => {
				setConfirmation('');
				setModalVisible(false);
			}}
		>
			<Form handleSubmit={handleFormSubmit}>
				<FormGroup>
					<div className="sm:col-span-6">
						<GenericField
							required
							type="text"
							name="confirmation"
							value={confirmation}
							label={
								<span>
									Enter{' '}
									<span className="italic">
										{confirmationText}
									</span>{' '}
									to confirm
								</span>
							}
							handleInputChange={handleChange}
							placeholder={`e.g., ${confirmationText}`}
						/>
					</div>
				</FormGroup>
				<FormFooter>
					<Button
						type="submit"
						tier="tertiary"
						text={confirmationText}
					/>
				</FormFooter>
			</Form>
		</Modal>
	);
};
