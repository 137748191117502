import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, useNavigate } from 'react-router-dom';
import {
	useId,
	useState,
	Dispatch,
	Fragment,
	useEffect,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Table,
	Button,
	DataCell,
	useAxios,
	TableRow,
	TableBody,
	TableHead,
	Container,
	EmptyTable,
	HeaderCell,
	LoadingBox,
	TextButton,
	FormFooter,
	ContentWrapper,
	ContentSection,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	ASSAYS,
	ClinicData,
	formatDate,
	Pagination,
	NonClinicData,
	PaginationState,
	getLabelFromValue,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

const projectedFields = [
	'type',
	'_id',
	'sample.batch.name',
	'metadata.accessioning.completed.value',
	'sample.pangea_id',
	'sample.specimen_id',
	'patient.first_name',
	'patient.last_name',
	'sample.sample_received_date',
	'requisition_form.sample_collection_date',
	'sample.misc.sample_stability.stable_until',
	'customer.first_name',
	'customer.last_name',
	'organization.name',
];

type Data = (ClinicData | NonClinicData) & { checked: boolean };

/* eslint-disable-next-line */
export interface CreateBatchDataProps {}

export default function CreateBatchData(props: CreateBatchDataProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const navigate = useNavigate();

	const { user } = useAuth0();
	const { assay } = useParams();
	const assayLabel = assay
		? getLabelFromValue(assay, ASSAYS['assays'])
		: null;

	const [disabled, setDisabled] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const [mainCheckbox, setMainCheckbox] = useState(false);
	const [data, setData] = useState<Data[] | undefined>(undefined);
	const [selectedSamples, setSelectedSamples] = useState<Data[]>([]);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !user || !assay) return;
			else if (!unmounted) setData(undefined);

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/cls/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query: {
							'sample.batch': null,
							'sample.assay': assay,
						},
						projected_fields: projectedFields,
					}
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setData(
						parsedData.map((datum: ClinicData | NonClinicData) => ({
							...datum,
							checked: false,
						}))
					);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		user,
		assay,
		axios,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	// Checkbox functions
	const handleSelectRow = (sampleId: string) => {
		setData((prevValue) => {
			if (prevValue) {
				const newArray = prevValue.map((x_data) => {
					if (x_data._id.$oid === sampleId)
						return {
							...x_data,
							checked: !x_data.checked,
						};
					else return x_data;
				});

				return newArray;
			}

			return;
		});
	};

	const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
		if (data) {
			setMainCheckbox(true);

			setData(() =>
				data.map((x_data) => {
					return {
						...x_data,
						checked: event.target.checked,
					};
				})
			);
		}
	};

	useEffect(() => {
		if (data) {
			let count = 0;

			for (const user of data) {
				if (user.checked) count += 1;
			}

			setMainCheckbox(Boolean(data.length) && count === data.length);
			setShowButton(Boolean(count));
		}
	}, [data]);

	const handleAddSamples = () => {
		if (!data) return;

		setSelectedSamples(data.filter((sample) => sample.checked));
	};

	const handleCreateBatch = async () => {
		if (disabled || !axios || !selectedSamples) return;
		else if (!selectedSamples.length) {
			toast.error('Add samples to the batch!');
			return;
		}

		setDisabled(true);

		toast.loading('Creating...', toastOptions);

		try {
			const {
				data: { data },
			} = await (
				await axios
			).post('/api/cls/create/batch', {
				user,
				assay: assay,
				samples: selectedSamples.map((sample) => sample._id.$oid),
			});

			toast.dismiss();

			const parsedData = JSON.parse(data);
			console.log(parsedData);

			navigate(`/cls/view/batches/${parsedData.$oid}`);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	return (
		<Container>
			<ContentWrapper
				heading="Create batch"
				Icon="RectangleGroupIcon"
				description={
					assayLabel ? `For ${assayLabel} samples.` : undefined
				}
			>
				{!data ? (
					<LoadingBox />
				) : (
					<Fragment>
						<ContentSection heading="Available samples">
							<SamplesTable
								data={data}
								mainCheckbox={mainCheckbox}
								handleSelectRow={handleSelectRow}
								handleSelectAll={handleSelectAll}
								pagination={{
									pagination,
									setPagination,
								}}
							/>
							<div className="flex items-center justify-end pt-4">
								<Button
									type="submit"
									tier="tertiary"
									Icon="PlusIcon"
									text="Add sample(s)"
									disabled={!showButton}
									onClick={handleAddSamples}
								/>
							</div>
						</ContentSection>

						<ContentSectionDivider />

						<ContentSection
							heading="Selected samples"
							headingAction={
								<div className="bg-gray-100 border border-gray-200 px-3 py-1 rounded-md">
									<p className="text-sm font-medium">
										Number of selected samples:{' '}
										{selectedSamples.length}
									</p>
								</div>
							}
						>
							<SamplesTable
								data={selectedSamples}
								type="selected_samples"
								setSelectedSamples={setSelectedSamples}
							/>
							<FormFooter>
								<Button
									type="submit"
									tier="primary"
									Icon="CheckIcon"
									text="Create batch"
									onClick={handleCreateBatch}
									disabled={!(selectedSamples.length > 0)}
								/>
							</FormFooter>
						</ContentSection>
					</Fragment>
				)}
			</ContentWrapper>
		</Container>
	);
}

interface SamplesTableProps {
	mainCheckbox?: boolean;
	data: Data[] | undefined;
	type?: 'selected_samples';
	pagination?: PaginationState;
	handleSelectRow?: (sampleId: string) => void;
	handleSelectAll?: ChangeEventHandler<HTMLInputElement>;
	setSelectedSamples?: Dispatch<SetStateAction<Data[]>>;
}

function SamplesTable(props: SamplesTableProps) {
	return (
		<Table pagination={props.pagination}>
			<TableHead>
				{'mainCheckbox' in props && 'handleSelectAll' in props && (
					<HeaderCell customWidth="w-16">
						<div className="flex items-center justify-center">
							<input
								type="checkbox"
								checked={props.mainCheckbox}
								onChange={props.handleSelectAll}
								className="w-5 h-5 cursor-pointer border border-gray-300 rounded-md"
							/>
						</div>
					</HeaderCell>
				)}
				{props.setSelectedSamples && <HeaderCell>Actions</HeaderCell>}
				{/* <HeaderCell>In other batches?</HeaderCell> */}
				<HeaderCell>Accessioning completed?</HeaderCell>
				<HeaderCell>Pangea id</HeaderCell>
				<HeaderCell>Specimen id</HeaderCell>
				<HeaderCell>Patient First Name</HeaderCell>
				<HeaderCell>Patient Last Name</HeaderCell>
				<HeaderCell>Received Date</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
				<HeaderCell>Stable by date</HeaderCell>
				<HeaderCell>Provider</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data && props.data.length ? (
					props.data.map((datum, index) => {
						const clinicSample = datum.type === 'clinic';

						return (
							<TableRow key={index} index={index}>
								{'mainCheckbox' in props && (
									<DataCell>
										<div className="flex items-center justify-center">
											<input
												type="checkbox"
												checked={datum.checked}
												onChange={() => {
													if (props.handleSelectRow)
														props.handleSelectRow(
															datum._id.$oid
														);
												}}
												className="w-5 h-5 cursor-pointer border border-gray-300 rounded-md"
											/>
										</div>
									</DataCell>
								)}
								{props.setSelectedSamples && (
									<DataCell>
										<TextButton
											color="blue"
											text="Remove"
											type="button"
											className="group-hover:text-gray-700"
											onClick={() => {
												if (props.setSelectedSamples)
													props.setSelectedSamples(
														(prevSamples) => {
															return prevSamples.filter(
																(sample) =>
																	sample._id
																		.$oid !==
																	datum._id
																		.$oid
															);
														}
													);
											}}
										/>
									</DataCell>
								)}
								{/* <DataCell>
									{datum.sample.batch &&
										datum.sample.batch.name}
								</DataCell> */}
								<DataCell>
									{getLabelFromValue(
										datum.metadata.accessioning.completed.value.toString(),
										FORMS.yes_no_options
									)}
								</DataCell>
								<DataCell>{datum.sample.pangea_id}</DataCell>
								<DataCell>{datum.sample.specimen_id}</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.first_name
										: null}
								</DataCell>
								<DataCell>
									{datum.patient
										? datum.patient.last_name
										: null}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.sample.sample_received_date
									)}
								</DataCell>
								<DataCell>
									{clinicSample
										? formatDate(
												datum.requisition_form
													.sample_collection_date
										  )
										: formatDate(
												datum.sample
													.sample_collection_date
										  )}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.sample.misc.sample_stability
											.stable_until &&
											datum.sample.misc.sample_stability
												.stable_until.$date
									)}
								</DataCell>
								<DataCell>
									{`${datum.customer.first_name} ${datum.customer.last_name} - ${datum.organization.name}`}
								</DataCell>
							</TableRow>
						);
					})
				) : (
					<EmptyTable
						Icon="DocumentTextIcon"
						heading="No selected sample(s)"
						description="Select a sample from the table above"
					/>
				)}
			</TableBody>
		</Table>
	);
}
