/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	useState,
	Fragment,
	useContext,
	ChangeEvent,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	FormGroup,
	DateField,
	LoadingBox,
	OptionCard,
	SelectField,
	ContentSection,
	MultipleCombobox,
	OptionCardContainer,
} from '@pangea-lis-apps/ui';
import { STI, Option, ICD_10_CODES, FORMS } from '@pangea-lis-apps/utils';

import ProcessTRFComponents from '../../components/index';
import { usePopulateFormValues } from '../../utils/helpers';
import { ProcessTRFStepsContext } from '../../components/form-wrapper';
import { handleMultipleComboboxSelect } from '../../../../../../../../../utils/helpers/forms';
import AddNewICD10CodeModal from '../../../../../../../../components/modals/add-new-icd-10-code-modal';

export interface FormValues {
	order_icd_10_codes: Option[];
	order_tests: Option[];
	order_ordering_provider_signature_provided: string;
	order_ordering_provider_date: string;
	sample_specimen_type: string;
	sample_collection_date: string;
	flag: {
		order_icd_10_codes: boolean;
		order_tests: boolean;
		order_ordering_provider_signature_provided: boolean;
		order_ordering_provider_date: boolean;
		sample_specimen_type: boolean;
		sample_collection_date: boolean;
	};
}

const initialFormValues = {
	order_icd_10_codes: [],
	order_tests: [],
	order_ordering_provider_signature_provided: '',
	order_ordering_provider_date: '',
	sample_specimen_type: '',
	sample_collection_date: '',
	flag: {
		order_icd_10_codes: false,
		order_tests: false,
		order_ordering_provider_signature_provided: false,
		order_ordering_provider_date: false,
		sample_specimen_type: false,
		sample_collection_date: false,
	},
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OrderSampleInfoProps {}

export default function OrderSampleInfo(props: OrderSampleInfoProps) {
	const {
		data,
		formValuesRef,
		handleSubmit,
		formId,
		formValues,
		setFormValues,
	} = useContext(ProcessTRFStepsContext);

	const [ICD10CodeOptions, setICD10CodeOptions] = useState(
		ICD_10_CODES.codes.sti
	);
	const [orderTestsOptions, setOrderTestsOptions] = useState(
		STI['1.0'].tests
	);

	usePopulateFormValues(
		data,
		formValuesRef,
		setFormValues,
		initialFormValues,
		'requisition_form'
	);

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name)
			setFormValues((prevValues: FormValues) => {
				if (prevValues)
					return {
						...prevValues,
						[target.name]: target.value,
					};

				return prevValues;
			});
	};

	// For adding ICD-10 code
	const [showModal, setShowModal] = useState(false);

	return !formValues ? (
		<LoadingBox />
	) : (
		<Fragment>
			<AddNewICD10CodeModal
				assay="sti"
				formValues={formValues}
				setFormValues={setFormValues}
				visible={{
					visible: showModal,
					setVisible: setShowModal,
				}}
			/>
			<ContentSection>
				<Form id={formId} handleSubmit={handleSubmit}>
					<FormGroup heading="Order information">
						<div className="sm:col-span-2">
							<MultipleCombobox
								label="ICD-10 Code(s)"
								name="order_icd_10_codes"
								showRequiredAsterisk={true}
								value={formValues.order_icd_10_codes}
								handleSelect={(selectedOptions: Option[]) => {
									handleMultipleComboboxSelect(
										selectedOptions,
										'order_icd_10_codes',
										setFormValues,
										setICD10CodeOptions,
										setShowModal
									);
								}}
								options={ICD10CodeOptions}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="order_icd_10_codes"
									/>
								}
							/>
						</div>
						{formValues.order_icd_10_codes &&
						formValues.order_icd_10_codes.length > 0 ? (
							<Fragment>
								<div className="sm:col-span-2">
									<OptionCardContainer heading="Selected ICD-10 Code(s)">
										{formValues.order_icd_10_codes.map(
											(option: Option, index: number) => (
												<OptionCard
													key={index}
													option={option}
													handleRemove={(
														selectedCode: string
													) => {
														setFormValues(
															(
																prevVal: FormValues
															) => {
																if (prevVal) {
																	const new_order_icd_10_codes =
																		prevVal.order_icd_10_codes.filter(
																			(
																				option: Option
																			) =>
																				option.value !==
																				selectedCode
																		);

																	return {
																		...prevVal,
																		order_icd_10_codes:
																			new_order_icd_10_codes,
																	};
																}

																return prevVal;
															}
														);
													}}
												/>
											)
										)}
									</OptionCardContainer>
								</div>
								<div className="sm:col-span-2"></div>
							</Fragment>
						) : (
							<div className="sm:col-span-4"></div>
						)}
						<div className="sm:col-span-2">
							<MultipleCombobox
								label="Test(s)"
								name="order_tests"
								showRequiredAsterisk={true}
								value={formValues.order_tests}
								handleSelect={(selectedOptions: Option[]) => {
									handleMultipleComboboxSelect(
										selectedOptions,
										'order_tests',
										setFormValues,
										setOrderTestsOptions
									);
								}}
								options={orderTestsOptions}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										property="order_tests"
										formValues={formValues}
										setFormValues={setFormValues}
									/>
								}
							/>
						</div>
						{formValues.order_tests &&
						formValues.order_tests.length > 0 ? (
							<Fragment>
								<div className="sm:col-span-2">
									<OptionCardContainer heading="Selected test(s)">
										{formValues.order_tests.map(
											(option: Option, index: number) => (
												<OptionCard
													key={index}
													option={option}
													handleRemove={(
														selectedTest: string
													) => {
														setFormValues(
															(
																prevVal: FormValues
															) => {
																if (prevVal) {
																	const newArray =
																		prevVal.order_tests.filter(
																			(
																				option: Option
																			) =>
																				option.value !==
																				selectedTest
																		);

																	return {
																		...prevVal,
																		order_tests:
																			newArray,
																	};
																}

																return prevVal;
															}
														);
													}}
												/>
											)
										)}
									</OptionCardContainer>
								</div>
								<div className="sm:col-span-2"></div>
							</Fragment>
						) : (
							<div className="sm:col-span-4"></div>
						)}
						<div className="sm:col-span-2">
							<SelectField
								required
								name="order_ordering_provider_signature_provided"
								label="Provider Signature Provided"
								value={
									formValues.order_ordering_provider_signature_provided
								}
								handleSelect={handleChange}
								options={FORMS.yes_no_options}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="order_ordering_provider_signature_provided"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<DateField
								label="Date Provided"
								showRequiredAsterisk={true}
								handleInputChange={handleChange}
								name="order_ordering_provider_date"
								value={formValues.order_ordering_provider_date}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="order_ordering_provider_date"
									/>
								}
							/>
						</div>
					</FormGroup>
					<FormGroup heading="Sample information">
						<div className="sm:col-span-2">
							<DateField
								name="sample_collection_date"
								label="Collection Date"
								showRequiredAsterisk={true}
								handleInputChange={handleChange}
								value={formValues.sample_collection_date}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="sample_collection_date"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-2">
							<SelectField
								label="Specimen Type"
								name="sample_specimen_type"
								showRequiredAsterisk={true}
								handleSelect={handleChange}
								options={STI['1.0']['specimen_types']}
								value={formValues.sample_specimen_type}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="sample_specimen_type"
									/>
								}
							/>
						</div>
					</FormGroup>
				</Form>
			</ContentSection>
		</Fragment>
	);
}
