/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	useState,
	Dispatch,
	Fragment,
	FormEvent,
	useContext,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Modal,
	Button,
	FormGroup,
	DateField,
	FormFooter,
	LoadingBox,
	OptionCard,
	GenericField,
	ContentSection,
	MultipleCombobox,
	OptionCardContainer,
} from '@pangea-lis-apps/ui';
import { Option, BLADDERCARE } from '@pangea-lis-apps/utils';

import ProcessTRFComponents from '../../components/index';
import { usePopulateFormValues } from '../../utils/helpers';
import { handleComboboxSelect } from '../../../../helpers/form';
import { ProcessTRFStepsContext } from '../../components/form-wrapper';

type NewOptionProperties =
	| 'relevant_clinical_information_treatments'
	| 'relevant_clinical_information_urological_cancers'
	| 'relevant_clinical_information_urological_conditions';

interface FormValues {
	relevant_clinical_information_date_of_diagnosis: string;
	relevant_clinical_information_urological_cancers: Option[];
	relevant_clinical_information_urological_conditions: Option[];
	relevant_clinical_information_treatments: Option[];
	flag: {
		relevant_clinical_information_date_of_diagnosis: boolean;
		relevant_clinical_information_urological_cancers: boolean;
		relevant_clinical_information_urological_conditions: boolean;
		relevant_clinical_information_treatments: boolean;
	};
}

const initialFormValues = {
	relevant_clinical_information_date_of_diagnosis: '',
	relevant_clinical_information_urological_cancers: [],
	relevant_clinical_information_urological_conditions: [],
	relevant_clinical_information_treatments: [],
	flag: {
		relevant_clinical_information_date_of_diagnosis: true,
		relevant_clinical_information_urological_cancers: true,
		relevant_clinical_information_urological_conditions: true,
		relevant_clinical_information_treatments: true,
	},
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RelevantClinicalInfoProps {}

export default function RelevantClinicalInfo(props: RelevantClinicalInfoProps) {
	const {
		data,
		formId,
		formValuesRef,
		handleSubmit,
		formValues,
		setFormValues,
	} = useContext(ProcessTRFStepsContext);

	const [addOtherOptionModal, setAddOtherOptionModal] = useState(false);
	const [addOtherOptionProperty, setAddOtherOptionProperty] =
		useState<NewOptionProperties>(
			'relevant_clinical_information_urological_cancers'
		);

	usePopulateFormValues(
		data,
		formValuesRef,
		setFormValues,
		initialFormValues,
		'requisition_form'
	);

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name)
			setFormValues((prevValues: FormValues) => {
				if (prevValues)
					return {
						...prevValues,
						[target.name]: target.value,
					};

				return prevValues;
			});
	};

	return !formValues || !data ? (
		<LoadingBox />
	) : (
		<Fragment>
			<AddOtherOptionModal
				property={addOtherOptionProperty}
				visible={{
					visible: addOtherOptionModal,
					setVisible: setAddOtherOptionModal,
				}}
				setFormValues={setFormValues}
			/>
			<ContentSection>
				<Form id={formId} handleSubmit={handleSubmit}>
					<FormGroup heading="Relevant clinical information">
						<div className="sm:col-span-2">
							<DateField
								label="Date of Diagnosis"
								handleInputChange={handleChange}
								name="relevant_clinical_information_date_of_diagnosis"
								value={
									formValues[
										'relevant_clinical_information_date_of_diagnosis'
									]
								}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="relevant_clinical_information_date_of_diagnosis"
									/>
								}
							/>
						</div>
						<div className="sm:col-span-4"></div>
						<div className="sm:col-span-2">
							<MultipleCombobox
								label="Urological Cancers"
								name="relevant_clinical_information_urological_cancers"
								value={
									formValues[
										'relevant_clinical_information_urological_cancers'
									]
								}
								handleSelect={(selectedOptions: Option[]) => {
									if (
										selectedOptions.findIndex(
											(option) => option.value === 'other'
										) !== -1
									) {
										setAddOtherOptionProperty(
											'relevant_clinical_information_urological_cancers'
										);
										setAddOtherOptionModal(true);
										return;
									}

									handleComboboxSelect(
										selectedOptions,
										setFormValues,
										'relevant_clinical_information_urological_cancers'
									);
								}}
								options={
									BLADDERCARE['4.1']
										.urological_cancers_options
								}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="relevant_clinical_information_urological_cancers"
									/>
								}
							/>
						</div>
						{formValues[
							'relevant_clinical_information_urological_cancers'
						] &&
						formValues[
							'relevant_clinical_information_urological_cancers'
						].length ? (
							<Fragment>
								<div className="sm:col-span-2">
									<OptionCardContainer heading="Selected urological cancer(s)">
										{formValues[
											'relevant_clinical_information_urological_cancers'
										].map(
											(option: Option, index: number) => (
												<OptionCard
													key={index}
													option={option}
													handleRemove={(
														selectedTest: string
													) => {
														setFormValues(
															(
																prevVal: FormValues
															) => {
																if (prevVal)
																	return {
																		...prevVal,
																		relevant_clinical_information_urological_cancers:
																			prevVal[
																				'relevant_clinical_information_urological_cancers'
																			].filter(
																				(
																					option: Option
																				) =>
																					option.value !==
																					selectedTest
																			),
																	};

																return prevVal;
															}
														);
													}}
												/>
											)
										)}
									</OptionCardContainer>
								</div>
								<div className="sm:col-span-2"></div>
							</Fragment>
						) : (
							<div className="sm:col-span-4"></div>
						)}
						<div className="sm:col-span-2">
							<MultipleCombobox
								label="Urological Conditions"
								name="relevant_clinical_information_urological_conditions"
								value={
									formValues[
										'relevant_clinical_information_urological_conditions'
									]
								}
								handleSelect={(selectedOptions: Option[]) => {
									if (
										selectedOptions.findIndex(
											(option) => option.value === 'other'
										) !== -1
									) {
										setAddOtherOptionProperty(
											'relevant_clinical_information_urological_conditions'
										);
										setAddOtherOptionModal(true);
										return;
									}

									handleComboboxSelect(
										selectedOptions,
										setFormValues,
										'relevant_clinical_information_urological_conditions'
									);
								}}
								options={
									BLADDERCARE['4.1']
										.urological_conditions_options
								}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="relevant_clinical_information_urological_conditions"
									/>
								}
							/>
						</div>
						{formValues[
							'relevant_clinical_information_urological_conditions'
						] &&
						formValues[
							'relevant_clinical_information_urological_conditions'
						].length ? (
							<Fragment>
								<div className="sm:col-span-2">
									<OptionCardContainer heading="Selected urological condition(s)">
										{formValues[
											'relevant_clinical_information_urological_conditions'
										].map(
											(option: Option, index: number) => (
												<OptionCard
													key={index}
													option={option}
													handleRemove={(
														selectedTest: string
													) => {
														setFormValues(
															(
																prevVal: FormValues
															) => {
																if (prevVal)
																	return {
																		...prevVal,
																		relevant_clinical_information_urological_conditions:
																			prevVal[
																				'relevant_clinical_information_urological_conditions'
																			].filter(
																				(
																					option: Option
																				) =>
																					option.value !==
																					selectedTest
																			),
																	};

																return prevVal;
															}
														);
													}}
												/>
											)
										)}
									</OptionCardContainer>
								</div>
								<div className="sm:col-span-2"></div>
							</Fragment>
						) : (
							<div className="sm:col-span-4"></div>
						)}
						<div className="sm:col-span-2">
							<MultipleCombobox
								label="Treatments"
								name="relevant_clinical_information_treatments"
								value={
									formValues[
										'relevant_clinical_information_treatments'
									]
								}
								handleSelect={(selectedOptions: Option[]) => {
									if (
										selectedOptions.findIndex(
											(option) => option.value === 'other'
										) !== -1
									) {
										setAddOtherOptionProperty(
											'relevant_clinical_information_treatments'
										);
										setAddOtherOptionModal(true);

										return;
									}

									handleComboboxSelect(
										selectedOptions,
										setFormValues,
										'relevant_clinical_information_treatments'
									);
								}}
								options={BLADDERCARE['4.1'].treatment_options}
								fieldAction={
									<ProcessTRFComponents.FlagButton
										formValues={formValues}
										setFormValues={setFormValues}
										property="relevant_clinical_information_treatments"
									/>
								}
							/>
						</div>
						{formValues[
							'relevant_clinical_information_treatments'
						] &&
						formValues['relevant_clinical_information_treatments']
							.length ? (
							<Fragment>
								<div className="sm:col-span-2">
									<OptionCardContainer heading="Selected treatment option(s)">
										{formValues[
											'relevant_clinical_information_treatments'
										].map(
											(option: Option, index: number) => (
												<OptionCard
													key={index}
													option={option}
													handleRemove={(
														selectedTest: string
													) => {
														setFormValues(
															(
																prevVal: FormValues
															) => {
																if (prevVal)
																	return {
																		...prevVal,
																		relevant_clinical_information_treatments:
																			prevVal[
																				'relevant_clinical_information_treatments'
																			].filter(
																				(
																					option: Option
																				) =>
																					option.value !==
																					selectedTest
																			),
																	};

																return prevVal;
															}
														);
													}}
												/>
											)
										)}
									</OptionCardContainer>
								</div>
								<div className="sm:col-span-2"></div>
							</Fragment>
						) : (
							<div className="sm:col-span-4"></div>
						)}
					</FormGroup>
				</Form>
			</ContentSection>
		</Fragment>
	);
}

interface AddOtherOptionModalProps {
	property: NewOptionProperties;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
	setFormValues: Dispatch<SetStateAction<FormValues | undefined>>;
}

const AddOtherOptionModal = ({
	visible: { visible, setVisible },
	property,
	setFormValues,
}: AddOtherOptionModalProps) => {
	const [value, setValue] = useState('');

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		setFormValues((prevValues: FormValues | undefined) => {
			if (prevValues && property in prevValues) {
				const propertyValues = prevValues[
					property as keyof typeof prevValues
				] as Option[];

				return {
					...prevValues,
					[property]: [
						...propertyValues,
						{
							label: value,
							value: value,
						},
					],
				};
			}

			return prevValues;
		});

		setVisible(false);
		setValue('');
	};

	const handleChange: ChangeEventHandler<HTMLInputElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement;

		if (target && target.name) setValue(target.value);
	};

	return (
		<Modal
			visible={visible}
			customWidth="max-w-sm"
			title="Add new option"
			onClose={() => setVisible(false)}
			description="Check the list before adding a new option."
		>
			<Form handleSubmit={handleSubmit}>
				<FormGroup>
					<div className="sm:col-span-6">
						<GenericField
							required
							type="text"
							name="value"
							value={value}
							label="New option"
							handleInputChange={handleChange}
							placeholder="Enter new option"
						/>
					</div>
				</FormGroup>
				<FormFooter>
					<Button
						text="Add"
						type="submit"
						tier="tertiary"
						Icon="PlusIcon"
					/>
				</FormFooter>
			</Form>
		</Modal>
	);
};
