/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
	useId,
	useRef,
	Fragment,
	useState,
	ChangeEvent,
	KeyboardEvent,
	ChangeEventHandler,
	useContext,
} from 'react';

import {
	Form,
	Button,
	useAxios,
	FormGroup,
	Container,
	DateField,
	FormFooter,
	TextButton,
	LoadingBox,
	OptionCard,
	SelectField,
	GenericField,
	ContentSection,
	ContentWrapper,
	MultipleCombobox,
	OptionCardContainer,
} from '@pangea-lis-apps/ui';
import { ASSAYS, Option } from '@pangea-lis-apps/utils';

import { handleRemoveOption } from '../../helpers/form';
import { ProcessNonClinicSamplesContext } from './form-wrapper';
import FlagButton from '../../../../../../components/flag-button';
import { handleMultipleComboboxSelect } from '../../../../../../../utils/helpers/forms';
import {
	usePopulateFormValues,
	normalizeAccessioningFormValues,
} from '../../clinic-samples/trf/utils/helpers';
import IdOverwriteConfirmationModal from '../../helpers/specimen-id-overwrite-confirmation-modal';

interface FormValues {
	pangea_id: string;
	specimen_id: string;
	order_tests: Option[];
	tracking_number: string;
	sample_specimen_type: string;
	sample_received_date: string;
	sample_collection_date: string;
	flag: {
		specimen_id: boolean;
		order_tests: boolean;
		tracking_number: boolean;
		sample_specimen_type: boolean;
		sample_received_date: boolean;
		sample_collection_date: boolean;
	};
}

const initialFormValues = {
	pangea_id: '',
	specimen_id: '',
	order_tests: [],
	tracking_number: '',
	sample_specimen_type: '',
	sample_received_date: '',
	sample_collection_date: '',
	flag: {
		specimen_id: false,
		order_tests: false,
		tracking_number: false,
		sample_specimen_type: false,
		sample_received_date: false,
		sample_collection_date: false,
	},
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProcessSTINonClinicSampleProps {}

export default function ProcessSTINonClinicSample(
	props: ProcessSTINonClinicSampleProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const navigate = useNavigate();

	const [disabled, setDisabled] = useState(false);
	const {
		data,
		formValues,
		setFormValues,
		formValuesRef,
		redirectPathQuery,
	} = useContext(ProcessNonClinicSamplesContext);

	usePopulateFormValues(
		data,
		formValuesRef,
		setFormValues,
		initialFormValues,
		'sample'
	);

	const [orderTestsOptions, setOrderTestsOptions] = useState([
		{ label: 'Select option(s)', value: '', disabled: true },
		...ASSAYS['sti']['tests'],
	]);

	// For overwriting specimen id
	const pangeaIdOverwriteConfirmationRef = useRef(false);
	const [
		showIdOverwriteConfirmationModal,
		setShowIdOverwriteConfirmationModal,
	] = useState(false);

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name)
			setFormValues((prevValues: FormValues) => {
				return {
					...prevValues,
					[target.name]: target.value,
				};
			});
	};

	const handleSubmit = async () => {
		if (disabled || !axios || !formValues || !data) return;

		const redirectURL = `/accessioner/accession/sample/sti/data/${data._id.$oid}/non-clinic-sample/print-barcode${redirectPathQuery}`;

		if (
			JSON.stringify(formValuesRef.current) === JSON.stringify(formValues)
		) {
			navigate(redirectURL);
			return;
		} else if (
			formValues['sample_received_date'] &&
			formValues['sample_collection_date'] &&
			new Date(formValues['sample_received_date']) <
				new Date(formValues['sample_collection_date'])
		) {
			toast.error(
				'Invalid received date! Received Date must be after the collection date.'
			);
			return;
		} else if (data) {
			if (
				data.sample.specimen_id &&
				formValues['specimen_id'] !== data.sample.specimen_id
			) {
				if (!pangeaIdOverwriteConfirmationRef.current) {
					setShowIdOverwriteConfirmationModal(true);
					return;
				}

				pangeaIdOverwriteConfirmationRef.current = false;
				setShowIdOverwriteConfirmationModal(false);
			}
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			const normalizedFormValues = normalizeAccessioningFormValues(
				data,
				formValues,
				'sample'
			);

			await (
				await axios
			).patch(`/api/accessioner/data/${data._id.$oid}/sample`, {
				form_data: normalizedFormValues,
			});

			toast.dismiss();

			setFormValues(initialFormValues);
			navigate(redirectURL);
		} catch (error) {
			setDisabled(false);
		}
	};

	return (
		<Container>
			<ContentWrapper
				Icon="FingerPrintIcon"
				heading="Process non-clinic STI sample"
			>
				{!formValues || !data ? (
					<LoadingBox />
				) : (
					<Fragment>
						<IdOverwriteConfirmationModal
							data={data}
							idToOverwrite={'specimen_id'}
							id={formValues.specimen_id}
							handleUpdateSample={handleSubmit}
							resetId={() =>
								setFormValues((prevValue: FormValues) => ({
									...prevValue,
									specimen_id: data.sample.specimen_id,
								}))
							}
							idOverwriteConfirmationRef={
								pangeaIdOverwriteConfirmationRef
							}
							visible={{
								visible: showIdOverwriteConfirmationModal,
								setVisible: setShowIdOverwriteConfirmationModal,
							}}
						/>
						<ContentSection id="form">
							<Form>
								<FormGroup heading="Order information">
									<div className="sm:col-span-2">
										<MultipleCombobox
											label="Tests"
											name="order_tests"
											showRequiredAsterisk={true}
											fieldAction={
												<div className="space-x-2">
													<TextButton
														color="blue"
														type="button"
														onClick={() => {
															setFormValues(
																(
																	prevValues: FormValues
																) => ({
																	...prevValues,
																	order_tests:
																		ASSAYS[
																			'sti'
																		][
																			'tests'
																		],
																})
															);
														}}
														text="Select all"
													/>
													<FlagButton
														formValues={formValues}
														setFormValues={
															setFormValues
														}
														property="order_tests"
													/>
												</div>
											}
											value={formValues['order_tests']}
											handleSelect={(
												selectedOptions: Option[]
											) =>
												handleMultipleComboboxSelect(
													selectedOptions,
													'order_tests',
													setFormValues,
													setOrderTestsOptions
												)
											}
											options={orderTestsOptions}
										/>
									</div>
									{formValues['order_tests'].length ? (
										<Fragment>
											<div className="sm:col-span-2">
												<OptionCardContainer heading="Selected test(s)">
													{formValues[
														'order_tests'
													].map(
														(
															option: Option,
															index: number
														) => (
															<OptionCard
																key={index}
																option={option}
																handleRemove={(
																	selectedTest: string
																) =>
																	handleRemoveOption(
																		selectedTest,
																		setFormValues,
																		'order_tests'
																	)
																}
															/>
														)
													)}
												</OptionCardContainer>
											</div>
											<div className="sm:col-span-2"></div>
										</Fragment>
									) : (
										<div className="sm:col-span-4"></div>
									)}
									<div className="sm:col-span-2">
										<SelectField
											name="sample_specimen_type"
											label="Specimen Type"
											showRequiredAsterisk={true}
											handleSelect={handleChange}
											value={
												formValues[
													'sample_specimen_type'
												]
											}
											options={[
												{
													value: '',
													label: 'Select an Option',
												},
												...ASSAYS['sti'][
													'specimen_types'
												],
											]}
											fieldAction={
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="sample_specimen_type"
												/>
											}
										/>
									</div>
								</FormGroup>

								<hr />

								<FormGroup heading="Sample information">
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											disabled={true}
											name="pangea_id"
											label="Pangea ID"
											placeholder="e.g., XZ12345678"
											value={formValues['pangea_id']}
											handleInputChange={handleChange}
										/>
									</div>
									<div className="sm:col-span-4"></div>
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											name="specimen_id"
											label="Specimen ID"
											handleKeyDown={(
												event: KeyboardEvent<HTMLInputElement>
											) => {
												if (event.code === 'Enter')
													event.preventDefault();
											}}
											placeholder="e.g., XZ12345678"
											value={formValues['specimen_id']}
											handleInputChange={handleChange}
											fieldAction={
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="specimen_id"
												/>
											}
										/>
									</div>
									<div className="sm:col-span-2">
										<DateField
											showRequiredAsterisk={true}
											name="sample_collection_date"
											label="Sample Collection Date"
											handleInputChange={handleChange}
											value={
												formValues[
													'sample_collection_date'
												]
											}
											fieldAction={
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="sample_collection_date"
												/>
											}
										/>
									</div>
									<div className="sm:col-span-2">
										<DateField
											showRequiredAsterisk={true}
											name="sample_received_date"
											label="Received Date"
											min={
												formValues[
													'sample_collection_date'
												] &&
												formValues[
													'sample_collection_date'
												]
											}
											handleInputChange={handleChange}
											value={
												formValues[
													'sample_received_date'
												]
											}
											fieldAction={
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="sample_received_date"
												/>
											}
										/>
									</div>
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											name="tracking_number"
											label="Tracking Number"
											placeholder="e.g., XZ12345678"
											value={
												formValues['tracking_number']
											}
											handleInputChange={handleChange}
											fieldAction={
												<FlagButton
													formValues={formValues}
													setFormValues={
														setFormValues
													}
													property="tracking_number"
												/>
											}
										/>
									</div>
								</FormGroup>
								<FormFooter className="flex items-center justify-between">
									<TextButton
										color="gray"
										type="button"
										onClick={() => {
											navigate(
												redirectPathQuery
													? redirectPathQuery
													: `/accessioner/accession/sessions/${data.accessioning_session._id.$oid}/preprocess`
											);
										}}
										text={<span>&#8592; Back</span>}
									/>
									<Button
										type="button"
										tier="tertiary"
										disabled={disabled}
										onClick={handleSubmit}
										text="Save and continue"
									/>
								</FormFooter>
							</Form>
						</ContentSection>
					</Fragment>
				)}
			</ContentWrapper>
		</Container>
	);
}
