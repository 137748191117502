import { ReactNode } from 'react';

import { IconButton } from '@pangea-lis-apps/ui';
import { Item, SHIPPING, Option } from '@pangea-lis-apps/utils';

export interface ItemCardProps {
	item: Item;
	handleRemove: (name: string) => void;
}

export function ItemCard(props: ItemCardProps) {
	const { items } = SHIPPING;

	return (
		<div className="shadow-sm border border-gray-200 rounded-lg inline-flex px-4 py-2 items-center space-x-6">
			<div>
				<p className="text-sm font-semibold text-gray-500">
					{
						items.filter((item) => item.value === props.item.sku)[0]
							.label
					}
				</p>
				<p className="text-base">
					{props.item.quantity}{' '}
					{props.item.quantity === 1 ? 'Unit' : 'Units'}
				</p>
			</div>
			<IconButton
				color="gray"
				type="button"
				Icon="XMarkIcon"
				onClick={() => props.handleRemove(props.item.sku)}
				className="p-2 rounded-full hover:bg-gray-100 border border-gray-200"
			/>
		</div>
	);
}

interface OptionCardProps {
	option: Option;
	handleRemove: (name: string) => void;
}

export function OptionCard(props: OptionCardProps) {
	return (
		<div className="shadow-sm bg-white border border-gray-300 rounded-lg inline-flex pl-2 py-1 pr-4 items-center space-x-2">
			<IconButton
				color="gray"
				type="button"
				Icon="XMarkIcon"
				className="p-1 rounded-full hover:bg-gray-100"
				onClick={() => props.handleRemove(props.option.value)}
			/>
			<p className="text-sm text-gray-500 font-medium">
				{props.option.label}
			</p>
		</div>
	);
}

interface OptionCardContainerProps {
	heading: string;
	children: ReactNode;
	description?: ReactNode;
}

export function OptionCardContainer(props: OptionCardContainerProps) {
	return (
		<div className="border border-gray-200 shadow-sm bg-gray-100 py-3 px-3 rounded-md flex flex-col">
			<p className="text-sm font-medium">{props.heading}</p>

			<hr className="mt-1 mb-3" />

			<div className="flex items-end flex-wrap gap-2">
				{props.children}
			</div>
		</div>
	);
}
