import { ChangeEventHandler } from 'react';

import { classNames } from '@pangea-lis-apps/utils';

export interface CheckboxFieldProps {
	name: string;
	value: string;
	label?: string;
	disabled?: boolean;
	required?: boolean;
	checkedValues?: string[];
	withDescription?: boolean;
	description?: string | null;
	handleInputChange: ChangeEventHandler<HTMLInputElement> | undefined;
}

export function CheckboxField(props: CheckboxFieldProps) {
	return (
		<div className="relative flex items-start">
			<div className="flex h-5 items-center">
				<input
					type="checkbox"
					id={props.name}
					name={props.name}
					value={props.value}
					disabled={props.disabled}
					required={props.required}
					onChange={props.handleInputChange}
					aria-describedby={`${props.name}-description`}
					checked={
						props.checkedValues
							? props.checkedValues.includes(props.value)
							: props.value === 'true'
					}
					className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed"
				/>
			</div>
			{props.label && (
				<div className="ml-3 text-sm">
					<label
						htmlFor={props.name}
						className={classNames(
							props.withDescription ? 'font-medium' : '',
							'text-gray-700'
						)}
					>
						{props.label}
					</label>
					{props.withDescription && (
						<span
							className="text-gray-500"
							id={`${props.name}-description`}
						>
							<span className="sr-only">{props.label}</span>{' '}
							{props.description}
						</span>
					)}
				</div>
			)}
		</div>
	);
}

export default CheckboxField;
