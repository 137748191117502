import { toast } from 'react-hot-toast';
import { useParams, useNavigate } from 'react-router-dom';
import { useId, useRef, useState, Fragment, useEffect, FormEvent } from 'react';

import {
	Form,
	Button,
	useAxios,
	Container,
	LoadingBox,
	FormFooter,
	ContentWrapper,
	ContentSection,
	DescriptionItem,
	DescriptionList,
	ContentSectionDivider,
	TRFComponents,
} from '@pangea-lis-apps/ui';
import { ClinicData } from '@pangea-lis-apps/utils';

import {
	BillingInfoFields,
	BillingInfoFormValues,
	getInitialBillingInfoFormValues,
} from '../accessioner/accessioning/accession/clinic-samples/trf/components/billing-information-fields';

import Notes from '../../components/notes';
import { normalizeAccessioningFormValues } from '../accessioner/accessioning/accession/clinic-samples/trf/utils/helpers';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdateBillingInfoProps {}

export default function UpdateBillingInfo(props: UpdateBillingInfoProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { dataId } = useParams();
	const navigate = useNavigate();

	const formValuesRef = useRef({});

	const [refresh, setRefresh] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [data, setData] = useState<ClinicData | undefined>(undefined);
	const [formValues, setFormValues] = useState<
		BillingInfoFormValues | undefined
	>(undefined);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			setFormValues(undefined);

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(`/api/medical-biller/data/${dataId}`);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					if (parsedData) {
						// Set data state
						setData(parsedData);

						// Set form values state
						const assay = parsedData['sample']['assay'];
						const initialFormValues =
							getInitialBillingInfoFormValues(assay);

						const INITIAL_FORM_VALUES: BillingInfoFormValues =
							Object.assign({}, initialFormValues);

						setFormValues(() => {
							for (const key in INITIAL_FORM_VALUES) {
								if (parsedData.requisition_form[key] !== null) {
									let databaseValue =
										parsedData.requisition_form[key];

									if (typeof databaseValue === 'boolean')
										databaseValue =
											databaseValue.toString();

									INITIAL_FORM_VALUES[key] = databaseValue;
								}
							}

							formValuesRef.current = INITIAL_FORM_VALUES;
							return INITIAL_FORM_VALUES;
						});
					}
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, dataId, formValuesRef, refresh]);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios || !formValues || !data) return;
		else if (
			JSON.stringify(formValues) === JSON.stringify(formValuesRef.current)
		) {
			toast.error('Nothing was updated!');
			return;
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			// Prepare payload
			const normalizedFormValues = normalizeAccessioningFormValues(
				data,
				formValues,
				'requisition_form'
			);

			await (
				await axios
			).patch(`/api/medical-biller/data/${dataId}/trf`, {
				requisition_form_data: normalizedFormValues,
			});

			toast.dismiss();

			setDisabled(false);
			setFormValues(undefined);

			navigate(`/medical-biller/view/samples/${dataId}`);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	return (
		<Container>
			<ContentWrapper
				Icon="DocumentTextIcon"
				subheading="Billing verification"
				heading="Update billing information"
				description="Update any of the fields."
			>
				{!formValues || !data ? (
					<LoadingBox />
				) : (
					<Fragment>
						<TRFComponents.PatientInformation data={data} />

						<ContentSectionDivider />

						<ContentSection heading="Miscellaneous">
							<DescriptionList>
								<DescriptionItem
									term="Notes"
									customColSpan="sm:col-span-3"
								>
									<Notes
										id={dataId}
										collection="data"
										setRefresh={setRefresh}
										data={data.notes}
										receiveDepartment="medical_billing"
									/>
								</DescriptionItem>
							</DescriptionList>
						</ContentSection>

						<ContentSectionDivider />

						<ContentSection>
							<Form handleSubmit={handleSubmit}>
								<BillingInfoFields
									hideFlagButton
									data={data}
									formValues={formValues}
									setFormValues={setFormValues}
								/>
								<FormFooter>
									<Button
										text="Update"
										type="submit"
										tier="tertiary"
										Icon="CheckIcon"
									/>
								</FormFooter>
							</Form>
						</ContentSection>
					</Fragment>
				)}
			</ContentWrapper>
		</Container>
	);
}
