import { useNavigate } from 'react-router-dom';

import {
	Table,
	TableRow,
	DataCell,
	TableBody,
	TableHead,
	EmptyTable,
	HeaderCell,
	TableLoading,
	ArrowLinkButton,
	HeaderCellWithSearch,
} from '@pangea-lis-apps/ui';
import {
	SHARED,
	ClinicData,
	formatDate,
	QueryState,
	PaginationState,
} from '@pangea-lis-apps/utils';

import { useOrganizations, useCustomers } from '../../../utils/hooks/index';

interface AllRequisitionFormsTableProps {
	query: QueryState;
	data: ClinicData[] | undefined;
	pagination: PaginationState;
}

export default function AllRequisitionFormsTable(
	props: AllRequisitionFormsTableProps
) {
	const navigate = useNavigate();

	const customers = useCustomers();
	const organizations = useOrganizations();

	const { query, setQuery } = props.query;

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Received Date</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
				<HeaderCellWithSearch
					type="text"
					name="pangea_id"
					label="Pangea ID"
					query={query}
					property="sample.pangea_id"
					setQuery={setQuery}
				/>
				<HeaderCellWithSearch
					type="text"
					name="specimen_id"
					label="Specimen ID"
					query={query}
					property="sample.specimen_id"
					setQuery={setQuery}
				/>
				<HeaderCellWithSearch
					query={query}
					name="customer"
					type="combobox"
					label="Customer"
					property="customer._id"
					setQuery={setQuery}
					options={customers || SHARED.no_options}
				/>
				<HeaderCellWithSearch
					type="combobox"
					query={query}
					name="organization"
					setQuery={setQuery}
					label="Organization"
					property="organization._id"
					options={organizations || SHARED.no_options}
				/>
				<HeaderCellWithSearch
					type="text"
					name="patient_first_name"
					label="Patient First Name"
					query={query}
					property="requisition_form.patient_first_name"
					setQuery={setQuery}
				/>
				<HeaderCellWithSearch
					type="text"
					name="patient_last_name"
					label="Patient Last Name"
					query={query}
					property="requisition_form.patient_last_name"
					setQuery={setQuery}
				/>
				<HeaderCellWithSearch
					type="date"
					name="patient_date_of_birth"
					label="Patient DOB"
					query={query}
					property="requisition_form.patient_date_of_birth"
					setQuery={setQuery}
				/>
				<HeaderCell>Processor</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => (
							<TableRow key={index} index={index}>
								<DataCell>
									{formatDate(
										datum.requisition_form
											.requisition_form_received_date
											? datum.requisition_form
													.requisition_form_received_date
											: undefined
									)}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.requisition_form
											.sample_collection_date
									)}
								</DataCell>
								<DataCell>
									<ArrowLinkButton
										text={datum.sample.pangea_id}
										onClick={() =>
											navigate(
												`/accessioner/view/trfs/${datum._id.$oid}`
											)
										}
									/>
								</DataCell>
								<DataCell>{datum.sample.specimen_id}</DataCell>
								<DataCell>
									{datum.customer.first_name.concat(
										' ',
										datum.customer.last_name
									)}
								</DataCell>
								<DataCell>{datum.organization.name}</DataCell>
								<DataCell>
									{datum.requisition_form.patient_first_name}
								</DataCell>
								<DataCell>
									{datum.requisition_form.patient_last_name}
								</DataCell>
								<DataCell>
									{formatDate(
										datum.requisition_form
											.patient_date_of_birth
									)}
								</DataCell>
								<DataCell>
									{datum.metadata.created_by.first_name.concat(
										' ',
										datum.metadata.created_by.last_name
									)}
								</DataCell>
							</TableRow>
						))
					) : (
						<EmptyTable
							heading="No TRFs"
							Icon="DocumentTextIcon"
							description="Start by accessioning a requisition form"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
