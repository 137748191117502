import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import {
	useId,
	useState,
	Fragment,
	Dispatch,
	useEffect,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Table,
	Modal,
	Button,
	useAxios,
	DataCell,
	TableRow,
	FormGroup,
	Container,
	TableBody,
	TableHead,
	FormFooter,
	LoadingBox,
	EmptyTable,
	HeaderCell,
	TextButton,
	GenericField,
	TableLoading,
	useTabsContext,
	ContentWrapper,
	ContentSection,
	ArrowLinkButton,
	DescriptionItem,
	DescriptionList,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ASSAYS,
	capitalize,
	ClinicData,
	Pagination,
	QueryState,
	formatDate,
	NonClinicData,
	ORGANIZATIONS,
	PaginationState,
	getLabelFromValue,
	initialPaginationValues,
	generateLabelForOrderTests,
} from '@pangea-lis-apps/utils';

import TableSearchFiltersForm from '../../../components/tables/accessioning/table-search-filters-form';

const projectedFields = [
	'sample.accessioning_approval.needs_review.reason.value',
	'sample.pangea_id',
	'_id',
	'sample.specimen_id',
	'sample.collection_kit_id',
	'customer.first_name',
	'customer.last_name',
	'organization.name',
	'patient.first_name',
	'patient.last_name',
	'patient.date_of_birth',
	'sample.sample_collection_date',
	'sample.ruo',
	'sample.priority',
	'sample.assay',
	'sample.order_tests',
	'type',
	'sample.sample_received_date',
	'metadata.accessioning.completed.value',
	'organization.name',
];

/* eslint-disable-next-line */
export interface ViewSamplesPendingReviewProps {}

export default function ViewSamplesPendingReview(
	props: ViewSamplesPendingReviewProps
) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [query, setQuery] = useState({});
	const [refresh, setRefresh] = useState(false);
	const [data, setData] = useState<
		(ClinicData | NonClinicData)[] | undefined
	>(undefined);
	const [disabled, setDisabled] = useState(false);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setData(undefined);
				setDisabled(true);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/accessioner-admin/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query: {
							...query,
							'sample.status': 'needs_review',
						},
						sort: {
							'sample.accessioning_approval.needs_review.reason.metadata.date':
								-1,
						},
						projected_fields: projectedFields,
					}
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setData(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);

			toast.dismiss();
		};

		fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [
		axios,
		query,
		refresh,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	return (
		<Container>
			<ContentWrapper
				Icon="DocumentCheckIcon"
				heading="Samples Pending Review"
				description="Samples that need to be approved or rejected for further processing."
			>
				<TableSearchFiltersForm
					tableType="pending-review"
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection heading="Samples pending review">
					<SamplesPendingReviewTable
						data={data}
						setRefresh={setRefresh}
						query={{ query, setQuery }}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}

interface SamplesPendingReviewTableProps {
	query: QueryState;
	pagination: PaginationState;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	data: (ClinicData | NonClinicData)[] | undefined;
}

function SamplesPendingReviewTable(props: SamplesPendingReviewTableProps) {
	const navigate = useNavigate();

	const [action, setAction] = useState<'approve' | 'reject'>('approve');
	const [selectedSample, setSelectedSample] = useState<
		ClinicData | NonClinicData | undefined
	>(undefined);
	const [modalVisible, setModalVisible] = useState(false);

	return (
		<Fragment>
			<ReviewSampleModal
				action={action}
				data={selectedSample}
				setRefresh={props.setRefresh}
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<Table pagination={props.pagination}>
				<TableHead>
					<HeaderCell>Actions</HeaderCell>
					<HeaderCell customWidth="w-72">
						Request to review reason
					</HeaderCell>
					<HeaderCell>Assay</HeaderCell>
					<HeaderCell>Priority level</HeaderCell>
					<HeaderCell>RUO</HeaderCell>
					<HeaderCell>Customer</HeaderCell>
					<HeaderCell>Organization</HeaderCell>
					<HeaderCell>Pangea ID</HeaderCell>
					<HeaderCell>Specimen ID</HeaderCell>
					<HeaderCell>Collection Kit ID</HeaderCell>
					<HeaderCell>Patient First Name</HeaderCell>
					<HeaderCell>Patient Last Name</HeaderCell>
					<HeaderCell>Patient Date of Birth</HeaderCell>
					<HeaderCell>Received Date</HeaderCell>
					<HeaderCell>Collection Date</HeaderCell>
				</TableHead>
				<TableBody>
					{props.data ? (
						props.data.length ? (
							props.data.map((datum, index) => {
								return (
									<TableRow key={index} index={index}>
										<DataCell>
											<TextButton
												color="blue"
												type="button"
												text="Approve"
												className="mr-4"
												onClick={() => {
													setAction('approve');
													setModalVisible(true);
													setSelectedSample(datum);
												}}
											/>
											<TextButton
												color="blue"
												type="button"
												text="Reject"
												onClick={() => {
													setAction('reject');
													setModalVisible(true);
													setSelectedSample(datum);
												}}
											/>
										</DataCell>
										<DataCell>
											{
												datum.sample
													.accessioning_approval
													.needs_review.reason.value
											}
										</DataCell>
										<DataCell>
											{getLabelFromValue(
												datum.sample.assay,
												ASSAYS['assays']
											)}
										</DataCell>
										<DataCell>
											{getLabelFromValue(
												datum.sample.priority,
												ORGANIZATIONS.priorityLevels
											)}
										</DataCell>
										<DataCell>
											{datum.sample.ruo === null
												? null
												: datum.sample.ruo
												? 'Yes'
												: 'No'}
										</DataCell>
										<DataCell>
											{datum.customer.first_name.concat(
												' ',
												datum.customer.last_name
											)}
										</DataCell>
										<DataCell>
											{datum.organization.name}
										</DataCell>
										<DataCell>
											<ArrowLinkButton
												text={datum.sample.pangea_id}
												onClick={() =>
													navigate(
														`/accessioner-admin/view/samples/${datum._id.$oid}`
													)
												}
											/>
										</DataCell>
										<DataCell>
											{datum.sample.specimen_id}
										</DataCell>
										<DataCell>
											{datum.sample.collection_kit_id}
										</DataCell>
										<DataCell>
											{datum.patient
												? datum.patient.first_name
												: null}
										</DataCell>
										<DataCell>
											{datum.patient
												? datum.patient.last_name
												: null}
										</DataCell>
										<DataCell>
											{datum.patient
												? formatDate(
														datum.patient
															.date_of_birth
												  )
												: null}
										</DataCell>
										<DataCell>
											{formatDate(
												datum.sample
													.sample_received_date
													? datum.sample
															.sample_received_date
													: undefined,
												true
											)}
										</DataCell>
										<DataCell>
											{formatDate(
												datum.sample
													.sample_collection_date
											)}
										</DataCell>
									</TableRow>
								);
							})
						) : (
							<EmptyTable
								Icon="FingerPrintIcon"
								heading="No rejected samples"
								description="Contact devs if there is an issue"
							/>
						)
					) : (
						<TableLoading />
					)}
				</TableBody>
			</Table>
		</Fragment>
	);
}

interface ReviewSampleModalProps {
	action: 'approve' | 'reject';
	data: ClinicData | NonClinicData | undefined;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

export function ReviewSampleModal(props: ReviewSampleModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();

	const { setRefreshTabs } = useTabsContext();
	const { visible, setVisible } = props.visible;
	const [disabled, setDisabled] = useState(false);
	const [confirmation, setConfirmation] = useState('');

	const handleSubmit = async () => {
		if (disabled || !props.data || !axios) return;
		else if (
			(props.action === 'approve' && confirmation !== 'Approve') ||
			(props.action === 'reject' && confirmation !== 'Reject')
		) {
			toast.error('Please double check the confirmation statement!');
			return;
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			await (
				await axios
			).patch(
				`/api/accessioner-admin/data/${props.data._id.$oid}/sample/reviewed`,
				{
					user,
					action: props.action,
				}
			);

			toast.dismiss();

			handleClose();
			setRefreshTabs((value) => !value);
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleClose = () => {
		setVisible(false);
		setDisabled(false);
		setConfirmation('');
	};

	const handleChange: ChangeEventHandler<HTMLInputElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement;

		if (target && target.name) setConfirmation(target.value);
	};

	return (
		<Modal
			visible={visible}
			onClose={handleClose}
			customWidth="max-w-xl"
			title={`${capitalize(props.action)} request`}
		>
			{!props.data ? (
				<LoadingBox />
			) : (
				<div className="mb-4 space-y-6">
					<DescriptionList>
						<DescriptionItem
							term="Priority Level"
							details={getLabelFromValue(
								props.data.sample.priority,
								ORGANIZATIONS.priorityLevels
							)}
						/>
						<div className="sm:col-span-2"></div>
						<DescriptionItem
							term="Customer"
							details={props.data.customer.first_name.concat(
								' ',
								props.data.customer.last_name
							)}
						/>
						<DescriptionItem
							term="Organization"
							details={props.data.organization.name}
						/>
						<div className="sm:col-span-1"></div>
						<DescriptionItem
							term="Assay"
							details={getLabelFromValue(
								props.data.sample.assay,
								ASSAYS['assays']
							)}
						/>
						{'order_tests' in props.data.sample ? (
							<DescriptionItem
								term="Ordered tests"
								customColSpan="sm:col-span-2"
								details={generateLabelForOrderTests(
									props.data.sample.order_tests
								)}
							/>
						) : (
							<div className="sm:col-span-2"></div>
						)}
						<DescriptionItem
							term="Sample received?"
							details={
								props.data.sample.sample_received_date
									? props.data.sample.sample_received_date
									: 'No'
							}
						/>
						<DescriptionItem
							customColSpan="sm:col-span-2"
							term="Accessioning completed?"
							details={
								props.data.metadata.accessioning.completed.value
							}
						/>
						<DescriptionItem
							term="Pangea ID"
							details={props.data.sample.pangea_id}
						/>
						<DescriptionItem
							term="Specimen ID"
							details={props.data.sample.specimen_id}
						/>
						<DescriptionItem
							term="Collection Date"
							details={formatDate(
								props.data.sample.sample_collection_date
							)}
						/>
						<DescriptionItem
							customColSpan="sm:col-span-3"
							term="Request to review reason"
							details={
								props.data.sample.accessioning_approval
									.needs_review.reason.value
							}
						/>
						{props.data.type === 'clinic' && (
							<Fragment>
								<DescriptionItem
									term="Patient first name"
									details={
										props.data.patient
											? props.data.patient.first_name
											: null
									}
								/>
								<DescriptionItem
									term="Patient last name"
									details={
										props.data.patient
											? props.data.patient.last_name
											: null
									}
								/>
								<DescriptionItem
									term="Patient date of birth"
									details={
										props.data.patient
											? formatDate(
													props.data.patient
														.date_of_birth
											  )
											: null
									}
								/>
							</Fragment>
						)}
					</DescriptionList>

					<hr />

					<FormGroup>
						<div className="sm:col-span-6">
							<GenericField
								required
								type="text"
								name="confirmation"
								value={confirmation}
								label={
									<span>
										Enter{' '}
										<span className="italic">
											{capitalize(props.action)}
										</span>{' '}
										to confirm
									</span>
								}
								handleInputChange={handleChange}
								placeholder={`e.g., ${capitalize(
									props.action
								)}`}
							/>
						</div>
					</FormGroup>
					<FormFooter>
						<Button
							type="button"
							tier="tertiary"
							Icon="CheckIcon"
							disabled={disabled}
							onClick={handleSubmit}
							text={capitalize(props.action)}
						/>
					</FormFooter>
				</div>
			)}
		</Modal>
	);
}
