import validator from 'validator';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	useId,
	Fragment,
	Dispatch,
	useState,
	useEffect,
	FormEvent,
	ChangeEvent,
	SetStateAction,
	FormEventHandler,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Modal,
	Button,
	useAxios,
	FormGroup,
	Container,
	FormFooter,
	OptionCard,
	LoadingBox,
	SelectField,
	GenericField,
	ContentSection,
	ContentWrapper,
	OptionCardContainer,
	AddBillingMethodModal,
	CustomerBillingMethods,
	BillingMethodFormValues,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	Scope,
	SHARED,
	Option,
	Auth0Role,
	Organization,
	getRoleFromPathname,
	normalizeFormValues,
	CLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID,
} from '@pangea-lis-apps/utils';
import Alert from 'libs/ui/src/lib/components/alert/alert';

interface FormValues {
	title: string;
	first_name: string;
	middle_name: string;
	last_name: string;
	email_address: string;
	address: string;
	address2: string;
	city: string;
	state: string;
	zip_code: string;
	country: string;
	phone_number: string;
	outbound_emails: string[];
	national_provider_identifier: string;
	role: string;
	billing_methods: BillingMethodFormValues[];
}

const initialFormValues = {
	title: '',
	first_name: '',
	middle_name: '',
	last_name: '',
	email_address: '',
	address: '',
	address2: '',
	city: '',
	state: '',
	zip_code: '',
	country: '',
	phone_number: '',
	national_provider_identifier: '',
	role: '',
	outbound_emails: [],
	billing_methods: [],
};

interface AddBillingMethodModalState {
	updateId: string;
	visible: boolean;
	action: 'create' | 'update' | string;
}

const initialAddBillingMethodModalState = {
	updateId: '',
	visible: false,
	action: 'create',
};

/* eslint-disable-next-line */
export interface CreateMemberProps {
	role: 'accounts' | 'organization-admin';
}

export function CreateMember(props: CreateMemberProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const navigate = useNavigate();
	const { organizationId } = useParams();

	const [visible, setVisible] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [organization, setOrganization] = useState<Organization | undefined>(
		undefined
	);

	const [roles, setRoles] = useState<Option[] | undefined>(undefined);
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
	const [billingModalState, setBillingModalState] =
		useState<AddBillingMethodModalState>(initialAddBillingMethodModalState);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const organizationDetailsRequest =
					props.role === 'accounts'
						? (await axios).get(
								`/api/accounts/organizations/${organizationId}`
						  )
						: (await axios).post(
								`/api/organization-admin/organization`,
								{
									user,
								}
						  );

				const organizationRolesRequest =
					props.role === 'accounts'
						? (await axios).get(
								'/api/accounts/organization/roles/all'
						  )
						: (await axios).get(
								`/api/${props.role}/organization/roles/assignable`
						  );

				const [
					{
						data: { data: data1 },
					},
					{
						data: { data: data2 },
					},
				] = await Promise.all([
					organizationDetailsRequest,
					organizationRolesRequest,
				]);

				if (!unmounted) {
					// organization information
					const parsedData = JSON.parse(data1);
					console.log(parsedData);

					setOrganization(parsedData);

					// roles data
					console.log(data2);
					setRoles(() =>
						data2.map((role: Auth0Role) => ({
							label: role.description
								.split(' - ')[1]
								.split(' ')[1],
							value: role.id,
						}))
					);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, props.role, user]);

	const handleSubmit: FormEventHandler<HTMLFormElement> = async (
		event: FormEvent
	) => {
		event.preventDefault();

		// Validation
		if (disabled || !axios || !props.role || !organizationId) return;
		else if (formValues.outbound_emails.length === 0) {
			toast.error(
				'At least one outbound email address must be provided!'
			);
			return;
		} else if (process.env['NX_ENV'] === 'development') {
			const [username, domain] = formValues.email_address.split('@');

			if (
				![
					'pangealab.com',
					'zymoresearch.com',
					'epimorphy.com',
				].includes(domain)
			) {
				if (username.includes('test.pangealab')) {
					// pass
				} else {
					toast.error(
						'User must be a Pangea Lab, Epimorphy, or Zymo Research associate!'
					);
					return;
				}
			}
		}

		setDisabled(true);

		toast.loading('Creating...', toastOptions);

		try {
			const normalizedFormValues = normalizeFormValues(formValues);

			const {
				data: { data },
			} = await (
				await axios
			).post(
				`/api/${props.role}/organizations/${organizationId}/member`,
				{
					...normalizedFormValues,
					national_provider_identifier:
						formValues.role ===
						CLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID
							? normalizedFormValues[
									'national_provider_identifier'
							  ]
							: null,
				}
			);

			toast.dismiss();

			if (props.role === 'accounts') {
				navigate(`/accounts/view/organizations/${organizationId}`);
			} else {
				const parsedData = JSON.parse(data);
				navigate(`/organization-admin/view/members/${parsedData.$oid}`);
			}
		} catch (error) {
			console.log(error);
			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setFormValues((prevValues) => {
				const value = target.value;

				return {
					...prevValues,
					[target.name]: value,
				};
			});
		}
	};

	const handleUpdateBillingMethods = async (
		event: FormEvent<Element>,
		formValues: BillingMethodFormValues
	) => {
		event.preventDefault();

		setFormValues((prevValues) => {
			if (
				billingModalState.action === 'update' &&
				billingModalState.updateId
			) {
				const updated_billing_methods = prevValues.billing_methods.map(
					(method) => {
						if (method.id === billingModalState.updateId)
							return {
								...formValues,
							};

						return method;
					}
				);

				return {
					...prevValues,
					billing_methods: updated_billing_methods,
				};
			}

			return {
				...prevValues,
				billing_methods: [
					...prevValues.billing_methods,
					{
						...formValues,
						id: uuidv4(),
					},
				],
			};
		});
	};

	const [stateSelection, setStateSelection] = useState('');

	useEffect(() => {
		if (formValues && !stateSelection) {
			if (
				!FORMS.states
					.map((option: Option) => option.value)
					.includes(formValues.state)
			)
				setStateSelection('other');
			else setStateSelection(formValues.state);
		}
	}, [formValues, stateSelection]);

	const handleStateSelectionChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setStateSelection(target.value);

			setFormValues((prevValues: FormValues) => {
				if (prevValues) {
					return {
						...prevValues,
						[target.name]:
							target.value === 'other' ? '' : target.value,
					};
				}

				return prevValues;
			});
		}
	};

	const [outboundEmail, setOutboundEmail] = useState('');

	const handleAddOutboundEmail = (event: FormEvent) => {
		event.preventDefault();

		if (!outboundEmail || !validator.isEmail(outboundEmail)) {
			toast.error('Enter a valid email address!');
			return;
		}

		console.log(outboundEmail);

		setFormValues((prevVal: FormValues) => {
			if (prevVal) {
				if (prevVal['outbound_emails'].includes(outboundEmail)) {
					toast.error('Email already exists!');
					return prevVal;
				}

				return {
					...prevVal,
					outbound_emails: [
						...prevVal['outbound_emails'],
						outboundEmail,
					],
				};
			}

			return prevVal;
		});

		setOutboundEmail('');
	};

	return (
		<Fragment>
			<AssignablePermissionsModal visible={{ visible, setVisible }} />
			<AddBillingMethodModal
				modalState={billingModalState}
				setModalState={setBillingModalState}
				handleSubmit={handleUpdateBillingMethods}
				billingMethods={formValues.billing_methods}
			/>
			<Container>
				{!roles || !organization ? (
					<LoadingBox />
				) : (
					<ContentWrapper
						Icon="UserPlusIcon"
						heading="Create member"
						subheading={organization.name}
						description="The member will receive an email to reset their password after you create their account."
					>
						<ContentSection>
							<Form
								id="create-member"
								handleSubmit={handleSubmit}
							>
								{organization.entity === 'clinic' && (
									<FormGroup
										heading="Assign role"
										description={
											<span>
												Associates have the full set of
												permissions and the authority of
												assigning permissions to staff
												members.{' '}
												<button
													className="text-blue-600 hover:text-blue-700 font-medium"
													onClick={() =>
														setVisible(true)
													}
												>
													View
												</button>{' '}
												a full list of these
												permissions.
											</span>
										}
									>
										<div className="sm:col-span-2">
											<SelectField
												required
												name="role"
												label="Role"
												value={formValues.role}
												handleSelect={handleChange}
												options={(() => {
													if (roles)
														return [
															{
																label: 'Select an Option',
																value: '',
															},
															...roles,
														];

													return SHARED.no_options;
												})()}
											/>
										</div>
									</FormGroup>
								)}
								<FormGroup heading="Member data">
									<div className="sm:col-span-2">
										<SelectField
											label="Title"
											name="title"
											options={FORMS.titles}
											value={formValues.title}
											handleSelect={handleChange}
										/>
									</div>
									<div className="sm:col-span-4"></div>
									<div className="sm:col-span-2">
										<GenericField
											required
											type="text"
											name="first_name"
											label="First Name"
											placeholder="e.g., John"
											value={formValues.first_name}
											handleInputChange={handleChange}
										/>
									</div>
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											name="middle_name"
											label="Middle Name"
											placeholder="e.g., Ashley"
											value={formValues.middle_name}
											handleInputChange={handleChange}
										/>
									</div>
									<div className="sm:col-span-2">
										<GenericField
											required
											type="text"
											name="last_name"
											label="Last Name"
											value={formValues.last_name}
											handleInputChange={handleChange}
											placeholder="e.g., Kim"
										/>
									</div>
									{organization &&
										organization.entity === 'clinic' &&
										formValues.role ===
											CLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID && (
											<div className="sm:col-span-2">
												<GenericField
													required
													type="text"
													maxLength={10}
													name="national_provider_identifier"
													label="National Provider Identifier (NPI)"
													value={
														formValues.national_provider_identifier
													}
													handleInputChange={
														handleChange
													}
													placeholder="e.g., XZ123456789"
												/>
											</div>
										)}
								</FormGroup>
								<FormGroup
									heading="Contact info"
									description="The provided email address must be unique within the organization."
								>
									<div className="sm:col-span-2">
										<GenericField
											type="email"
											required={
												formValues.role !==
												CLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID
											}
											label="Email Address"
											name="email_address"
											value={formValues.email_address}
											placeholder="e.g., contact@fairlifemedical.com"
											handleInputChange={handleChange}
										/>
									</div>
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											name="phone_number"
											label="Phone Number"
											value={formValues.phone_number}
											placeholder="e.g., +1 (234) 567-8901"
											handleInputChange={handleChange}
										/>
									</div>
								</FormGroup>
								<FormGroup heading="Address info">
									<div className="sm:col-span-4">
										<GenericField
											type="text"
											name="address"
											label="Address"
											value={formValues.address}
											placeholder="e.g., 1234 Main St."
											handleInputChange={handleChange}
										/>
									</div>
									<div className="sm:col-span-2"></div>
									<div className="sm:col-span-4">
										<GenericField
											type="text"
											name="address2"
											label="Address Line 2"
											value={formValues.address2}
											placeholder="e.g., Suite 101"
											handleInputChange={handleChange}
										/>
									</div>
									<div className="sm:col-span-2"></div>
									<div className="sm:col-span-2">
										<GenericField
											name="city"
											type="text"
											label="City"
											value={formValues.city}
											placeholder="e.g., Albany"
											handleInputChange={handleChange}
										/>
									</div>
									<div className="sm:col-span-2">
										<SelectField
											label="State"
											name="state"
											options={FORMS.states}
											handleSelect={
												handleStateSelectionChange
											}
											value={stateSelection}
										/>
									</div>
									{stateSelection === 'other' ? (
										<div className="sm:col-span-2">
											<GenericField
												required
												type="text"
												label="If other, please specify"
												name="state"
												placeholder="e.g., New York"
												value={formValues.state}
												handleInputChange={handleChange}
											/>
										</div>
									) : (
										<div className="sm:col-span-2"></div>
									)}
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											name="zip_code"
											label="ZIP Code"
											placeholder="e.g., 92830"
											value={formValues.zip_code}
											handleInputChange={handleChange}
										/>
									</div>
									<div className="sm:col-span-2">
										<SelectField
											label="Country"
											name="country"
											options={FORMS.countries}
											handleSelect={handleChange}
											value={formValues.country}
										/>
									</div>
								</FormGroup>
							</Form>
						</ContentSection>
						<ContentSection>
							<Form
								id="add-outbound-emails"
								handleSubmit={handleAddOutboundEmail}
							>
								<FormGroup
									heading="Outbound Communications"
									description="All client-bound communications will be sent to the emails addresses added below."
								>
									<div className="sm:col-span-2">
										<GenericField
											type="email"
											label="Email Address"
											name="outbound_email"
											value={outboundEmail}
											showRequiredAsterisk={true}
											placeholder="e.g., contact@fairlifemedical.com"
											handleInputChange={(
												event: ChangeEvent
											) => {
												setOutboundEmail(
													(
														event.target as HTMLInputElement
													).value
												);
											}}
										/>
									</div>
									<div className="sm:col-span-2 flex items-end">
										<Button
											text="Add"
											type="submit"
											tier="tertiary"
											Icon="PlusIcon"
										/>
									</div>
									<div className="sm:col-span-2"></div>
									{formValues.outbound_emails.length > 0 && (
										<div className="sm:col-span-4">
											<OptionCardContainer heading="Outbound Email Address(es)">
												{formValues.outbound_emails.map(
													(
														option: string,
														index: number
													) => (
														<OptionCard
															key={index}
															option={{
																label: option,
																value: option,
															}}
															handleRemove={(
																selectedCode: string
															) => {
																setFormValues(
																	(
																		prevVal: FormValues
																	) => {
																		if (
																			prevVal
																		) {
																			const outbound_emails =
																				prevVal[
																					'outbound_emails'
																				].filter(
																					(
																						option: string
																					) =>
																						option !==
																						selectedCode
																				);

																			return {
																				...prevVal,
																				outbound_emails:
																					outbound_emails,
																			};
																		}

																		return prevVal;
																	}
																);
															}}
														/>
													)
												)}
											</OptionCardContainer>
										</div>
									)}
								</FormGroup>
							</Form>
						</ContentSection>

						{organization.entity === 'clinic' &&
							process.env['NX_PORTAL_TYPE'] === 'ASSOCIATE' &&
							formValues.role ===
								CLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID && (
								<CustomerBillingMethods
									setBillingModalState={setBillingModalState}
									billingMethods={formValues.billing_methods}
								/>
							)}

						<FormFooter>
							<Button
								type="submit"
								tier="tertiary"
								Icon="PlusIcon"
								disabled={disabled}
								text="Create member"
								formId="create-member"
							/>
						</FormFooter>
					</ContentWrapper>
				)}
			</Container>
		</Fragment>
	);
}

interface AssignablePermissionsModalProps {
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

const AssignablePermissionsModal = (props: AssignablePermissionsModalProps) => {
	const toastId = useId();
	const axios = useAxios(toastId);

	const role = getRoleFromPathname();
	const { visible, setVisible } = props.visible;

	const [permissions, setPermissions] = useState<Scope | undefined>(
		undefined
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(`/api/${role}/scopes?entity=clinic`);

				if (!unmounted) {
					console.log(data);
					setPermissions(data);
				}
			} catch (error) {
				console.log(error);
			}
		};

		if (visible) fetchData();

		return () => {
			unmounted = true;
		};
	}, [role, axios, visible]);

	return (
		<Modal
			visible={visible}
			customWidth="max-w-lg"
			title="All permissions"
			onClose={() => setVisible(false)}
		>
			<div className="space-y-4">
				<Alert
					type="info"
					heading="Clinic and non-clinic permissions"
					description="Non-clinic organizations will not submit requisition forms and patient info, so those permissions will be neither available nor assignable when working with client accounts. Staff members also start with zero permissions; they need to be assigned permissions by an associate or account manager."
				/>
				<ul className="list-disc list-inside">
					{!permissions ? (
						<LoadingBox />
					) : (
						permissions.permission_groups.map(
							(permission, index) => (
								<li key={index}>
									<span className="text-sm font-medium text-gray-700 mb-1">
										{permission.name}
									</span>{' '}
									<span className="text-sm text-gray-500 mb-1">
										{permission.description}
									</span>
								</li>
							)
						)
					)}
				</ul>
			</div>
		</Modal>
	);
};

export default CreateMember;
