import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useId, useState, useEffect } from 'react';

import {
	Button,
	useAxios,
	Container,
	ContentWrapper,
	ContentSection,
} from '@pangea-lis-apps/ui';
import {
	Auth0User,
	Pagination,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import AllUsersTable from '../../../components/tables/shared/all-users';

/* eslint-disable-next-line */
export interface ViewUsersProps {}

export default function ViewUsers(props: ViewUsersProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();
	const navigate = useNavigate();

	const isAdmin =
		user &&
		(user['custom_claims/roles'].includes('BITSS_super-admin') ||
			user['custom_claims/roles'].includes('BITSS_admin'));

	const [users, setUsers] = useState<Auth0User[] | undefined>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(
					`/api/admin/users/?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`
				);

				if (!unmounted) {
					console.log(data);
					const { users, total } = data;

					setUsers(users);
					setPagination((prev) => ({
						...prev,
						total_entries: total,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, pagination.page_number, pagination.entries_per_page]);

	return (
		<Container>
			<ContentWrapper
				Icon="UserPlusIcon"
				heading="View Users"
				description="Manage all users within Pangea Lab."
			>
				<ContentSection
					heading="All users"
					description="To change a user's roles, click on Details to get started."
					headingAction={
						isAdmin && (
							<Button
								type="button"
								tier="tertiary"
								Icon="PlusIcon"
								text="Create user"
								onClick={() => navigate('/admin/create/user')}
							/>
						)
					}
				>
					<AllUsersTable
						data={users}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
