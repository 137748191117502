import { Item, SHIPPING } from '@pangea-lis-apps/utils';

export interface OrderItemsSummaryProps {
	items: Item[];
	minified: boolean;
	role: 'associate' | 'customer';
}

export function OrderItemsSummary(props: OrderItemsSummaryProps) {
	return (
		<div className={!props.minified ? 'divide-y divide-gray-200' : ''}>
			{props.items.map((item, index: number) =>
				props.minified ? (
					<MinifiedSummary
						key={index}
						item={item}
						role={props.role}
					/>
				) : (
					<ExpandedSummary
						key={index}
						item={item}
						role={props.role}
					/>
				)
			)}
		</div>
	);
}

interface SummaryProps {
	item: Item & { name: string };
	role: 'associate' | 'customer';
}

const ExpandedSummary = (props: SummaryProps) => {
	const item = SHIPPING.items.filter(
		(shipping_item) => shipping_item.value === props.item.sku
	)[0];

	return (
		<div className="flex space-x-4 py-6 first:pt-2 last:pb-0">
			<div className="border rounded-md p-2 shadow">
				<img
					className="w-24"
					src={item.image_src}
					alt={item.image_src}
				/>
			</div>
			<div className="flex flex-col items-start justify-between">
				<div>
					<h2 className="font-medium">{item.label}</h2>
					<p className="mt-1 text-gray-500 text-xs max-w-lg">
						{item.description}
					</p>
				</div>
				<p>{displayQuantity(props.item.quantity, props.role)}</p>
			</div>
		</div>
	);
};

const MinifiedSummary = (props: SummaryProps) => {
	return (
		<p className="text-gray-500">
			{
				SHIPPING.items.filter(
					(shipping_item) => shipping_item.value === props.item.sku
				)[0].label
			}
			: {displayQuantity(props.item.quantity, props.role)}
		</p>
	);
};

const displayQuantity = (quantity: number, role: 'associate' | 'customer') => {
	const absoluteQuantity = Math.abs(quantity);
	const units = absoluteQuantity === 1 ? 'unit' : 'units';

	const value = absoluteQuantity.toString().concat(' ', units);

	if (quantity < 0) return value.concat(' ', 'not yet received');
	else if (quantity > 0)
		return value.concat(' ', role === 'customer' ? 'ordered' : 'received');
	else return value;
};

export default OrderItemsSummary;
