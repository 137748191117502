import { Dispatch, SetStateAction } from 'react';

import { IconButton } from '@pangea-lis-apps/ui';

interface FlagButtonProps {
	property: string;
	formValues: any;
	setFormValues: Dispatch<SetStateAction<any>>;
}

export default function FlagButton(props: FlagButtonProps) {
	const handleFlag = (property: string) => {
		props.setFormValues((prevValue: any) => {
			if (prevValue)
				return {
					...prevValue,
					flag: {
						...prevValue.flag,
						[property]: !prevValue.flag[property],
					},
				};

			return prevValue;
		});

		return;
	};

	return (
		<IconButton
			type="button"
			Icon="FlagIcon"
			onClick={() => handleFlag(props.property)}
			color={props.formValues.flag[props.property] ? 'orange' : 'gray'}
		/>
	);
}
