import { Routes, Route } from 'react-router-dom';

import ViewSample from './samples/view-sample';
import ViewSamples from './samples/view-all-samples';
import SearchReports from './search-reports';
import UpdateReport from './update-report';
import UpdateReportNonClinic from './update-report-non-clinic';
import ViewTestRequisitionForm from './requisition-forms/view-test-requisition-form';

import RoleProtectedRoute from '../../../utils/routing';

export function ClientServicesRoutes() {
	return (
		<Routes>
			<Route
				element={
					<RoleProtectedRoute
						roles={['BITSS_client-services', 'BITSS_super-admin']}
					/>
				}
			>
				<Route path="search/reports" element={<SearchReports />} />
				<Route path="view/all-samples" element={<ViewSamples />} />
				<Route path="view/samples/:dataId" element={<ViewSample />} />
				<Route
					path="view/samples-update-report/:assayVersion/:dataId"
					element={<UpdateReport />}
				/>
				<Route
					path="view/non-clinic-samples-update-report/:dataId"
					element={<UpdateReportNonClinic />}
				/>
				<Route
					path="view/trfs/:dataId"
					element={<ViewTestRequisitionForm />}
				/>
			</Route>
		</Routes>
	);
}
