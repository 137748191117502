/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, useNavigate } from 'react-router-dom';
import {
	useId,
	useState,
	useEffect,
	FormEvent,
	ChangeEvent,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Button,
	useAxios,
	Combobox,
	FormGroup,
	Container,
	TextButton,
	LoadingBox,
	FormFooter,
	SelectField,
	ContentWrapper,
	ContentSection,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	Option,
	ASSAYS,
	VERSIONS,
	BaseHydratedCustomer,
	generateCustomerLabel,
	CLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID,
	NONCLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID,
} from '@pangea-lis-apps/utils';

const projectedFields = [
	'_id',
	'first_name',
	'last_name',
	'entity',
	'organization.name',
	'entity',
	'national_provider_identifier',
];

interface FormValues {
	ruo: string;
	assay: string;
	entity: string;
	version: string;
	customer: string | undefined;
	requisition_form_provided: string;
}

const initialFormValues = {
	ruo: '',
	assay: '',
	entity: '',
	version: '',
	customer: undefined,
	requisition_form_provided: '',
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SelectProviderProps {}

export default function SelectProvider(props: SelectProviderProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const navigate = useNavigate();
	const { accessioningSessionID } = useParams();

	const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
	const [customers, setCustomers] = useState<
		BaseHydratedCustomer[] | undefined
	>(undefined);

	useEffect(() => {
		let unmounted = false;

		const setPreviousFormValues = () => {
			if (sessionStorage.getItem('accessioningPreviousInputs')) {
				setFormValues(
					JSON.parse(
						sessionStorage.getItem('accessioningPreviousInputs')!
					)
				);
			}
		};

		const fetchData = async () => {
			if (!axios) return;

			try {
				const {
					data: {
						data: { data: customersInfo },
					},
				} = await (
					await axios
				).post('/api/accessioner/search/customers', {
					query: {
						roles: [
							CLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID,
							NONCLINIC_ORGANIZATION_ASSOCIATE_ROLE_ID,
						], // only return associates
					},
					projected_fields: projectedFields,
				});

				if (!unmounted) {
					// Grab customers info
					const parsedCustomersInfo = JSON.parse(customersInfo);
					console.log(parsedCustomersInfo);

					setCustomers(parsedCustomersInfo);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();
		setPreviousFormValues();

		return () => {
			unmounted = true;
		};
	}, [axios]);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (!axios || !formValues.customer) return;

		toast.loading('Creating...', toastOptions);

		try {
			const {
				data: { data },
			} = await (
				await axios
			).post('/api/accessioner/data', {
				user,
				form_data: {
					...formValues,
					accessioning_session_id: accessioningSessionID,
				},
			});

			toast.dismiss();

			const parsedData = JSON.parse(data);

			// Save customer and assay in session storage
			sessionStorage.setItem(
				'accessioningPreviousInputs',
				JSON.stringify(formValues)
			);

			if (formValues.entity === 'clinic') {
				if (formValues.requisition_form_provided === 'false') {
					navigate(
						`/accessioner/accession/sample/${formValues.assay}/data/${parsedData.$oid}/sample/update-info`
					);
					return;
				}

				navigate(
					`/accessioner/accession/trf/${formValues.assay}/v${formValues.version}/data/${parsedData.$oid}/trf/provider-organization-info`
				);

				return;
			}

			navigate(
				`/accessioner/accession/sample/${formValues.assay}/data/${parsedData.$oid}/non-clinic-sample`
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleCustomerSelect = (customerId: string) => {
		if (customers) {
			const customer = customers.filter(
				(customer) => customer._id.$oid === customerId
			)[0];

			if (customer) {
				setFormValues({
					ruo: '',
					assay: '',
					version: '',
					entity: customer.entity,
					customer: customer._id.$oid,
					requisition_form_provided: '',
				});
			}
		}
	};

	const handleChange: ChangeEventHandler<
		HTMLSelectElement | HTMLInputElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLSelectElement | HTMLInputElement;

		if (target && target.name) {
			setFormValues((prevValue) => {
				let version = prevValue.version;

				if (target.name === 'assay' && VERSIONS[target.value])
					version = (VERSIONS[target.value].slice(-1)[0] as Option)
						.value;

				return {
					...prevValue,
					[target.name]: target.value,
					version,
				};
			});
		}
	};

	return (
		<Container>
			<ContentWrapper Icon="DocumentTextIcon" heading="Accession">
				{!customers ? (
					<LoadingBox />
				) : (
					<ContentSection>
						<Form handleSubmit={handleSubmit}>
							<FormGroup heading="Sample details">
								<div className="sm:col-span-2">
									<Combobox
										required
										name="customer"
										label="Provider"
										value={formValues.customer || ''}
										handleSelect={handleCustomerSelect}
										options={(() => {
											const hold = customers
												? customers.map((customer) => ({
														label: generateCustomerLabel(
															customer
														),
														value: customer._id
															.$oid,
												  }))
												: [];

											return [
												{
													value: '',
													disabled: true,
													label: 'Select an Option',
												},
												...hold,
											];
										})()}
									/>
								</div>
								<div className="sm:col-span-4"></div>
								<div className="sm:col-span-2">
									<SelectField
										required
										name="assay"
										value={formValues.assay}
										label="Assay"
										options={(() => {
											let options = [
												{
													value: '',
													disabled: true,
													label: 'Select an Option',
												},
												...ASSAYS['assays'],
											];

											if (
												formValues.customer &&
												formValues.entity === 'clinic'
											) {
												options = options.filter(
													(assay) =>
														assay.value !==
														'precision_biome_wound'
												);
											}

											return options;
										})()}
										handleSelect={handleChange}
									/>
								</div>
								{formValues.entity === 'non_clinic' && (
									<div className="sm:col-span-2">
										<SelectField
											required
											name="ruo"
											label="RUO?"
											handleSelect={handleChange}
											options={FORMS.yes_no_options}
											value={formValues['ruo']}
										/>
									</div>
								)}
							</FormGroup>
							{formValues.entity === 'clinic' && (
								<FormGroup heading="Requisition form details">
									<div className="sm:col-span-2">
										<SelectField
											required
											name="requisition_form_provided"
											label="Requisition Form Provided?"
											handleSelect={handleChange}
											options={FORMS.yes_no_options}
											value={
												formValues[
													'requisition_form_provided'
												]
											}
										/>
									</div>
									<div className="sm:col-span-2">
										<SelectField
											required
											name="version"
											label="Version"
											value={formValues.version}
											handleSelect={handleChange}
											options={
												formValues.assay
													? formValues.assay in
													  VERSIONS
														? VERSIONS[
																formValues.assay
														  ]
														: [
																{
																	value: '',
																	label: 'Undefined assay',
																},
														  ]
													: [
															{
																value: '',
																label: 'Select an Option',
															},
													  ]
											}
											disabled={
												!formValues.assay ||
												!(
													formValues.assay in VERSIONS
												) ||
												formValues[
													'requisition_form_provided'
												] !== 'true'
											}
										/>
									</div>
								</FormGroup>
							)}
							<FormFooter className="!justify-between">
								<TextButton
									color="gray"
									type="button"
									onClick={() =>
										navigate(
											`/accessioner/accession/sessions/${accessioningSessionID}`
										)
									}
									text={
										<span>
											&#8592; Return to session details
											page
										</span>
									}
								/>
								<Button
									type="submit"
									tier="tertiary"
									text="Continue"
									Icon="ArrowSmallRightIcon"
								/>
							</FormFooter>
						</Form>
					</ContentSection>
				)}
			</ContentWrapper>
		</Container>
	);
}
