import { useId, useState, useEffect } from 'react';

import { useAxios } from '@pangea-lis-apps/ui';
import {
	Option,
	Organization,
	getRoleFromPathname,
} from '@pangea-lis-apps/utils';

export function useOrganizations(query?: { [key: string]: string }) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const role = getRoleFromPathname();

	const [organizations, setOrganizations] = useState<Option[] | undefined>(
		undefined
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || organizations) return;

			try {
				const {
					data: {
						data: { data },
					},
				} = await (
					await axios
				).post(`/api/${role}/search/organizations`, {
					query: query ? query : {},
					projected_fields: ['_id', 'name'],
				});

				if (!unmounted) {
					const organizations = JSON.parse(data);
					console.log(organizations);

					setOrganizations(() => {
						const hold = organizations.map(
							({ _id, name }: Organization) => ({
								label: name,
								value: _id.$oid,
							})
						);

						if (hold.length)
							return [
								{
									value: '',
									disabled: true,
									label: 'Select an Option',
								},
								...hold,
							];

						return [
							{
								value: '',
								disabled: true,
								label: 'No option(s) available',
							},
						];
					});
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [role, axios, query, organizations]);

	return organizations;
}
