import toast from 'react-hot-toast';
import { useId, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
	Table,
	DataCell,
	useAxios,
	TableRow,
	Container,
	TableHead,
	TableBody,
	HeaderCell,
	EmptyTable,
	TableLoading,
	ContentWrapper,
	ContentSection,
	ArrowLinkButton,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ASSAYS,
	ClinicData,
	formatDate,
	Pagination,
	QueryState,
	NonClinicData,
	PaginationState,
	getLabelFromValue,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import TableSearchFiltersForm from '../../../components/tables/cls/table-search-filters-form';

const projectedFields = [
	'_id',
	'type',
	'sample.assay',
	'sample.pangea_id',
	'sample.specimen_id',
	'sample.collection_kit_id',
	'patient.first_name',
	'patient.last_name',
	'patient.date_of_birth',
	'requisition_form.sample_collection_date',
	'sample.sample_collection_date',
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewFailedSamplesProps {}

export default function ViewFailedSamples(props: ViewFailedSamplesProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { assay } = useParams();

	const [query, setQuery] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [data, setData] = useState<
		(ClinicData | NonClinicData)[] | undefined
	>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setData(undefined);
				setDisabled(true);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/cls/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query: {
							'sample.results.from_all_null_exists': true,
							...query,
						},
						projected_fields: projectedFields,
					}
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData, total_entries);

					setData(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);

			toast.dismiss();
		};

		fetchData();

		return () => {
			toast.dismiss();
			unmounted = true;
		};
	}, [
		axios,
		query,
		assay,
		setQuery,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	return (
		<Container>
			<ContentWrapper
				Icon="FingerPrintIcon"
				heading="Failed Samples"
				description="These samples failed during sample processing, meaning one or more targets are missing results. This page only applies for STI and Respiratory 4-Plex samples."
			>
				<TableSearchFiltersForm
					tableType="failed-samples"
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection heading="All failed samples">
					<FailedSamplesTable
						data={data}
						query={{ query, setQuery }}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}

interface FailedSamplesTableProps {
	query: QueryState;
	pagination: PaginationState;
	data: (ClinicData | NonClinicData)[] | undefined;
}

function FailedSamplesTable(props: FailedSamplesTableProps) {
	const navigate = useNavigate();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Pangea ID</HeaderCell>
				<HeaderCell>Specimen ID</HeaderCell>
				<HeaderCell>Collection Kit ID</HeaderCell>
				<HeaderCell>Assay</HeaderCell>
				<HeaderCell>Patient First Name</HeaderCell>
				<HeaderCell>Patient Last Name</HeaderCell>
				<HeaderCell>Patient Date of Birth</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => {
							const clinicSample = datum.type === 'clinic';

							return (
								<TableRow key={index} index={index}>
									<DataCell>
										<ArrowLinkButton
											text={datum.sample.pangea_id}
											onClick={() =>
												navigate(
													`/cls/view/samples/${datum._id.$oid}`
												)
											}
										/>
									</DataCell>
									<DataCell>
										{datum.sample.specimen_id}
									</DataCell>
									<DataCell>
										{datum.sample.collection_kit_id}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.assay,
											ASSAYS['assays']
										)}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.first_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.last_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? formatDate(
													datum.patient.date_of_birth
											  )
											: null}
									</DataCell>
									<DataCell>
										{clinicSample
											? formatDate(
													datum.requisition_form
														.sample_collection_date
											  )
											: formatDate(
													datum.sample
														.sample_collection_date
											  )}
									</DataCell>
								</TableRow>
							);
						})
					) : (
						<EmptyTable
							Icon="FingerPrintIcon"
							heading="No failed samples"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
