import { Rack } from '@pangea-lis-apps/utils';
import { convertIndexToWellLocation } from '../../../utils/helpers/wells';

import Well from './well';

interface RackTableProps {
	rack: Rack;
	handleSelectWell?: (occupiedStatus: number, index: number) => void;
}

export default function RackTable(props: RackTableProps) {
	let gridCols = '';

	switch (props.rack.columns) {
		case 1:
			gridCols = 'grid-cols-1';
			break;
		case 2:
			gridCols = 'grid-cols-2';
			break;
		case 3:
			gridCols = 'grid-cols-3';
			break;
		case 4:
			gridCols = 'grid-cols-4';
			break;
		case 5:
			gridCols = 'grid-cols-5';
			break;
		case 6:
			gridCols = 'grid-cols-6';
			break;
		case 7:
			gridCols = 'grid-cols-7';
			break;
		case 8:
			gridCols = 'grid-cols-8';
			break;
		case 9:
			gridCols = 'grid-cols-9';
			break;
		case 10:
			gridCols = 'grid-cols-10';
			break;
		case 11:
			gridCols = 'grid-cols-11';
			break;
		case 12:
			gridCols = 'grid-cols-12';
			break;
		default:
			gridCols = '';
	}
	return (
		<div className="bg-gray-100 border p-2 rounded-xl overflow-x-auto shadow-inner">
			<div className={`w-screen grid ${gridCols} gap-2`}>
				{props.rack.sequence.map(
					({ occupied_status, data_reference }, index) => (
						<Well
							key={index}
							index={index}
							wellLabel={convertIndexToWellLocation(
								index,
								props.rack.columns
							)}
							dataReference={data_reference}
							occupiedStatus={occupied_status}
							handleSelectWell={props.handleSelectWell}
						/>
					)
				)}
			</div>
		</div>
	);
}
