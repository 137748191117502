import toast from 'react-hot-toast';
import { useId, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
	ClinicData,
	Pagination,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';
import {
	Button,
	useAxios,
	Container,
	ContentWrapper,
	ContentSection,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';

import AllFlaggedSamplesTable from '../../../../components/tables/flagged-samples';
import TableSearchFiltersForm from '../../../../components/tables/accessioning/table-search-filters-form';

const projectedFields = [
	'_id',
	'type',
	'sample.ruo',
	'sample.assay',
	'sample.priority',
	'organization.name',
	'customer.first_name',
	'customer.last_name',
	'requisition_form.metadata.version',
	'sample.sample_received_date',
	'sample.accessioning_approval.flag.selected_well.rack_name',
	'sample.accessioning_approval.flag.selected_well.location',
	'sample.pangea_id',
	'sample.specimen_id',
	'sample.collection_kit_id',
	'patient.first_name',
	'patient.last_name',
	'patient.date_of_birth',
	'sample.sample_collection_date',
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewFlaggedSamplesProps {}

export default function ViewFlaggedSamples(props: ViewFlaggedSamplesProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const specimenId = searchParams.get('specimenId');

	const [query, setQuery] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [updateTable, setUpdateTable] = useState(false);
	const [flaggedSamples, setFlaggedSamples] = useState<
		ClinicData[] | undefined
	>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setDisabled(true);
				setFlaggedSamples(undefined);
			}

			toast.dismiss('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/accessioner/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query: {
							'sample.status': 'flag',
							...query,
						},
						projected_fields: projectedFields,
					}
				);

				if (!unmounted) {
					setFlaggedSamples(JSON.parse(data));
					setPagination((prev) => ({
						...prev,
						total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);

			toast.dismiss();
		};

		fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [
		axios,
		query,
		updateTable,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	useEffect(() => {
		if (specimenId) {
			setQuery((prevValue) => ({
				...prevValue,
				'sample.specimen_id': specimenId.toString(),
			}));
		}
	}, [specimenId]);

	return (
		<Container>
			<ContentWrapper
				Icon="FlagIcon"
				heading="Flagged Samples"
				description="Samples with discrepancies, i.e., missing or incorrect information."
			>
				<TableSearchFiltersForm
					tableType="flag"
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection
					heading="Flagged samples"
					headingAction={
						<Button
							type="button"
							tier="tertiary"
							text="Rack View"
							Icon="Squares2X2Icon"
							onClick={() =>
								navigate(
									'/accessioner/view/flagged-samples/rack'
								)
							}
						/>
					}
					description="You can only approve clinic samples whose corresponding requisition forms have been completed."
				>
					<AllFlaggedSamplesTable
						data={flaggedSamples}
						query={{ query, setQuery }}
						setUpdateTable={setUpdateTable}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
