import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useId, useState, useEffect, Fragment } from 'react';

import {
	useAxios,
	Container,
	LoadingBox,
	EditDataModal,
	ContentSection,
	ContentWrapper,
	DescriptionList,
	DescriptionItem,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	Option,
	Attribute,
	Auth0Role,
	capitalize,
	Auth0UserWithRoles,
	initialAttributeValue,
} from '@pangea-lis-apps/utils';

/* eslint-disable-next-line */
export interface ViewUserProps {}

export default function ViewUser(props: ViewUserProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user: auth0_user } = useAuth0();
	const isSuperAdmin =
		auth0_user &&
		auth0_user['custom_claims/roles'].includes('BITSS_super-admin');
	const isAdmin =
		auth0_user && auth0_user['custom_claims/roles'].includes('BITSS_admin');

	const { auth0UserID } = useParams();

	const [refresh, setRefresh] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [attribute, setAttribute] = useState<Attribute>(
		initialAttributeValue
	);
	const [roles, setRoles] = useState<Option[] | undefined>(undefined);
	const [user, setUser] = useState<Auth0UserWithRoles | undefined>(undefined);
	const [admin, setAdmin] = useState<Auth0UserWithRoles | undefined>(
		undefined
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !auth0_user || !auth0UserID) return;

			setAdmin(undefined);
			setUser(undefined);
			setRoles(undefined);

			try {
				const [
					{
						data: { data: data1 },
					},
					{
						data: { data: data2 },
					},
					{
						data: { data: data3 },
					},
				] = await Promise.all([
					(await axios).get(`/api/admin/users/${auth0_user.sub}`),
					(await axios).get(`/api/admin/users/${auth0UserID}`),
					(await axios).get(`/api/admin/roles`),
				]);

				if (!unmounted) {
					console.log(data1);
					setAdmin(data1);

					console.log(data2);
					setUser(data2);

					console.log(data3);
					setRoles(() =>
						data3
							.filter(
								(role: Auth0Role) =>
									!role.name.includes('organization') &&
									!role.name.includes('super-admin')
							)
							.map((role: Auth0Role) => ({
								label: role.description.split(' - ')[1],
								value: role.id,
							}))
					);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, auth0_user, refresh, auth0UserID]);

	const handleClick = (
		property: string,
		value: string,
		label: string,
		type: 'text' | 'multiple_select',
		selectOptions?: Option[],
		selectLabel?: string
	) => {
		setAttribute({
			property,
			value,
			label,
			type,
			selectOptions,
			selectLabel,
		});
		setModalVisible(true);
	};

	return (
		<Fragment>
			<EditDataModal
				data={user}
				attribute={attribute}
				setRefresh={setRefresh}
				heading="Edit user info"
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
				endpoint={`/api/admin/users/${user?.user_id}`}
			/>
			<Container>
				<ContentWrapper Icon="UserIcon" heading="User details">
					{!(admin && user && roles) ? (
						<LoadingBox />
					) : (
						<Fragment>
							<ContentSection heading="Personal Info">
								<DescriptionList>
									<DescriptionItem
										term="First Name"
										details={
											'user_metadata' in user &&
											user.user_metadata['first_name']
												? user.user_metadata[
														'first_name'
												  ]
												: ''
										}
										withFunction
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'first_name',
												'user_metadata' in user &&
													user.user_metadata[
														'first_name'
													]
													? user.user_metadata[
															'first_name'
													  ]
													: '',
												'First Name',
												'text'
											)
										}
									/>
									<DescriptionItem
										term="Last Name"
										details={
											'user_metadata' in user &&
											user.user_metadata['last_name']
												? user.user_metadata[
														'last_name'
												  ]
												: ''
										}
										withFunction
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'last_name',
												'user_metadata' in user &&
													user.user_metadata[
														'last_name'
													]
													? user.user_metadata[
															'last_name'
													  ]
													: '',
												'Last Name',
												'text'
											)
										}
									/>
									<div className="sm:col-span-1"></div>
									<DescriptionItem
										term="Email"
										details={user.email}
									/>
									<DescriptionItem
										term="Email verified"
										details={
											user.email_verified ? 'Yes' : 'No'
										}
									/>
									<div className="sm:col-span-1"></div>
									<DescriptionItem
										customColSpan="sm:col-span-3"
										withFunction={
											auth0UserID !== auth0_user?.sub &&
											!user.roles.find(
												(role) =>
													role.name ===
													'BITSS_super-admin'
											) &&
											(isAdmin || isSuperAdmin)
										}
										term="Roles"
										details={(() => {
											return user.roles
												.map((role: Auth0Role) => {
													const [_, x_role] =
														role.name.split('_');

													return capitalize(
														x_role.replace(
															/-/g,
															' '
														)
													);
												})
												.join(', ');
										})()}
										Icon="PencilIcon"
										handleClick={() =>
											handleClick(
												'roles',
												(() => {
													return user.roles
														.map(
															(role: Auth0Role) =>
																role.id
														)
														.join(', ');
												})(),
												'Roles',
												'multiple_select',
												(() => {
													if (isSuperAdmin) {
														if (roles)
															return [
																{
																	label: 'Select option(s)',
																	value: '',
																},
																...roles,
															];
													}

													if (admin)
														return admin.roles
															.filter(
																(
																	role: Auth0Role
																) =>
																	role.name.includes(
																		'BITSS'
																	) &&
																	!role.name.includes(
																		'admin'
																	)
															)
															.map(
																(
																	role: Auth0Role
																) => ({
																	label: role.description.split(
																		' - '
																	)[1],
																	value: role.id,
																})
															);

													return [
														{
															label: 'No option(s) available',
															value: '',
														},
													];
												})(),
												(() => {
													return user.roles
														.map(
															(role: Auth0Role) =>
																capitalize(
																	role.name
																		.split(
																			'_'
																		)[1]
																		.replace(
																			/-/g,
																			' '
																		)
																)
														)
														.join(', ');
												})()
											)
										}
									/>
								</DescriptionList>
							</ContentSection>

							<ContentSectionDivider />

							<ContentSection heading="Metadata">
								<DescriptionList>
									<DescriptionItem
										term="Last login"
										details={
											user.last_login
												? new Date(
														user.last_login
												  ).toLocaleString()
												: 'N/A'
										}
									/>
									<div className="sm:col-span-1"></div>
									<DescriptionItem
										term="Profile created at"
										details={new Date(
											user.created_at
										).toLocaleString()}
									/>
									<DescriptionItem
										term="Profile updated at"
										details={new Date(
											user.updated_at
										).toLocaleString()}
									/>
								</DescriptionList>
							</ContentSection>
						</Fragment>
					)}
				</ContentWrapper>
			</Container>
		</Fragment>
	);
}
