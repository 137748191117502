import toast from 'react-hot-toast';
import { Disclosure } from '@headlessui/react';
import {
	Fragment,
	useState,
	Dispatch,
	ChangeEvent,
	SetStateAction,
	MouseEventHandler,
} from 'react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';

import {
	Combobox,
	DateField,
	FormGroup,
	SelectField,
	GenericField,
	ContentSection,
	SearchFiltersForm,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	ASSAYS,
	SHARED,
	DateRange,
	Pagination,
	ORGANIZATIONS,
	getSampleStatuses,
	TableSearchFunctions,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import { useCustomers, useOrganizations } from '../../../../utils/hooks/index';

interface FormValues {
	'customer._id'?: string;
	'sample.status'?: string;
	'organization._id'?: string;
	'sample.priority'?: string;
	'sample.ruo'?: string;
	'sample.pangea_id'?: string;
	'sample.specimen_id'?: string;
	'sample.collection_kit_id'?: string;
	'sample.misc.added_fields.first_name'?: string;
	'sample.misc.added_fields.last_name'?: string;
	'sample.misc.added_fields.date_of_birth'?: string;
	'sample.sample_received_date'?: string | DateRange;
	[key: string]: string | string[] | undefined | DateRange;
	'patient.first_name'?: string;
	'patient.last_name'?: string;
	'patient.date_of_birth'?: string;
	'patient.medical_record_number'?: string;
	'sample.sample_collection_date'?: string | DateRange;
	'sample.assay'?: string;
	'sample.sample_specimen_type'?: string;
	'requisition_form.billing_verified'?: string;
	'sample.accessioning_approval.flag.selected_well.rack_name'?: string;
	'sample.accessioning_approval.flag.selected_well.location'?: string;
	'sample.accessioning_approval.hold.selected_well.rack_name'?: string;
	'sample.accessioning_approval.hold.selected_well.location'?: string;
}

const initialFormValues = {
	'sample.ruo': '',
	'sample.status': '',
	'customer._id': '',
	'organization._id': '',
	'sample.priority': '',
	'sample.pangea_id': '',
	'sample.specimen_id': '',
	'sample.collection_kit_id': '',
	'sample.misc.added_fields.first_name': '',
	'sample.misc.added_fields.last_name': '',
	'sample.misc.added_fields.date_of_birth': '',
	'sample.sample_received_date': '',
	'sample.sample_collection_date': '',
	'patient.first_name': '',
	'patient.last_name': '',
	'patient.date_of_birth': '',
	'patient.medical_record_number': '',
	'sample.assay': '',
	'sample.sample_specimen_type': '',
	'requisition_form.billing_verified': '',
	'sample.accessioning_approval.flag.selected_well.rack_name': '',
	'sample.accessioning_approval.flag.selected_well.location': '',
	'sample.accessioning_approval.hold.selected_well.rack_name': '',
	'sample.accessioning_approval.hold.selected_well.location': '',
};

const initialDateRangesDisplayValues = {
	'sample.sample_received_date': false,
	'sample.sample_collection_date': false,
};

const dateRangeProperties = [
	'sample.sample_received_date',
	'sample.sample_collection_date',
];

interface TableSearchFiltersFormProps {
	disabled: boolean;
	setQuery: Dispatch<SetStateAction<FormValues>>;
	setPagination: Dispatch<SetStateAction<Pagination>>;
	tableType?:
		| 'flag'
		| 'hold'
		| 'reviewed'
		| 'reject'
		| 'overdue'
		| 'past-stability'
		| 'pending-review';
}

export default function TableSearchFiltersForm({
	setQuery,
	disabled,
	tableType,
	setPagination,
}: TableSearchFiltersFormProps) {
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

	// For search filters
	const customers = useCustomers();
	const organizations = useOrganizations();
	const [dateRangesDisplay, setDateRangesDisplay] = useState<{
		[key: string]: boolean;
	}>(initialDateRangesDisplayValues);

	const handleChange = (event: ChangeEvent) =>
		TableSearchFunctions.handleChange(
			event,
			setFormValues,
			dateRangeProperties,
			dateRangesDisplay
		);

	const handleChangeDateDisplay = (propName: string) =>
		TableSearchFunctions.handleChangeDateDisplay(
			propName,
			setDateRangesDisplay,
			setFormValues
		);

	const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (
		event
	) => {
		event.preventDefault();

		if (!TableSearchFunctions.hasAtLeastOneNonNullValue(formValues)) {
			toast.error('Provide a value for at least one filter criterion.');
			return;
		}

		const normalizedFormValues =
			TableSearchFunctions.normalizeFormValues(formValues);

		setQuery(normalizedFormValues);
		setPagination((prevValue) => ({
			...prevValue,
			page_number: 1,
		}));
	};

	const handleCustomerSelect = (customerId: string) => {
		if (customers) {
			const customer = customers.filter(
				(customer) => customer.value === customerId
			)[0];

			if (customer) {
				setFormValues((prevValue) => ({
					...prevValue,
					'customer._id': customer.value,
				}));
			}
		}
	};

	const handleOrganizationSelect = (organizationId: string) => {
		if (organizations) {
			const organization = organizations.filter(
				(organization) => organization.value === organizationId
			)[0];

			if (organization) {
				setFormValues((prevValue) => ({
					...prevValue,
					'organization._id': organization.value,
				}));
			}
		}
	};

	return (
		<SearchFiltersForm
			disabled={disabled}
			handleSubmit={handleSubmit}
			clearFilters={() => {
				setQuery({});
				setFormValues(initialFormValues);
				setPagination(initialPaginationValues);
				setDateRangesDisplay(initialDateRangesDisplayValues);
			}}
		>
			<FormGroup>
				{!tableType && (
					<Fragment>
						<div className="sm:col-span-2">
							<SelectField
								name="sample.status"
								label="Status"
								handleSelect={handleChange}
								value={formValues['sample.status'] || ''}
								options={[
									{
										label: 'Select an Option',
										value: '',
									},
									...getSampleStatuses(),
								]}
							/>
						</div>
						<div className="sm:col-span-4" />
					</Fragment>
				)}
				<div className="sm:col-span-2">
					<SelectField
						name="sample.assay"
						label="Assay"
						handleSelect={handleChange}
						value={formValues['sample.assay'] || ''}
						options={[
							{
								label: 'Select an Option',
								value: '',
							},
							...ASSAYS['assays'],
						]}
					/>
				</div>
				<div className="sm:col-span-4" />
				<div className="sm:col-span-2">
					<Combobox
						name="customer._id"
						label="Customer"
						value={formValues['customer._id'] || ''}
						handleSelect={handleCustomerSelect}
						options={customers || SHARED.no_options}
					/>
				</div>
				<div className="sm:col-span-2">
					<Combobox
						name="organization._id"
						label="Organization"
						value={formValues['organization._id'] || ''}
						handleSelect={handleOrganizationSelect}
						options={organizations || SHARED.no_options}
					/>
				</div>
				<div className="sm:col-span-2"></div>
				<div className="sm:col-span-2">
					<SelectField
						name="sample.priority"
						label="Priority level"
						handleSelect={handleChange}
						value={formValues['sample.priority'] || ''}
						options={ORGANIZATIONS.priorityLevels}
					/>
				</div>
				<div className="sm:col-span-2">
					<SelectField
						name="sample.ruo"
						label="RUO"
						handleSelect={handleChange}
						value={formValues['sample.ruo'] || ''}
						options={FORMS.yes_no_options}
					/>
				</div>
				<div className="sm:col-span-2">
					<GenericField
						type="text"
						label="Specimen Type"
						name="sample.sample_specimen_type"
						placeholder="e.g., XZ12345678"
						handleInputChange={handleChange}
						value={formValues['sample.sample_specimen_type'] || ''}
					/>
				</div>
				<div className="sm:col-span-2">
					<DateField
						label="Received Date"
						range={dateRangesDisplay['sample.sample_received_date']}
						handleChangeDateDisplay={handleChangeDateDisplay}
						name="sample.sample_received_date"
						handleInputChange={handleChange}
						value={formValues['sample.sample_received_date'] || ''}
					/>
				</div>
				<div className="sm:col-span-2">
					<DateField
						label="Collection Date"
						range={
							dateRangesDisplay['sample.sample_collection_date']
						}
						handleChangeDateDisplay={handleChangeDateDisplay}
						name="sample.sample_collection_date"
						handleInputChange={handleChange}
						value={
							formValues['sample.sample_collection_date'] || ''
						}
					/>
				</div>
				<div className="sm:col-span-1" />
				<div className="sm:col-span-2">
					<GenericField
						type="text"
						label="Pangea ID"
						name="sample.pangea_id"
						placeholder="e.g., XZ12345678"
						handleInputChange={handleChange}
						value={formValues['sample.pangea_id'] || ''}
					/>
				</div>
				<div className="sm:col-span-2">
					<GenericField
						type="text"
						label="Specimen ID"
						name="sample.specimen_id"
						placeholder="e.g., XZ12345678"
						handleInputChange={handleChange}
						value={formValues['sample.specimen_id'] || ''}
					/>
				</div>
				<div className="sm:col-span-2">
					<GenericField
						type="text"
						label="Collection Kit ID"
						name="sample.collection_kit_id"
						placeholder="e.g., XZ12345678"
						handleInputChange={handleChange}
						value={formValues['sample.collection_kit_id'] || ''}
					/>
				</div>
				<div className="sm:col-span-2">
					<GenericField
						type="text"
						label="Patient First Name"
						name="patient.first_name"
						placeholder="e.g., Arthur"
						handleInputChange={handleChange}
						value={formValues['patient.first_name'] || ''}
					/>
				</div>
				<div className="sm:col-span-2">
					<GenericField
						type="text"
						label="Patient Last Name"
						name="patient.last_name"
						placeholder="e.g., Manning"
						handleInputChange={handleChange}
						value={formValues['patient.last_name'] || ''}
					/>
				</div>
				<div className="sm:col-span-2">
					<DateField
						label="Patient Date of Birth"
						name="patient.date_of_birth"
						handleInputChange={handleChange}
						value={formValues['patient.date_of_birth'] || ''}
					/>
				</div>
				<div className="sm:col-span-2">
					<SelectField
						name="requisition_form.billing_verified"
						label="Billing verified?"
						handleSelect={handleChange}
						value={
							formValues['requisition_form.billing_verified'] ||
							''
						}
						options={FORMS.yes_no_options}
					/>
				</div>
			</FormGroup>
			{tableType === 'flag' && (
				<FormGroup heading="Flag Rack Filters">
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Rack Name"
							name="sample.accessioning_approval.flag.selected_well.rack_name"
							placeholder="e.g., Flag Rack #2"
							handleInputChange={handleChange}
							value={
								formValues[
									'sample.accessioning_approval.flag.selected_well.rack_name'
								] || ''
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Well"
							name="sample.accessioning_approval.flag.selected_well.location"
							placeholder="e.g., B2"
							handleInputChange={handleChange}
							value={
								formValues[
									'sample.accessioning_approval.flag.selected_well.location'
								] || ''
							}
						/>
					</div>
				</FormGroup>
			)}
			{tableType === 'hold' && (
				<FormGroup heading="Hold Rack Filters">
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Rack Name"
							name="sample.accessioning_approval.hold.selected_well.rack_name"
							placeholder="e.g., Hold Rack #2"
							handleInputChange={handleChange}
							value={
								formValues[
									'sample.accessioning_approval.hold.selected_well.rack_name'
								] || ''
							}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Well"
							name="sample.accessioning_approval.hold.selected_well.location"
							placeholder="e.g., B2"
							handleInputChange={handleChange}
							value={
								formValues[
									'sample.accessioning_approval.hold.selected_well.location'
								] || ''
							}
						/>
					</div>
				</FormGroup>
			)}
			<Disclosure>
				{({ open }) => (
					<div>
						<Disclosure.Button
							className={`${
								open
									? 'rounded-t-md border-l border-r border-t'
									: 'rounded-md border'
							} bg-gray-100 w-full text-left text-sm border-gray-200 px-4 py-2 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75`}
						>
							<div className="flex justify-between w-full">
								<p className="text-gray-900 font-medium">
									Additional searchable fields for non-clinic
									samples
								</p>
								<ChevronUpIcon
									className={`${
										open ? 'rotate-180 transform' : ''
									} h-5 w-5 text-gray-500`}
								/>
							</div>
							<p className="mt-1 text-xs">
								These are data points provided by organizations
								that aren't required for accessioning or
								processing the sample.
							</p>
						</Disclosure.Button>
						<Disclosure.Panel>
							<ContentSection className="border rounded-b-lg border-gray-200 shadow-sm bg-gray-100 py-4 px-4 sm:!px-4 lg:!px-4">
								<FormGroup>
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											label="First Name"
											name="sample.misc.added_fields.first_name"
											placeholder="e.g., Arthur"
											handleInputChange={handleChange}
											value={
												formValues[
													'sample.misc.added_fields.first_name'
												] || ''
											}
										/>
									</div>
									<div className="sm:col-span-2">
										<GenericField
											type="text"
											label="Last Name"
											name="sample.misc.added_fields.last_name"
											placeholder="e.g., Manning"
											handleInputChange={handleChange}
											value={
												formValues[
													'sample.misc.added_fields.last_name'
												] || ''
											}
										/>
									</div>
									<div className="sm:col-span-2">
										<DateField
											label="Date of Birth"
											name="sample.misc.added_fields.date_of_birth"
											handleInputChange={handleChange}
											value={
												formValues[
													'sample.misc.added_fields.date_of_birth'
												] || ''
											}
										/>
									</div>
								</FormGroup>
							</ContentSection>
						</Disclosure.Panel>
					</div>
				)}
			</Disclosure>
		</SearchFiltersForm>
	);
}
