import { Routes, Route } from 'react-router';

import { FORM_STEPS } from './steps';
import ProcessTRFComponents from '../../components/index';

import ProviderOrganizationInfo from './provider-organization-info';
import OrderSampleInfo from './order-sample-info';
import PatientInfo from './patient-info';
import RelevantClinicalInfo from './relevant-clinical-info';
import BillingInfo from './billing-info';
import MiscAttachments from './misc-attachments';
import Review from './review';

export default function V4_1_ROUTES() {
	return (
		<Routes>
			<Route
				path="/data/:dataId/trf"
				element={
					<ProcessTRFComponents.FormWrapper
						version="4.1"
						assay="bladdercare"
						steps={FORM_STEPS}
					/>
				}
			>
				<Route
					path="provider-organization-info"
					element={<ProviderOrganizationInfo />}
				/>
				<Route path="order-sample-info" element={<OrderSampleInfo />} />
				<Route path="patient-info" element={<PatientInfo />} />
				<Route
					path="relevant-clinical-info"
					element={<RelevantClinicalInfo />}
				/>
				<Route path="billing-info" element={<BillingInfo />} />
				<Route path="misc-attachments" element={<MiscAttachments />} />
				<Route path="review" element={<Review />} />
			</Route>
		</Routes>
	);
}
