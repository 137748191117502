/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import {
	useId,
	useState,
	Dispatch,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Modal,
	Alert,
	Button,
	useAxios,
	FormGroup,
	FormFooter,
	NumberField,
	GenericField,
} from '@pangea-lis-apps/ui';

interface CreateRackModalProps {
	rackType: 'hold' | 'flag' | string;
	setRefresh: Dispatch<SetStateAction<boolean>>;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

const initialFormValues = {
	rows: 1,
	name: '',
	columns: 1,
};

export default function CreateRackModal(props: CreateRackModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { visible, setVisible } = props.visible;
	const [disabled, setDisabled] = useState(false);
	const [formValues, setFormValues] = useState(initialFormValues);

	const handleCreateRack = async () => {
		if (disabled || !axios) return;

		setDisabled(true);

		toast.loading('Creating...', toastOptions);

		try {
			await (
				await axios
			).post(`/api/accessioner/racks`, {
				...formValues,
				type: props.rackType,
			});

			toast.dismiss();

			handleClose();
			props.setRefresh((value) => !value);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<
		HTMLSelectElement | HTMLInputElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLSelectElement | HTMLInputElement;

		if (target && target.name)
			setFormValues((prevValue) => ({
				...prevValue,
				[target.name]: target.value,
			}));
	};

	const handleClose = () => {
		setVisible(false);
		setDisabled(false);
		setFormValues(initialFormValues);
	};

	return (
		<Modal
			visible={visible}
			onClose={handleClose}
			customWidth="max-w-md"
			title={`Create new ${props.rackType} rack`}
			description="The name of the rack should be unique."
		>
			<Alert
				type="info"
				className="mb-4"
				heading="Attention needed"
				description={
					<span>
						Names are <span className="italic">case sensitive</span>{' '}
						and dimensions <span className="italic">cannot</span> be
						changed once a rack has been created.
					</span>
				}
			/>
			<Form>
				<FormGroup>
					<div className="sm:col-span-6">
						<GenericField
							required
							type="text"
							name="name"
							label="Rack name"
							value={formValues.name}
							handleInputChange={handleChange}
							placeholder="e.g., Bladder CARE Rack"
						/>
					</div>
					<div className="sm:col-span-3">
						<NumberField
							min={1}
							max={12}
							required
							name="rows"
							label="Rows"
							value={formValues.rows}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-3">
						<NumberField
							min={1}
							max={12}
							required
							name="columns"
							label="Columns"
							value={formValues.columns}
							handleInputChange={handleChange}
						/>
					</div>
				</FormGroup>
				<FormFooter>
					<Button
						type="button"
						text="Create"
						Icon="PlusIcon"
						tier="tertiary"
						disabled={disabled}
						onClick={handleCreateRack}
					/>
				</FormFooter>
			</Form>
		</Modal>
	);
}
