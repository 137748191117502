import {
	formatDate,
	DataReference,
	getLabelFromValue,
	getSampleStatuses,
} from '@pangea-lis-apps/utils';

type HandleSelectWell = (occupiedStatus: number, index: number) => void;

interface WellProps {
	index: number;
	wellLabel: string;
	occupiedStatus: number;
	dataReference: DataReference;
	handleSelectWell?: HandleSelectWell; // handleSelectWell only exists in the accessioning flow when marking samples as flagged
}

export default function Well({
	index,
	wellLabel,
	occupiedStatus,
	dataReference,
	handleSelectWell,
}: WellProps) {
	const { divStyles, textStyles } = determineCustomStyles(
		occupiedStatus,
		handleSelectWell
	);

	return (
		<div
			onClick={() => {
				if (handleSelectWell)
					handleSelectWell(occupiedStatus, index + 1);
			}}
			className={`h-40 p-2 rounded-lg text-xs font-medium ${divStyles}`}
		>
			<p className={textStyles}>
				{wellLabel}
				{dataReference
					? ' - ' +
					  getLabelFromValue(
							dataReference.status,
							getSampleStatuses()
					  )
					: ''}
			</p>
			{dataReference && (
				<div>
					<hr className="mt-1 mb-2" />
					<p className={textStyles}>
						PID - {dataReference.pangea_id || 'N/A'}
					</p>
					<p className={textStyles}>
						SID - {dataReference.specimen_id || 'N/A'}
					</p>
					<p className={textStyles}>
						CD -{' '}
						{dataReference.collection_date
							? formatDate(dataReference.collection_date)
							: 'N/A'}
					</p>
					<p className={textStyles}>
						PN -{' '}
						{dataReference.patient_first_name ||
						dataReference.patient_last_name
							? dataReference.patient_first_name.concat(
									' ',
									dataReference.patient_last_name
							  )
							: 'N/A'}
					</p>
					<p className={textStyles}>
						DOB -{' '}
						{dataReference.patient_date_of_birth
							? formatDate(dataReference.patient_date_of_birth)
							: 'N/A'}
					</p>
				</div>
			)}
		</div>
	);
}

const determineCustomStyles = (
	occupiedStatus: number,
	handleSelectWell?: HandleSelectWell
) => {
	// handleSelectWell only exists in the accessioning flow when flagging samples
	// styles are different in the accessioning flow and in the rack view of the flagged samples page, nothing should be colored or selectable in the latter

	let divStyles = '',
		textStyles = '';

	if (handleSelectWell) {
		// div styles
		if (occupiedStatus === 1)
			divStyles =
				'bg-emerald-600 border-b border-emerald-700 text-white cursor-not-allowed shadow-inner shadow-sm';
		else if (occupiedStatus === 0)
			divStyles =
				'bg-white shadow-inner hover:ring hover:ring-blue-200 hover:bg-blue-100 hover:border-blue-600 border border-gray-300 cursor-pointer group';
		else
			divStyles =
				'bg-blue-600 cursor-pointer text-white hover:ring hover:ring-blue-200';

		// text styles
		if (occupiedStatus === 1 || occupiedStatus === 2)
			textStyles = 'text-white';
		else textStyles = 'text-gray-400 group-hover:text-blue-600';
	} else {
		if (occupiedStatus === 1) {
			divStyles =
				'bg-orange-600 border-b border-orange-700 shadow-inner shadow-sm';
		} else divStyles = 'bg-white border border-gray-300 shadow-inner';

		if (occupiedStatus === 1) textStyles = 'text-white';
		else textStyles = 'text-gray-500';
	}

	return {
		divStyles,
		textStyles,
	};
};
