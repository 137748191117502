import toast from 'react-hot-toast';
import { useId, useState, useEffect } from 'react';

import {
	useAxios,
	Container,
	ContentWrapper,
	ContentSection,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	ClinicData,
	Pagination,
	NonClinicData,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import RejectedSamplesTable from '../../../components/tables/rejected-samples';
import TableSearchFiltersForm from '../../../components/tables/accessioning/table-search-filters-form';

const projectedFields = [
	'_id',
	'type',
	'sample.ruo',
	'sample.assay',
	'sample.priority',
	'customer.first_name',
	'customer.last_name',
	'organization.name',
	'sample.pangea_id',
	'sample.specimen_id',
	'sample.collection_kit_id',
	'patient.first_name',
	'patient.last_name',
	'patient.date_of_birth',
	'requisition_form.sample_collection_date',
	'sample.sample_collection_date',
	'sample.accessioning_approval.reject.reason.value',
];

/* eslint-disable-next-line */
export interface ViewRejectedSamplesProps {}

export default function ViewRejectedSamples(props: ViewRejectedSamplesProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [query, setQuery] = useState({});
	const [data, setData] = useState<
		(ClinicData | NonClinicData)[] | undefined
	>(undefined);
	const [disabled, setDisabled] = useState(false);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setDisabled(true);
				setData(undefined);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/accessioner/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query: {
							'sample.status': 'reject',
							...query,
						},
						projected_fields: projectedFields,
					}
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setData(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);

			toast.dismiss();
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	return (
		<Container>
			<ContentWrapper
				Icon="XCircleIcon"
				heading="Rejected Samples"
				description="Samples that have been rejected for unresolvable discrepancies on requisition forms or samples."
			>
				<TableSearchFiltersForm
					tableType="reject"
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection heading="Rejected samples">
					<RejectedSamplesTable
						data={data}
						query={{ query, setQuery }}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
