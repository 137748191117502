import { Fragment } from 'react';

import {
	FormErrors,
	ContentSection,
	DescriptionList,
	DescriptionItem,
} from '@pangea-lis-apps/ui';
import { FieldProps, REQUISITION_FORM } from '@pangea-lis-apps/utils';

export const AccessioningStatus = ({ data }: FieldProps) => {
	const formErrorLabels =
		REQUISITION_FORM[data.sample.assay]['shared']['form_error_labels'];

	return (
		<ContentSection heading="Accessioning status">
			<DescriptionList>
				<DescriptionItem
					term="Completed?"
					details={data.metadata.accessioning.completed.value}
				/>
				{data.metadata.accessioning.completed.value === false && (
					<Fragment>
						<div className="sm:col-span-2"></div>
						<DescriptionItem
							customColSpan="sm:col-span-6"
							term="Reason(s) for Incompletion"
						>
							{data.metadata.accessioning.completion_errors
								.length ? (
								<FormErrors
									data={
										data.metadata.accessioning
											.completion_errors
									}
									errorLabels={formErrorLabels}
								/>
							) : (
								'None'
							)}
						</DescriptionItem>
						<DescriptionItem
							term="Flagged Field(s)"
							customColSpan="sm:col-span-6"
						>
							{data.metadata.accessioning.flagged_fields
								.length ? (
								<FormErrors
									data={
										data.metadata.accessioning
											.flagged_fields
									}
									errorLabels={formErrorLabels}
								/>
							) : (
								'None'
							)}
						</DescriptionItem>
					</Fragment>
				)}
			</DescriptionList>
		</ContentSection>
	);
};
