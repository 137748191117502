import {
	Dispatch,
	useState,
	useEffect,
	FormEvent,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Modal,
	Button,
	FormGroup,
	FormFooter,
	SelectField,
	GenericField,
} from '@pangea-lis-apps/ui';
import { FORMS, Option, CustomerBillingMethod } from '@pangea-lis-apps/utils';

interface BillingModalState {
	updateId: string;
	visible: boolean;
	action: 'create' | 'update' | string;
}

const initialBillingModalState = {
	updateId: '',
	visible: false,
	action: 'create',
};

export interface BillingMethodFormValues {
	id: string;
	client_name: string;
	client_institution: string;
	client_address: string;
	client_city: string;
	client_state: string;
	client_zip_code: string;
	client_country: string;
	client_phone_number: string;
	client_fax_number: string;
	client_email_address: string;
	billing_contact_name: string;
	billing_contact_institution: string;
	billing_contact_address: string;
	billing_contact_city: string;
	billing_contact_state: string;
	billing_contact_zip_code: string;
	billing_contact_country: string;
	billing_contact_phone_number: string;
	billing_contact_fax_number: string;
	billing_contact_email_address: string;
}

const initialBillingMethodFormValues = {
	id: '',
	client_name: '',
	client_institution: '',
	client_address: '',
	client_city: '',
	client_state: '',
	client_zip_code: '',
	client_country: '',
	client_phone_number: '',
	client_fax_number: '',
	client_email_address: '',
	billing_contact_name: '',
	billing_contact_institution: '',
	billing_contact_address: '',
	billing_contact_city: '',
	billing_contact_state: '',
	billing_contact_zip_code: '',
	billing_contact_country: '',
	billing_contact_phone_number: '',
	billing_contact_fax_number: '',
	billing_contact_email_address: '',
};

interface AddBillingMethodModalProps {
	modalState: BillingModalState;
	billingMethods: (CustomerBillingMethod | BillingMethodFormValues)[];
	setModalState: Dispatch<SetStateAction<BillingModalState>>;
	handleSubmit: (
		event: FormEvent<Element>,
		formValues: BillingMethodFormValues
	) => Promise<void>;
}

export function AddBillingMethodModal(props: AddBillingMethodModalProps) {
	const { visible, action, updateId } = props.modalState;
	const [formValues, setFormValues] = useState<BillingMethodFormValues>(
		initialBillingMethodFormValues
	);

	useEffect(() => {
		if (visible && action && action === 'update') {
			const billing_method = props.billingMethods.filter((method) => {
				if ('id' in method) return method.id === updateId;

				return method._id.$oid === updateId;
			})[0];

			setFormValues({
				id:
					'id' in billing_method
						? billing_method.id
						: billing_method._id.$oid,
				client_name: billing_method.client_name,
				client_institution: billing_method.client_institution,
				client_address: billing_method.client_address,
				client_city: billing_method.client_city,
				client_state: billing_method.client_state,
				client_zip_code: billing_method.client_zip_code,
				client_country: billing_method.client_country,
				client_phone_number: billing_method.client_phone_number,
				client_fax_number: billing_method.client_fax_number,
				client_email_address: billing_method.client_email_address,
				billing_contact_name: billing_method.billing_contact_name,
				billing_contact_institution:
					billing_method.billing_contact_institution,
				billing_contact_address: billing_method.billing_contact_address,
				billing_contact_city: billing_method.billing_contact_city,
				billing_contact_state: billing_method.billing_contact_state,
				billing_contact_zip_code:
					billing_method.billing_contact_zip_code,
				billing_contact_country: billing_method.billing_contact_country,
				billing_contact_phone_number:
					billing_method.billing_contact_phone_number,
				billing_contact_fax_number:
					billing_method.billing_contact_fax_number,
				billing_contact_email_address:
					billing_method.billing_contact_email_address,
			});
		}
	}, [visible]);

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLSelectElement
	> = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setFormValues((prevValues) => ({
				...prevValues,
				[target.name]: target.value,
			}));
		}
	};

	const handleSubmit = async (event: FormEvent) => {
		await props.handleSubmit(event, formValues);

		setFormValues(initialBillingMethodFormValues);
		handleClose();
	};

	const handleClose = () => {
		props.setModalState({ ...initialBillingModalState });
	};

	const [stateSelection, setStateSelection] = useState({
		client_state: '',
		billing_contact_state: '',
	});

	useEffect(() => {
		if (visible && formValues) {
			if (!stateSelection.client_state) {
				if (
					formValues['client_state'] !== '' &&
					!FORMS.states
						.map((option: Option) => option.value)
						.includes(formValues.client_state)
				) {
					setStateSelection((prevVal) => ({
						...prevVal,
						client_state: 'other',
					}));
				} else {
					setStateSelection((prevVal) => ({
						...prevVal,
						client_state: formValues.client_state,
					}));
				}
			}

			if (!stateSelection.billing_contact_state) {
				if (
					formValues['billing_contact_state'] !== '' &&
					!FORMS.states
						.map((option: Option) => option.value)
						.includes(formValues.billing_contact_state)
				) {
					setStateSelection((prevVal) => ({
						...prevVal,
						billing_contact_state: 'other',
					}));
				} else {
					setStateSelection((prevVal) => ({
						...prevVal,
						billing_contact_state: formValues.billing_contact_state,
					}));
				}
			}
		}
	}, [
		visible,
		formValues,
		stateSelection.client_state,
		stateSelection.billing_contact_state,
	]);

	const handleStateSelectionChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setStateSelection((prevValue) => ({
				...prevValue,
				[target.name]: target.value,
			}));

			setFormValues((prevValues: BillingMethodFormValues) => {
				if (prevValues) {
					return {
						...prevValues,
						[target.name]:
							target.value === 'other' ? '' : target.value,
					};
				}

				return prevValues;
			});
		}
	};

	return (
		<Modal
			visible={visible}
			onClose={handleClose}
			customWidth="max-w-5xl"
			title="Add billing method"
		>
			<Form handleSubmit={handleSubmit}>
				<FormGroup heading="Client information">
					<div className="sm:col-span-2">
						<GenericField
							required
							type="text"
							label="Name"
							name="client_name"
							placeholder="e.g., John Smith"
							value={formValues['client_name']}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							required
							type="text"
							label="Institution or Company"
							name="client_institution"
							placeholder="e.g., City of Irvine"
							value={formValues['client_institution']}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2"></div>
					<div className="sm:col-span-2">
						<GenericField
							required
							type="text"
							label="Address"
							name="client_address"
							placeholder="e.g., 1234 Orange Ave."
							value={formValues['client_address']}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-4"></div>
					<div className="sm:col-span-2">
						<GenericField
							required
							type="text"
							label="City"
							showRequiredAsterisk={true}
							name="client_city"
							placeholder="e.g., Irvine"
							value={formValues['client_city']}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2">
						<SelectField
							label="State"
							name="client_state"
							options={FORMS.states}
							showRequiredAsterisk={true}
							handleSelect={handleStateSelectionChange}
							value={stateSelection.client_state}
						/>
					</div>
					{stateSelection.client_state === 'other' ? (
						<div className="sm:col-span-2">
							<GenericField
								required
								type="text"
								label="If other, please specify"
								name="client_state"
								placeholder="e.g., New York"
								value={formValues['client_state']}
								handleInputChange={handleChange}
							/>
						</div>
					) : (
						<div className="sm:col-span-2"></div>
					)}
					<div className="sm:col-span-2">
						<GenericField
							required
							type="text"
							label="ZIP Code"
							showRequiredAsterisk={true}
							name="client_zip_code"
							placeholder="e.g., 92780"
							value={formValues['client_zip_code']}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2">
						<SelectField
							label="Country"
							name="client_country"
							options={FORMS.countries}
							handleSelect={handleChange}
							showRequiredAsterisk={true}
							value={formValues['client_country']}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							required
							type="text"
							label="Phone Number"
							showRequiredAsterisk={true}
							name="client_phone_number"
							placeholder="e.g., 2137893645"
							value={formValues['client_phone_number']}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Fax Number"
							name="client_fax_number"
							placeholder="e.g., 2137893645"
							value={formValues['client_fax_number'] || ''}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Email Address"
							name="client_email_address"
							placeholder="e.g., cityofirvine@gmail.com"
							value={formValues['client_email_address'] || ''}
							handleInputChange={handleChange}
						/>
					</div>
				</FormGroup>
				<FormGroup heading="Client's billing contact information">
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Name"
							required
							name="billing_contact_name"
							placeholder="e.g., Ashley Park"
							value={formValues['billing_contact_name']}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Institution or Company"
							required
							name="billing_contact_institution"
							placeholder="e.g., MedBill Solutions"
							value={formValues['billing_contact_institution']}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2"></div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Address"
							required
							name="billing_contact_address"
							placeholder="e.g., 321 Pine Road"
							value={formValues['billing_contact_address']}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-4"></div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="City"
							required
							name="billing_contact_city"
							placeholder="e.g., Appleville"
							value={formValues['billing_contact_city']}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2">
						<SelectField
							label="State"
							name="billing_contact_state"
							options={FORMS.states}
							showRequiredAsterisk={true}
							handleSelect={handleStateSelectionChange}
							value={stateSelection.billing_contact_state}
						/>
					</div>
					{stateSelection.billing_contact_state === 'other' ? (
						<div className="sm:col-span-2">
							<GenericField
								required
								type="text"
								label="If other, please specify"
								name="billing_contact_state"
								placeholder="e.g., New York"
								value={formValues['billing_contact_state']}
								handleInputChange={handleChange}
							/>
						</div>
					) : (
						<div className="sm:col-span-2"></div>
					)}
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="ZIP Code"
							required
							name="billing_contact_zip_code"
							placeholder="e.g., 29112"
							value={formValues['billing_contact_zip_code']}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2">
						<SelectField
							label="Country"
							name="billing_contact_country"
							options={FORMS.countries}
							handleSelect={handleChange}
							showRequiredAsterisk={true}
							value={formValues['billing_contact_country']}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Phone Number"
							required
							name="billing_contact_phone_number"
							placeholder="e.g., 2137893645"
							value={formValues['billing_contact_phone_number']}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Fax Number"
							name="billing_contact_fax_number"
							placeholder="e.g., 2137893645"
							value={
								formValues['billing_contact_fax_number'] || ''
							}
							handleInputChange={handleChange}
						/>
					</div>
					<div className="sm:col-span-2">
						<GenericField
							type="text"
							label="Email Address"
							name="billing_contact_email_address"
							placeholder="e.g., cityofirvine@gmail.com"
							value={
								formValues['billing_contact_email_address'] ||
								''
							}
							handleInputChange={handleChange}
						/>
					</div>
				</FormGroup>
				<FormFooter>
					{action === 'create' ? (
						<Button
							text="Add"
							type="submit"
							tier="tertiary"
							Icon="PlusIcon"
						/>
					) : (
						<Button
							type="submit"
							text="Update"
							tier="tertiary"
							Icon="CheckIcon"
						/>
					)}
				</FormFooter>
			</Form>
		</Modal>
	);
}
