// import { ChangeEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	Table,
	TableRow,
	DataCell,
	TableBody,
	TableHead,
	HeaderCell,
	EmptyTable,
	TableLoading,
	ArrowLinkButton,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	ASSAYS,
	ClinicData,
	formatDate,
	ORGANIZATIONS,
	PaginationState,
	getLabelFromValue,
} from '@pangea-lis-apps/utils';

interface PendingReportsTableProps {
	// mainCheckbox: boolean;
	pagination: PaginationState;
	// handleSelectRow: (sampleId: string) => void;
	// handleSelectAll: ChangeEventHandler<HTMLInputElement>;
	data: (ClinicData & { checked: boolean })[] | undefined;
}

export default function PendingReportsTable(props: PendingReportsTableProps) {
	const navigate = useNavigate();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Pangea ID</HeaderCell>
				<HeaderCell>Specimen ID</HeaderCell>
				<HeaderCell>Collection Kit ID</HeaderCell>
				<HeaderCell>Priority Level</HeaderCell>
				<HeaderCell>Accessioning completed?</HeaderCell>
				<HeaderCell>Customer</HeaderCell>
				<HeaderCell>Assay</HeaderCell>
				<HeaderCell>First Name</HeaderCell>
				<HeaderCell>Last Name</HeaderCell>
				<HeaderCell>Collection Date</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => {
							return (
								<TableRow key={index} index={index}>
									<DataCell>
										<ArrowLinkButton
											text={datum.sample.pangea_id}
											onClick={() =>
												navigate(
													`/cls/view/samples/${datum._id.$oid}`
												)
											}
										/>
									</DataCell>
									<DataCell>
										{datum.sample.specimen_id}
									</DataCell>
									<DataCell>
										{datum.sample.collection_kit_id}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.priority,
											ORGANIZATIONS.priorityLevels
										)}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.metadata.accessioning.completed.value.toString(),
											FORMS.yes_no_options
										)}
									</DataCell>
									<DataCell>
										{`${datum.customer.first_name} ${datum.customer.last_name} - ${datum.organization.name}`}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.assay,
											ASSAYS['assays']
										)}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.first_name
											: null}
									</DataCell>
									<DataCell>
										{datum.patient
											? datum.patient.last_name
											: null}
									</DataCell>
									<DataCell>
										{formatDate(
											datum.sample.sample_collection_date
										)}
									</DataCell>
								</TableRow>
							);
						})
					) : (
						<EmptyTable
							heading="No samples"
							Icon="FingerPrintIcon"
							description="Please wait for reports to be created"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
